import sidebarTrialPromoImage from 'img/sidebarTrialPromoImage.png';
import sidebarTrialPromoImageDark from 'img/sidebarTrialPromoImageDark.png';
import { IS_AUTO_TEST_HIDE, IS_FAMILY_USER, PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isDarkTheme, sidebarTrialPromo } from 'reactApp/appHelpers/featuresHelpers';
import { loadProductsAndOpenFastCheckout } from 'reactApp/modules/payment/payment.module';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { sendDwh } from 'reactApp/utils/ga';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const SIDEBAR_TRIAL_PROMO_ID = 'sidebar-trial-promo';
const SIDEBAR_TRIAL_PROMO_SHOW_COUNT = `${SIDEBAR_TRIAL_PROMO_ID}-count`;

function markPromoShown() {
    reduxStore.dispatch(promoShown(EPromoType.sidebarTrialPromo));
    storeHelper.markAsShown(SIDEBAR_TRIAL_PROMO_ID);
    reduxStore.dispatch(removePromo(EPromoType.sidebarTrialPromo));
}

export function* initSidebarTrialPromoModal() {
    const storage = yield* select(getCurrentStorage);
    const isCorpUser = yield* select(UserSelectors.isCorpUser);
    const isBizUser = yield* select(UserSelectors.isBizUser);
    const isPaidUser = yield* select(UserSelectors.isPaidUser);
    const shownCount = yield put(UserStorageActions.get(SIDEBAR_TRIAL_PROMO_SHOW_COUNT)) || 0;

    const { isHome } = getStorage(storage);

    // Показываем промо сначала 1 месяц раз в неделю, далее 1 раз в 2 недели
    const periodInDays = shownCount < 5 ? 7 : 14;
    const periodPassed =
        storeHelper.getValue(SIDEBAR_TRIAL_PROMO_ID) && storeHelper.isPassed(SIDEBAR_TRIAL_PROMO_ID, { daysCount: periodInDays });

    if (periodPassed) {
        storeHelper.clearStore(SIDEBAR_TRIAL_PROMO_ID);
    }

    if (
        (sidebarTrialPromo !== 'c' && sidebarTrialPromo !== 'd') ||
        storeHelper.getValue(SIDEBAR_TRIAL_PROMO_ID) ||
        IS_AUTO_TEST_HIDE ||
        !isHome ||
        isCorpUser ||
        isBizUser ||
        isPaidUser ||
        IS_FAMILY_USER
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.sidebarTrialPromo,
            promoId: SIDEBAR_TRIAL_PROMO_ID,
            onClick: () => {
                markPromoShown();
                emitAnalyticEvent(AnalyticEventNames.SIDEBAR_TRIAL_PROMO_CLICK);
                sendDwh({ eventCategory: 'sidebar-trial-promo-click' });
                reduxStore.dispatch(
                    loadProductsAndOpenFastCheckout({
                        productId: PROMO_TARIFFS?.default1TbTrial,
                        paySource: 'sidebar_promo_trial',
                        title: 'Подписка Mail Space: место для файлов без рекламы',
                        hideOtherTariffs: true,
                    })
                );
            },
            onShow: () => {
                markPromoShown();
                emitAnalyticEvent(AnalyticEventNames.SIDEBAR_TRIAL_PROMO_SHOW);
                sendDwh({ eventCategory: `sidebar-trial-promo-show` });
            },
            onClose: () => {
                markPromoShown();
                emitAnalyticEvent(AnalyticEventNames.SIDEBAR_TRIAL_PROMO_CLOSE);
                sendDwh({ eventCategory: `sidebar-trial-promo-close` });
            },
        })
    );
}

export function* showSidebarTrialPromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.sidebarTrialPromo));
    const shownCount = yield put(UserStorageActions.get(SIDEBAR_TRIAL_PROMO_SHOW_COUNT)) || 0;

    if (promo) {
        const { onShow, onClick, onClose } = promo;
        yield openAsidePromoModal({
            title: `Подписка Mail Space\n${'\u00A0'}\nна месяц ${sidebarTrialPromo === 'c' ? 'за 0 ₽' : 'бесплатно'}`,
            text: `Добавьте 1 ТБ места с подпиской — \n и${'\u00A0'}попробуйте другие опции в деле`,
            buttonText: 'Подключить 1 ТБ',
            imageUrl: isDarkTheme ? sidebarTrialPromoImageDark : sidebarTrialPromoImage,
            bottom: 45,
            qaId: 'mailspace-trial-promo',
            onShow,
            onClick,
            onClose,
        });
        yield put(UserStorageActions.set(SIDEBAR_TRIAL_PROMO_SHOW_COUNT, shownCount + 1));
    }
}
