import { isDomainFolder, isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getItemById, hasParentsDomainFolder } from 'reactApp/modules/storage/storage.selectors';
import { CloudItem } from 'reactApp/modules/storage/storage.types';
import { joinPath } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { ERetryErrorFileOptions, EUploadingMakeAction, EUploadingState } from 'reactApp/modules/uploading/uploading.types';
import { EFileError, EFileStatus, EProgressStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { cancelAllUploadFileAction, setProgressStatusAction, updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { renderSelectAnotherFolderDialog } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.toolkit';
import { put, select } from 'redux-saga/effects';

let mutex = false;

export function* handleReadOnlyDirectory(descriptor: UploadingDescriptor) {
    let retryResult: ERetryErrorFileOptions = ERetryErrorFileOptions.cancel;

    const savedAnswer = descriptor.uploadingPacketConfig.conflictResolutionReadOnlyFolder;

    sendGaUploaderNew('file', 'read_only_folder');

    if (descriptor.isFile) {
        yield put(
            updateUploadFilesAction({
                descriptorId: descriptor.id,
                cloudPath: descriptor.cloudPath,
                status: EFileStatus.WARNING,
                error: EFileError.READ_ONLY_DIRECTORY,
            })
        );
    }

    if (!savedAnswer) {
        if (mutex) {
            return ERetryErrorFileOptions.postpone;
        }

        try {
            mutex = true;

            descriptor.uploadingPacketConfig.makeAction = EUploadingMakeAction.stopAndWaitUser;

            const answer = yield new Promise((resolve) =>
                renderSelectAnotherFolderDialog({
                    forbiddenFolderName: descriptor.uploadingPacketConfig.workingDirectory.slice(1), // убираем / в начале
                    onSuccess: (answer) => {
                        resolve(answer);
                    },
                })
            );

            mutex = false;

            const folder = yield select(getItemById, answer.destination);

            const isReadOnly = answer.action === 'move' && folder && folder.isReadOnly;

            if (isReadOnly) {
                throw new Error('ReadOnly');
            }

            descriptor.uploadingPacketConfig.conflictResolutionReadOnlyFolder = answer;

            retryResult = yield handleSelectAnotherFolder(descriptor, answer);
        } catch (error) {
            descriptor.uploadingPacketConfig.conflictResolutionReadOnlyFolder = null;

            retryResult = ERetryErrorFileOptions.shouldRetry;
        }
    } else {
        retryResult = yield handleSelectAnotherFolder(descriptor, savedAnswer);
    }

    return retryResult;
}

function* handleSelectAnotherFolder(descriptor: UploadingDescriptor, answer) {
    if (answer.action === 'move') {
        const destination = answer.destination;
        const folder = yield select(getItemById, answer.destination);
        descriptor.cloudPath = joinPath(destination, descriptor.localPath);
        descriptor.uploadingPacketConfig.workingDirectory = destination;
        descriptor.uploadingPacketConfig.isDomainFolder = folder ? isDomainFolder(folder) : false;
        descriptor.uploadingPacketConfig.hasParentDomainFolder = folder
            ? yield select(hasParentsDomainFolder, folder as unknown as CloudItem)
            : false;
        descriptor.uploadingPacketConfig.isMountedFolder = folder ? isMountedFolder(folder) : false;
        descriptor.uploadingPacketConfig.makeAction = EUploadingMakeAction.move;
        descriptor.error = null;
        descriptor.state = EUploadingState.STATE_PENDING;

        sendGaUploaderNew('file', 'move_from_ro');

        if (descriptor.isFile) {
            yield put(
                updateUploadFilesAction({
                    descriptorId: descriptor.id,
                    cloudPath: descriptor.initialCloudPath,
                    status: EFileStatus.MOVED,
                })
            );
        }

        return ERetryErrorFileOptions.shouldRetryImmediately;
    }

    descriptor.uploadingPacketConfig.makeAction = EUploadingMakeAction.cancel;

    sendGaUploaderNew('file', 'skip_from_ro');

    yield put(cancelAllUploadFileAction());
    uploadingService.cancelAll();
    yield put(setProgressStatusAction({ status: EProgressStatus.COMPLETE }));

    yield put(
        updateUploadFilesAction({
            descriptorId: descriptor.id,
            status: EFileStatus.CANCEL,
            error: EFileError.SKIPPED_FILE,
            currentUpload: false,
            hideError: true,
        })
    );

    return ERetryErrorFileOptions.cancel;
}
