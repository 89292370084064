import { useSelector } from 'react-redux';
import { IS_USER_WITH_ONLY_CLOUD } from 'reactApp/appHelpers/configHelpers';
import { isFamilyQuotaButtonEnabled, isFamilySubsAvailable, quotaTooltip } from 'reactApp/appHelpers/featuresHelpers';
import { isFamilyEmpty, isUserFamilyOwner, userCanCreateFamily } from 'reactApp/modules/family/family.selectors';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';

export const useContent = ({ isMarketingPromo, isSubscriptionsPage }) => {
    const isAlmostFull = useSelector(UserQuotaSelectors.isAlmostFull);
    const isFull = useSelector(UserQuotaSelectors.isFull);
    const is100PercentFull = useSelector(UserQuotaSelectors.is100PercentFull);
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const isB2BUser = useSelector(UserSelectors.isB2BUser);
    const isBiz = useSelector(UserSelectors.isBizUser);
    const isDefaultContent = useSelector(isDefaultRootContent);

    const isFamilyOwner = useSelector(isUserFamilyOwner);
    const canCreateFamily = useSelector(userCanCreateFamily);
    const emptyFamily = useSelector(isFamilyEmpty);

    const isOverquota = isAlmostFull || isFull;

    let warning = '';

    if (isAlmostFull) {
        warning = 'Хранилище почти заполнено';
    }
    if (isFull) {
        warning = 'Хранилище переполнено';
    }
    if (is100PercentFull) {
        warning = 'Хранилище заполнено';
    }

    let buttonText = 'Увеличить место';

    const isMySubscription = isPaidUser && !isOverquota;
    if (isMySubscription) {
        buttonText = 'Мои подписки';
    }

    const showFamilyButton =
        !isOverquota && isFamilySubsAvailable && isFamilyQuotaButtonEnabled && (canCreateFamily || (isFamilyOwner && emptyFamily));

    if (showFamilyButton) {
        buttonText = 'Поделиться местом';
    }

    if (isMarketingPromo) {
        buttonText = 'Увеличить место';
    }

    if (isMarketingPromo && !isOverquota && !isDefaultContent) {
        if (quotaTooltip === 'b') {
            buttonText = '+1 ТБ за 87 ₽ в месяц';
        } else if (quotaTooltip === 'c' || quotaTooltip === 'd') {
            buttonText = '+256 ГБ за 54 ₽ в месяц';
        }
    }

    const buttonState = {
        primary: !isMySubscription && !isOverquota,
        secondary: isMySubscription,
        error: isOverquota,
    };

    return {
        buttonText,
        warning,
        buttonState,
        hideButton: isSubscriptionsPage || isB2BUser,
        isMySubscription: isMySubscription && !showFamilyButton,
        hideMenu: (isSubscriptionsPage && IS_USER_WITH_ONLY_CLOUD) || isBiz,
        showFamilyButton,
    };
};
