export const getDiscount = ({
    discountPrice,
    price,
}: {
    discountPrice: number;
    price: number;
}): { discount: number; roundedDiscount: number } => {
    const discount = Math.round(100 - discountPrice / (price / 100));
    const roundedDiscount = Math.round(discount / 5) * 5;

    return {
        discount: discount >= 100 ? 99 : discount,
        roundedDiscount,
    };
};
