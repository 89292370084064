import classNames from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { IS_DOCUMENTS_DOMAIN, NEW_PORTAL_HEADER, platform } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams, getUtmObject } from 'reactApp/appHelpers/settingsHelpers';
import { ALL_DOCUMENTS_DOMAIN_ROUTES, ALL_DOCUMENTS_PATH } from 'reactApp/modules/allDocuments/allDocuments.constants';
import { allDocumentsHideSpaceRadar, isAdditionalSections } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import { allDocumentsAdditionalLoadRequest, allDocumentsLoadRequest } from 'reactApp/modules/allDocuments/allDocuments.module';
import {
    getAllDocumentsCurrentSection,
    getAllDocumentsPrevSection,
    getDocumentsState,
} from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { AllDocumentsSections, EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { isAllDocumentsHideSpace } from 'reactApp/modules/features/features.helpers';
import { getFeatureAllDocumentsHideSections } from 'reactApp/modules/features/features.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { MainPage } from 'reactApp/sections/MainPage/MainPage';
import { TrashbinPage } from 'reactApp/sections/TrashbinPage/TrashbinPage';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './AllDocumentsPage.css';

interface Props {
    documentType: AllDocumentsSections;
}

const getSnackbarText = () => {
    const cloudDomainPath = `${changeDocumentDomainToCloud(window.location.origin)}/home/`;
    return [
        'Здесь вы можете работать с документами.<br>Ваши почтовые вложения находятся в ',
        `<a href="${cloudDomainPath}" rel="noopener" target="_blank">Облаке</a>.`,
    ].join(' ');
};

const AllDocumentsPageContent: FC<Props> = ({ documentType }) => {
    const dispatch = useDispatch();
    const { isLoaded, type, count, docsSize } = useSelector(getDocumentsState);
    const [isSent, setIsSent] = useState(false);
    const prevSection = useSelector(getAllDocumentsPrevSection);
    const currentSection = useSelector(getAllDocumentsCurrentSection);
    const isTrashbinSection = documentType === 'trashbin';
    const queryParams = getQueryParams();

    useEffect(() => {
        if (isSent) {
            return;
        }

        const queries = getQueryParams();
        const { utm_source } = getUtmObject();

        const tab = queries?.fromPage;

        const addDwhParams = {
            ...(tab ? { tab } : {}),
            ...(utm_source ? { referal: utm_source } : {}),
        };

        setIsSent(true);

        sendPaymentGa({
            eventCategory: ECategoryGa.entered,
            action: 'page-entered',
            source: 'docs',
        });

        sendDwh({
            eventCategory: ECategoryGa.entered,
            action: 'docs',
            dwhData: {
                platform,
                size_file: docsSize,
                cnt_file: count,
                ...addDwhParams,
            },
        });
    }, [isSent]);

    useEffect(() => {
        if (isAdditionalSections(documentType)) {
            dispatch(allDocumentsAdditionalLoadRequest(documentType));
        } else {
            dispatch(allDocumentsLoadRequest(documentType));
        }

        sendXray(['page', 'enter', 'alldocs', documentType]);

        allDocumentsHideSpaceRadar(isAllDocumentsHideSpace);
    }, [documentType]);

    useEffect(() => {
        if (queryParams?.from === 'mail') {
            snackbarController.showSnackbar({
                id: 'all-documents-from-mail',
                closable: true,
                text: getSnackbarText(),
                disableCloseTimeout: true,
            });
            sendDwh({
                eventCategory: ECategoryGa.entered,
                action: 'docs-from-mail',
            });
        }
    }, [queryParams]);

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        sendDwh({
            eventCategory: ECategoryGa.entered,
            action: 'docs-section',
            dwhData: {
                platform,
                size_file: docsSize,
                cnt_file: count,
                place: type,
                tab: prevSection,
            },
        });
    }, [currentSection, isLoaded]);

    return (
        <MainPage storage={EStorageType.alldocuments} renderWorm showUploader={!isTrashbinSection} showSpace={!isAllDocumentsHideSpace}>
            {isTrashbinSection ? (
                <TrashbinPage />
            ) : (
                <div className={styles.root}>
                    <div className={classNames({ [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                        <BreadcrumbsContainer />
                    </div>
                    <div className={classNames({ [styles.datalistContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                        <DataList goTo={noop} storage={EStorageType.alldocuments} />
                    </div>
                </div>
            )}
        </MainPage>
    );
};

export const AllDocumentsPage = () => {
    const { documentType = EAllDocumentsType.document } = (useParams() || {}) as { documentType: AllDocumentsSections };
    const hiddenSections = useSelector(getFeatureAllDocumentsHideSections);

    return hiddenSections.includes(documentType) ? (
        <Navigate
            replace
            to={`${IS_DOCUMENTS_DOMAIN ? ALL_DOCUMENTS_DOMAIN_ROUTES.document : ALL_DOCUMENTS_PATH}${window.location.search}`}
        />
    ) : (
        <AllDocumentsPageContent documentType={documentType} />
    );
};
