import { Icon20Dropdown } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { MouseEvent, ReactElement, useCallback } from 'react';

import styles from './SharingNewDropdown.css';
import { EDropdownColors, IProps } from './SharingNewDropdown.types';

export const SharingNewDropdown = React.memo(function SharingNewDropdown(props: IProps): ReactElement {
    const { onClick, color = EDropdownColors, isActive, disabled, isPhone, isUserDropdown, inInput, value, qaValue } = props;

    const handleClick = useCallback(
        (e: MouseEvent): void => {
            e.stopPropagation();
            onClick?.(e);
        },
        [onClick]
    );

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`root_color_${color}`]]: color,
                [styles.root_opened]: isActive,
                [styles.root_disabled]: disabled,
                [styles.root_in_input]: inInput,
                [styles.root_phone]: isPhone,
                [styles.root_in_user]: isUserDropdown,
            })}
            onClick={handleClick}
            data-qa-value={qaValue}
        >
            <div className={styles.container}>
                <div className={styles.text}>{value}</div>
                <Icon20Dropdown className={styles.icon} />
            </div>
        </div>
    );
});
