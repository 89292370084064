import classNames from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { ENABLE_FULL_RESPONSIVE, IS_B2B_BIZ_USER, IS_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { useShowTooltip } from 'reactApp/components/SharingWindow/SharingNew/Weblink/hooks/useShowTooltip';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { AccessRights } from 'reactApp/types/Tree';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { CopyWeblinkTooltip } from 'reactApp/ui/CopyWeblinkTooltip/CopyWeblinkTooltip';
import { ShadeRight } from 'reactApp/ui/ShadeRight/ShadeRight';
import { CopyIcon, LinkIcon } from 'reactApp/ui/VKUIIcons';
import { copy as copyApiCall } from 'reactApp/utils/copyToClipboard';
import { noop } from 'reactApp/utils/helpers';

import styles from './CopyWeblink.css';
import { renderPreCopyWeblink } from './PreCopyWeblinkDialog/preCopyWeblink.helpers';

interface CopyProps {
    onCopy?: (props?: any) => any;
    onClick?: (props?: any) => any;
    publicUrl?: string;
    autoCopy?: boolean;
    theme?: 'folder' | 'publish';
    type?: 'link' | 'new';
    isPhone?: boolean;
    isDownloadable?: boolean;
    weblinkAccessRights?: AccessRights;
    isFolder?: boolean;
    weblinkDomestic?: boolean;
    fileName?: string;
    isModifiedWindow?: boolean;
    buttonMobile?: string;
    // tempexp_17237-next-line
    tooltipEnabledByExp?: boolean;
}

const sharingLinkId = 'sharing-link-fakedoor-tooltip';

export const CopyWeblink = ({
    onCopy = noop,
    onClick = noop,
    buttonMobile = 'Поделиться',
    type,
    publicUrl,
    autoCopy,
    isPhone,
    isDownloadable,
    weblinkAccessRights,
    isFolder,
    theme,
    fileName,
    isModifiedWindow,
    weblinkDomestic,
    tooltipEnabledByExp = false,
}: CopyProps) => {
    const input = useRef<HTMLInputElement>(null);

    const isReadWriteWeblinkAccess = weblinkAccessRights && weblinkAccessRights === AccessRights.rw;
    const { needShowTooltip, closeTooltip, openTooltip } = useShowTooltip({
        tooltipId: sharingLinkId,
        preventShow: isReadWriteWeblinkAccess || !tooltipEnabledByExp || isPhone,
        autoShow: !isReadWriteWeblinkAccess && tooltipEnabledByExp,
    });

    const tooltipContainerRef = useRef<HTMLDivElement>(null);

    const copy = useCallback((): void => {
        if (!input || !input.current) {
            return;
        }

        if (isPhone) {
            toolbarActions.shareLink({ url: input.current.value });
            onCopy();
            return;
        }

        const processCopy = (notification): void => {
            if (notification) {
                snackbarController.showSnackbar(notification);
            }

            if (input.current) {
                input.current.select();
                input.current.setSelectionRange(0, input.current.value.length);
            }

            onCopy(notification?.type === 'success');
        };

        const copyText = () => {
            const copyTextWithAccessBase = 'Ссылка скопирована с доступом для';

            if (isModifiedWindow) {
                return 'Доступ по ссылке открыт';
            }

            if (!IS_B2B_BIZ_USER || isFolder) {
                return 'Ссылка успешно скопирована';
            }

            if (isDownloadable) {
                return `${copyTextWithAccessBase} скачивания`;
            }

            if (isReadWriteWeblinkAccess) {
                return `${copyTextWithAccessBase} редактирования`;
            }

            return `${copyTextWithAccessBase} просмотра`;
        };

        copyApiCall(input.current.value)
            .then((resp): void => {
                const hasBeenCopied = resp ? resp.hasBeenCopied : true;
                const text = copyText();
                return processCopy(
                    hasBeenCopied
                        ? {
                              type: SnackbarTypes.success,
                              text,
                              id: 'copyLink',
                              closable: true,
                          }
                        : null
                );
            })
            .catch((): void =>
                processCopy({ type: SnackbarTypes.failure, text: 'Не удалось скопировать ссылку', id: 'copyLink', closable: true })
            );
    }, [onCopy, isPhone, isDownloadable, weblinkAccessRights, isFolder, isModifiedWindow]);

    const handleCopy = useCallback(
        (e?: React.MouseEvent<HTMLInputElement | HTMLButtonElement | SVGSVGElement>) => {
            e?.stopPropagation();
            copy();
        },
        [copy]
    );
    const renderPreCopyDialog = useCallback(() => {
        renderPreCopyWeblink({ onSuccess: handleCopy, fileName });
    }, [fileName, handleCopy]);

    const handleCopyButtonClick = useCallback(
        (e?: React.MouseEvent<HTMLInputElement | HTMLButtonElement | SVGSVGElement>) => {
            if (!isFolder && IS_ONPREMISE && !weblinkDomestic) {
                return renderPreCopyDialog();
            }

            handleCopy(e);
        },
        [isFolder, weblinkDomestic, renderPreCopyDialog, handleCopy]
    );

    const handleClick = useCallback(() => {
        onClick?.();
    }, [onClick]);

    const handleInputClick = useCallback(() => {
        if (!input?.current) {
            return;
        }
        input.current.select();
        input.current.setSelectionRange(0, input.current.value.length);
        openTooltip();
    }, []);

    const handleCm = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    useEffect(() => {
        if (!!publicUrl && autoCopy && !IS_BIZ_USER) {
            copy();
        }
    }, []);

    if (type === 'new') {
        return (
            <div
                className={classNames({
                    [styles[`root_type_${type}`]]: type,
                    [styles.root_phone]: isPhone,
                    [styles.root_type_new_responsive]: ENABLE_FULL_RESPONSIVE,
                })}
                onContextMenu={handleCm}
                ref={tooltipContainerRef}
            >
                <input value={publicUrl} className={styles.input} ref={input} readOnly onClick={handleInputClick} />
                {needShowTooltip && (
                    <Portal>
                        <CopyWeblinkTooltip target={tooltipContainerRef} onClose={closeTooltip} />
                    </Portal>
                )}
                <Button
                    theme={isPhone ? 'vk' : 'octavius'}
                    primary
                    middle
                    onClick={handleCopyButtonClick}
                    sizeMode={isPhone ? ButtonSizeMode.big : ButtonSizeMode.middle}
                    className={styles.button}
                >
                    {isPhone ? buttonMobile : 'Скопировать'}
                </Button>
            </div>
        );
    }

    return (
        <div
            className={classNames(styles.controls, {
                [styles[`controls_${theme}`]]: !!theme,
            })}
            onContextMenu={handleCm}
        >
            <div className={styles.link}>
                <LinkIcon />
            </div>
            <ShadeRight theme={theme}>
                <input value={publicUrl} className={styles.input} ref={input} title="Скопировать" onClick={handleCopy} readOnly />
            </ShadeRight>
            <div className={styles.copy} title="Копировать ссылку" onClick={handleClick}>
                <CopyIcon data-qa-name="copy-link-icon" onClick={handleCopy} />
            </div>
        </div>
    );
};
