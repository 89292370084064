import { Product, Tariff } from 'reactApp/types/Billing';

export interface ITariffsSectionProps {
    onBuyClick: (tariffId: string, openWithoutQueryParams: boolean) => void;
    onChangeTab: (tab: string) => void;
}

export type TariffsByTabProductsType = {
    yearProduct: Product;
    monthProduct?: Product;
    threeMonthProduct?: Product;
};

export enum TariffPromoLabelType {
    'autoupload10TB' = 'autoupload10TB',
}

export interface ITariffsByTab extends Pick<Tariff, 'space'> {
    tariffId: string;
    products: TariffsByTabProductsType;
    isRecommended?: boolean;
    isBase?: boolean;
    tariffPromoLabelType?: TariffPromoLabelType;
}
