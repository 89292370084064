import { requestPayment } from 'Cloud/Application/Payment';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Tariff } from 'reactApp/types/Billing';
import { Screen } from 'reactApp/ui/Screen/Screen';
import { TariffCard } from 'reactApp/ui/TariffCard/TariffCard';
import { sendGa } from 'reactApp/utils/ga';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { isYearPeriod } from 'reactApp/utils/Period';

import styles from './OverquotaPopup.css';

export const OverquotaPopup = memo(({ tariff, onClose }: { tariff: Tariff; onClose: () => void }): ReactElement => {
    const { over } = useSelector(UserSelectors.getCloudSpace);

    const product = tariff.products.find((product) => isYearPeriod(product.period)) || tariff.products[0];

    const sendOverquotaPaymentGa = useCallback(
        (label) => {
            sendPaymentGa({
                action: EPaymentGa.overquotaBanner,
                label,
                size_delete: over.original,
                tariff: tariff?.space.original,
                group: 'None',
            });
        },
        [over.original, tariff?.space.original]
    );

    const sendNotifyGa = useCallback((label) => {
        sendGa('overquota', 'notify', label);
    }, []);

    const onBuy = useCallback(() => {
        if (!product) {
            return;
        }

        sendNotifyGa('buyclick');
        sendOverquotaPaymentGa('click');
        onClose();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({
            productId: product.id,
            forceFastCheckout: true,
            paySource: 'start_overquota_modal',
            onSuccess: () => {
                sendNotifyGa('success');
            },
        });

        emitAnalyticEvent(AnalyticEventNames.OVERQUOTA_POPUP_CLICK);
    }, [onClose, product, sendNotifyGa]);

    const onCloseScreen = useCallback(() => {
        sendOverquotaPaymentGa('close');
        sendNotifyGa('close');
        onClose();
    }, [onClose, sendNotifyGa, sendOverquotaPaymentGa]);

    const tariffBlock = useMemo(() => {
        const { id: productId, space, price, discountPrice, period } = product;
        const { id: tariffId, flags } = tariff;

        return (
            <div className={styles.tariffCardWrapper}>
                <TariffCard
                    id={productId}
                    tariffId={tariffId}
                    flags={flags}
                    space={space}
                    price={price}
                    discountPrice={discountPrice}
                    onBuyClick={onBuy}
                    showOldPrice={false}
                    period={period}
                    isPrimary
                    isVisible
                    withoutTariffIcon
                    modificator="overquota"
                    labelText="Самый выгодный"
                    showMonthlyPrice
                />
            </div>
        );
    }, [onBuy, product, tariff]);

    useEffect(() => {
        sendOverquotaPaymentGa('view');
        sendNotifyGa('show');
        emitAnalyticEvent(AnalyticEventNames.OVERQUOTA_POPUP_SHOW);
    }, []);

    return (
        <Screen onClose={onCloseScreen}>
            <div className={classNames(styles.root, styles.root_centered)}>
                <div className={styles.title}>Ваше Облако переполнено</div>
                <div className={styles.description}>
                    Файлы занимают на <span className={styles.quota}>{over.value}</span> больше места, чем у вас есть.
                    <br />
                    Увеличьте объём Облака или удалите лишнее.
                </div>
                {tariffBlock}
                <div className={styles.footer}>
                    Подробную информацию об ограничениях и последствиях вы можете посмотреть{' '}
                    <a href="/LA/" target="_blank">
                        здесь
                    </a>
                </div>
            </div>
        </Screen>
    );
});

OverquotaPopup.displayName = 'OverquotaPopup';
