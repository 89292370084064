import { convertBufferToJSON } from 'reactApp/utils/convertBufferToJSON';

import { ServerError } from './ServerError';

class UserNoAuthError extends ServerError {
    static radarName = 'failure_{SOURCE}_user-no-auth';

    static ERROR_CSRF_TOKEN_NOT_VALID = 'token';
    static ERROR_SDC_NOT_VALID = 'nosdc';
    static ERROR_NOT_AUTHORIZED = 'NOT/AUTHORIZED';

    className = 'UserNoAuthError';
    message = 'Вы не авторизованы.';

    isRetryable = true;
    maxRetries = 2;
    error: string;
    /**
     * @constructor
     * @extends {UploadFail}
     * @param {Error} stack
     * @param {string} source
     */
    constructor({ response, version, responseType }) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        let errorSource = response;
        if (responseType === 'arraybuffer') {
            const jsonResponse = convertBufferToJSON(response);
            if (jsonResponse.isConverted) {
                errorSource = jsonResponse.data;
            }
        }

        // api/v2 присылает тип ошибки в поле body
        this.error = version === 2 ? errorSource.body : errorSource.error;
    }

    isCSRFTokenError() {
        return this.error === UserNoAuthError.ERROR_CSRF_TOKEN_NOT_VALID;
    }

    isSDCError() {
        return this.error === UserNoAuthError.ERROR_SDC_NOT_VALID;
    }

    isNotAuthorizedError() {
        return this.error === UserNoAuthError.ERROR_NOT_AUTHORIZED;
    }
}

export { UserNoAuthError };
