import classNames from 'clsx';
import React, { FC } from 'react';

import styles from './Skeleton.css';

export const Skeleton: FC<{ free?: boolean }> = ({ free = false }) => {
    const features = new Array(free ? 2 : 4);

    return (
        <div
            className={classNames(styles.root, {
                [styles.rootFree]: free,
            })}
        >
            <div className={styles.quota} />
            <div className={styles.info} />
            <>
                {Array.from(features).map((_, index) => (
                    <div key={index} className={styles.feature} />
                ))}
            </>
            <div className={styles.button} />
        </div>
    );
};
