import classNames from 'clsx';
import React, { memo, ReactElement, RefObject, useCallback, useEffect } from 'react';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { getSubscriptionsUrl } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.constants';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './PromoProTooltip.css';

export interface IProps {
    target?: RefObject<HTMLElement>;
    onClose: () => void;
    gaLabel?: string;
    tariffsType?: string;
    bottom?: boolean;
    overAll?: boolean;
}

export const PromoProTooltip = memo(
    ({ target, onClose, gaLabel, tariffsType = '', bottom = false, overAll = false }: IProps): ReactElement | null => {
        const isPaid = tariffsType === 'paid';

        const sendGaTooltip = useCallback(
            (action: string) => {
                sendPaymentGa({ eventCategory: gaLabel ?? 'promo-pro-tooltip', action });
                sendPaymentGa({ eventCategory: gaLabel ?? 'promo-pro-tooltip', action, label: tariffsType });
            },
            [gaLabel, tariffsType]
        );

        const handleClose = useCallback(
            (e) => {
                e?.preventDefault();
                e?.stopPropagation();

                sendGaTooltip('close');
                onClose();
            },
            [onClose]
        );

        const handleClick = useCallback((e) => {
            e?.stopPropagation();
            sendGaTooltip('click');
        }, []);

        useEffect(() => {
            sendGaTooltip('show');
        }, []);

        if (!(target && target.current)) {
            return null;
        }

        const br = bottom ? null : <br />;

        return (
            <FloatingTooltip
                target={target}
                placement={bottom ? ETooltipPlacement.bottom : ETooltipPlacement.right}
                appearance={isPaid || bottom ? ETooltipAppearance.white : ETooltipAppearance.dark}
                onClose={handleClose}
                withinModal={!bottom}
                qaId="tooltip-promo-pro"
                overAll={overAll}
            >
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_light]: isPaid || bottom,
                        [styles.root_bottom]: bottom,
                    })}
                >
                    Автоудаление папок доступно только
                    {br} в {isPaid ? 'платных' : 'PRO'} тарифах.{' '}
                    <a
                        className={classNames({ [styles.link]: true, [styles.button]: bottom })}
                        href={getSubscriptionsUrl(PaymentUTM.promoAutodeleteTooltip)}
                        target="_blank"
                        rel="noreferrer"
                        onClick={handleClick}
                    >
                        {bottom ? 'Попробовать' : 'Попробуйте!'}
                    </a>
                </div>
            </FloatingTooltip>
        );
    }
);

PromoProTooltip.displayName = 'PromoProTooltip';
