import { getUtmObject } from 'reactApp/appHelpers/settingsHelpers';
import { sendDwh } from 'reactApp/utils/ga';

export const sendPromoBlockDWH = (action: string, name: string, place: string) => {
    const utms = getUtmObject();

    sendDwh({
        eventCategory: 'main-page',
        action: `infoblock-${action}`,
        dwhData: {
            name,
            place,
            source: 'touch',
            ...utms,
        },
    });
};
