import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EpubConvertV3APICall } from 'reactApp/api/EpubConvertV3APICall';
import { EErrorCondition } from 'reactApp/api/ErrorConditions';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { cloneEpubFromPublic } from 'reactApp/modules/ebook/ebook.module';
import { IPopupHelperProps } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import opener from 'reactApp/utils/opener';

import { FAILURE_SNACKBAR_ID, HAPPY_READING_QUERY_ACTION, LOADING_SNACKBAR_ID } from './constants';
import styles from './ConvertFb2ToEpubDialog.css';

export type ConvertFb2ToEpubDialogProps = Pick<IPopupHelperProps, 'onClose'> & {
    className?: string;
    fileId: string;
};

export const ConvertFb2ToEpubDialog: FC<ConvertFb2ToEpubDialogProps> = ({ onClose, fileId }) => {
    const [loading, setLoading] = useState(false);

    const storage = useSelector(getCurrentStorage);
    const { isPublic, isAttaches } = getStorage(storage);

    const isPublicOwner = useSelector(isOwnPublic);

    const dispatch = useDispatch();

    const handleConvert = useCallback(async () => {
        let pathForConvert = fileId;

        setLoading(true);

        if ((isPublic && !isPublicOwner) || isAttaches) {
            try {
                pathForConvert = await new Promise((resolve, reject) =>
                    dispatch(
                        cloneEpubFromPublic({
                            data: { id: fileId, source: `${isPublic ? 'public' : 'attaches'}-viewer` },
                            onSuccess: (result) => {
                                resolve(result.home[0]);
                            },
                            onFailure: reject,
                        })
                    )
                );
            } catch (error) {
                setLoading(false);

                return;
            }
        }

        let requestOptions: { home?: string; public?: string } = { home: pathForConvert };

        if (isPublic && isPublicOwner) {
            requestOptions = { public: fileId };
        }

        try {
            const { data } = await new EpubConvertV3APICall().makeRequest(requestOptions);

            opener(`/home/${data.home}?dialog=${popupNames.EBOOK_READER}&action=${HAPPY_READING_QUERY_ACTION}`);
        } catch (error: any) {
            const { status, response } = error;

            if (status >= 400 && response?.error === EErrorCondition.OVERQUOTA) {
                snackbarController.showSnackbar({
                    id: FAILURE_SNACKBAR_ID,
                    title: 'Превышен объём Облака',
                    text: 'Невозможно создать копию файла',
                    type: SnackbarTypes.failure,
                    closable: true,
                });
            } else {
                snackbarController.showSnackbar({
                    id: FAILURE_SNACKBAR_ID,
                    title: 'Ошибка конвертации',
                    type: SnackbarTypes.failure,
                    closable: true,
                });
            }
        } finally {
            setLoading(false);
            onClose?.();
        }
    }, [dispatch, fileId, isAttaches, isPublic, isPublicOwner, onClose]);

    const prevLoading = useRef(false);
    useEffect(() => {
        if (prevLoading.current < loading) {
            snackbarController.showSnackbar({
                id: LOADING_SNACKBAR_ID,
                title: 'Сохранение в EPUB',
                text: 'Книга скоро откроется',
                type: SnackbarTypes.loading,
                closable: true,
                disableCloseTimeout: true,
            });
        }

        if (prevLoading.current > loading) {
            snackbarController.hideSnackbar(LOADING_SNACKBAR_ID);
        }

        prevLoading.current = loading;
    }, [loading]);

    const successButtonText = (isPublic && !isPublicOwner) || isAttaches ? 'Сохранить в Облако' : 'Читать в EPUB';

    return (
        <BaseConfirmDialog
            onClose={onClose}
            renderHeader={() => <div className={styles.header}>Читайте книги в EPUB</div>}
            renderContent={() => (
                <div className={styles.content}>
                    Чтобы вы могли почитать эту книгу, мы сделаем её копию в формате <b>EPUB</b>
                </div>
            )}
            buttons={[EButtonType.accept]}
            disableAcceptButton={loading}
            successButtonText={successButtonText}
            onSuccess={handleConvert}
        />
    );
};
