import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { renderLicenseAgreementDialog } from 'reactApp/components/LicenseAgreement/licenseAgreement.toolkit';
import { cloneToCloudFailure, cloneToCloudSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { channel } from 'redux-saga';
import { select, take, takeEvery } from 'typed-redux-saga';

import { cloneEpubFromPublic } from './ebook.module';

function* handleCloneEpubFromPublic(action: ReturnType<typeof cloneEpubFromPublic>) {
    const { onSuccess, onFailure, data } = action.payload;
    const email = yield* select(UserSelectors.getEmail);
    const isAuthorized = !!email;

    const isNewbie = yield* select(UserSelectors.isNewbie);

    if (isNewbie) {
        const licenseAgreed = channel();

        renderLicenseAgreementDialog({
            onClose: () => licenseAgreed.put(false),
            onSuccess: () => licenseAgreed.put(true),
        });

        const isLicenseAgreed = yield take(licenseAgreed);

        if (!isLicenseAgreed) {
            onFailure?.();

            return;
        }
    }

    toolbarActions.clone({ id: data.id, source: data.source });

    if (isAuthorized) {
        const whoIsTheFirst = yield take([cloneToCloudFailure.toString(), cloneToCloudSuccess.toString()]);

        if (whoIsTheFirst.type === cloneToCloudFailure.toString()) {
            onFailure?.();
        }

        if (whoIsTheFirst.type === cloneToCloudSuccess.toString()) {
            onSuccess?.(whoIsTheFirst.payload);
        }
    }
}

export function* watchEbook() {
    yield takeEvery(cloneEpubFromPublic.toString(), handleCloneEpubFromPublic);
}
