/* eslint-disable react/sort-comp */
import { captureException } from '@sentry/browser';
import classNames from 'clsx';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ENABLE_FULL_RESPONSIVE, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Loader } from '../Loader/Loader';
import styles from './Content.css';

export default class Content extends PureComponent {
    state = {
        showLoader: false,
    };

    static propTypes = {
        isModal: PropTypes.bool,
        isPage: PropTypes.bool,
        hasError: PropTypes.bool,
        retry: PropTypes.func,
        buttonText: PropTypes.string,
        pageName: PropTypes.string,
        children: PropTypes.node,
        isLoading: PropTypes.bool,
        disableLoaderCentering: PropTypes.bool,
        prerenderContent: PropTypes.bool,
        text: PropTypes.string,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        wrapClass: PropTypes.string,
        qaId: PropTypes.string,
        isMobile: PropTypes.bool,
        layout: PropTypes.oneOf(['flex', 'grid']),
    };

    static defaultProps = {
        isModal: false,
        isPage: false,
        qaId: '',
        text: 'Произошла ошибка, попробуйте перезагрузить страницу или воспользуйтесь сервисом позже.',
        layout: 'flex',
    };

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && this.props.isLoading !== prevProps.isLoading) {
            this.setState({
                showLoader: false,
            });
        }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            showError: true,
        });
        captureException(error, { extra: errorInfo });

        logger.error(error);

        const { pageName } = this.props;
        const radarSuffix = pageName ? `_${pageName}` : '';
        xray.send(`route_exc${radarSuffix}`, {
            rlog: 'route_exception',
            rlog_message: {
                error,
                pageName,
            },
        });
    }

    handleOnRetryClick = (e) => {
        this.setState({
            showLoader: true,
            showError: false,
        });

        this.props.retry(e);
    };

    renderContent() {
        const {
            isPage,
            isModal,
            description,
            retry,
            buttonText,
            hasError,
            isLoading,
            children: content,
            prerenderContent,
            text,
            disableLoaderCentering,
            isMobile,
        } = this.props;
        const { showError } = this.state;

        if ((isLoading || prerenderContent) && !hasError) {
            return [
                isLoading && <Loader key="loader" isModal={isModal} centered={!disableLoaderCentering && (isPage || isModal)} />,
                prerenderContent && (
                    <div
                        key="content"
                        className={classNames({
                            [styles.prerender]: true,
                            [styles.prerender_loading]: isLoading,
                        })}
                    >
                        {content}
                    </div>
                ),
            ];
        }

        if (hasError || showError) {
            return (
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_page]: isPage,
                        [styles.root_modal]: isModal,
                        [styles.root_mobile]: isMobile,
                    })}
                >
                    <div className={styles.icon} />
                    <div className={styles.text}>{text}</div>
                    {description && <div className={styles.description}>{description}</div>}
                    {retry && (
                        <div className={styles.retry}>
                            <ButtonLink size="big" disabled={this.state.showLoader} onClick={this.handleOnRetryClick} primary>
                                {buttonText}
                            </ButtonLink>
                        </div>
                    )}
                </div>
            );
        }

        return content;
    }

    render() {
        const { wrapClass, qaId } = this.props;

        return (
            <div
                className={classNames(styles.container, this.props.layout === 'flex' ? styles.flex : styles.grid, {
                    [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                    [wrapClass]: wrapClass,
                    [styles.container_new_portal_header]: NEW_PORTAL_HEADER,
                })}
                data-qa-id={qaId}
            >
                {this.renderContent()}
            </div>
        );
    }
}
