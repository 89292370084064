import classNames from 'clsx';
import { sanitize } from 'dompurify';
import React, { memo, ReactNode, useCallback } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { CancelIconOutline } from 'reactApp/ui/VKUIIcons';

import styles from './Snackbar.css';

export enum ESnackbarTheme {
    REGULAR = 'regular',
    COMPACT = 'compact',
}

export type SnackbarProps = {
    onClose?: () => void;
    icon?: any;
    iconSize?: 'sm' | 'flex';
    stretched?: boolean;
    title?: string | ReactNode;
    text?: string | Node;
    closable?: boolean;
    hideCloseIcon?: boolean;
    buttonText?: string;
    buttonRight?: boolean;
    onButtonClick?: () => void;
    visible?: boolean;
    theme?: ESnackbarTheme;
    bigSnackbar?: boolean;
    oneline?: boolean;
    wrapText?: boolean;
    wrapTitle?: boolean;
    alignTop?: boolean;
    thumb?: { data?: IThumb; ext?: string };
};

export const Snackbar = memo(function Snackbar(props: SnackbarProps) {
    const {
        title,
        text,
        icon,
        iconSize = 'sm',
        thumb,
        closable,
        hideCloseIcon = false,
        buttonText,
        buttonRight = false,
        onClose,
        onButtonClick,
        visible = true,
        oneline = false,
        theme = ESnackbarTheme.COMPACT,
        bigSnackbar,
        wrapText,
        wrapTitle,
        alignTop,
    } = props;

    const enableOneline = !title && oneline;

    const onClick = useCallback(() => {
        onButtonClick?.();
        onClose?.();
    }, [onButtonClick, onClose]);

    const buttonElement = React.useMemo(() => {
        return (
            buttonText && (
                <div
                    className={classNames(styles.buttonLink, {
                        [styles.buttonLinkOneLine]: enableOneline,
                        [styles.buttonLinkRight]: buttonRight,
                    })}
                    onClick={onClick}
                >
                    {buttonText}
                </div>
            )
        );
    }, [buttonText, enableOneline, onClick, buttonRight]);

    return (
        <div
            className={classNames(styles.root, styles[`root_theme_${theme}`], {
                [styles.root_visible]: Boolean(visible),
                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                [styles.alignTop]: alignTop,
            })}
        >
            {icon && !thumb && <div className={classNames(styles.icon, styles[`icon-${iconSize}`])}>{icon}</div>}
            {thumb && (
                <div className={styles.thumb}>
                    <FilePic thumbUrl={thumb?.data} isVirus={false} ext={thumb?.ext || ''} isDark />
                </div>
            )}

            <div
                className={classNames(styles.container, {
                    [styles.containerOneline]: enableOneline,
                })}
            >
                {title && <div className={classNames(styles.title, { [styles.wrapTitle]: wrapTitle })}>{title}</div>}
                {text && (
                    <div
                        className={classNames(styles.text, bigSnackbar ? styles.textBig : styles.textSmall, {
                            [styles.wrapText]: wrapText,
                        })}
                        dangerouslySetInnerHTML={{ __html: sanitize(text, { ADD_ATTR: ['target'] }) }}
                    />
                )}

                {!buttonRight && buttonElement}
            </div>

            {buttonRight && buttonElement}

            {closable && !hideCloseIcon && (
                <div className={styles.close} onClick={onClose}>
                    <CancelIconOutline className={styles.closeIcon} />
                </div>
            )}
        </div>
    );
});
