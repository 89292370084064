import { Spacing } from '@vkontakte/vkui';
import coverImage from 'img/subscriptions/cancel-autorenew-autoupload.jpg';
import { plural } from 'lib/plural';
import React, { memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { renderCancelAutoRenew10TbModal } from 'reactApp/ui/CancelAutoRenew10TbModal/CancelAutoRenew10TbModal.helpers';

import styles from './CancelAutoRenewAutouploadModal.css';
import { CancelAutoRenewAutouploadModalProps } from './CancelAutoRenewAutouploadModal.types';

const quotaLostPlural = (count: number): string => ['Пропадёт', 'Пропадут', 'Пропадут'][plural(count)];

export const CancelAutoRenewAutouploadModal = memo<CancelAutoRenewAutouploadModalProps>(({ onClose, subscription }): ReactElement => {
    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);

    useEffect(() => {
        sendAnalytics(ESubscriptionModalAction.showCancelAutoupload);
    }, []);

    const content = useMemo(
        (): ReactElement => (
            <div className={styles.content}>
                <Spacing size={16} />
                <ul className={styles.list}>
                    <li>
                        {quotaLostPlural(subscription.space.space)} {subscription.space.value} тарифа и 10 ТБ на автозагрузку
                    </li>
                    <li>Нельзя будет загружать файлы больше 100 ГБ</li>
                    <li>Вернётся реклама — и в Почте тоже</li>
                </ul>
                <Spacing size={36} />
            </div>
        ),
        [subscription.space.value]
    );

    const handleKeepAutoRenew = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.notCancelAutoupload);
        onClose?.();
    }, [onClose, sendAnalytics]);

    const handleCancelAutoRenew = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.cancelAutouploadCancel);
        renderCancelAutoRenew10TbModal({ subscription });
        onClose?.();
    }, [onClose, subscription, sendAnalytics]);

    return (
        <WhatsNewDialog
            onClose={onClose}
            onClick={handleKeepAutoRenew}
            onSecondary={handleCancelAutoRenew}
            primaryButtonText="Сохранить подписку"
            secondaryButtonText="Отключить"
            title="Что вы потеряете, отключив подписку"
            content={content}
            imageUrl={coverImage}
            imageBgColor="var(--vkui--color_background)"
            buttonTheme="vk"
            dialogSize="tiny"
        />
    );
});

CancelAutoRenewAutouploadModal.displayName = 'CancelAutoRenewAutouploadModal';
