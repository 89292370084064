import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { Product } from 'reactApp/types/Billing';
import { TariffCard } from 'reactApp/ui/TariffCardNew/TariffCard';
import {
    EButtonTextSize,
    ETariffCardSizeButtonSize,
    ETariffCardWidth,
    TariffCardContentSize,
} from 'reactApp/ui/TariffCardNew/TariffCard.types';
import { useButtonAdditionalText } from 'reactApp/ui/TariffSectionNew/hooks/useButtonAdditionalText';
import { useSwitchProducts } from 'reactApp/ui/TariffSectionNew/hooks/useSwitchProducts';
import { useTariffLabel } from 'reactApp/ui/TariffSectionNew/hooks/useTariffLabel';
import { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { noopVoid } from 'reactApp/utils/helpers';
import { isMonthPeriod } from 'reactApp/utils/Period';

import { useDisableAdsFeatures } from './hooks/useFeatures';
import { useProductPrice } from './hooks/useProductPrice';
import styles from './TariffListCard.css';
import { TariffListCardInfoBlock } from './TariffListCardInfoBlock';

interface TariffListCardProps {
    activeTab?: ETabsName;
    tariff: ITariffsByTab;
    onBuyClick: (product: Product) => void;
}

export const TariffListCard = memo<TariffListCardProps>(({ activeTab, tariff, onBuyClick }) => {
    const { isRecommended, products, tariffId } = tariff;
    const userQuota = useSelector(UserQuotaSelectors.getTotalOrBase);
    const { product, yearSavingDiscount } = useSwitchProducts({
        products,
        activeTab,
    });
    const savingDiscount = product.hasTrial ? 0 : yearSavingDiscount;
    const { discount, priceText } = useProductPrice({ product });
    const [showLabel, label, labelKind] = useTariffLabel({
        product,
        skipDefaultLabel: true,
        showDiscount: false,
        isRecommended: isRecommended && !discount,
        primaryLabel: isRecommended ? 'Выгодно' : '',
    });
    const featureListWithoutBenefits = useDisableAdsFeatures();
    const buttonAdditionalText = useButtonAdditionalText(product);
    const onClickButton = useCallback(() => onBuyClick(product), [onBuyClick, product]);
    const newQuota = userQuota + product.space.original;
    const infoBlock = useMemo(() => <TariffListCardInfoBlock newQuota={newQuota} />, [newQuota]);
    return (
        <div className={styles.root}>
            <TariffCard
                accentFocused
                compact
                boldSavingLabel
                buttonTextSize={EButtonTextSize.large}
                contentSize={TariffCardContentSize.small}
                buttonInFooterGap={24}
                buttonPrimaryLight
                buttonInFooter
                headerPrimaryText=""
                onClick={noopVoid}
                tariffId={tariffId}
                width={ETariffCardWidth.fluid}
                focused={isRecommended}
                focusedWithoutShadow
                buttonPrimary={isRecommended}
                buttonText={priceText}
                onClickButton={onClickButton}
                buttonAdditionalText={buttonAdditionalText}
                space={product.space}
                featureListOptions={featureListWithoutBenefits}
                buttonSize={ETariffCardSizeButtonSize.big}
                infoBlock={infoBlock}
                savingInButton={isMonthPeriod(product.period) ? 0 : savingDiscount}
                {...(showLabel
                    ? {
                          label,
                          labelKind,
                      }
                    : {})}
            />
        </div>
    );
});

TariffListCard.displayName = 'TariffListCard';
