import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import deleteLettersImg from 'img/deleteLetters.png';
import deleteLettersMobile from 'img/quota-landing/deleteLettersMobile.png';
import { plural } from 'lib/plural';
import { xray } from 'lib/xray';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatureQuotaCleanerDeletePromo } from 'reactApp/modules/features/features.selectors';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { startSelecting } from 'reactApp/modules/selections/selections.actions';
import { hasMailSubscription } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { setShowUploaderAction } from 'reactApp/modules/uploadList/uploadList.module';
import {
    getIsItemsDeleteError,
    getIsItemsDeleteProcess,
    isMeesagesRemoveNeeded,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { UserQuotaGroupId, UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { useBuyCleanerTariff } from 'reactApp/ui/QuotaCleanerPage/hooks/useBuyCleanerTariff';
import { QuotaSpacePromo } from 'reactApp/ui/QuotaSpacePromo/QuotaSpacePromo';

import styles from './DeleteConfirmationDialog.css';

export interface DeleteConfirmationDialogProps {
    count: number;
    groupType: UserQuotaGroupType;
    groupId: UserQuotaGroupId;
    deleteSize: string;
    isTwoStepLettersDelete: boolean;
    onDelete: () => void;
    onLettersDelete: () => void;
    onClose: () => void;
    onGroupReload: () => void;
}

const pluralTitle = {
    [UserQuotaGroupType.Letter]: ['письмо', 'письма', 'писем'],
    [UserQuotaGroupType.Cloud]: ['файла', 'файлов', 'файлов'],
};

const pluralGroupTitle = {
    [UserQuotaGroupId.Mounted]: ['общей папки', 'общих папок', 'общих папок'],
};

const pluralGroupMessage = {
    [UserQuotaGroupId.Mounted]: ['общую папку', 'общие папки', 'общие папки'],
};

const getTitle = (
    groupId: UserQuotaGroupId,
    groupType: UserQuotaGroupType,
    count: number,
    isMessagesRemove: boolean,
    isMobile: boolean
): string => {
    if (groupId === UserQuotaGroupId.Mounted) {
        return `Отключение ${pluralGroupTitle[groupId][plural(count)]}`;
    }

    if (groupType === UserQuotaGroupType.Cloud) {
        const fileText = count === 1 ? 'файл' : 'файлы';
        return `Переместить ${fileText} в\xA0корзину?`;
    }

    if (isMessagesRemove && isMobile) {
        return `Удалить ${count}\xA0${pluralTitle[groupType][plural(count)]} навсегда?`;
    }

    if (isMessagesRemove) {
        return 'Удалить навсегда?';
    }

    return `Перенести ${count}\xA0${pluralTitle[groupType][plural(count)]} в\xA0корзину?`;
};

const getMessage = (groupId: UserQuotaGroupId, groupType: UserQuotaGroupType, count: number, isMessagesRemove: boolean) => {
    if (groupId === UserQuotaGroupId.Mounted) {
        return `Вы действительно хотите отключить ${pluralGroupMessage[groupId][plural(count)]} от своего Облака?`;
    }

    if (groupType === UserQuotaGroupType.Cloud) {
        return 'Вы действительно хотите перенести файлы в корзину?';
    }

    if (isMessagesRemove) {
        return (
            <>
                Это освободит место, но письма <span className={styles.message}>не получится восстановить.</span>
            </>
        );
    }

    return 'Письма удалятся из корзины автоматически через 30 дней.';
};

const getDeleteText = (groupType: UserQuotaGroupType, isMessagesRemove: boolean, deleteSize: string): string => {
    if (groupType === UserQuotaGroupType.Cloud) {
        return `Переместить ${deleteSize}`;
    }

    if (isMessagesRemove) {
        return `Удалить навсегда`;
    }

    return 'Перенести в корзину';
};

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
    count,
    groupType,
    groupId,
    deleteSize,
    isTwoStepLettersDelete,
    onDelete,
    onLettersDelete,
    onClose,
    onGroupReload,
}) => {
    const dispatch = useDispatch();
    const isPaidUser = useSelector(hasMailSubscription);
    const isMessagesRemove = useSelector(isMeesagesRemoveNeeded);
    const isItemsDeleteProcess = useSelector(getIsItemsDeleteProcess);
    const isItemsDeleteError = useSelector(getIsItemsDeleteError);

    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    const { tariffPrice, tariffValue, onBuy } = useBuyCleanerTariff();
    const isQuotaPromo = useSelector(getFeatureQuotaCleanerDeletePromo);

    const title = getTitle(groupId, groupType, count, isMessagesRemove, isMobile);
    const message = getMessage(groupId, groupType, count, isMessagesRemove);
    const deleteText = getDeleteText(groupType, isMessagesRemove, deleteSize);

    const prefix = isMessagesRemove ? 'msg-rmv' : 'trash';

    const onSubmit = useCallback(() => {
        if (isMessagesRemove) {
            onLettersDelete();
        } else {
            onDelete();
        }

        if ((isTwoStepLettersDelete && isMessagesRemove) || !isTwoStepLettersDelete || groupType === UserQuotaGroupType.Cloud) {
            onClose();
        }

        xray.send(`quota-cln-dlte-dlg-${prefix}-sbmt${isMobile ? '-mob' : ''}`, { i: groupId });
    }, [groupId, groupType, onDelete, onClose, isMessagesRemove, isTwoStepLettersDelete, onLettersDelete]);

    const onCancel = useCallback(() => {
        if (isMessagesRemove && isTwoStepLettersDelete && groupId !== UserQuotaGroupId.MailBin) {
            onGroupReload();
            if (isMobile) {
                dispatch(setShowUploaderAction(false));
                dispatch(startSelecting());
            }
        }

        xray.send(`quota-cln-dlte-dlg-${prefix}-cls${isMobile ? '-mob' : ''}`, { i: groupId });
        onClose();
    }, [onClose, isMessagesRemove, isTwoStepLettersDelete]);

    useEffect(() => {
        xray.send(`quota-cln-dlte-dlg-${prefix}-shw${isMobile ? '-mob' : ''}`, { i: groupId });
    }, [isMessagesRemove]);

    useEffect(() => {
        if (isItemsDeleteError) {
            onClose();
        }
    }, [isItemsDeleteError]);

    const content = (
        <div className={classNames(styles.wrapper, { [styles.wrapper_mobile]: isMobile })}>
            {isMobile && isMessagesRemove && (
                <div className={styles.imageWrapper}>
                    <img className={styles.image} src={deleteLettersMobile} alt="Cover image" />
                </div>
            )}
            <div className={classNames(styles.title, { [styles.titleCentre]: isMobile && isMessagesRemove })}>
                <Title level={isMobile && isMessagesRemove ? '1' : '2'} weight="2">
                    {title}
                </Title>
                <Spacing size={12} />
                <Title level="3" weight="3">
                    {message}
                </Title>
                <Spacing size={32} />
            </div>
            {isQuotaPromo && !isPaidUser && tariffPrice && (
                <>
                    <QuotaSpacePromo tariffPrice={tariffPrice} tariffValue={tariffValue} onBuy={onBuy} />
                    <Spacing size={32} />
                </>
            )}
            <div className={classNames(styles.buttons, { [styles.buttons_mobile]: isMobile })}>
                <Button
                    theme="octavius"
                    sizeMode={isMobile ? ButtonSizeMode.extraBig : ButtonSizeMode.small}
                    error
                    onClick={onSubmit}
                    qaId="delete-btn"
                    disabled={isItemsDeleteProcess}
                >
                    <Text Component="h6" weight="2" style={{ color: 'var(--vkui--color_button_contrast)' }}>
                        {deleteText}
                    </Text>
                </Button>
                <div className={classNames(styles.cancel, { [styles.cancel_mobile]: isMobile })}>
                    <Button
                        style={{ width: '100%' }}
                        theme="octavius"
                        sizeMode={isMobile ? ButtonSizeMode.extraBig : ButtonSizeMode.small}
                        secondary
                        onClick={onCancel}
                        qaId="cancel-btn"
                    >
                        <Text Component="h6" weight="2">
                            {isMessagesRemove ? 'Оставить в корзине' : 'Отменить'}
                        </Text>
                    </Button>
                </div>
            </div>
        </div>
    );

    return isMobile ? (
        <MobileDialog
            id={`quota-delete-confirm:${groupType}:${prefix}`}
            closeOnDimmerClick
            mod="base"
            topmost
            onClose={onCancel}
            disableScrolling={false}
        >
            {content}
        </MobileDialog>
    ) : (
        <Dialog
            open
            id={`quota-delete-confirm:${groupType}:${prefix}`}
            size={isMessagesRemove ? 'middle' : 'small'}
            whiteCloseIcon={isMessagesRemove}
            bigCloseIcon={isMessagesRemove}
            closable
            onCancel={onCancel}
            img={isMessagesRemove ? <img src={deleteLettersImg} className={styles.img} /> : null}
        >
            {content}
        </Dialog>
    );
};
