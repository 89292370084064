import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon20CloudOutline } from '@vkontakte/icons';
import { Caption } from '@vkontakte/vkui';
import React from 'react';

import styles from './TariffListCardInfoBlock.css';

interface TariffListCardInfoBlockProps {
    newQuota: number;
}

export const TariffListCardInfoBlock = ({ newQuota }: TariffListCardInfoBlockProps) => (
    <div className={styles.root}>
        <Icon20CloudOutline />{' '}
        <Caption level="3" weight="2">
            Ваш объём составит {bytesToNDigits(newQuota, 3).value}
        </Caption>
    </div>
);
