import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { useCallback, useMemo, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IS_IOS_MOBILE } from 'reactApp/appHelpers/configHelpers';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useTariffBuyDialog } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.hooks';
import { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';

import { DISCOUNTS } from './DiscountCard.constant';
import styles from './DiscountCard.css';
import { formatTitle, sendAnalytics } from './DiscountCard.helpers';
import type { Props } from './DiscountCard.types';

export const DiscountCard: VFC<Props> = ({ title, text, type, buttonText, link, id, analyticsName }) => {
    const dispatch = useDispatch();

    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, id));

    const isPromoType = type === DISCOUNTS.promo;
    const { space, titleWithoutSpace } = formatTitle(title);

    const { openTariffBuy } = useTariffBuyDialog();

    const onClick = useCallback(() => {
        closePopupHelper(popupNames.MOBILE_DISCOUNTS);

        if (type === DISCOUNTS.promocode) {
            sendAnalytics('activate-promocode', title);
            return;
        }

        sendAnalytics('action-click', analyticsName || title);

        if (type === DISCOUNTS.promo) {
            dispatch(
                historyPush({
                    id: getIdByStorage(EStorageType.subscriptions),
                    hash: DISCOUNTS.special,
                })
            );

            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }

        if (type === DISCOUNTS.summerPromo) {
            dispatch(
                historyPush({
                    id: getIdByStorage(EStorageType.quotaCleaner),
                    search: `?utm_source=cloud_touch_${
                        IS_IOS_MOBILE ? 'ios' : 'and'
                    }&utm_medium=touch_union_quota_landing&utm_campaign=action_summer_action`,
                })
            );
            return;
        }

        if (product) {
            const price = product?.hasTrial ? 0 : product?.discountPrice || product?.price || 0;

            openTariffBuy({ id, price, period: product?.period });
        }
    }, [id, openTariffBuy, product, title, type, analyticsName]);

    const button = useMemo(
        () => (
            <Button
                size="tiny"
                theme="vk"
                middle
                className={classNames(styles.button, { [styles.buttonSecondary]: !isPromoType })}
                primary={isPromoType}
                onClick={onClick}
            >
                {buttonText}
            </Button>
        ),
        [buttonText, isPromoType, onClick]
    );

    if (id && id !== 'promocode' && id !== 'promo' && id !== 'summerPromo' && !product) {
        return null;
    }

    const containsDarkTheme = document.body.classList.contains(VK_UI_DARK_THEME);

    return (
        <div
            className={classNames(styles.root, styles[`root_type_${type}`], {
                [styles.summerPromoDarkTheme]: type === DISCOUNTS.summerPromo && containsDarkTheme,
            })}
        >
            <div className={styles.title}>
                {space && <span className={styles[`${type}_type_units`]}>{space}</span>}
                <span>{titleWithoutSpace}</span>
            </div>
            <Text className={styles.text}>{text}</Text>
            {link ? <Link to={link}>{button}</Link> : button}
        </div>
    );
};
