import settings from 'Cloud/settings';
import { IS_AUTO_TEST_HIDE, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, clearCurrentPromo, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store } from 'reactApp/store';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const SECOND_CLICK_TIP_KEY = 'show-second-click-tip';
const SECOND_CLICK_TIP_HOME_KEY = 'show-second-click-tip-home';
const SECOND_CLICK_TIP_PUBLIC_KEY = 'show-second-click-tip-public';
const SECOND_CLICK_TIP_QUOTA_CLEANER_KEY = 'show-second-click-tip-quota-cleaner';

const mapStoreToTipKey: { [key: string]: string } = {
    [EStorageType.home]: SECOND_CLICK_TIP_HOME_KEY,
    [EStorageType.public]: SECOND_CLICK_TIP_PUBLIC_KEY,
    [EStorageType.quotaCleaner]: SECOND_CLICK_TIP_QUOTA_CLEANER_KEY,
};

export function* initSecondClickPromo() {
    if (storeHelper.getValue(SECOND_CLICK_TIP_KEY)) {
        storeHelper.markAsShown(SECOND_CLICK_TIP_HOME_KEY);
        storeHelper.clearStore(SECOND_CLICK_TIP_KEY);
    }

    if (IS_AUTO_TEST_HIDE || settings?.request?.action || IS_PHONE_BROWSER) {
        return;
    }

    const currentStorage = yield* select(getCurrentStorage);
    const tipKey = mapStoreToTipKey[currentStorage];

    if (!tipKey) {
        return;
    }

    const isDayPast = [SECOND_CLICK_TIP_HOME_KEY, SECOND_CLICK_TIP_PUBLIC_KEY, SECOND_CLICK_TIP_QUOTA_CLEANER_KEY].every((promoKey) => {
        return !storeHelper.getValue(tipKey) || storeHelper.isPassed(promoKey, { daysCount: 1 });
    });

    if (!isDayPast) {
        return;
    }

    if (storeHelper.getValue(tipKey)) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.secondClick,
            promoId: tipKey,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.secondClick));
                storeHelper.markAsShown(tipKey);
            },
            onHide: () => store.dispatch(clearCurrentPromo()),
            onClose: () => {
                store.dispatch(removePromo(EPromoType.secondClick));
            },
        })
    );
}
