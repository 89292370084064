import summerPromoModalImage from 'img/aside-promo-modal/summer-promo-modal.png';
import { IS_ACTION_USER } from 'reactApp/appHelpers/configHelpers';
import { summerPromotion } from 'reactApp/appHelpers/featuresHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const SUMMER_PROMO_MODAL_ID = 'summer-promo-modal';
const SUMMER_PROMO_MODAL_CLOSE_CLICK_COUNT = `${SUMMER_PROMO_MODAL_ID}-close`;
const PERIOD_IN_DAYS = 1;
const MAX_SHOWN_COUNT = 5;
const MAX_ON_CLOSE_CLICK_COUNT = 2;

function markPromoShown() {
    reduxStore.dispatch(promoShown(EPromoType.summerPromo));
    storeHelper.markAsShown(SUMMER_PROMO_MODAL_ID);
    reduxStore.dispatch(removePromo(EPromoType.summerPromo));
}

export function* initSummerPromoModal() {
    const storage = yield* select(getCurrentStorage);
    const shownCount = yield put(UserStorageActions.get(SUMMER_PROMO_MODAL_ID)) || 0;
    const onCloseClickCount = yield put(UserStorageActions.get(SUMMER_PROMO_MODAL_CLOSE_CLICK_COUNT)) || 0;

    if (shownCount > MAX_SHOWN_COUNT || onCloseClickCount > MAX_ON_CLOSE_CLICK_COUNT) {
        return;
    }

    const periodPassed =
        storeHelper.getValue(SUMMER_PROMO_MODAL_ID) && storeHelper.isPassed(SUMMER_PROMO_MODAL_ID, { daysCount: PERIOD_IN_DAYS });

    if (periodPassed) {
        storeHelper.clearStore(SUMMER_PROMO_MODAL_ID);
    }

    const isCorrectUrl = storage === EStorageType.home || storage === EStorageType.public || storage === EStorageType.gallery;

    if (!IS_ACTION_USER || storeHelper.getValue(SUMMER_PROMO_MODAL_ID) || !isCorrectUrl || !summerPromotion) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.summerPromo,
            promoId: SUMMER_PROMO_MODAL_ID,
            onClick() {
                markPromoShown();
                sendPaymentGa({
                    action: 'click',
                    eventCategory: ECategoryGa.ballon,
                    name: 'summer_action',
                });
                opener(
                    getQuotaPromoUrl({
                        query: 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=viewer_balloon_summer_action',
                    }),
                    true
                );
            },
            onShow: () => {
                markPromoShown();
                sendPaymentGa({
                    action: 'show',
                    eventCategory: ECategoryGa.ballon,
                    name: 'summer_action',
                });
            },
            onClose: () => {
                markPromoShown();
                UserStorageActions.set(SUMMER_PROMO_MODAL_CLOSE_CLICK_COUNT, onCloseClickCount + 1);
            },
        })
    );
}

export function* showSummerPromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.summerPromo));
    const shownCount = yield put(UserStorageActions.get(SUMMER_PROMO_MODAL_ID)) || 0;

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        yield openAsidePromoModal({
            title: 'Летняя скидка до 85%',
            text: 'Добавьте места в Облаке и\u00A0отключите рекламу с подпиской Mail Space',
            buttonText: 'Забрать скидку',
            imageUrl: summerPromoModalImage,
            showAboveViewer: true,
            onShow,
            onClick,
            onClose,
        });

        yield put(UserStorageActions.set(SUMMER_PROMO_MODAL_ID, shownCount + 1));
    }
}
