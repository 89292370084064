import { ETabs } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';

enum ETabName {
    quota = 'Хранилище',
    features = 'Преимущества',
    tariffs = 'Тарифы',
    questions = 'Вопросы',
    cleaner = 'Управление',
    apps = 'Приложения',
    feedback = 'Обратная связь',
    help = 'Помощь',
}

export interface Tab {
    id: ETabs;
    isCurrent: boolean;
    isVisible?: boolean;
    text: string;
}

/**
 * При разных условиях, могут отображаться не все табы на странице
 * По этому если приходит таб которого может не быть, мы вычисляем ближайший до него
 */
export const getNearestVisibleTab = (currentTab: ETabs, tabs: Tab[]): ETabs => {
    let result = currentTab;
    for (const tab of tabs) {
        if (tab.isVisible) {
            result = tab.id;
        }
        if (tab.id === currentTab) {
            break;
        }
    }

    return result;
};

export const getTabs = (currentTab, isTariffTabMajor: boolean, isQuotaMajor: boolean): Tab[] => {
    const newQuotaTabs = [ETabs.tariffs, ETabs.features, ETabs.quota, ETabs.cleaner, ETabs.questions];
    const newQuotaTabsWithMajor = [ETabs.tariffs, ETabs.quota, ETabs.features, ETabs.cleaner, ETabs.questions];

    const tabsWithTariffMajor = [ETabs.quota, ETabs.cleaner, ETabs.tariffs, ETabs.features, ETabs.questions];
    const defaultTabs = [ETabs.quota, ETabs.cleaner, ETabs.features, ETabs.tariffs, ETabs.questions];
    let tabs = isTariffTabMajor ? tabsWithTariffMajor : defaultTabs;

    tabs = isQuotaMajor ? newQuotaTabsWithMajor : newQuotaTabs;

    return tabs.map((tab) => ({ id: tab, isCurrent: tab === currentTab, text: ETabName[tab] }));
};

export const getMobileTabs = (currentTab, filterTabs: (tab: ETabs) => boolean, isQuotaCleanerMob: boolean): Tab[] => {
    const defaultTabs = [ETabs.quota, ETabs.features, ETabs.tariffs, ETabs.questions, ETabs.apps, ETabs.feedback, ETabs.help].filter(
        filterTabs
    );
    const tabsWithCleaner = [
        ETabs.quota,
        ETabs.cleaner,
        ETabs.features,
        ETabs.tariffs,
        ETabs.questions,
        ETabs.apps,
        ETabs.feedback,
        ETabs.help,
    ].filter(filterTabs);

    let tabs = defaultTabs;

    if (isQuotaCleanerMob) {
        tabs = tabsWithCleaner;
    }

    return tabs.map((tab) => ({ id: tab, isCurrent: tab === currentTab, text: ETabName[tab] }));
};

export const getTabsChecker = (config) => (tab: ETabs) => !config[tab];
