/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import { Icon16Cancel, Icon16Done, Icon16KeyOutline, Icon16ShoppingCartOutline } from '@vkontakte/icons';
import theme from '@vkontakte/vkui-tokens/themes/cloud/cssVars/theme';
import { ReactComponent as ArrowBlueDownIcon } from 'img/icons/arrow-blue-down.svg';
import { ReactComponent as ArrowBlueUpIcon } from 'img/icons/arrow-blue-up.svg';
import { ReactComponent as DiskoIcon } from 'img/icons/disko.svg';
import { ReactComponent as HistoryIcon } from 'img/icons/history.svg';
import { ReactComponent as MarkIcon } from 'img/icons/mark2.svg';
import { ReactComponent as NoadsIcon } from 'img/icons/noads.svg';
import { ReactComponent as UploadIcon } from 'img/icons/uploads2.svg';
import { ReactComponent as VkMusicIcon } from 'img/icons/vk_music.svg';
import React, { ReactElement, ReactNode } from 'react';
import { isFamilySubsInTariff } from 'reactApp/appHelpers/featuresHelpers';
import { openDisko } from 'reactApp/constants/products/features.constants';
import { quotaFeatures } from 'reactApp/constants/products/quotaFeatures';
import { EPartner } from 'reactApp/modules/subscriptions/subscriptions.types';
import { MailIcon, TrashbinIcon } from 'reactApp/ui/VKUIIcons';

export interface IFeatureData {
    /**
     * Вместо text лучше использовать ?textJsx, если есть
     * В старом компоненте string использовался в dangerouslySetInnerHTML
     * @deprecated
     */
    text: string;
    textJsx?: ReactNode;
    key: string;
    icon?: ReactElement;

    onClick?();

    delimiters?: boolean;
}

const baseFeatures: Record<string, IFeatureData[]> = {
    noads: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <MarkIcon width={12} height={11} />,
        },
    ],
    noadsPro: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <NoadsIcon width={16} height={16} />,
        },
    ],
    paidFeatures: [
        {
            text: 'Версионирование',
            key: 'versions',
            icon: <MarkIcon width={12} height={11} />,
        },
    ],
    upload: [
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
            key: 'upload',
            icon: <MarkIcon width={12} height={11} />,
        },
    ],
    autodelete: [
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <MarkIcon width={12} height={11} />,
        },
    ],
    baseUpload: [
        {
            text: 'Загрузка файлов до\u00A02\u00A0ГБ',
            key: 'baseUpload',
            icon: <MarkIcon width={12} height={11} />,
        },
    ],
    corpUpload: [
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
            key: 'corpUpload',
            icon: <MarkIcon width={12} height={11} />,
        },
    ],
    [EPartner.sferum]: [
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'sferumUpload',
            icon: <MarkIcon width={12} height={11} />,
        },
    ],
    [EPartner.mail]: [
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'vkComboUpload',
            icon: <MarkIcon width={12} height={11} />,
        },
    ],
    giftsOpen: [
        {
            text: 'Плюс подарки',
            key: 'giftsOpen',
            icon: <ArrowBlueDownIcon width={11} height={7} />,
        },
    ],
    giftsClose: [
        {
            text: 'Плюс подарки',
            key: 'giftsClose',
            icon: <ArrowBlueUpIcon width={11} height={7} />,
        },
    ],
    disko: [
        {
            text: 'Без рекламы',
            key: 'ad',
        },
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'upload',
            icon: <MarkIcon width={12} height={11} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <MarkIcon width={12} height={11} />,
        },
        {
            text: '30 дней истории изменений файлов',
            key: 'hist',
            icon: <HistoryIcon width={22} height={22} />,
        },
        {
            text: 'Доступ ко\u00A0всем возможностям Диск&#8209;О:',
            key: 'disko',
            icon: <DiskoIcon width={23} height={17} />,
        },
    ],
    modalPro: [
        {
            text: 'Без рекламы',
            key: 'ad',
        },
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
            key: 'upload',
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
        },
        {
            text: 'Доступ ко\u00A0всем возможностям Диск&#8209;О:',
            key: 'disko',
        },
        {
            text: 'Скидки и бонусы от партнеров',
            key: 'discount',
        },
    ],
    singleQuota: [
        {
            text: 'Без рекламы в Почте и Облаке',
            key: 'ad',
        },
        {
            text: 'Быстрая поддержка — ответим в течение часа с\u00A09:00\u00A0до\u00A021:00\u00A0МСК',
            key: 'support',
        },
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ в Облако',
            key: 'upload',
        },
        {
            text: 'Отправка файлов до\u00A0100\u00A0ГБ в письмах',
            key: 'sending',
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
        },
    ],
    singleQuotaFamily: [
        {
            text: 'Без рекламы в Почте и Облаке',
            key: 'ad',
        },
        {
            text: 'Делитесь местом с близкими',
            key: 'family',
        },
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ в Облако',
            key: 'upload',
        },
        {
            text: 'Отправка файлов до\u00A0100\u00A0ГБ в письмах',
            key: 'sending',
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
        },
        {
            text: 'Приоритетная поддержка',
            key: 'support',
        },
    ],
    singleQuotaBase: [
        {
            text: 'Без рекламы в Почте и Облаке',
            key: 'ad',
            icon: <Icon16Cancel fill={theme.colorIconSecondary.normal.value} />,
        },
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ в Облако',
            key: 'upload',
            icon: <Icon16Cancel fill={theme.colorIconSecondary.normal.value} />,
        },
        {
            text: 'Отправка файлов до\u00A0100\u00A0ГБ в письмах',
            key: 'sending',
            icon: <Icon16Cancel fill={theme.colorIconSecondary.normal.value} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Cancel fill={theme.colorIconSecondary.normal.value} />,
        },
        {
            text: 'Приоритетная поддержка',
            key: 'support',
            icon: <Icon16Cancel fill={theme.colorIconSecondary.normal.value} />,
        },
    ],
    singleQuotaPro: [
        {
            text: 'Без рекламы в Почте и Облаке',
            key: 'ad',
        },
        isFamilySubsInTariff && {
            text: 'Делитесь местом с близкими',
            key: 'family',
        },
        {
            text: 'Быстрая поддержка — ответим в течение часа с\u00A09:00\u00A0до\u00A021:00\u00A0МСК',
            key: 'support',
        },
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ в Облако',
            key: 'upload',
        },
        {
            text: 'Отправка файлов до\u00A0100\u00A0ГБ в письмах',
            key: 'sending',
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
        },
        {
            text: 'Доступ ко\u00A0всем возможностям Диск-О:',
            key: 'disko',
        },
        {
            text: 'Скидки и бонусы от партнеров',
            key: 'discount',
        },
    ],
    // TODO выпилить когда полностью перейдем на TariffSectionNew
    professional: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <NoadsIcon width={18} height={18} />,
        },
        {
            text: 'Загружайте файлы до\u00A0100\u00A0ГБ\nбез\u00A0ограничений',
            key: 'gb100',
            icon: <UploadIcon width={20} height={21} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <TrashbinIcon />,
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
            icon: <HistoryIcon width={22} height={22} />,
        },
        {
            text: 'Доступ ко\u00A0всем возможностям <span style="color: #0470ff">Диск&#8209;О:</span>',
            key: 'disko',
            icon: <DiskoIcon width={23} height={17} />,
            onClick: openDisko,
        },
        {
            text: 'VK Музыка на 3\u00A0месяца\u00A0в подарок!',
            key: 'combo',
            icon: <VkMusicIcon width={24} height={24} />,
        },
    ],
    // TODO выпилить когда полностью перейдем на TariffSectionNew
    professional6M: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <NoadsIcon width={18} height={18} />,
        },
        {
            text: 'Загружайте файлы до\u00A0100\u00A0ГБ<br />без\u00A0ограничений',
            key: 'gb100',
            icon: <UploadIcon width={20} height={21} />,
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={22} height={22} />,
        },
        {
            text: 'Доступ ко\u00A0всем возможностям <span style="color: #0470ff">Диск-О:</span>',
            key: 'disko',
            icon: <DiskoIcon width={23} height={17} />,
            onClick: openDisko,
        },
        {
            text: 'VK Музыка на 1\u00A0месяц\u00A0в подарок!',
            key: 'combo',
            icon: <VkMusicIcon width={24} height={24} />,
        },
    ],
    professional_new: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Загружайте файлы до\u00A0100\u00A0ГБ',
            key: 'gb100',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={18} />,
        },
        {
            key: 'disko',
            text: 'Лицензия Диск-О: на год в подарок',
            icon: <DiskoIcon width={16} height={16} />,
            onClick: openDisko,
        },
        {
            text: 'VK Музыка бесплатно на 3\u00A0месяца',
            key: 'combo',
            icon: <VkMusicIcon width={24} height={24} />,
            delimiters: true,
        },
    ],
    professional_new6M: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Загружайте файлы до\u00A0100\u00A0ГБ',
            key: 'gb100',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: '60 дней истории изменений файлов',
            key: 'hist',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={18} />,
        },
        {
            text: 'Лицензия Диск-О: на 6\u00A0месяцев в подарок',
            key: 'disko',
            icon: <DiskoIcon width={16} height={16} />,
            onClick: openDisko,
        },
        {
            text: 'VK Музыка бесплатно на 1\u00A0месяц',
            key: 'combo',
            icon: <VkMusicIcon width={24} height={24} />,
            delimiters: true,
        },
    ],
    capsule1st: [
        {
            text: '1 ТБ в Облаке на год',
            key: 'year1',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Загружайте файлы до 32 ГБ',
            key: 'big',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: '30 дней истории изменений',
            key: 'hist',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Звонки с помощью Капсулы',
            key: 'vk',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Персональные плейлисты',
            key: 'playlist',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Детские сказки от голосового помощника Маруси',
            key: 'music',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
    ],

    capsule2nd: [
        {
            text: 'Покупка осуществляется на сайте<br />capsula.mail.ru',
            key: 'buy',
            icon: <Icon16ShoppingCartOutline />,
        },
        {
            text: 'После покупки на указанную почту<br />придёт письмо с промокодом на 1 ТБ<br />в Облаке',
            key: 'promo',
            icon: <MailIcon />,
        },
        {
            text: 'При активации промокода привязка<br />карты обязательна!',
            key: 'card',
            icon: <Icon16KeyOutline />,
        },
    ],

    business: [
        {
            text: 'Оплата по счету для юрлиц',
            key: 'business',
            icon: <MarkIcon width={13} height={11} />,
        },
        {
            text: 'Любой объем Облака по запросу',
            key: 'space',
            icon: <MarkIcon width={13} height={11} />,
        },
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <MarkIcon width={13} height={11} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <MarkIcon width={13} height={11} />,
        },
        {
            text: 'Загружайте файлы любого размера без ограничений',
            key: 'nolimits',
            icon: <MarkIcon width={13} height={11} />,
        },
        {
            text: '60 дней истории изменения файлов',
            key: 'hist',
            icon: <MarkIcon width={13} height={11} />,
        },
    ],

    paidWithIcon: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'upload',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={14} height={15} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={18} />,
        },
        {
            text: 'Версионирование',
            key: 'version',
            icon: <Icon16Done fill="var(--vkui--color_background_accent)" width={16} height={16} />,
        },
    ],

    action: [
        {
            text: 'Без рекламы',
            key: 'noads',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Загрузка файлов до\u00A032\u00A0ГБ',
            key: 'upload',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Версионирование',
            key: 'version',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
    ],
    overquota: [
        {
            text: 'Ваши файлы остаются в сохранности',
            key: 'save',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
            key: 'upload',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Автоудаление папок',
            key: 'autodelete',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
        {
            text: 'Никакой рекламы',
            key: 'noads',
            icon: <Icon16Done fill={theme.colorIconAccent.normal.value} />,
        },
    ],
    // tempexp_17127-start
    disableAds: [
        {
            text: 'Без рекламы в Почте и Облаке',
            key: 'ad',
        },
        {
            text: 'Отправка файлов до\u00A0100\u00A0ГБ',
            key: 'sending',
        },
        {
            text: 'Отмена отправки писем',
            key: 'cancel',
        },
    ],
    // tempexp_17127-next-line
};

export const features = {
    ...baseFeatures,
    ...quotaFeatures,
};
