import { PayloadAction } from '@reduxjs/toolkit';
import { captureException, captureMessage } from '@sentry/browser';
import app from 'Cloud/Application/app';
import { logger } from 'lib/logger';
import { MoveAPICall } from 'reactApp/api/MoveApiCall';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { allDocumentsMoreRequest } from 'reactApp/modules/allDocuments/allDocuments.module';
import { isFolder } from 'reactApp/modules/file/utils';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { getCurrentFolderHome, isThisOrParentMounted } from 'reactApp/modules/home/home.selectors';
import { modifyingStart, modifyingStop, moveItemsSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { errorsMove } from 'reactApp/modules/modifying/modifying.constants';
import { getNewItem, getSnackbarButton, getSnackbarText } from 'reactApp/modules/modifying/modifying.helpers';
import { EModifyReason, ESnackbarType, IMoveItems } from 'reactApp/modules/modifying/modifying.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { resetSelected } from 'reactApp/modules/selections/selection.saga';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { EConflictMode } from 'reactApp/types/EConflictMode';
import { all, put, select } from 'redux-saga/effects';

const moveApiCall = ({ id, folder, conflict = EConflictMode.RENAME }) => new MoveAPICall().makeRequest({ home: id, folder, conflict });

function moveRequest(id, folder) {
    return moveApiCall({ id, folder })
        .then((response) => ({ id: response?.data?.body }))
        .catch((error) => ({ error: error?.response?.body }));
}

export function* processMoveItems(oldItems: any[], newIds: any, destination: string) {
    let foldersCount = 0;
    let filesCount = 0;

    const storage: EStorageType = yield select(getCurrentStorage);
    const oldItemsIds: string[] = [];
    const snackbarItems: { name: string }[] = [];
    const isMounted: boolean = yield select(isThisOrParentMounted, destination);
    const isAllDocuments = IS_DOCUMENTS_DOMAIN || storage === EStorageType.alldocuments;

    const newItems = oldItems.reduce((result, item, index) => {
        const newId = newIds[index]?.id;

        if (!newId) {
            return result;
        }

        const isFolderItem = isFolder(item);
        foldersCount += Number(isFolderItem);
        filesCount += Number(!isFolderItem);

        const newItem = getNewItem({ item, newId, isMounted });

        result.push({
            ...newItem,
            url: {},
        });

        oldItemsIds.push(item.id);
        snackbarItems.push({ name: item.name });

        return result;
    }, []);

    const { error } = newIds.find((item) => item.error) || {};

    if (error) {
        yield put(
            showSnackbarAction({
                id: 'move-items-error',
                type: SnackbarTypes.failure,
                text: errorsMove.getMessage(error),
                closable: true,
            })
        );
    }

    captureMessage('MOVE', { extra: error });

    if (!newItems.length) {
        yield put(modifyingStop({ storage, reason: EModifyReason.move }));
        return;
    }

    if (storage === EStorageType.home) {
        // Если переместили текущую папку, то открываем родителя
        const currentFolder = yield select(getCurrentFolderHome);
        const parentId = currentFolder?.parent;

        if (oldItemsIds.includes(currentFolder?.id) && parentId) {
            yield put(changeHomeHistory({ id: parentId }));
        }
    }

    yield put(moveItemsSuccess({ storage, oldIds: oldItemsIds, newItems, newParent: destination }));

    if (isAllDocuments) {
        yield put(allDocumentsMoreRequest());
    }

    try {
        // Добавляем в старое дерево
        yield app?.addOldItems?.(newItems, destination, storage);
    } catch (_) {}

    yield closePopupHelper(popupNames.SELECT_FOLDER_DIALOG);
    yield put(modifyingStop({ storage, reason: EModifyReason.move }));

    yield put(
        showSnackbarAction({
            closable: true,
            id: 'move-items-success',
            type: SnackbarTypes.success,
            ...getSnackbarButton({ items: newItems, destination }),
            text: getSnackbarText({ items: snackbarItems, foldersCount, filesCount, type: ESnackbarType.MOVE }),
        })
    );

    // @ts-ignore
    yield put(updateUser());

    yield resetSelected();
}

export function* handleMoveItems(action: PayloadAction<IMoveItems>) {
    const { items, destination } = action.payload;

    yield put(modifyingStart());

    try {
        const newIds = yield all(items.map((item) => moveRequest(item.id, destination)));

        yield processMoveItems(items, newIds, destination);
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield put(modifyingStop({ reason: EModifyReason.move }));
    }
}
