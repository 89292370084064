import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { historyPush } from 'reactApp/modules/router/router.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { showWelcome } from 'reactApp/modules/welcome/welcome.module';
import { getQuotaPromoUrl, QUOTA_URL } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { getSubscriptionsUrl, SUBSCRIPTIONS_URL } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.constants';
import opener from 'reactApp/utils/opener';

interface IArgs {
    query?: string;
    anchor?: string;
    inSameTab?: boolean;
    isNewTariffsPage?: boolean;
}

export const useGoToSubscriptions = ({ searchSuffix = '' } = {}) => {
    const dispatch = useDispatch();
    const isNewbie = useSelector(UserSelectors.isNewbie);

    const goToSubscriptions = useCallback(
        ({ query = '', anchor = '', inSameTab = true, isNewTariffsPage = false }: IArgs = {}) => {
            if (isNewbie) {
                dispatch(showWelcome());
                return;
            }

            if (inSameTab) {
                let search = searchSuffix;
                if (query) {
                    search = (search ? `${search}&` : '?') + query;
                }

                if (IS_DOCUMENTS_DOMAIN) {
                    return opener(isNewTariffsPage ? QUOTA_URL : SUBSCRIPTIONS_URL);
                }

                dispatch(historyPush({ id: isNewTariffsPage ? '/promo/quota' : '/subscriptions', search, hash: anchor }));
            } else {
                if (isNewTariffsPage) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    opener(getQuotaPromoUrl({ hash: anchor, query }), inSameTab);
                    return;
                }

                opener(getSubscriptionsUrl(query), inSameTab);
            }
        },
        [isNewbie]
    );

    return { goToSubscriptions };
};
