import { useSelector } from 'react-redux';
import { getIntegrationClient } from 'reactApp/modules/integration/integration.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';

export const useIsTutoriaIntegration = () => {
    const storage = useSelector(getCurrentStorage);
    const { isInlineIntegration, isIntegration } = getStorage(storage);
    const { isTutoria } = useSelector(getIntegrationClient);

    return isInlineIntegration || (isTutoria && isIntegration);
};
