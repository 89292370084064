export interface ListenerType<T> {
    (event: T): void;
}

export interface DisposableType {
    dispose();
}

export interface TypedEventType<T> {
    on(listener: ListenerType<T>): DisposableType;
    off(listener: ListenerType<T>): void;
    emit(event: T): void;
}

/**
 * Реализация простейшего event emitter. Поддерживает подписку на событие, отписку и нотификацию подписчиков.
 */
export class TypedEvent<T> implements TypedEventType<T> {
    private listeners: ListenerType<T>[] = [];

    on(listener: ListenerType<T>): DisposableType {
        this.listeners.push(listener);
        return {
            dispose: () => this.off(listener),
        };
    }

    off(listener: ListenerType<T>) {
        const callbackIndex = this.listeners.indexOf(listener);
        if (callbackIndex > -1) {
            this.listeners.splice(callbackIndex, 1);
        }
    }

    emit(event: T) {
        this.listeners.forEach((listener) => listener(event));
    }
}
