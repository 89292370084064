import { bytesToNDigits } from '@mail/cross-sizes-utils';
import settings from 'Cloud/settings';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import React from 'react';
import {
    GetExpiredAndroidSubscriptionAPICall,
    GetExpiredIosSubscriptionAPICall,
} from 'reactApp/api/billing/GetExpiredDeviceSubscriptionAPICall';
import { IS_AUTO_TEST_HIDE, IS_IOS_MOBILE } from 'reactApp/appHelpers/configHelpers';
import { renderSaveSubsPopup } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog.helpers';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureSaveSubscriptionPromo, getFeatureSaveSubscriptionTransit } from 'reactApp/modules/features/features.selectors';
import { openSaveSubscriptionPayment } from 'reactApp/modules/payment/payment.module';
import { productsController } from 'reactApp/modules/products/products.controller';
import { initProducts, loadProductsSuccess } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { showPromoBanner } from 'reactApp/modules/promo/sagas/topBanner.saga';
import { getCurrentStorage, isReactLandingPage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { handleFutureRequest, handleSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.saga';
import { getMobileSubscriptionForTransit, hasFutureTransitSubscription } from 'reactApp/modules/subscriptions/subscriptions.selectors';
import { isIosSubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { store } from 'reactApp/store';
import { Product } from 'reactApp/types/Billing';
import { renderMobileSplashScreen } from 'reactApp/ui/Mobile/SplashScreen/helpers/SplashScreen.helpers';
import { useSaveSubscriptionScreen } from 'reactApp/ui/Mobile/SplashScreen/hooks/useSaveSubscriptionScreen';
import { ESplashScreenAction, sendSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';
import { SaveSubscriptionTopBanner } from 'reactApp/ui/SaveSubscriptionTopBanner/SaveSubscriptionTopBanner';
import { put as reduxPut } from 'redux-saga/effects';
import { call, put, select, take } from 'typed-redux-saga';

const MOBILE_SAVE_SUBS_PROMO_ID = 'mobileSaveSubs';
const SAVE_SUBS_TOP_BANNER_ID = 'saveSubsTopBanner';
const SAVE_SUBS_POPUP_ID = 'saveSubsPopup';

const SAVE_SUBS_REQ = 'save-subs-req';

let subscriptionForPromo;

function* gerExpiredSubscription(isPhone: boolean) {
    const featureConfig = yield* select(getFeatureSaveSubscriptionPromo);

    if (!featureConfig) {
        return;
    }

    if (subscriptionForPromo) {
        return subscriptionForPromo;
    }

    const { startDate = 0, endDate = 0 } = featureConfig || {};

    if ((isPhone && IS_IOS_MOBILE) || !isPhone) {
        const { data: dataIos } = yield* call(new GetExpiredIosSubscriptionAPICall().makeRequest);

        // если есть подписка на ios, подходящая под даты, возвращаем ее
        if (dataIos && startDate < dataIos.expire_at && dataIos.expire_at < endDate) {
            return {
                ...dataIos,
                quota: bytesToNDigits(dataIos.services.quota, 3),
                isTransit: false,
                isIos: true,
            };
        }
    }

    // если подписки на ios нет, проверям подписку на android
    const { data: dataAndroid } = yield* call(new GetExpiredAndroidSubscriptionAPICall().makeRequest);

    // если есть подписка на android, подходящая под даты, возвращаем ее
    if (dataAndroid && startDate < dataAndroid.expire_at && dataAndroid.expire_at < endDate) {
        return {
            ...dataAndroid,
            quota: bytesToNDigits(dataAndroid.services.quota, 3),
            isTransit: false,
            isIos: false,
        };
    }
}

function* getExistSubscription() {
    const featureConfig = yield* select(getFeatureSaveSubscriptionTransit);

    if (!featureConfig) {
        return;
    }

    yield handleFutureRequest();

    const hasTransit = yield* select(hasFutureTransitSubscription);

    if (hasTransit) {
        return;
    }

    yield handleSubscriptionsRequest({ skipFutureReq: true });

    const subscription = yield* select(getMobileSubscriptionForTransit);

    if (!subscription) {
        return;
    }

    const isIos = isIosSubscription(subscription);

    return {
        isTransit: true,
        isIos,
        quota: subscription.space,
        source: isIos ? 'ios' : 'android',
    };
}

function* getSubscriptionForPromo(isPhoneBanner = false) {
    const isPaidUser = yield* select(UserSelectors.isPaidUser);
    const isPhone = yield* select(EnvironmentSelectors.isPhone);
    const isWebview = yield* select(EnvironmentSelectors.isWebview);
    const isAnonymous = yield* select(UserSelectors.isAnonymous);
    const isBizUser = yield* select(UserSelectors.isBizUser);
    const isLP = isReactLandingPage();

    if (
        isAnonymous ||
        isBizUser ||
        !(isPhoneBanner ? isPhone : !isPhone) ||
        isWebview ||
        settings?.request?.action ||
        isLP ||
        IS_AUTO_TEST_HIDE
    ) {
        return;
    }

    const reqDate = yield reduxPut(UserStorageActions.get(SAVE_SUBS_REQ));

    // если прошло меньше 1 дня между повторными запросами за подпиской, то снова запрос не делаем
    if (reqDate && !isAfter(new Date(), addDays(reqDate, 1))) {
        return;
    }

    if (isPaidUser) {
        subscriptionForPromo = yield getExistSubscription();
    } else {
        subscriptionForPromo = yield gerExpiredSubscription(isPhoneBanner);
    }

    yield reduxPut(UserStorageActions.set(SAVE_SUBS_REQ, Date.now()));

    return subscriptionForPromo;
}

export function* initSaveSubscriptionPopup() {
    const storage = yield* select(getCurrentStorage);
    const { isPublicOrStock, isSubscription } = getStorage(storage);

    if (isPublicOrStock || isSubscription) {
        return;
    }

    if (storeHelper.getValue(SAVE_SUBS_POPUP_ID) && !storeHelper.isPassed(SAVE_SUBS_POPUP_ID, { daysCount: 3 })) {
        return;
    }

    const subscription = yield getSubscriptionForPromo();

    if (!subscription) {
        return;
    }

    yield put(initProducts());
    yield take(loadProductsSuccess);

    const product = yield select(ProductsSelectors.getSaveSubscriptionProduct, {
        isTransit: subscription.isTransit,
        isIos: subscription.isIos,
        quota: subscription.quota.space,
    });

    if (!product) {
        return;
    }

    const sendAnalytics = (action: ESplashScreenAction) => {
        sendSplashScreenAnalytics({
            action,
            name: subscription.isTransit ? 'save-subs-popup-transit' : 'save-subs-popup',
            storage,
        });
    };

    yield put(
        addPromoToShowQueue({
            type: EPromoType.saveSubsPopup,
            promoId: SAVE_SUBS_POPUP_ID,
            onShow: () => {
                sendAnalytics(ESplashScreenAction.show);
                store.dispatch(promoShown(EPromoType.saveSubsPopup));
                storeHelper.markAsShown(SAVE_SUBS_POPUP_ID);
            },
            onClose: () => {
                sendAnalytics(ESplashScreenAction.close);
                store.dispatch(removePromo(EPromoType.saveSubsPopup));
            },
            onClick: () => sendAnalytics(ESplashScreenAction.click),
            source: subscription.source,
            productId: product.id,
            oldSubscriptionSpace: subscription.quota,
            isTransit: subscription.isTransit,
        })
    );
}

export function* showSaveSubscriptionPopup() {
    const saveSubsPopup = yield* select(PromoSelectors.getPromo(EPromoType.saveSubsPopup));
    const isAnyDialogShown = yield* select(isDialogVisible);
    const isViewerOpening = yield* select(ViewerSelectors.isViewerOpening);
    const isViewerActive = yield* select(ViewerSelectors.isViewerActive);
    // TODO: если id невалидный, то product будет undefined и всё посыпется. Стоит предусмотреть и убрать as Product
    const product = (yield* select(ProductsSelectors.getProductById, saveSubsPopup?.productId)) as Product;

    if (saveSubsPopup && !isAnyDialogShown && !isViewerActive && !isViewerOpening) {
        renderSaveSubsPopup({
            onClose: saveSubsPopup.onClose,
            onShow: saveSubsPopup.onShow,
            onClick: saveSubsPopup.onClick,
            source: saveSubsPopup.source,
            product,
            oldSubscriptionSpace: saveSubsPopup.oldSubscriptionSpace,
            isTransit: saveSubsPopup.isTransit,
        });
    }
}

export function* initMobileSaveSubscriptionPromo() {
    const storage = yield* select(getCurrentStorage);
    const { isPublicOrStock, isSubscription } = getStorage(storage);

    if (isPublicOrStock || isSubscription) {
        return;
    }

    const subscription = yield getSubscriptionForPromo(true);

    if (!subscription) {
        return;
    }

    if (storeHelper.getValue(MOBILE_SAVE_SUBS_PROMO_ID) && !storeHelper.isPassed(MOBILE_SAVE_SUBS_PROMO_ID, { daysCount: 1 })) {
        return;
    }

    yield productsController.loadProducts();

    const product = yield select(ProductsSelectors.getSaveSubscriptionProduct, {
        isTransit: subscription.isTransit,
        isIos: subscription.isIos,
        quota: subscription.quota.space,
    });

    if (!product) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.mobileSaveSubs,
            promoId: MOBILE_SAVE_SUBS_PROMO_ID,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.mobileSaveSubs));
                storeHelper.markAsShown(MOBILE_SAVE_SUBS_PROMO_ID);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.mobileSaveSubs));
            },
            source: subscription.source,
            productId: product.id,
            oldSubscriptionSpace: subscription.quota,
            isTransit: subscription.isTransit,
        })
    );
}

export function* showMobileSaveSubscriptionPromo() {
    const mobileSaveSubscriptionPromo = yield* select(PromoSelectors.getPromo(EPromoType.mobileSaveSubs));

    if (!mobileSaveSubscriptionPromo) {
        return;
    }

    mobileSaveSubscriptionPromo.onShow();

    yield call(
        renderMobileSplashScreen,
        {
            contentHook: useSaveSubscriptionScreen,
            hookParams: {
                productId: mobileSaveSubscriptionPromo.productId,
                source: mobileSaveSubscriptionPromo.source,
                oldSubscriptionSpace: mobileSaveSubscriptionPromo.oldSubscriptionSpace,
                isTransit: mobileSaveSubscriptionPromo.isTransit,
            },
        },
        mobileSaveSubscriptionPromo.onClose
    );
}

export function* initSaveSubscriptionTopBanner() {
    if (storeHelper.getValue(SAVE_SUBS_TOP_BANNER_ID) && !storeHelper.isPassed(SAVE_SUBS_TOP_BANNER_ID, { daysCount: 1 })) {
        return;
    }

    const subscription = yield getSubscriptionForPromo();

    if (!subscription) {
        return;
    }

    const storage = yield* select(getCurrentStorage);

    const sendAnalytics = (action: ESplashScreenAction) => {
        sendSplashScreenAnalytics({
            action,
            name: subscription.isTransit ? 'save-subs-banner-transit' : 'save-subs-banner',
            storage,
        });
    };

    showPromoBanner({
        onShow: () => {
            sendAnalytics(ESplashScreenAction.show);
            storeHelper.markAsShown(SAVE_SUBS_TOP_BANNER_ID);
        },
        onClose: () => sendAnalytics(ESplashScreenAction.close),
        onClick: () => {
            sendAnalytics(ESplashScreenAction.click);
            store.dispatch(
                openSaveSubscriptionPayment({
                    oldSubscriptionSpace: subscription.quota,
                    source: subscription.source,
                    isTransit: subscription.isTransit,
                })
            );
        },
        closeColor: 'color_white',
        height: 60,
        htmlReact: <SaveSubscriptionTopBanner isTransit={subscription.isTransit} />,
    });
}
