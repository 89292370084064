import React, { memo, ReactElement, ReactEventHandler, useEffect } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { Product } from 'reactApp/types/Billing';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { noop } from 'reactApp/utils/helpers';
import { isMonthPeriod } from 'reactApp/utils/Period';

import { DetailedTariffBenefitsContent } from './DetailedTariffBenefitsContent';
import { DetailedTariffBenefitsHeader } from './DetailedTariffBenefitsHeader';
import styles from './DetailedTariffBenefitsPopup.css';

interface DetailedTariffBenefitsPopupProps {
    onClose?: (event?: MouseEvent) => void;
    product: Product;
    buttonText: string;
    onButtonClick: ReactEventHandler;
}

export const DetailedTariffBenefitsPopup = memo(function DetailedTariffBenefitsPopup({
    onClose = noop,
    product,
    buttonText,
    onButtonClick,
}: DetailedTariffBenefitsPopupProps): ReactElement {
    const { id: productId, space, period } = product;
    useEffect(() => {
        emitAnalyticEvent(AnalyticEventNames.MODAL_TARIFF_ADVANTAGES_SHOW);
        sendQuotaGa({
            action: 'modal-tariff',
            label: 'benefits_view',
            tariff: productId,
            platform: 'web',
        });
    }, []);
    return (
        <Dialog
            open
            closeOnDimmerClick
            closeOnEscape
            id="detailed-tariff-benefits-popup"
            closable
            size="large"
            className={styles.root}
            onCancel={onClose}
        >
            <div>
                <DetailedTariffBenefitsHeader units={space.units} space={space.space} isMonth={isMonthPeriod(period)} />
                <DetailedTariffBenefitsContent product={product} buttonText={buttonText} onButtonClick={onButtonClick} />
            </div>
        </Dialog>
    );
});
