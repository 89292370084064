import { useSelector } from 'react-redux';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { RootState } from 'reactApp/store';
import { Product } from 'reactApp/types/Billing';

export const useTariffs = ({
    quota,
    isUserWithoutPayment,
    isUserFreeTb,
    isPaidUser,
    productId,
}: {
    quota: number;
    isUserWithoutPayment: boolean;
    isUserFreeTb: boolean;
    isPaidUser: boolean;
    productId: string | undefined;
}): Product | undefined => {
    const forcedTariff = useSelector((state: RootState) => ProductsSelectors.getAvailableProductById(state, productId));
    const month = useSelector((state: RootState) => ProductsSelectors.getMonthProductByQuota(state, quota));
    const discountProduct = useSelector((state: RootState) => ProductsSelectors.getProductById(state, PROMO_TARIFFS?.discountMailPopup));
    const trial = useSelector((state: RootState) => ProductsSelectors.getMailTrialByQuota(state, quota));

    // если найден тариф, который стоит зафорсить, то предлагаем его, если с ним можно будет загрузить все файлы
    if (forcedTariff && forcedTariff.space.original > quota) {
        return forcedTariff;
    }

    // если пользователь никогда не совершал оплат в Облаке, не бесплатный ТБ и ему доступен триал - возвращаем триал
    if (isUserWithoutPayment && !isUserFreeTb && trial) {
        return trial;
    }

    // если у пользователя были оплаты в облаке, но он бесплатник(но не 1ТБ)
    // - годовый скидочный тариф, при условии что квота влезет в квоту тарифа
    if (!isPaidUser && !isUserWithoutPayment && !isUserFreeTb && discountProduct && discountProduct.space.original > quota) {
        return discountProduct;
    }

    // платникам, бесплатным ТБ - обычный месячный тариф
    return month;
};
