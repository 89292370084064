import { Icon20CloudArrowUpOutline, Icon20Dropdown } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as CrossIcon } from 'img/icons/documents/cross.svg';
import throttle from 'lodash.throttle';
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { startUploadDocuments } from 'reactApp/modules/personalDocuments/personalDocuments.module';
import { getDocumentItemById } from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { DocumentsItem } from 'reactApp/modules/personalDocuments/personalDocuments.types';
import { getAllowedMimeTypes } from 'reactApp/modules/upload/upload.selectors';
import { getEntries, getFiles } from 'reactApp/modules/uploading/helpers/dataTransfer.helpers';
import { addToUploadQueue } from 'reactApp/modules/uploading/uploading.module';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { RootState } from 'reactApp/store';
import { Hint } from 'reactApp/ui/Hint/Hint';

import { ITEMS_META } from '../PersonalDocuments.constants';
import { PhotoCard } from '../PhotoCard/PhotoCard';
import styles from './Item.css';
import { useCollapsibleItem } from './Item.hooks';
import { IProps } from './Item.types';

// eslint-disable-next-line max-lines-per-function
export const Item = memo(function Item(props: IProps) {
    const dispatch = useDispatch();
    const { id, count, avatar } = props;
    const documentsCount = count?.all;
    const item = useMemo(() => ITEMS_META[id], [id]);
    const path = `/documents/${item.title}`;
    const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');
    const file = useSelector((state: RootState) => getDocumentItemById(state, avatar));

    const isSectionClosed = dispatch(UserStorageActions.get(id)) as unknown as number;
    const [isOpened, onCollapse] = useCollapsibleItem(!isSectionClosed);
    const [isDragOver, setDragOver] = useState<boolean>(false);
    const ref = useRef<any>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const onUploadClick = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        inputRef.current?.click();
    }, []);

    const onInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const files = e.target?.files;

            if (!files?.length) {
                return;
            }

            dispatch(addToUploadQueue({ files: Array.from(files), descriptorOptions: { documentType: item.title } }));
        },
        [item.title]
    );

    const throttledSetDragOver = throttle(setDragOver, 100);

    const onDrop = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            const data: { entries?: any[]; files?: any[] } = {};
            const dataTransfer = e.dataTransfer;

            if (!dataTransfer) {
                throttledSetDragOver(false);
                return;
            }

            const entries = getEntries(dataTransfer);

            if (entries && entries.length) {
                data.entries = entries;
            } else {
                const files = getFiles(dataTransfer);

                if (files && files.length) {
                    data.files = files;
                }
            }

            if (!data.entries?.length && !data.files?.length) {
                throttledSetDragOver(false);
                return;
            }

            throttledSetDragOver(false);
            dispatch(startUploadDocuments({ documentType: item.title, data: dataTransfer }));
        },
        [dispatch, item.title]
    );

    const onClick = useCallback(
        (e) => {
            onCollapse();
            dispatch(UserStorageActions.set(id, isOpened ? 1 : 0));
            e.preventDefault();
            e.stopPropagation();
        },
        [dispatch, isOpened, onCollapse, id]
    );

    const onDragEnter = () => {
        throttledSetDragOver(true);
    };

    const onDragOver = useCallback((e) => {
        e.preventDefault();
        e.dataTransfer.effectAllowed = 'copy';
        e.dataTransfer.dropEffect = 'copy';
    }, []);

    const onDragLeave = () => {
        throttledSetDragOver(false);
    };

    useEffect(() => {
        const node = ref.current;
        node?.addEventListener('dragenter', onDragEnter);
        node?.addEventListener('dragleave', onDragLeave);
        node?.addEventListener('dragover', onDragOver);
        node?.addEventListener('drop', onDrop);

        return () => {
            node?.removeEventListener('dragenter', onDragEnter);
            node?.removeEventListener('dragleave', onDragLeave);
            node?.removeEventListener('dragover', onDragOver);
            node?.removeEventListener('drop', onDrop);
        };
    }, [documentsCount]);

    if (!documentsCount || !avatar) {
        return (
            <>
                <input
                    type="file"
                    multiple
                    className={styles.input}
                    ref={inputRef}
                    onChange={onInputChange}
                    accept={allowedMimeTypes}
                    data-qa-id={item.title}
                />
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_empty]: true,
                        [styles.root_dragOver]: isDragOver,
                    })}
                    onClick={onUploadClick}
                    ref={ref}
                    data-qa-id={item.title}
                >
                    <div className={styles.icon}>
                        <div className={styles.iconWrapper}>{item.Icon}</div>
                    </div>
                    <div className={styles.name}>{item.name}</div>

                    <div className={styles.hoverItem}>
                        <div className={styles.icon}>
                            <div className={styles.iconWrapper}>
                                <div className={styles.iconContainer}>{item.FilledIcon}</div>
                            </div>
                        </div>
                        <div className={styles.title}>
                            <CrossIcon className={styles.cross} />
                            {item.uploadTitle}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <input
                type="file"
                multiple
                className={styles.input}
                ref={inputRef}
                onChange={onInputChange}
                accept={allowedMimeTypes}
                data-qa-id={item.title}
            />

            <Link
                className={classNames({
                    [styles.root]: true,
                    [styles.root_opened]: isOpened,
                    [styles.root_dragOver]: isDragOver,
                })}
                ref={ref}
                to={path}
                data-qa-id={item.title}
            >
                <div className={styles.header} onClick={onClick} ref={headerRef}>
                    <div className={styles.headerSection}>
                        <span>{item.name}</span>
                        <div className={styles.counter}>{documentsCount}</div>
                    </div>
                    <div className={styles.headerSection}>
                        <Hint text="Загрузить ещё">
                            <div className={styles.upload} onClick={onUploadClick}>
                                <Icon20CloudArrowUpOutline />
                            </div>
                        </Hint>
                        <Icon20Dropdown className={styles.arrow} />
                    </div>
                </div>
                <div className={styles.content}>
                    <PhotoCard item={file as DocumentsItem} isOpened={isOpened} />
                </div>

                <div className={styles.dropArea}>
                    <div className={styles.title}>
                        <CrossIcon className={styles.cross} />
                        {item.uploadTitle}
                    </div>
                </div>
            </Link>
        </>
    );
});
