import { getExtension } from 'reactApp/modules/file/helpers/getExtention';
import { isPreviewable } from 'server/helpers/file/isPreviewable';

export const getHashThumbnails = (item) => {
    if (!isPreviewable(item)) {
        return;
    }

    const ext = getExtension(item.folder ?? item);

    const hashext = `${item.hash}.${encodeURIComponent(ext)}`;

    return `thumb/w14/${hashext}`;
};
