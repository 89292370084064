import { captureException } from '@sentry/browser';
import { Rubles } from 'Cloud/Application/Rubles';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { AttachesSidebarAB, attachesSidebarVersion } from 'reactApp/appHelpers/featuresHelpers';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';

import styles from './ReactViewer.css';

const DOM_ID_VIEWER_SIDEBAR = config.get('DOM_ID_VIEWER_SIDEBAR');

export const AttachesSidebarBanner = memo((): ReactElement | null => {
    const updateAds = () => {
        try {
            Rubles?.updateViewerAttachesSidebar();
        } catch (error) {
            captureException(error);
        }
    };

    const renderItem = () => (
        <div
            className={classNames(styles.attachesSidebar, {
                [styles.attachesSidebar_left]: attachesSidebarVersion === AttachesSidebarAB.Left,
                [styles.attachesSidebar_rightHeader]: attachesSidebarVersion === AttachesSidebarAB.RightHeader,
            })}
        >
            <DisableButton
                className={classNames(styles.attachesSidebar_button, {
                    [styles.attachesSidebar_button_rightHeader]: attachesSidebarVersion === AttachesSidebarAB.RightHeader,
                })}
                id="sidebar-at"
            />
            <div className={styles.attachesSidebar_content} id={DOM_ID_VIEWER_SIDEBAR} />
        </div>
    );

    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds,
        renderItem,
    });
});

AttachesSidebarBanner.displayName = 'AttachesSidebarBanner';

AttachesSidebarBanner.whyDidYouRender = true;
