import { Text } from '@vkontakte/vkui';
import mailSpaceTooltip from 'img/mailSpaceTooltip.png';
import mailSpaceTooltipDark from 'img/mailSpaceTooltipDark.png';
import React, { RefObject, useCallback, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_AUTO_TEST_HIDE, IS_FAMILY_USER } from 'reactApp/appHelpers/configHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

interface IProps {
    targetRef: RefObject<HTMLDivElement>;
    onClose: () => void;
    onShow: () => void;
}

const TOOLTIP_ID = 'quota-tooltip';
const TOOLTIP__SHOW_COUNT = `${TOOLTIP_ID}-count`;

export const QuotaTooltip: VFC<IProps> = ({ targetRef, onShow, onClose }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isDarkTheme = isDarkThemeModeEnabled();
    const shownCount = Number(dispatch(UserStorageActions.get(TOOLTIP__SHOW_COUNT))) || 0;
    const periodInDays = shownCount < 5 ? 7 : 14;
    const periodPassed = storeHelper.getValue(TOOLTIP_ID) && storeHelper.isPassed(TOOLTIP_ID, { daysCount: periodInDays });

    if (periodPassed) {
        storeHelper.clearStore(TOOLTIP_ID);
    }

    const handleClose = useCallback(() => {
        setShow(false);
        onClose();
        dispatch(UserStorageActions.set(TOOLTIP_ID, true));
    }, [dispatch]);

    const needToHide = storeHelper.getValue(TOOLTIP_ID) || IS_AUTO_TEST_HIDE || isCorpUser || isBizUser || IS_FAMILY_USER;

    const handleShow = useCallback(() => {
        onShow?.();
        storeHelper.markAsShown(TOOLTIP_ID);
        dispatch(UserStorageActions.set(TOOLTIP__SHOW_COUNT, shownCount + 1));
    }, []);

    if (needToHide || !show || !targetRef.current) {
        return null;
    }

    return (
        <FloatingTooltip
            target={targetRef}
            title="Подписка Mail Space"
            text={
                <Text>
                    Больше места
                    <br />в Облаке и никакой
                    <br />
                    рекламы
                </Text>
            }
            placement={ETooltipPlacement.right}
            onClose={handleClose}
            onShow={handleShow}
            qaId="tooltip-quota-widget"
            img={isDarkTheme ? mailSpaceTooltipDark : mailSpaceTooltip}
            overAll
            usePortal
            appearance={isDarkTheme ? ETooltipAppearance.contrast : undefined}
            onTooltipClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
            paddingRight={82}
        />
    );
};

QuotaTooltip.displayName = 'QuotaTooltip';
