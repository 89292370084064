/**
 *  Преобразует возможные значения фичи в строку.
 *  Строки остаются как есть (включая пустую строку).
 *  Числа преобразуются явным образом (включая 0, -1).
 *  Для остальных значений не входящих в unexpectedValues возвращается пустая строка.
 *  Значения из unxepectedValue возвращаются как есть
 *  @param {string} expName - имя эксперимента.
 *  @param {unknown} featureValue - текущее значение фичи для эксперимента.
 *  @param {boolean} unexpectedValues - Default: []; Список значений, обработка которых не предусмотрена .
 */

export function featureValueToString(featureValue: unknown, unexpectedValues: unknown[] = []) {
    if (unexpectedValues.includes(featureValue)) {
        return featureValue;
    }
    if (typeof featureValue === 'string') {
        return featureValue;
    }
    if (typeof featureValue === 'number') {
        return String(featureValue);
    }
    return '';
}
