import { EStatus, ESubscriptionsTypes, ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { Product } from 'reactApp/types/Billing';
import { formatDefaultDate, formatFileHistoryDate } from 'reactApp/utils/formatDate';
import {
    getPeriodNameAsWord,
    getTrialOrDiscountDateEnd,
    getTypeOfPeriodName,
    isMonth3Period,
    isMonth6Period,
    isYear2Period,
    nowIsTrialOrDiscountPeriod,
} from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

export const getExpiresTitle = (expires: number, status?: EStatus) =>
    `${status === EStatus.SUSPENDED ? '' : 'до '}${formatDefaultDate(expires)}`;

export const getTariffConditionsText = (subscription: ISubscription, product: Product) => {
    const isTrialPeriod = product?.trialPeriod && nowIsTrialOrDiscountPeriod(product.trialPeriod, subscription.start);

    let productPeriodSuffix =
        isYear2Period(product?.period) || isMonth6Period(product?.period)
            ? '— разовый платёж'
            : `в ${getTypeOfPeriodName(product?.period)}`;

    if (isMonth3Period(product?.period)) {
        productPeriodSuffix = 'на 3 месяца';
    }

    if ((subscription?.isTrial || isTrialPeriod) && product?.trialPeriod) {
        const trialDateEnd = getTrialOrDiscountDateEnd(product.trialPeriod, subscription.start);

        const freeCondition = `${getPeriodNameAsWord(product?.trialPeriod, false, true)} бесплатно`;
        const discountCondition =
            product?.discountPeriod &&
            `затем ${formatPrice(product.discountPrice, 'ru')} ₽ в ${getTypeOfPeriodName(product.discountPeriod)}`;
        const defaultCondition = `${formatPrice(product?.price, 'ru')} ₽ в ${getTypeOfPeriodName(product?.period)}`;

        if (discountCondition) {
            const discountDateEnd = getTrialOrDiscountDateEnd(product?.discountPeriod, trialDateEnd);
            return `${freeCondition}, ${discountCondition},\nс ${formatFileHistoryDate(discountDateEnd.valueOf())} — ${defaultCondition}`;
        }

        return `${freeCondition}, затем ${defaultCondition}`;
    } else if (subscription.id !== ESubscriptionsTypes.base) {
        const periodLetter = product?.period?.slice(-1);
        const period = product?.discountPeriodCount ? `${product.discountPeriodCount}${periodLetter}` : product?.discountPeriod;
        const isDiscountPeriod = product?.discountPeriod && nowIsTrialOrDiscountPeriod(period, subscription.start);

        return product?.discountPeriod && product?.discountPrice && isDiscountPeriod
            ? `${getPeriodNameAsWord(period || '', false, true)} за ${formatPrice(product.discountPrice, 'ru')} ₽, затем ${formatPrice(
                  product?.price,
                  'ru'
              )} ₽ в ${getTypeOfPeriodName(product?.period)}`
            : `${formatPrice(product?.price, 'ru')} ₽ ${productPeriodSuffix}`;
    }

    return `${formatPrice(product.price, 'ru')}₽ ${productPeriodSuffix}`;
};

export const getCardNumber = <T extends { number: string }>(card: T | null) => card && `Карта ∙∙${card.number.slice(-4)}`;

export const getNextPayment = (renewAt?: number, expires?: number) => {
    if (renewAt) {
        return formatFileHistoryDate(renewAt);
    }

    if (expires) {
        return formatFileHistoryDate(expires);
    }

    return '';
};
