import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import { itemV4ToV2 } from 'reactApp/api/helpers/apiV4Helpers';
import { getCoordinatesId } from 'reactApp/modules/faces/faces.helpers';
import {
    ICoordinates,
    IFaceApiData,
    IFacesStore,
    IRequestFacesListStartData,
    IRequestFacesListSuccessData,
    IRequestFacesOnPhotoStartData,
    IRequestFacesOnPhotoSuccessData,
    IRequestFilesWithFaceStartData,
    IRequestFilesWithFaceSuccessData,
    ISelectFaceData,
} from 'reactApp/modules/faces/faces.types';
import { normalizePublicFile } from 'reactApp/modules/public/public.helpers';
import { PublicFile } from 'reactApp/modules/public/public.types';

export const requestFacesListStart = createAction<IRequestFacesListStartData>('faces/requestFacesListStart');
export const requestFacesListLoadMoreRequest = createAction('faces/requestFacesListLoadMoreRequest');
export const requestFacesListSuccess = createAction<IRequestFacesListSuccessData>('faces/requestFacesListSuccess');
export const requestFacesListFail = createAction<string>('faces/requestFacesListFail');
export const requestFilesWithFaceStart = createAction<IRequestFilesWithFaceStartData>('faces/requestFilesWithFaceStart');
export const requestMoreFilesWithFaceStart = createAction<IRequestFilesWithFaceStartData>('faces/requestMoreFilesWithFaceStart');
export const requestFilesWithFaceSuccess = createAction<IRequestFilesWithFaceSuccessData>('faces/requestFilesWithFaceSuccess');
export const requestFilesWithFaceFail = createAction<{ error: string; faceId: string }>('faces/requestFilesWithFaceFail');
export const selectFace = createAction<ISelectFaceData>('faces/selectFace');
export const resetFace = createAction('faces/resetFace');
export const requestFacesOnPhotoStart = createAction<IRequestFacesOnPhotoStartData>('faces/requestFacesOnPhotoStart');
export const requestFacesOnPhotoSuccess = createAction<IRequestFacesOnPhotoSuccessData>('faces/requestFacesOnPhotoSuccess');
export const setLoadingFacesRequestState = createAction<IRequestFacesListStartData>('faces/setLoadingFacesRequestState');

const initialState: IFacesStore = {
    files: {},
    currentId: '',
    selectedFaceId: '',
    faces: {},
    facesState: {},
    requestFacesListState: {},
    requestFacesListCursor: '',
    facesIdxByFile: {},
    coordinates: {},
};

const facesSchema = new schema.Entity<IFaceApiData>('faces', undefined, {
    processStrategy: (value) => {
        return {
            id: value.id,
            imageUrl: value.image,
            numOfPhotos: value.count,
            coordinates: value.coordinates ?? [],
        };
    },
});

const fileItemSchema = new schema.Entity<PublicFile>('files', undefined, {
    idAttribute: 'weblink',
    processStrategy: (value) => {
        return normalizePublicFile(itemV4ToV2(value)) as PublicFile;
    },
});

export const facesReducer = createReducer(initialState, {
    [setLoadingFacesRequestState.type]: (state, action: ReturnType<typeof setLoadingFacesRequestState>) => {
        state.currentId = action.payload.id;
        state.requestFacesListState = { isLoading: true };
    },
    [requestFacesListSuccess.type]: (state, action: ReturnType<typeof requestFacesListSuccess>) => {
        const { id, faces, cursor } = action.payload;

        if (id !== state.currentId) {
            return;
        }

        state.requestFacesListState = { isLoaded: true };
        state.requestFacesListCursor = cursor;

        const normalizedData = normalize(faces, [facesSchema]);

        if (normalizedData.entities.faces) {
            state.faces = { ...state.faces, ...normalizedData.entities.faces };
        }
    },
    [requestFacesListFail.type]: (state, action: ReturnType<typeof requestFacesListFail>) => {
        state.requestFacesListState = { isLoading: false, isLoaded: true, error: action.payload };
    },
    [requestFacesListLoadMoreRequest.type]: (state) => {
        state.requestFacesListState = { isLoading: true };
    },
    [selectFace.type]: (state, action: PayloadAction<ISelectFaceData>) => {
        state.selectedFaceId = action.payload.id;
    },
    [resetFace.type]: (state) => {
        state.selectedFaceId = null;
    },
    [requestFilesWithFaceStart.type]: (state, action: ReturnType<typeof requestFilesWithFaceStart>) => {
        const { faceId = state.selectedFaceId } = action.payload || {};
        if (!faceId) {
            return;
        }
        if (!state.facesState[faceId]) {
            state.facesState[faceId] = { requestState: {}, filesIdx: [] };
        }
        state.facesState[faceId].requestState = { isLoading: true };
        state.facesState[faceId].cursor = '';
    },
    [requestMoreFilesWithFaceStart.type]: (state, action: ReturnType<typeof requestFilesWithFaceStart>) => {
        const { faceId = state.selectedFaceId } = action.payload || {};
        if (!faceId || state.facesState[faceId]?.requestState?.isLoading) {
            return;
        }
        state.facesState[faceId].requestState = { isLoading: true };
    },
    [requestFilesWithFaceSuccess.type]: (state, action: ReturnType<typeof requestFilesWithFaceSuccess>) => {
        const { id, files, cursor } = action.payload;

        if (id !== state.currentId) {
            return;
        }

        const normalizedData = normalize(files, [fileItemSchema]);

        if (normalizedData.entities.files) {
            state.facesState[action.payload.faceId].requestState = { isLoaded: true };
            state.facesState[action.payload.faceId].cursor = cursor;
            state.files = {
                ...state.files,
                ...normalizedData.entities.files,
            };
            state.facesState[action.payload.faceId].filesIdx = Array.from(
                new Set([...state.facesState[action.payload.faceId].filesIdx, ...normalizedData.result])
            );
        }
    },
    [requestFilesWithFaceFail.type]: (state, action: ReturnType<typeof requestFilesWithFaceFail>) => {
        state.facesState[action.payload.faceId].requestState = { isLoading: false, isLoaded: true, error: action.payload.error };
    },
    [requestFacesOnPhotoSuccess.type]: (state: IFacesStore, action: ReturnType<typeof requestFacesOnPhotoSuccess>) => {
        const { id, path, faces } = action.payload;

        if (id !== state.currentId) {
            return;
        }
        state.requestFacesListState = { isLoaded: true };

        const normalizedData = normalize(faces, [facesSchema]);

        if (normalizedData.entities.faces) {
            const newFaces = {};
            normalizedData.result.forEach((faceId) => {
                const { coordinates, ...rest } = normalizedData.entities.faces?.[faceId] ?? {};
                if (!coordinates) {
                    return;
                }
                const coordinatesId = getCoordinatesId(path, faceId);
                state.coordinates[coordinatesId] =
                    coordinates
                        .filter((coordinates) => coordinates?.length >= 4)
                        .map<ICoordinates>((coordinates) => {
                            return {
                                face: faceId,
                                file: path,
                                left: coordinates[0],
                                top: coordinates[1],
                                right: coordinates[2],
                                bottom: coordinates[3],
                            };
                        }) ?? [];

                if (!state.faces[faceId]) {
                    newFaces[faceId] = { ...rest };
                }
            });
            state.faces = { ...state.faces, ...newFaces };
            state.facesIdxByFile[path] = normalizedData.result;
        }
    },
});
