import { Icon20InfoCircleOutline } from '@vkontakte/icons';
import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { OverquotaBannerCtrl, sendDwhOverquota } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal.helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './OverquotaBanner.css';

export const OverquotaBanner = memo(function OverquotaPopup(): ReactElement | null {
    const storage = useSelector(getCurrentStorage);
    const dispatch = useDispatch();

    useEffect(
        () =>
            sendDwhOverquota({
                action: 'show',
                storage,
                name: 'overquota_information_alert_new',
                category: ECategoryGa.informationAlert,
            }),
        [storage]
    );

    const onPrimaryHandler = useCallback(() => {
        sendDwhOverquota({
            action: 'click',
            storage,
            name: 'overquota_information_alert_new',
            category: ECategoryGa.informationAlert,
            control: OverquotaBannerCtrl.purchase,
        });

        dispatch(
            historyPush({
                id: '/promo/quota',
                search: '?utm_source=cloud_web&utm_medium=webview_tariffs_quota_landing&utm_campaign=overquota_infoblock_new',
            })
        );
    }, []);

    const onSecondaryHandler = useCallback(() => {
        sendDwhOverquota({
            action: 'click',
            storage,
            name: 'overquota_information_alert_new',
            category: ECategoryGa.informationAlert,
            control: OverquotaBannerCtrl.cleaning,
        });

        dispatch(
            historyPush({
                id: '/promo/quota',
                search: '',
                hash: 'cleaner',
            })
        );
    }, []);

    return (
        <div className={styles.root}>
            <Icon20InfoCircleOutline className={styles.icon} />
            <div className={styles.content}>
                <div className={styles.title}>Вашим файлам не&nbsp;хватает места</div>
                <div className={styles.text}>Скоро они будут удалены. Увеличьте или&nbsp;очистите место, чтобы их&nbsp;сохранить</div>
            </div>
            <div className={styles.buttons}>
                <Button
                    className={styles.button}
                    data-name="freeze-renewal"
                    theme="octavius"
                    sizeMode={ButtonSizeMode.small}
                    onClick={onPrimaryHandler}
                    error
                >
                    Увеличить место
                </Button>
                <Button
                    className={styles.button}
                    onClick={onSecondaryHandler}
                    theme="octavius"
                    sizeMode={ButtonSizeMode.small}
                    data-name="secondary"
                >
                    Очистить Облако
                </Button>
            </div>
        </div>
    );
});
