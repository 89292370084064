import { useEffect, useState } from 'react';

const handleMsg = (msg, onReady?: () => void, onError?: () => void) => {
    try {
        const data = typeof msg.data === 'object' ? msg.data : JSON.parse(msg.data);
        if (typeof data !== 'object') {
            return;
        }

        if (data.type === 'r7wopi_done') {
            onReady?.();
        } else if (data.type === 'r7wopi_err') {
            onError?.();
        }
    } catch {}
};

export const useR7Stage = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fn = (msg) => {
            handleMsg(
                msg,
                () => setReady(true),
                () => setError(true)
            );
        };

        window.addEventListener('message', fn, false);

        return () => {
            window.removeEventListener('message', fn, false);
        };
    }, []);

    return { ready, error };
};

interface Props {
    onReady?: () => void;
    onError?: () => void;
}

export const r7StageCB = ({ onReady, onError }: Props) => {
    const fn = (msg) => {
        handleMsg(msg, onReady, onError);
    };

    window.addEventListener('message', fn, false);

    return () => {
        window.removeEventListener('message', fn, false);
    };
};
