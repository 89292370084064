import { createSelector } from '@reduxjs/toolkit';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import {
    abAutoupload10TB,
    abDecreaseBaseQuota,
    abProfitableTariffsFirstTouch,
    additionalTariffsList,
    desktopTariffMinSize,
    favorableTariffs as favorableTariffsIDs,
    horizontalTariffs,
    priceIncrease10PercentTouch,
    priceIncrease10PercentWeb,
    summerPromotion,
    touchTariffMinSize,
    trialTariffsList,
} from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { getBaseUserQuota } from 'reactApp/modules/family/family.selectors';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import {
    AUTOUPLOAD_10TB_TARIFFS,
    getProductFromTariffListByQuotaAndPeriod,
    increasePrice10PercentInTariffs,
    SPACE_SIZES,
    summerTariff4TBId,
    upsertSummerPromoTariffs,
} from 'reactApp/modules/products/products.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { normalizeMobileTariffList } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.helpers';
import { ITariffsMobile } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.types';
import { RootState } from 'reactApp/store';
import { Product, Tariff } from 'reactApp/types/Billing';
import { ITariffsByTab, TariffPromoLabelType } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import { getDiscount } from 'reactApp/utils/getDiscount';
import { getMonthes, getTariffProductsByPeriods, PERIODS } from 'reactApp/utils/Period';

import { baseYearProduct } from './TariffsPlans.helpers';

const RECOMMENDED_ID = 'W256G';
const RECOMMENDED_ID_FAVORABLE = 'W1T';

export const getMaxTariffDiscount = createSelector(
    [
        (state: RootState) => ProductsSelectors.getTariffsForQuotaLanding(state),
        (state, actionName: string) => ProductsSelectors.getPromoTariffs(state, actionName, true),
        (state, actionName) => actionName,
        (state, actionName, isMobile) => isMobile,
        // tempexp_16373-next-line
        (state) => ProductsSelectors.getFavorableTariffList(state),
        (state) => ProductsSelectors.getSummerPromoTariffs(state),
        (state) => ProductsSelectors.getPreferredTariffs(state),
    ],
    // tempexp_16373-next-line
    (nonSpecialTariffs, promoTariffs, actionName, isMobile, favorableTariffs, summerPromoTarrifs, preferredTariffs) => {
        let tariffs: Tariff[] = actionName ? promoTariffs : nonSpecialTariffs;

        const minTariffSize = SPACE_SIZES[`_${isMobile ? touchTariffMinSize : desktopTariffMinSize}`];
        tariffs = tariffs.filter((tariff) => tariff?.space?.original >= minTariffSize);

        // tempexp_16521-next-line
        if (favorableTariffs.length && (!abDecreaseBaseQuota || abDecreaseBaseQuota === 'b')) {
            tariffs = favorableTariffs;
        }

        if (summerPromotion) {
            tariffs = upsertSummerPromoTariffs(tariffs, summerPromoTarrifs);
        }

        if (preferredTariffs.length) {
            tariffs = preferredTariffs;
        }

        const discounts = tariffs.map((tariffItem) => {
            const { monthProduct, yearProduct } = getTariffProductsByPeriods(tariffItem.products);

            if (!(monthProduct && yearProduct)) {
                return 0;
            }

            const yearPriceByMonth = getMonthes(yearProduct.period) * monthProduct.price;
            const { discount } = getDiscount({
                discountPrice: yearProduct?.discountPrice || yearProduct.price,
                price: yearPriceByMonth,
            });

            return discount;
        });

        return discounts.length && Math.max(...discounts);
    }
);

export const getTariffsList = createSelector(
    [
        (state: RootState) => ProductsSelectors.getTariffsForQuotaLanding(state),
        (state: RootState, actionName: string) => ProductsSelectors.getPromoTariffs(state, actionName, true),
        (state: RootState, actionName) => actionName,
        // tempexp_16481-next-line
        (state: RootState, actionName, showAutoupload1TbPromo) => showAutoupload1TbPromo,
        (state: RootState, actionName, showAutoupload1TbPromo, isMobile) => isMobile,
        (state: RootState) => getBaseUserQuota(state),
        (state: RootState) => ProductsSelectors.getNormalizedProductsBySpace(state, trialTariffsList),
        (state: RootState) => PaidInfoSelectors.isFreeUserWithoutTrials(state),
        // tempexp_16373-next-line
        (state: RootState) => ProductsSelectors.getFavorableTariffList(state),
        // tempexp_16481-next-line @TODO: пришлось захардкодить массив тк нужно было синхронизировать вебвью мобилки между экспами и много не переделывать. Переделать если эксп взлетит.
        (state: RootState) =>
            ProductsSelectors.getNormalizedProductsBySpace(
                state,
                getQueryParams()?.forwardApp === 'true' && IS_WEBVIEW ? AUTOUPLOAD_10TB_TARIFFS : abAutoupload10TB
            ),
        // tempexp_16521-next-line
        (state: RootState) => UserQuotaSelectors.getLoadingState(state),
        (state) => UserSelectors.isAnonymous(state),
        (state: RootState) => ProductsSelectors.getNormalizedProductsBySpace(state, additionalTariffsList?.tariffs || []),
        (state: RootState) => ProductsSelectors.getSummerPromoTariffs(state),
        (state: RootState) => ProductsSelectors.getPreferredTariffs(state),
        // tempexp-17081-next-line
        (state: RootState) => ProductsSelectors.getPriceIncrease10PercentTariffs(state),
        (state: RootState) => ProductsSelectors.getThreeMonthPeriodTariffs(state),
        (state: RootState) => ProductsSelectors.getAvailableProductsByListIds(state, abProfitableTariffsFirstTouch.tariffs),
    ],
    (
        nonSpecialTariffs,
        promoTariffs,
        actionName,
        // tempexp_16481-next-line
        showAutoupload1TbPromo,
        isMobile,
        baseQuota,
        trialsList,
        isFreeUserWithoutTrials,
        // tempexp_16373-next-line
        favorableTariffs,
        // tempexp_16481-next-line
        autoupload10TbList,
        // tempexp_16521-next-line
        userQuotaLoadingState,
        isAnonymous,
        additionalTariffs,
        summerPromoTarrifs,
        preferredTariffs,
        priceIncrease10PercentTariffs,
        threeMonthPeriodTariffs,
        touchProfitableTariffs
    ): ITariffsByTab[] | ITariffsMobile[] => {
        let tariffs: Tariff[] = [];

        // В задаче CLOUDWEB-17222 в варианте 'c' базовые годовые тарифы без скидки, поэтому промо тарифы не показываем
        tariffs = actionName && additionalTariffsList?.variant !== 'c' ? promoTariffs : nonSpecialTariffs;

        // tempexp_16373-next-line tempexp_16521-next-line
        if (favorableTariffs.length && (!abDecreaseBaseQuota || abDecreaseBaseQuota === 'b')) {
            tariffs = favorableTariffs;
        }

        if (summerPromotion) {
            tariffs = upsertSummerPromoTariffs(tariffs, summerPromoTarrifs, isMobile);
        }

        // tempexp-17081-start, tempexp-17082-start
        const shouldIncreasePrice10PercentWeb = priceIncrease10PercentWeb === 'b' && !isMobile;
        const shouldIncreasePrice10PercentTouch = priceIncrease10PercentTouch === 'b' && isMobile;

        if (shouldIncreasePrice10PercentWeb || shouldIncreasePrice10PercentTouch) {
            tariffs = increasePrice10PercentInTariffs(tariffs, priceIncrease10PercentTariffs);
        }
        // tempexp-17081-end, tempexp-17082-end

        if (preferredTariffs.length) {
            tariffs = preferredTariffs;
        } else if (threeMonthPeriodTariffs.length) {
            tariffs = tariffs.map((tariff) => {
                const threeMonthPeriodProduct = getProductFromTariffListByQuotaAndPeriod(
                    threeMonthPeriodTariffs,
                    tariff.space.original,
                    PERIODS.threeMonths
                );
                const products = [...tariff.products];
                if (threeMonthPeriodProduct) {
                    products.push(threeMonthPeriodProduct);
                }

                return {
                    ...tariff,
                    products,
                };
            });
        }

        let list = tariffs.reduce((acc: ITariffsByTab[], tariffItem) => {
            const products = getTariffProductsByPeriods(tariffItem.products);

            const trial = isFreeUserWithoutTrials ? trialsList[tariffItem.space.original] : undefined;
            const additionalTariff = additionalTariffs[tariffItem.space.original];
            // tempexp_16481-next-line
            const autoupload10TbTariff =
                showAutoupload1TbPromo && !summerPromotion ? autoupload10TbList[tariffItem.space.original] : undefined;

            if (products.yearProduct) {
                let recommendedId = RECOMMENDED_ID;

                // tempexp_16521-next-line
                if (favorableTariffsIDs.length && (!abDecreaseBaseQuota || abDecreaseBaseQuota === 'b')) {
                    recommendedId = RECOMMENDED_ID_FAVORABLE;
                }

                const yearProduct =
                    autoupload10TbTariff?.yearProduct || trial?.yearProduct || additionalTariff?.yearProduct || products.yearProduct;
                const monthProduct =
                    autoupload10TbTariff?.monthProduct ||
                    trial?.monthProduct ||
                    // tempexp-17081-next-line учитываем победивишй вариант b https://jira.vk.team/browse/CLOUDWEB-16596
                    (shouldIncreasePrice10PercentWeb ? products.monthProduct : additionalTariff?.monthProduct) ||
                    products.monthProduct;
                const threeMonthProduct = products.threeMonthProduct;

                // tempexp_16481-next-line
                const promo =
                    autoupload10TbTariff?.yearProduct && autoupload10TbTariff?.monthProduct
                        ? TariffPromoLabelType.autoupload10TB
                        : undefined;

                let recommendedTariffId = favorableTariffsIDs.length ? RECOMMENDED_ID_FAVORABLE : recommendedId;
                recommendedTariffId = summerPromotion ? summerTariff4TBId : recommendedTariffId;
                acc.push({
                    space: tariffItem.space,
                    tariffId: tariffItem.id,
                    // tempexp_16373-next-line
                    isRecommended: tariffItem.id.includes(recommendedTariffId),
                    tariffPromoLabelType: promo,
                    products: preferredTariffs.length
                        ? products
                        : {
                              yearProduct,
                              monthProduct,
                              threeMonthProduct,
                          },
                });
            }

            return acc;
        }, []);

        // tempexp_16373-next-line tempexp_16521-next-line
        const showDefaultTariffCard =
            !favorableTariffsIDs.length ||
            (abDecreaseBaseQuota && abDecreaseBaseQuota === 'c' && (userQuotaLoadingState.isLoaded || isAnonymous));

        if (showDefaultTariffCard) {
            list.unshift({
                tariffId: 'base',
                space: { ...baseQuota },
                isRecommended: false,
                isBase: true,
                products: {
                    yearProduct: {
                        ...baseYearProduct,
                        space: baseQuota,
                    },
                    monthProduct: undefined,
                },
            });
        }

        const minTariffSize = SPACE_SIZES[`_${isMobile ? touchTariffMinSize : desktopTariffMinSize}`];

        list = list.filter((tariff) => tariff?.space?.original >= minTariffSize);

        if (isMobile) {
            // tempexp-16876-start
            const profitableTariffs = abProfitableTariffsFirstTouch.tariffs;

            const sortedTouchProfitableTariffs = [...touchProfitableTariffs];
            sortedTouchProfitableTariffs.sort((a, b) => {
                return profitableTariffs.indexOf(a.id) - profitableTariffs.indexOf(b.id);
            });

            // tempexp_17242-next-line
            if (profitableTariffs.length && !horizontalTariffs.tariffs.length) {
                const sortedList: ITariffsByTab[] = [];
                sortedTouchProfitableTariffs.forEach((tariff) => {
                    const foundItem = list.find((t) => t.space.original === tariff.space.original);
                    if (foundItem) {
                        sortedList.push(foundItem);
                    }
                });
                list = sortedList;

                // tempexp_17221-next-line: Исключаем тарифы которых нет в массиве из фичи.
                list = list.filter((item) => additionalTariffsList?.tariffs || profitableTariffs.includes(item.tariffId));
            }
            // tempexp-16876-end

            const mobileTariffList = list.reduce((acc, item) => {
                // не показываем базовый тариф на таче
                if (item.isBase) {
                    return acc;
                }

                acc[item.space.original] = [item.products.yearProduct, item.products.monthProduct, item.products.threeMonthProduct];
                return acc;
            }, <Record<number, [Product, Product | undefined, Product | undefined]>>{});

            const mobileList = normalizeMobileTariffList(mobileTariffList, Boolean(actionName));

            // tempexp_16460-start
            if (showAutoupload1TbPromo && !summerPromotion) {
                mobileList.sort((a, b) => {
                    const aSpace = a.space.original;
                    const bSpace = b.space.original;

                    if (autoupload10TbList.hasOwnProperty(bSpace)) {
                        return 1;
                    }

                    if (autoupload10TbList.hasOwnProperty(aSpace)) {
                        return -1;
                    }

                    if (aSpace > bSpace) {
                        return 1;
                    }

                    return -1;
                });
            }
            // tempexp_16460-end

            return mobileList;
        }

        return list;
    }
);
