import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { initProducts } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { createGaSender } from 'reactApp/utils/ga';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import { useBigFileModalCallbacks } from './hooks/useBigFileModalCallbacks';
import { useContent } from './hooks/useContent';
import { useTariff } from './hooks/useTariff';
import styles from './UploadBigFileDialog.css';
import { Props } from './UploadBigFileDialog.types';
import { UploadBizBigFileDialog } from './UploadBizBigFileDialog';

const sendGa = createGaSender('upload-big-file');
const dataQAId = 'upload-big-file';

const UploadB2CBigFileDialog = React.memo(function UploadB2CBigFileDialog(props: Props): ReactElement {
    const dispatch = useDispatch();

    const { numberOfFiles, onClose: closeModal, skipFileLoading, continueFileLoading, file, files } = props;
    const fileSizeBytes = file?.sizeBytes || files?.sizeBytes;

    const tariffInfo = useTariff(fileSizeBytes || 0);
    const callbacks = useBigFileModalCallbacks({
        closeModal,
        skipFileLoading,
        continueFileLoading,
        sendGa,
        tariffInfo,
        fileSizeBytes,
        paySource: 'cloud_popup_heavy_file',
    });
    const modalContent = useContent({ tariffInfo, numberOfFiles, file, files, callbacks });
    const header = useMemo(() => <div className={styles.header}>{modalContent.header}</div>, [modalContent.header]);

    const productsState = useSelector(ProductsSelectors.getLifeCycleState);
    const isPhone = EnvironmentSelectors.isPhone();

    useEffect(() => {
        dispatch(initProducts());
    }, []);

    useEffect(() => {
        sendGa('show', tariffInfo.showTariffType);
        sendPaymentGa({ action: EPaymentGa.bigFileModal, label: 'view', size_offer: fileSizeBytes });
        emitAnalyticEvent(AnalyticEventNames.BIG_FILE_DIALOG_SHOW);
    }, []);

    const content = useMemo(
        () => (
            <>
                <div className={styles.content}>{modalContent.annotation}</div>
                <div className={styles.fileContainer}>
                    {Boolean(file) && (
                        <div className={styles.fileIcon}>
                            <FileIcon type={file?.extension} />
                        </div>
                    )}
                    <div className={styles.description}>
                        <span className={styles.fileDescription}>{modalContent.description}</span>
                        <span className={styles.fileSize}>0 КБ из {bytesToNDigits(file?.sizeBytes || files?.sizeBytes || 0, 3).value}</span>
                    </div>
                </div>
                {modalContent.actions}
            </>
        ),
        [file, files?.sizeBytes, modalContent.actions, modalContent.annotation, modalContent.description]
    );

    if (isPhone) {
        return (
            <MobileDialog
                className={styles.root_phone}
                id={dataQAId}
                open
                dimmer
                onClose={callbacks.onClose}
                disableScrolling
                mod="confirm"
                title={header}
                topmost
            >
                {content}
            </MobileDialog>
        );
    }

    return (
        <Dialog open header={header} closeOnDimmerClick={false} id={dataQAId} className={styles.root} onCancel={callbacks.onClose}>
            <Content isModal isLoading={productsState.isLoading}>
                {content}
            </Content>
        </Dialog>
    );
});

export const UploadBigFileDialog = React.memo(function UploadBigFileDialog(props: Props): ReactElement {
    const isBiz = useSelector(UserSelectors.isBizUser);

    return isBiz ? <UploadBizBigFileDialog {...props} /> : <UploadB2CBigFileDialog {...props} />;
});
