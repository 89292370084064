import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';

export const shouldCollapse = (data: TToolbarItem, priority: number, responsiveMode: boolean): boolean => {
    if (data.collapse !== undefined) {
        return data.collapse;
    }

    return (!!data?.priority && data?.priority <= priority) || responsiveMode;
};

export const getSortDateText = (storage: EStorageType): string => {
    if (IS_BIZ_USER) {
        if (storage === EStorageType.trashbin) {
            return 'По дате удаления';
        }

        return 'По дате изменения';
    }

    return 'По дате создания';
};

export const getSortDateType = (storage: EStorageType): string => {
    if (IS_BIZ_USER && storage === EStorageType.trashbin) {
        return 'deletedAt';
    }

    return 'mtime';
};
