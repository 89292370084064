import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { Product } from 'reactApp/types/Billing';
import { SwiperTariffList } from 'reactApp/ui/IframePages/BuyDisableAds/DisableAdsScreen/SwiperTariffList/SwiperTariffList';
import { TariffCardLoader } from 'reactApp/ui/IframePages/BuyDisableAds/DisableAdsScreen/TariffCardLoader/TariffCardLoader';
import { TariffListCard } from 'reactApp/ui/IframePages/BuyDisableAds/DisableAdsScreen/TariffListCard/TariffListCard';
import { useDisableAdsTariffs } from 'reactApp/ui/IframePages/BuyDisableAds/hooks/useDisableAdsTariffs';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';

import styles from './TariffList.css';

interface TariffListProps {
    tariffsIdsFromQuery?: string;
    activeTab: ETabsName;
    onBuyClick: (product: Product) => void;
}

const smBreackPoint = 768;

export const TariffList = ({ activeTab, tariffsIdsFromQuery, onBuyClick }: TariffListProps): ReactElement => {
    const { tariffs, tariffIds } = useDisableAdsTariffs(tariffsIdsFromQuery);
    const tariffsCount = (activeTab === ETabsName.year ? tariffIds.year : tariffIds.month).length;
    const { isLoading, isLoaded } = useSelector(ProductsSelectors.getLifeCycleState);
    const [lgBreakpointHit] = useMinWidthBreakpoint(smBreackPoint);
    const displayInSwiper = !lgBreakpointHit;

    return displayInSwiper ? (
        <SwiperTariffList
            tariffs={tariffs}
            tariffsCount={tariffsCount}
            isLoading={isLoading}
            activeTab={activeTab}
            isLoaded={isLoaded}
            onBuyClick={onBuyClick}
        />
    ) : (
        <div className={styles.root}>
            {isLoaded &&
                tariffs.map((tariff, index) => (
                    <TariffListCard key={index} activeTab={activeTab} tariff={tariff} onBuyClick={onBuyClick} />
                ))}
            {isLoading && Array.from(new Array(tariffsCount)).map((_, idx) => <TariffCardLoader key={idx} />)}
        </div>
    );
};

TariffList.displayName = 'TariffList';
