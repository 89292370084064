import { prepareCategoryByURL } from '@mail-core/dashboard/keeper/metrics.api';
import { captureMessage } from '@sentry/browser';
import axios, { AxiosResponse } from 'axios';
import { getFeature } from 'Cloud/Application/FeaturesEs6';
import config from 'Cloud/config';
import qs from 'qs';
import { O2Auth } from 'reactApp/api/O2Auth';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';

const CLIENT_ID = config.get('CORS_API_CLIENT_ID');
const CORSAPI_HOST = config.get('CORS_API_HOST');
const login = config.get('user.email');
const ATTACHES_LOAD_LIMIT = getLoadMoreLimit(EStorageType.attaches);
export const AF_API_HOST = getFeature('af-api-host') || 'https://af.attachmail.ru/cgi-bin/readmsg/';
/** Время жизни токена */

const CORS_API_BASE_URL = `https://${CORSAPI_HOST}/api/v1/`;

const COMMON_HEADERS = {
    'Content-Type': 'application/x-www-form-urlencoded',
} as const;

let attachAuth;

export function getAuthToken(isNew = false) {
    if (!attachAuth) {
        attachAuth = new O2Auth({ clientId: CLIENT_ID, login });
    }
    return isNew ? attachAuth.refreshToken() : attachAuth.getToken();
}

const axiosInstance = axios.create({
    baseURL: CORS_API_BASE_URL,
    method: 'POST',
    headers: COMMON_HEADERS,
    withCredentials: false,
});

axiosInstance.interceptors.response.use(
    async function (response) {
        const originalRequest: any = response.config;
        if (response.data.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            const token = await getAuthToken(true);
            originalRequest.data = originalRequest.data?.replace(/access_token=(\w|\d)+(?=&|$)/, `access_token=${token}`);
            return axiosInstance(originalRequest);
        }
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 500 && !originalRequest._retry) {
            originalRequest._retry = true;
            return axiosInstance(originalRequest);
        }
        return Promise.reject(error);
    }
);

function api(method, data?, options = { getFullResponse: false }): Promise<AxiosResponse | any> {
    return new Promise(function (resolve, reject) {
        const category = prepareCategoryByURL(method);
        getAuthToken().then((token) => {
            data = { access_token: token, ...(data || {}) };
            const reqData = Object.keys(data)
                .filter((key) => data[key] !== undefined && data[key] !== '')
                .map(function (p) {
                    return `${encodeURIComponent(p)}=${encodeURIComponent(data[p])}`;
                })
                .join('&');

            axiosInstance(method, {
                data: reqData,
                headers: O2Auth.prepareAuthHeader(token),
            })
                .then(function (response) {
                    if (response.status === 200) {
                        resolve(options?.getFullResponse ? response : response.data);
                    } else {
                        reject(response);
                        sendKaktamLog(response, 'cloud_corsapi');
                    }

                    sendXray('corsapi', `${category}_${response.status}`);
                })
                .catch(function (error) {
                    sendKaktamLog(error, 'cloud_corsapi');
                    sendXray('corsapi', `${category}_error`);

                    reject(error);
                });
        });
    });
}

export function getFolders() {
    return api('folders').then(function (response) {
        return response.body;
    });
}

export function getAttaches({
    folderId = -1,
    after = '',
    before = '',
    limit = ATTACHES_LOAD_LIMIT,
    extraThumbs = { image: '167x167' },
    query = '',
    type = '',
} = {}) {
    return api(
        'messages/attaches/search/clear',
        {
            folder_id: folderId,
            extra_thumbs: JSON.stringify(extraThumbs),
            after,
            before,
            limit,
            query,
            type,
        },
        { getFullResponse: true }
    );
}

export function getMessageAttaches({ messageId, email }: { messageId?: string; email?: string } = {}) {
    return api('messages/attaches', {
        id: messageId,
        email,
    }).then(function (response) {
        if (response.status === 200) {
            return response.body || response.map((a) => a.body);
        }
        sendKaktamLog({ messageId, text: 'getMessageAttaches api error', status: response.status, body: response.body }, 'attaches_api');
        captureMessage(`getMessageAtt error: status ${response.status} `, { extra: response.body });
        sendXray(['getmessageatt', 'status', response.status]);
        return null; // TODO: show message?
    });
}

export function getTemporaryMessageAttaches({ messageId, email }: { messageId?: string; email?: string } = {}) {
    return api('messages/attaches/temporary', {
        message_id: messageId,
        email,
    }).then(function (response) {
        if (response.status === 200) {
            return response.body || response.map((a) => a.body);
        }
        sendKaktamLog(
            { messageId, text: 'getTemporaryMessageAttaches api error', status: response.status, body: response.body },
            'attaches_api'
        );
        captureMessage(`getTempMessage error: status ${response.status} `, { extra: response.body });
        sendXray(['gettempmessage', 'status', response.status]);
        return null;
    });
}

export function getMessage({ messageId, email, folderId }: { messageId?: string; email?: string; folderId?: string } = {}) {
    return api('messages/message', {
        id: messageId,
        email,
        folder_id: folderId || undefined,
    }).then(function (response) {
        if (response.status === 200) {
            return response.body || response.map((a) => a.body);
        }
        sendKaktamLog({ messageId, text: 'getMessage api error', status: response.status, body: response.body }, 'attaches_api');
        captureMessage(`getMessage error: status ${response.status} `, { extra: response.body });
        sendXray(['getmessage', 'status', response.status]);
        return null;
    });
}

export function getDocumentUrl({ url }: { url?: string } = {}) {
    return api(url).then(function (response) {
        return response.body.url;
    });
}

export const cloneAttach = async ({ ids, folder, email }: { ids?: any; folder?: any; email?: string } = {}) => {
    const response = await api('attaches/add/to-cloud', {
        folder,
        email,
        ids: JSON.stringify(ids),
        htmlencoded: false,
    });

    const body = response.body || response.map((r) => r.body);

    if (response.status === 200) {
        return body;
    }

    sendXray(`clone_attach_err_${response.status}`);
    sendKaktamLog({ ids, folder, text: 'cloneAttach api error', status: response.status, body: response.body }, 'attaches_api');
    return Promise.reject({ body, status: response.status });
};

export const downloadDocumentApi = (url: string): Promise<{ data: Nullable<ArrayBuffer> }> =>
    getAuthToken(true).then((token) =>
        axiosInstance
            .post(url, qs.stringify({ access_token: token }), {
                baseURL: '',
                responseType: 'arraybuffer',
            })
            .catch((error) => {
                sendXray('down_doc_err');
                sendKaktamLog(error, 'down_doc_err');

                sendKaktamLog({ url, text: 'downloadDocumentApi api error', error }, 'down_doc_err');
                return { data: null };
            })
    );

export const removeMessages = (email: string, ids: string[]) =>
    api('messages/remove', {
        email,
        ids: JSON.stringify(ids),
    });
/*
    Урл файла для скачивания аттача через ajax
 */
export const getDocumentUrlByAttachId = ({ attachId, fileName }: { attachId: string; fileName: string }) => {
    //  См. af api https://confluence.vk.team/pages/viewpage.action?pageId=54013226
    // https://confluence.vk.team/pages/viewpage.action?pageId=542591183
    return `${AF_API_HOST}${fileName}?id=${encodeURIComponent(attachId)}&notype=1&project=cloud`;
};

export const getDocsView = (url?: string | null) => {
    if (!url) {
        return Promise.reject();
    }

    return api(url);
};

export function saveMainPageSettings(
    { settings, email }: { settings: { theme?: string; version: string }; email?: string } = { settings: { version: 'v1' } }
) {
    return api('user/edit/mainpage', {
        settings: JSON.stringify(settings),
        email,
    }).then(function (response) {
        if (response.status === 200) {
            return response.body || response.map((a) => a.body);
        }
        sendKaktamLog({ text: 'saveSettings api error', status: response.status, body: response.body }, 'attaches_api');
        captureMessage(`saveSettings error: status ${response.status} `, { extra: response.body });
        return null;
    });
}
