/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line max-lines-per-function
import { captureException, captureMessage } from '@sentry/browser';
import { BReactComponent } from 'blocks-cloud/b-react-component';
import app from 'Cloud/Application/app';
import { getDownloadDropdownList } from 'Cloud/Application/Editor/MyOffice/myOffice.helpers';
import { EditorID } from 'Cloud/Application/Editor/types';
import features from 'Cloud/Application/Features';
import { Promo } from 'Cloud/Application/Promo';
import { promoController } from 'Cloud/Application/PromoController';
import { callApiR7Convert } from 'Cloud/Application/r7helpers';
import { replaceState } from 'Cloud/Application/Router';
import { isClientEditorError, R7Theme } from 'Cloud/Application/types';
import { openPopup, openTab as _openTab } from 'Cloud/Application/Window';
import browser from 'Cloud/browser';
import config from 'Cloud/config';
import { epsilonTime } from 'lib/epsilonTime';
import { extInfo } from 'lib/extInfo';
import { logger } from 'lib/logger';
import { addParams } from 'lib/urlUtils';
import { xray } from 'lib/xray';
import { clone } from 'ramda';
import { WopiData, WopiProvider } from 'reactApp/api/wopi/WopiAPI.types';
import {
    CHOSEN_EDITOR,
    EDITORS_CONFIG,
    IS_FREE_B2B_BIZ_EDITORS_USER,
    IS_FREE_BIZ_SAAS_USER,
    IS_MOBILE_BROWSER,
    IS_MY_TEAM,
    IS_ONPREMISE,
    IS_PUBLIC,
    IS_REACT_PAGE,
    IS_STOCK,
    IS_TABLET_BROWSER,
    VIEWERS_CONFIG,
} from 'reactApp/appHelpers/configHelpers';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import {
    clearNotificationAboutCreatingCopyOfNoneditableFile,
    getNotificationAboutCreatingCopyOfNoneditableFile,
    NotifyFrom,
} from 'reactApp/appHelpers/notificationAboutCreatingCopyOfNoneditableFile';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import {
    renderBizPaidEditorDialog,
    renderComfirmationDialog,
    renderMessageBox,
} from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { isCreateDocumentQueryAction } from 'reactApp/components/CreateDocumentByLinkDialog/createDocumentByLinkDialog.type';
import { renderNewDocumentModal } from 'reactApp/components/NewDocumentModal/NewDocumentModal.helpers';
import { renderOpenDocument } from 'reactApp/components/OpenDocument/openDocument.toolkit';
import { r7StageCB } from 'reactApp/hooks/useR7Stage';
import { setRebranding } from 'reactApp/hooks/useRebranding';
import { isAvailableInDocumentSection } from 'reactApp/modules/allDocuments/allDocuments.helpers';
import { composeAvailableEditors, composeExtensions, composeSharedExtensions } from 'reactApp/modules/editor/editor.helpers';
import { startEditor } from 'reactApp/modules/editor/editor.module';
import { getEditorsByExtension, isEditorStorage } from 'reactApp/modules/editor/editor.selectors';
import { inlineHeader, isOnPremR7Product, LLM } from 'reactApp/modules/features/features.helpers';
import { getAstraMetaFeature, getFeatureMyOfficeSendDetailRadars } from 'reactApp/modules/features/features.selectors';
import { makeNewDocumentError, makeNewDocumentSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { editAttachCopyNotification, editPublicCopyNotification } from 'reactApp/modules/modifying/modifying.helpers';
import { routeStatusPage } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import {
    getCurrentFolder,
    getCurrentItem,
    getItemById,
    hasParentMountedOrSharedFolder,
    isMountedOrSharedFolder,
} from 'reactApp/modules/storage/storage.selectors';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { store as reduxStore } from 'reactApp/store';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { BizInlineEditorToolbar } from 'reactApp/ui/EditorHeader/BizInlineEditorToolbar/BizInlineEditorToolbar';
import { EditorHeaderContainer as EditorHeader } from 'reactApp/ui/EditorHeader/EditorHeaderContainer';
import { InlineEditorToolbar } from 'reactApp/ui/EditorHeader/InlineEditorToolbar/InlineEditorToolbar';
import { EditorLLMButton } from 'reactApp/ui/EditorLLM/EditorLLMButton/EditorLLMButton';
import { SCENARIO_SELECT_OPTIONS } from 'reactApp/ui/EditorLLM/EditorLLMDialog/constants/EditorLlmDialog.constants';
import { EditorLlmDialog } from 'reactApp/ui/EditorLLM/EditorLLMDialog/EditorLlmDialog';
import { llmFeature } from 'reactApp/ui/EditorLLM/helpers/EditorLlmHelpers';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { sendViewerDwh } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { sessionWatcher } from 'reactApp/ui/ReactViewer/SessionWatcher';
import { AstraMetaProcessorType, createAstraMetaDiv } from 'reactApp/utils/astraPluginHelpers';
import { sendCounter } from 'reactApp/utils/counter';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';
import { callWopiEditAPICall } from 'reactApp/utils/wopiHelpers';
import { calculateMaxEditableOrPreviewableSizeForEditor } from 'server/helpers/editors/calculateMaxEditableOrPreviewableSizeForEditor';
import { sortEditors } from 'server/helpers/editors/sortEditors';
import { EditorMode, ServerEditor } from 'server/helpers/editors/types';

import { BUBBLE_ID, OFFICE_EDITOR, X_IN_SHARED_FOLDER } from './Editor/config';
import {
    callApiCreateDoc,
    createWindowName,
    getExt,
    getIsOpenDocumentWarning,
    getUrlV2,
    isOpenedTab,
    joinPath,
    setIsOpenDocumentWarning,
    shortenFilename,
} from './Editor/helpers';
import { addEditorMessageListener, logEditorOpenError, startEditorSuccessWaiter } from './EditorStatistics';
import { user } from './User';

let isEnabledBubble = false;
let tabs = {};

// CLOUDWEB-5491, CLOUDWEB-5602, CLOUDWEB-5605, CLOUDWEB-5606

let tabGCTimeoutId = 0;

const startTabGC = () => {
    window.clearTimeout(tabGCTimeoutId);

    Object.keys(tabs).forEach(function (tabName) {
        if (!isOpenedTab(tabs[tabName])) {
            // затираем ссылку на вкладку тк с помощью delete она не удаляется
            tabs[tabName] = null;
            delete tabs[tabName];
        }
    });

    tabGCTimeoutId = window.setTimeout(startTabGC, 60000);
};

const editors: ServerEditor[] = (EDITORS_CONFIG?.length ? EDITORS_CONFIG : VIEWERS_CONFIG).sort(sortEditors);
const HAS_SHARED_EDITOR = editors.some((editor) => X_IN_SHARED_FOLDER in editor);
const ITEM_WITHOUT_HASH_MAX_SIZE = config.get('ITEM_WITHOUT_HASH_MAX_SIZE');

const DETAILED_RADARS = features().getFeatureParam('detailedRadars', 'editor', false);
const current = CHOSEN_EDITOR || {};

const extensions = composeExtensions(editors);
const editorsMap = composeAvailableEditors(editors, extensions);
const sharedExtensions = composeSharedExtensions(editors, extensions);

const getByExt = (item: any) => extensions[getExt(item)] || { editors: [] };

const EDITOR_ID_MAP: Record<WopiProvider.R7_WOPI | WopiProvider.MYOFFICE, EditorID.R7_WOPI | EditorID.MYOFFICE> = {
    [WopiProvider.R7_WOPI]: EditorID.R7_WOPI,
    [WopiProvider.MYOFFICE]: EditorID.MYOFFICE,
};

const flipObject = <Key extends PropertyKey, Value extends PropertyKey>(object: Record<Key, Value>): Record<Value, Key> =>
    Object.fromEntries(Object.entries(object).map(([key, value]) => [value, key]));

const AVAILABLE_PROVIDER_ID_MAP = flipObject(EDITOR_ID_MAP);

enum EditorStatus {
    Ready = 'Ready',
    Error = 'Error',
}

const getEditorData = async (
    id: string
): Promise<{ status: EditorStatus.Ready; data: WopiData } | { status: EditorStatus.Error; data: EditorID | null }> => {
    try {
        const { data } = await callWopiEditAPICall(id, current.id);

        return { status: EditorStatus.Ready, data };
    } catch (error: unknown) {
        if (isClientEditorError(error)) {
            const providerId = error.response.fields[1] || '';
            return { status: EditorStatus.Error, data: EDITOR_ID_MAP[providerId] };
        }

        return { status: EditorStatus.Error, data: null };
    }
};

const editor = {
    isEditor() {
        return current && current.id && editorsMap[current.id];
    },

    getDefaultEditorId(item, isCreate) {
        const state = reduxStore.getState();
        const hasSharedEditor = HAS_SHARED_EDITOR && sharedExtensions[getExt(item)];
        const isSharedOrMountedState = isMountedOrSharedFolder(state, isCreate ? getCurrentFolder(state) : item).is;
        const isSharedFolder = hasSharedEditor && isSharedOrMountedState;
        const editors = getEditorsByExtension(reduxStore.getState(), getExt(item) as any);

        if (hasSharedEditor && isSharedFolder) {
            return editors.find((editorId) => {
                return editorsMap[editorId].default === 'shared-folder';
            });
        }

        return editors.find((editorId) => {
            const editor = editorsMap[editorId];

            return editor?.force || editor?.default || editors.length === 1;
        });
    },

    getEditorsFor(item) {
        const result: any[] = [];

        if (!item) {
            return result;
        }

        const size = item.size || 0;

        if (size <= ITEM_WITHOUT_HASH_MAX_SIZE) {
            // для файлов размером меньше 21 байта просмотр недоступен
            return result;
        }

        const extData = extInfo.get(item.ext);
        const maxSize = calculateMaxEditableOrPreviewableSizeForEditor(current.id, extData.maxEditableSize);

        if (!maxSize || size > maxSize) {
            return result;
        }

        const state = reduxStore.getState();

        let isSharedFolder = false;
        const hasSharedEditor = HAS_SHARED_EDITOR && sharedExtensions[getExt(item)];
        if (hasSharedEditor) {
            isSharedFolder = isMountedOrSharedFolder(state, item).is;
        }

        const storage = getCurrentStorage(state);

        if (IS_REACT_PAGE) {
            if (item.storage !== EStorageType.home && item.storage !== EStorageType.feed && item.storage !== EStorageType.alldocuments) {
                return result;
            }
        } else if (!isEditorStorage(item.storage || storage)) {
            return result;
        }

        getByExt(item).editors.forEach((id) => {
            let editor = editorsMap[id];
            const isDefault = hasSharedEditor && isSharedFolder ? editor.default === 'shared-folder' : !!editor.default;

            if (!hasSharedEditor || !editor[X_IN_SHARED_FOLDER] || isSharedFolder) {
                editor = Object.assign(
                    {},
                    editor,
                    {
                        icon: editor.icon ? editor.icon[item.ext] : undefined,
                        icon_big: editor?.icon_big?.[item.ext],
                    },
                    {
                        name: editor.id,
                        text: editor.title,
                    }
                );

                if (isDefault) {
                    // default is always first
                    result.unshift(editor);
                } else {
                    result.push(editor);
                }
            }
        });

        return result;
    },

    openEditor(item, editorId) {
        if (user.isOverquota()) {
            openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.newFile });
            return;
        }
        if (!editorId) {
            editorId = this.getDefaultEditorId(item, false);
        }
        openEditor(editorsMap[editorId], item);
    },

    createNew(ext, editorId, currentFolder, from) {
        if (!editorId) {
            editorId = this.getDefaultEditorId(ext, true);
        }

        if (IS_FREE_B2B_BIZ_EDITORS_USER && !editorId) {
            renderBizPaidEditorDialog();
            return;
        }

        if (editorId === EditorID.R7_WOPI || editorId === EditorID.R7) {
            renderNewDocumentModal({ createDocument: openEditor, editorArg: editorsMap[editorId], item: { id: currentFolder }, ext, from });
            return;
        }

        openEditor(editorsMap[editorId], { id: currentFolder }, ext);
    },

    clearWindowName() {
        // CLOUDWEB-6848
        // Необходимо очищать window.name после загрузки Облака
        // в окне редактора, чтобы разрешить повторное редактирование.
        if (!editor.isEditor()) {
            const defaultWindowName = '';
            const currentWindowName = window.name || defaultWindowName;
            const editor = editors.find(function (editor) {
                return currentWindowName.indexOf(`${editor.id}_`) === 0;
            });

            if (editor) {
                window.name = defaultWindowName;

                const radar = RADAR.group('app_clear-win-name');

                radar.add(String(editor.id).toLowerCase());
                radar.add('all');
                radar.send();

                return true;
            }
        }

        return false;
    },

    // eslint-disable-next-line sonarjs/cognitive-complexity
    onRouteEnd: async () => {
        const state = reduxStore.getState();
        const item = app.get(app.getState().id) || getCurrentItem(state);

        const currentExt = String(current.ext).toLowerCase();
        const isWopiEnabled = ViewerSelectors.isWopiEnabled(state, currentExt) || current.id === EditorID.R7_WOPI;
        const editorConfig = editors.find((editor) => editor.id === current.id);
        const isChangeEditorPossible = AVAILABLE_PROVIDER_ID_MAP[current.id] && isWopiEnabled && editorConfig?.wopi;
        const editorData = await (isChangeEditorPossible ? getEditorData(item.id) : Promise.resolve(undefined));
        const astraMetaFeature = getAstraMetaFeature(state);
        const gaCategory = current.id === EditorID.R7 ? 'r7-edtr' : 'myoffice';

        if (isRebranding) {
            setRebranding();
        }

        let wopiData = editorData?.status === EditorStatus.Ready ? editorData.data : undefined;

        /**
         * Переопределяем текущий редактор.
         * Пользователь мог редактировать c помощью МойОфис,
         * а после у него включился эксперимент и основным стал редактор R7Wopi.
         *
         * Note. editorData.data может быть null (5хх ошибки) или EditorID (4хх ошибки). Переключение здесь только при EditorID
         */
        if (editorData?.status === EditorStatus.Error && editorData.data) {
            current.id = editorData.data;
        }

        const navigationStart = window.performance && performance.timing && performance.timing.navigationStart;
        const radarGroup = `editors-${current.id}`;
        const isEditorMyOffice = current.id === EditorID.MYOFFICE;
        const theme = isDarkThemeModeEnabled() ? R7Theme.dark : R7Theme.light;

        const editorCount = editor.getEditorsFor(item || current).length;

        const sendEpsilonTime = function (lable: string, time = 0) {
            if (DETAILED_RADARS) {
                // CLOUDWEB-7854
                if (arguments.length < 2) {
                    time = Date.now() - navigationStart;
                }

                // eslint-disable-next-line new-cap
                RADAR(`${radarGroup}_${lable}_time-${time > 60000 ? 'over1m' : epsilonTime(time)}`);
            }
        };

        const radar = RADAR.group(radarGroup);

        radar.add('open');
        radar.add(`open_${currentExt}`);
        sendEpsilonTime(`open_${currentExt}`);

        const currentEditorConfig =
            editorData?.status === EditorStatus.Error
                ? editors.find((editor) => [EditorID.R7_WOPI, EditorID.MYOFFICE].includes(editor.id))
                : editors.find((editor) => editor.id === current.id);

        let currentEditor = currentEditorConfig && OFFICE_EDITOR[current.id];
        let frameData = current.data;

        const editorWaiting = document.querySelector('#editor__waiting') as HTMLElement;

        if (isWopiEnabled && currentEditorConfig?.wopi) {
            try {
                if (!wopiData) {
                    wopiData = (await callWopiEditAPICall(item?.id, current.id)).data;
                }
                current.mode = EditorMode.EDIT;
                const url = addParams(wopiData?.url, {
                    title: 'title',
                    lang: 'ru-ru',
                    thm: theme,
                    closebutton: 1,
                    revisionhistory: 1,
                });

                frameData = {
                    ...wopiData,
                    [`${current.mode}_url`]: url,
                };
            } catch (error) {
                logger.error(error);
                captureException(error);
            }
        }
        const isNeedForceAmrFallback = currentEditor && !wopiData && !editorData?.data;

        if ((!currentEditor || (!IS_PUBLIC && editorCount === 0)) && !isNeedForceAmrFallback) {
            radar.add('error').send();
            sendEpsilonTime('error');
            reduxStore.dispatch(routeStatusPage({ status: EStatus.NOT_FOUND }));
        } else if ((!frameData?.edit_url || (!frameData?.access_token && !isEditorMyOffice)) && !isNeedForceAmrFallback) {
            radar.add('error').send();
            sendEpsilonTime('error');
            reduxStore.dispatch(routeStatusPage({ status: EStatus.INTERNAL_SERVER_ERROR }));
        } else {
            const OfficeEditor = OFFICE_EDITOR[current.id];
            currentEditor = new OfficeEditor({
                mode: current.mode,
                editorId: current.id,
            });

            reduxStore.dispatch(
                startEditor({
                    editorId: current.id,
                    // @ts-ignore
                    item: !IS_PUBLIC ? clone(item) : undefined,
                })
            );

            const notifyMeta = reduxStore.dispatch(getNotificationAboutCreatingCopyOfNoneditableFile());
            const createdFileId = notifyMeta?.newId;
            const notifyFrom = notifyMeta?.from;
            const currentFileId = item?.id;
            const editAttachCopyText = editAttachCopyNotification.getText();
            const editCopyText = editPublicCopyNotification.getText();

            if (editCopyText) {
                snackbarController.showSnackbar({
                    id: 'clone-edit-notification',
                    closable: true,
                    text: editCopyText,
                    type: SnackbarTypes.success,
                    oneline: true,
                    closeTimeout: 5000,
                });
                editPublicCopyNotification.clear();
            }

            if (editAttachCopyText) {
                snackbarController.showSnackbar({
                    id: 'clone-edit-attach-notification',
                    closable: true,
                    text: editAttachCopyText,
                    type: SnackbarTypes.success,
                    closeTimeout: 5000,
                });
                editAttachCopyNotification.clear();
            }

            if (createdFileId && createdFileId === currentFileId) {
                const { buttonText, onButtonClick } = {
                    [NotifyFrom.home]: {
                        buttonText: 'Перейти к файлу',
                        onButtonClick: () =>
                            toolbarActions.showInParentFolder({
                                folderId: item?.parent ?? '',
                                itemId: currentFileId,
                            }),
                    },
                    [NotifyFrom.sharedHome]: {
                        buttonText: 'Настроить доступ',
                        onButtonClick: () => toolbarActions.publish('editor', currentFileId),
                    },
                }[notifyFrom];
                snackbarController.showSnackbar({
                    id: 'create-copy-of-non-editable-file-success',
                    closable: true,
                    text: 'Документ создан',
                    buttonText,
                    onButtonClick,
                    type: SnackbarTypes.success,
                    oneline: true,
                    closeTimeout: 5000,
                });
                reduxStore.dispatch(clearNotificationAboutCreatingCopyOfNoneditableFile());
            }

            const header = document.querySelector('#react-header');

            const headerType = isOnPremR7Product ? 'b2b' : 'b2c';

            const renderB2CHeader = header && headerType === 'b2c' && [EditorID.MYOFFICE, EditorID.R7_WOPI].includes(current.id);
            const renderB2BHeader = header && (headerType === 'b2b' || current.id === EditorID.R7);

            if (renderB2BHeader) {
                const bizInlineToolbar = new BReactComponent({
                    ReactComponent: BizInlineEditorToolbar,
                });

                bizInlineToolbar.renderTo(header, {
                    props: {
                        isPublic: IS_PUBLIC,
                        gaCategory,
                        mode: current.mode,
                        file: item,
                    },
                });
            }

            if (renderB2CHeader) {
                const file = item as CloudFile;
                const isMyOfficeSendDetailRadars = getFeatureMyOfficeSendDetailRadars(state);

                if (isMyOfficeSendDetailRadars && (current.id === EditorID.MYOFFICE || currentEditorConfig?.wopi)) {
                    /** Проверка на флаг для моего офиса(моб/десктоп)
                     *
                     *  {@link https://jira.vk.team/browse/CLOUDWEB-13905 Задача}
                     */
                    const deviceType = !IS_MOBILE_BROWSER ? 'desktop' : 'mobile';
                    const viewerType = isWopiEnabled && currentEditorConfig?.wopi ? 'wopi' : 'amr';
                    const mode = 'edit';

                    /** Формат радара для моего офиса
                     *    @example Пример.
                     *    myoffice_open_<device_type>_<viewer_type>_<mode>_<ext>
                     */
                    xray.send(`myoffice_open_${deviceType}_${viewerType}_${mode}_${file?.ext}`);
                }

                startEditorSuccessWaiter(file, current.id, true);

                if (current.id !== EditorID.MYOFFICE) {
                    const documentBubble = Object.assign(new Promo(BUBBLE_ID), {
                        isEnabled() {
                            return isEnabledBubble;
                        },
                    });

                    if (!isEnabledBubble) {
                        promoController.register(1, documentBubble);
                    }

                    isEnabledBubble = true;
                }

                const isMounted = isMountedOrSharedFolder(state, item).isMounted || item.isThisOrParentMounted;

                if (inlineHeader.enable && current.id === EditorID.R7_WOPI) {
                    const inlineToolbar = new BReactComponent({
                        ReactComponent: InlineEditorToolbar,
                    });

                    inlineToolbar.renderTo(header, {
                        props: {
                            isPublic: IS_PUBLIC,
                            file,
                        },
                    });
                } else {
                    const reactHeader = new BReactComponent({
                        ReactComponent: EditorHeader,
                    });

                    reactHeader.renderTo(header, {
                        props: {
                            /**
                             * TODO Вернуть условие, когда появится новый дизайн промо, выключаем перед запуском раздела документов
                             *  https://jira.vk.team/browse/CLOUDWEB-16928
                             */
                            showBubble: false,
                            onBubbleShow: () => {
                                // @ts-ignore
                                promoController.markAsShown(BUBBLE_ID);
                            },
                            isPublic: IS_PUBLIC,
                            isStock: IS_STOCK,
                            enableFavorites: !IS_PUBLIC && !isMounted,
                            gaCategory,
                            downloadDropdownList: isEditorMyOffice ? getDownloadDropdownList(file.name, file?.ext) : null,
                            editorId: current.id,
                            file,
                        },
                    });
                }

                const hasParentMountedOrShared = hasParentMountedOrSharedFolder(state, [item]);

                if (IS_FREE_BIZ_SAAS_USER && !IS_PUBLIC && !hasParentMountedOrShared && item.weblinkAccessRights !== 'rw') {
                    renderBizPaidEditorDialog();
                    return;
                }

                const isLLMEnabled = LLM && isAvailableInDocumentSection(item?.ext) && SCENARIO_SELECT_OPTIONS.length;

                const llmButton = document.querySelector('#llm-button');
                const llmDialog = document.querySelector('#llm-dialog');

                if (isLLMEnabled && llmButton && llmDialog) {
                    const reactLlmButton = new BReactComponent({
                        ReactComponent: EditorLLMButton,
                    });

                    const reactLlmDialog = new BReactComponent({
                        ReactComponent: EditorLlmDialog,
                    });

                    reactLlmButton.renderTo(llmButton, {
                        props: {
                            isWithText: llmFeature.hoverType === 'withText',
                        },
                    });
                    reactLlmDialog.renderTo(llmDialog);
                }

                if (current.id === EditorID.R7_WOPI) {
                    r7StageCB({
                        onError: () => {
                            editorContainer?.remove();
                            header?.remove();
                            llmButton?.remove();
                            llmDialog?.remove();
                            reduxStore.dispatch(routeStatusPage({ status: EStatus.SOMETHING_WRONG }));
                        },
                    });
                }

                if (IS_TABLET_BROWSER && file.kind === 'document') {
                    // eslint-disable-next-line new-cap
                    RADAR(`public-mobile_openrw_${file?.ext}`);

                    const reactDialog = new BReactComponent({
                        ReactComponent: MobileDialog,
                    });

                    const publicNotifyEl = document.querySelector('.js-react-public-notify');
                    if (publicNotifyEl) {
                        reactDialog.renderTo(publicNotifyEl, {
                            props: {
                                title: 'Редактирование недоступно в мобильном браузере',
                                text: 'Пожалуйста, откройте ссылку на вашем компьютере',
                                closableStateInside: true,
                                isTablet: true,
                                mod: 'mobileV1',
                            },
                        });
                    }
                }
            }

            if (astraMetaFeature && current?.id) {
                createAstraMetaDiv({
                    processorType: AstraMetaProcessorType.editor,
                    processorIDsMap: astraMetaFeature,
                    editorID: current.id,
                    isWOPI: isWopiEnabled,
                    fileExt: currentExt,
                });
            }
            const sendInfo = (status: string, frameStartTime: number) => {
                if (navigationStart) {
                    const time = Date.now() - navigationStart;

                    radar.add('load', time);
                    radar.add(`${'load_'}${currentExt}`, time);
                    radar.add(`load_${status}`, time);
                    radar.add(`load_${status}_${currentExt}`, time);
                    sendEpsilonTime(`load_${status}_${currentExt}`, time);
                }

                const frameTime = Date.now() - frameStartTime;

                radar.add('frame-load', frameTime);
                radar.add(`${'frame-load_'}${currentExt}`, frameTime);
                radar.add(`frame-load_${status}`, frameTime);
                radar.add(`frame-load_${status}_${currentExt}`, frameTime);
                sendEpsilonTime(`frame-load_${status}_${currentExt}`, frameTime);

                try {
                    radar.send();
                } catch (_) {}
            };

            const frameStartTime = Date.now();

            addEditorMessageListener();

            let isEditorFailed;
            const editorContainer = document.querySelector('#editor-container');
            const editorUrl = frameData?.edit_url || frameData?.view_url;
            const params = {
                access_token: frameData?.access_token || '',
                access_token_ttl: frameData?.access_token_ttl || 0,
            };

            currentEditor
                .start(editorContainer, editorUrl, current.mode, params, isNeedForceAmrFallback)
                .fail(() => {
                    isEditorFailed = true;
                    // Не загрузился iframe с редактором,
                    // показываем пользователю Облачную ошибку.
                    sendInfo('error', frameStartTime);
                })
                .always(() => {
                    if (editorWaiting) {
                        editorWaiting.style.display = 'none';
                    }
                    if (!isEditorFailed && editorContainer instanceof HTMLElement) {
                        editorContainer.dataset.editorReady = 'true';
                    }
                });

            currentEditor
                .on('timeout', () => {
                    if (isEditorFailed) {
                        return;
                    }
                    // Событие iframe.load сработало, но в течение
                    // установленнного времени так и не пришло
                    // ни одного сообщения от редактора.
                    // Вероятно редактор показал какую-то ошибку.
                    sendInfo('error', frameStartTime);
                })
                .on('sharing', () => {
                    publishHelper({ item });
                })
                .on('start', () => {
                    sendViewerDwh({
                        id_public: 'None',
                        source: 'home',
                        type_content: item?.kind,
                        // @ts-ignore
                        extension: item?.ext?.toLowerCase() || '',
                        action: 'open-content',
                        is_full_render: true,
                        is_edit: true,
                        // @ts-ignore
                        size_files: item.size,
                    });
                    if (!isEditorFailed) {
                        sendInfo('success', frameStartTime);
                    }
                })
                .on('error', () => {
                    editorContainer?.remove();
                    header?.remove();
                    reduxStore.dispatch(routeStatusPage({ status: EStatus.SOMETHING_WRONG }));
                })
                .on('rename', (_: any, params: any) => {
                    const { newName } = params;

                    if (!newName) {
                        return;
                    }

                    const splitPathName = window.location.pathname.split('/');
                    const withoutFileName = splitPathName.slice(0, -1).join('/');
                    // в current.ext лежит оригинальное расширение до применения toLowerCase
                    const fullName = `${newName}.${current.ext}`;
                    const url = `${withoutFileName}/${fullName}`;
                    const title = `${fullName} / Облако Mail.ru`;

                    replaceState(title, url);
                });
        }
    },

    start: () => {
        sessionWatcher.init();

        return editor.onRouteEnd;
    },
};

function openEditor(editorArg: any, item: any, newExt?: string | null, force?: boolean, fileName?: string) {
    const isCreate = !!newExt;
    const mode = isCreate ? 'create' : 'edit';
    const ext = item.ext2 || item.ext;
    const IS_WEBKIT = browser.isWebKit();

    if (!editorArg) {
        snackbarController.showSnackbar({
            id: 'no-editor',
            closable: true,
            text: `Не удалось открыть на редактирование файл этого  формата`,
            type: SnackbarTypes.failure,
            closeTimeout: 5000,
        });

        return;
    }

    if (!force) {
        if (IS_FREE_B2B_BIZ_EDITORS_USER && !editorArg) {
            renderBizPaidEditorDialog();
            return;
        }

        const state = reduxStore.getState();
        const isSharedOrMountedState = isMountedOrSharedFolder(state, item);
        const isMounted = isSharedOrMountedState && isSharedOrMountedState.isMounted;
        const lastId = isSharedOrMountedState && isSharedOrMountedState.folderId;
        const isSharedOrMounted = isSharedOrMountedState && isSharedOrMountedState.is;
        const folder = lastId && getItemById(state, lastId);

        // @ts-ignore
        if (isMounted && folder && folder.isReadOnly) {
            renderMessageBox({
                dataQAId: 'document-edit-readonly-folder-dialog',
                title: `${isCreate ? 'Папка доступна' : 'Файл доступен'} только для просмотра`,
                renderContent: () =>
                    `Общая папка доступна вам только для просмотра. ${
                        isCreate ? 'Вы не можете создавать в ней файлы.' : 'Вы не можете редактировать содержащиеся в ней файлы.'
                    }`,
            });

            logEditorOpenError(editorArg.id, 'readonly');

            return;
        }

        if (
            !isCreate &&
            isSharedOrMounted &&
            !editorArg[X_IN_SHARED_FOLDER] &&
            !editorArg['x-skip-shared-folder-warning'] &&
            !getIsOpenDocumentWarning(HAS_SHARED_EDITOR)
        ) {
            renderOpenDocument({
                hasSharedEditor: HAS_SHARED_EDITOR,
                onSuccess: () => {
                    setIsOpenDocumentWarning(HAS_SHARED_EDITOR);
                    openEditor(editorArg, item, newExt, true);
                },
            });

            return;
        }
    }

    if (editorArg['x-counter']) {
        sendCounter(editorArg['x-counter']);
    }

    let tabName = createWindowName(editorArg.id, item);

    {
        // CLOUDWEB-5491, CLOUDWEB-5602, CLOUDWEB-5605, CLOUDWEB-5606
        if (isCreate) {
            // Открываем новую вкладку с редактором
            // для каждого нового документа.
            tabName += Date.now().toString(36);
        }

        // Chrome, Opera и Safari позволяют переключиться
        // на вкладку с редактором. IE включает мигание вкладки.
        tabs = tabs || {};
        const tab = tabs[tabName];

        if (tab) {
            // Для этого документа уже открывалась вкладка с редактором.
            if (isOpenedTab(tab)) {
                // Вкладка редактора для этого документа все еще открыта.
                // Просто переключаемся на вкладку с редактором
                // и завершаем дальнейшее выполнение openEditor.
                tab.focus();

                if (!IS_WEBKIT) {
                    // В IE включится мигание вкладки, по переключение
                    // не произойдет. Firefox вообще никак не отрегаирует.
                    // Поэтому показываем пользователю bubble.
                    const message = 'Документ «{NAME}» уже открыт для редактирования.'.replace(
                        '{NAME}',
                        shortenFilename(item, 60).split('').join('\u200B')
                    ); // TODO: use b-filename

                    const isViewerVisible = ViewerSelectors.isViewerActive(reduxStore.getState());
                    if (!isViewerVisible) {
                        snackbarController.showSnackbar({
                            id: 'error',
                            text: message,
                            type: SnackbarTypes.failure,
                            closable: true,
                        });
                    }
                }

                return;
            }
            // Вкладка редактора для этого документа уже закрыта,
            // удаляем ненужную сслыку на старый инстанс Window.
            delete tabs[tabName];
        }
    }

    const openTab = editorArg['x-open-popup'] ? openPopup : _openTab;

    // RADAR.ga('send', 'event', 'open_for_' + mode, newExt || item.ext, editorArg.id.toLowerCase());

    const radar = RADAR.group(`app_edit-${editorArg.id.toLowerCase()}`);

    radar.add(mode);
    radar.add(`${mode}_${newExt || item.ext}`);
    radar.send();

    if (editorArg.id !== EditorID.R7 && editorArg.id !== EditorID.MYOFFICE) {
        startEditorSuccessWaiter(item, editorArg.id, false);
    }

    const processApiError = (errors) => {
        openTab('/500', tabName);

        logEditorOpenError(editorArg.id, 'apierror');

        if (IS_REACT_PAGE) {
            reduxStore.dispatch(makeNewDocumentError());
        }

        captureMessage('EDITOR ERROR', {
            arguments: {
                item,
                errors,
            },
        } as any);
    };

    const promise = new Promise((resolve, reject) => {
        const convertExt = editorArg.convert_extensions[ext];
        if (editorArg.id === EditorID.R7 && convertExt) {
            renderComfirmationDialog({
                dataQAId: 'document-convert-confirmation-dialog',
                renderContent: () =>
                    `Формат файла не поддерживается. Создать копию в поддерживаемом формате ${convertExt} и начать редактирование?`,
                onSuccess: () => {
                    // TODO: где-то тут надо будет потом доработать от продукта
                    // что делать и как проверять, что файл уже существует,
                    // нужно ли спрашивать юзера про новое имя и тп
                    callApiR7Convert(item.id)
                        .then(({ data }) => {
                            resolve({ id: data.path, storage: item.storage, ext: convertExt });
                        })
                        .catch((err) => {
                            snackbarController.showSnackbar({
                                id: 'document-convert-error',
                                type: SnackbarTypes.failure,
                                text: `Не удалось сконвертировать документ: ${err}`,
                                closable: true,
                            });
                            reject();
                        });
                },
                onClose: () => reject(),
            });
        } else {
            // @ts-ignore
            resolve();
        }
    });

    promise.then((newItem) => {
        const state = reduxStore.getState();
        const { action, isFromExternal } = SettingsSelectors.getQueryParams(state);
        const isCreateDocumentByQueryParam = isCreateDocumentQueryAction(action);

        if (!IS_MY_TEAM && !isCreateDocumentByQueryParam /* CLOUDWEB-14757: не нужно что бы открывался таб */) {
            openTab('about:blank', tabName);
        }

        // после конвертации редактор по-умолчанию может измениться
        if (newItem) {
            const editorId = editor.getDefaultEditorId(newItem, false);
            editorArg = editorId && editorsMap[editorId];
        }

        const url = getUrlV2(editorArg, { item: newItem || item, newExt, token: null });
        let tab: any = null;

        // CLOUDWEB-5491, CLOUDWEB-5602, CLOUDWEB-5605, CLOUDWEB-5606
        if (isCreate) {
            callApiCreateDoc(url, newExt, fileName)
                .then(({ data = '' }) => {
                    const editUrl = getUrlV2(editorArg, {
                        item: { id: IS_ONPREMISE ? data.path : joinPath(item.id, data.name) },
                        newExt: null,
                        token: null,
                    });

                    const name = IS_MY_TEAM ? '_self' : tabName;

                    if (!isCreateDocumentByQueryParam /* CLOUDWEB-14757: не нужно что бы открывался таб */) {
                        tab = openTab(editUrl, name);
                    } else {
                        /* Не энкодим, потому что доверяем getUrlV2 */
                        window.location.href = isFromExternal ? `${editUrl}?isFromExternal=true` : editUrl;
                    }

                    if (IS_REACT_PAGE) {
                        reduxStore.dispatch(makeNewDocumentSuccess());
                    }
                })
                .catch(processApiError);
        } else if (IS_MY_TEAM) {
            window.open(url, '_self');
        } else {
            // Chrome, Opera и Safari позволяют переключиться
            // на вкладку с редактором, а IE включает мигание
            // вкладки, для этого запоминаем ссылку
            // на соответствующий инстанс Window.
            tab = openTab(url, tabName);

            startTabGC();
        }

        if (tab) {
            tabs[tabName] = tab;
        }
    });
}

export { editor };
