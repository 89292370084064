import type { AxiosResponse } from 'axios';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import {
    getGroupType,
    getSortParams,
    prepareParamsQuotaCleaner,
    QuotaCleanerDeleteAPICall,
    QuotaCleanerGroupAPICall,
    QuotaCleanerListAPICall,
} from 'reactApp/api/QuotaCleanerAPICall';
import { quotaCleanerOldGroupYear } from 'reactApp/appHelpers/featuresHelpers';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import type { AttachesFolderItem } from 'reactApp/modules/attaches/attaches.types';
import {
    getFeatureQuotaCleanerCloud,
    getFeatureQuotaCleanerGroups,
    getFeatureQuotaCleanerItemsMaxOffset,
    getFeatureQuotaCleanerLetters,
    getFeatureQuotaCleanerLimit,
    getFeatureQuotaCleanerMaxLettersCount,
    getFeatureQuotaCleanerYearFilter,
} from 'reactApp/modules/features/features.selectors';
import { showVirusDlg } from 'reactApp/modules/popup/popup.module';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { cloudGroups, letterGroups, quotaCleanerRlog } from 'reactApp/modules/userQuotaCleaner/helpers/constants';
import { getRemoveSnackbarText } from 'reactApp/modules/userQuotaCleaner/helpers/getDeleteSnackbarText';
import { getHasMoreToLoad } from 'reactApp/modules/userQuotaCleaner/helpers/getHasMoreToLoad';
import { openItemView } from 'reactApp/modules/userQuotaCleaner/helpers/openItemView';
import { deleteItemsError } from 'reactApp/modules/userQuotaCleaner/sagas/deleteItemsError';
import { deleteItemsSuccess } from 'reactApp/modules/userQuotaCleaner/sagas/deleteItemsSuccess';
import { deleteLetters } from 'reactApp/modules/userQuotaCleaner/sagas/deleteLetters';
import { handleGoToCleanerAndShowQuotaCleaner } from 'reactApp/modules/userQuotaCleaner/sagas/handleGoToCleanerAndShowQuotaCleaner';
import {
    deleteLettersFromQuota,
    deleteUserQuotaCleanerItems,
    deleteUserQuotaCleanerItemsFromStore,
    deleteUserQuotaCleanerItemsSuccess,
    getUserQuotaGroup,
    goToCleanerAndShowQuotaCleaner,
    loadUserQuotaCleanerList,
    loadUserQuotaCleanerListError,
    loadUserQuotaCleanerListSuccess,
    loadUserQuotaCleanerStart,
    loadUserQuotaGroup,
    loadUserQuotaGroupError,
    loadUserQuotaGroupFromList,
    loadUserQuotaGroupMore,
    loadUserQuotaGroupSuccess,
    openUserQuotaItemView,
    prepareUserQuotaCleanerParams,
    reloadGroupAfterDelete,
    setCurrentGroup,
    setIsItemsDeleteProcess,
    setMessagesRemove,
    setNeedsGroupReload,
    setYearGroupConfig,
    setYearGroupsConfig,
    startLoadingGroup,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import {
    getCurrentGroup,
    getGroupById,
    getGroupsParams,
    getItemByGroup,
    getItemById,
    getYearGroupConfig,
    getYearGroupsConfig,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import {
    QuotaCleanerGroup,
    QuotaCleanerGroupParam,
    QuotaCleanerListParam,
    QuotaCleanerYearConfig,
    QuotaCleanerYears,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import {
    UserQuotaCleanerDelete,
    UserQuotaCleanerGroup,
    UserQuotaCleanerListParams,
    UserQuotaGroupId,
    UserQuotaGroupType,
} from 'reactApp/types/QuotaCleaner';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { handleAttachFoldersRequest } from '../attaches/attaches.saga';
import { getYearDeleteConfig } from './helpers/getYearDeleteConfig';
import { getYearParams } from './helpers/getYearParams';

const quotaCleanerListApiCall = (groupParams: QuotaCleanerListParam): Promise<AxiosResponse<{ body?: UserQuotaCleanerGroup[] }>> =>
    new QuotaCleanerListAPICall().makeRequest(groupParams);

const quotaCleanerGroup = (groupParam: QuotaCleanerGroupParam): Promise<AxiosResponse<{ body?: UserQuotaCleanerGroup }>> =>
    new QuotaCleanerGroupAPICall().makeRequest(groupParam);

const quotaCleanerItemsDelete = (ids: string[]): Promise<AxiosResponse<{ body?: UserQuotaCleanerDelete }>> =>
    new QuotaCleanerDeleteAPICall().makeRequest({ ids });

function* prepareGroupParams(folders: AttachesFolderItem[], userFolders: AttachesFolderItem[], messagesTotalCount: number) {
    const externalGroups: UserQuotaGroupId[] = [];

    const isMailBinDisabled = yield select(UserQuotaSelectors.getIsMailBinDisabled);
    if (isMailBinDisabled) {
        externalGroups.push(UserQuotaGroupId.MailBin);
    }

    const isCloudEnabled = yield select(getFeatureQuotaCleanerCloud);
    if (!isCloudEnabled) {
        externalGroups.push(...cloudGroups);
    }

    const maxLettersCount = yield select(getFeatureQuotaCleanerMaxLettersCount);
    const isLettersEnabled = yield select(getFeatureQuotaCleanerLetters);
    const isLettersCountInvalid = maxLettersCount && messagesTotalCount > maxLettersCount;

    if (!isLettersEnabled || isLettersCountInvalid) {
        externalGroups.push(...letterGroups);
    }

    const folderIds = folders.map((folder) => folder.id);
    const userFoldersIds = userFolders.map((folder) => folder.id);

    const enabledGroups = yield select(getFeatureQuotaCleanerGroups);
    const disabledGroups = [...letterGroups, ...cloudGroups].filter((group) => !enabledGroups.includes(group));
    externalGroups.push(...disabledGroups);

    const limit = yield select(getFeatureQuotaCleanerLimit);
    const isYearFilter = yield select(getFeatureQuotaCleanerYearFilter);

    const params = prepareParamsQuotaCleaner({
        folders: folderIds,
        userFolders: userFoldersIds,
        limit,
        isYearFilter,
        oldYearGroupShift: quotaCleanerOldGroupYear,
        excludedGroupIds: externalGroups,
    });
    yield put(prepareUserQuotaCleanerParams(params));
}

function* getQuotaCleanerList() {
    yield put(loadUserQuotaCleanerStart());
    const groupParams: Record<UserQuotaGroupId, UserQuotaCleanerListParams> = yield select(getGroupsParams);
    const isMobile = yield select(QuotaLandingSelectors.isMobile);

    try {
        const groupList = Object.values(groupParams);
        if (!groupList.length) {
            yield put(loadUserQuotaCleanerListSuccess([]));
            return;
        }

        const {
            data: { groups },
        } = yield quotaCleanerListApiCall({ groups: groupList });
        yield put(loadUserQuotaCleanerListSuccess(groups));
        xray.send(`quota-cln-show-scc${isMobile ? '-mob' : ''}`);
    } catch (error) {
        yield put(loadUserQuotaCleanerListError());
        logger.error(error);
        xray.send(`quota-cln-get-list-err${isMobile ? '-mob' : ''}`, {
            rlog: quotaCleanerRlog,
            rlog_message: {
                error,
            },
        });
    }
}

function* loadQuotaCleanerList() {
    const isMobile = yield select(QuotaLandingSelectors.isMobile);
    let folders: AttachesFolderItem[] = [];
    let userFolders: AttachesFolderItem[] = [];
    let messagesTotalCount = 0;

    try {
        yield call(handleAttachFoldersRequest);
        const foldersData = yield select(AttachesSelectors.getAttachFolders);
        folders = Array.from(Object.values(foldersData));
        userFolders = folders.filter((folder) => !folder.system);
        messagesTotalCount = yield select(AttachesSelectors.getAllMessagesTotalCount);
    } catch (error) {
        logger.error(error);
        xray.send(`quota-cln-folders-err${isMobile ? '-mob' : ''}`, {
            rlog: quotaCleanerRlog,
            rlog_message: {
                error,
            },
        });
    }

    yield prepareGroupParams(folders, userFolders, messagesTotalCount);
    yield getQuotaCleanerList();
}

function* updateYearGroupConfig(action: { deletedCount: number; deletedSize: number; itemIds: string[] }) {
    const { deletedCount, deletedSize, itemIds } = action;
    const yearGroupsConfig = yield select(getYearGroupsConfig);
    const currentGroup: QuotaCleanerGroup | null = yield select(getCurrentGroup);
    if (!currentGroup) {
        return;
    }

    const { groupId, totalCount, size, list } = currentGroup;
    const resultedYearGroupsConfig: QuotaCleanerYearConfig = JSON.parse(JSON.stringify(yearGroupsConfig)); // делаем копию, т.к. потом будем удалять поле по ключу
    const currentYearGroupConfig = resultedYearGroupsConfig[groupId];
    if (!currentYearGroupConfig) {
        return;
    }

    // кейс когда ты находишься на фильтре все и удаляешь все элементы из какого-то года
    const deleteYearConfig = getYearDeleteConfig(list, itemIds);

    const { currentYear, config } = currentYearGroupConfig;
    const isAll = currentYear === QuotaCleanerYears.All;

    const updatedCount = Math.max(totalCount || 0 - deletedCount, 0);
    const updatedSize = Math.max(size - deletedSize, 0);

    if (!updatedCount || !updatedSize) {
        // когда вся группа пустая
        if (isAll) {
            delete resultedYearGroupsConfig[groupId];
        } else {
            currentYearGroupConfig[QuotaCleanerYears.All].size -= deleteYearConfig[currentYear]?.size || 0;
            currentYearGroupConfig[QuotaCleanerYears.All].totalCount -= deleteYearConfig[currentYear]?.count || 0;

            const years = Object.keys(config);
            const currentYearIndex = Object.keys(config).findIndex((year) => year === currentYear);
            // если за текущий год все кончилось, показываем предыдущий или, если его нет, следующий
            const updatedCurrentYear = years[currentYearIndex - 1] ?? years[currentYearIndex + 1] ?? QuotaCleanerYears.All;
            currentYearGroupConfig.currentYear = updatedCurrentYear;
            delete config[currentYear];
        }
    } else if (isAll) {
        Object.keys(deleteYearConfig).forEach((year) => {
            if (!config[year]) {
                return;
            }

            config[year].size -= deleteYearConfig[year]?.size || 0;
            config[year].totalCount -= deleteYearConfig[year]?.count || 0;
        });

        currentYearGroupConfig[QuotaCleanerYears.All] = {
            size: updatedSize,
            totalCount: updatedCount,
        };
    } else {
        currentYearGroupConfig[QuotaCleanerYears.All].size -= deleteYearConfig[currentYear]?.size || 0;
        currentYearGroupConfig[QuotaCleanerYears.All].totalCount -= deleteYearConfig[currentYear]?.count || 0;

        config[currentYear] = {
            size: updatedSize,
            totalCount: updatedCount,
        };
    }

    // чистим невалидные значения
    Object.keys(config).forEach((year) => {
        const { size, totalCount } = config[year];
        if (size <= 0 || totalCount <= 0) {
            delete config[year];
        }
    });

    // если после чистки текущий фильтр удалился
    if (!config[currentYearGroupConfig.currentYear]) {
        currentYearGroupConfig.currentYear = QuotaCleanerYears.All;
    }

    yield put(setYearGroupsConfig({ value: resultedYearGroupsConfig }));
}

function* loadGroup(action: ReturnType<typeof loadUserQuotaGroup>) {
    const {
        payload: { groupId, offset: currentOffset, isGroupReset, onSuccess },
    } = action;
    const validOffset = typeof currentOffset === 'number' && !Number.isNaN(currentOffset) ? currentOffset : 0;
    const maxOffset = yield select(getFeatureQuotaCleanerItemsMaxOffset);
    const limit = yield select(getFeatureQuotaCleanerLimit);
    const offset = Math.min(validOffset, maxOffset);

    const groupParams = yield select(getGroupsParams);
    const yearConfig = yield select(getYearGroupConfig, groupId);
    const isYearAallView = yearConfig?.currentYear === QuotaCleanerYears.All;
    const isMobile = yield select(QuotaLandingSelectors.isMobile);

    const groupParam = {
        ...groupParams[groupId],
        ...getSortParams(isYearAallView),
        ...getYearParams(yearConfig?.currentYear),
        limit,
        offset,
    };

    try {
        const { data } = yield quotaCleanerGroup(groupParam);
        yield put(loadUserQuotaGroupSuccess({ data, offset, limit, maxOffset, isGroupReset }));
        xray.send(`quota-cln-group-scc${isMobile ? '-mob' : ''}`, { i: groupId });

        onSuccess?.();
    } catch (error) {
        yield put(loadUserQuotaGroupError({ groupId, groupType: getGroupType(groupId) }));
        logger.error(error);
        xray.send(`quota-cln-get-group-err${isMobile ? '-mob' : ''}`, {
            i: groupId,
            rlog: quotaCleanerRlog,
            rlog_message: {
                error,
                groupId,
                groupParam,
                offset,
            },
        });

        yield put(
            showSnackbarAction({
                id: 'quotaCleanerLoadGroupError',
                type: SnackbarTypes.failure,
                text: 'Ошибка загрузки. Обновите страницу или попробуйте позже',
                closable: true,
            })
        );
    }
}

function* loadGroupFromList(action: ReturnType<typeof loadUserQuotaGroupFromList>) {
    const {
        payload: { groupId, onSuccess },
    } = action;
    const currentGroup = yield select(getGroupById, groupId);
    if (!currentGroup) {
        return;
    }

    const { childs, totalCount } = currentGroup;

    const maxOffset = yield select(getFeatureQuotaCleanerItemsMaxOffset);
    const limit = yield select(getFeatureQuotaCleanerLimit);

    yield put(
        setCurrentGroup({
            value: {
                ...currentGroup,
                hasMoreToLoad: getHasMoreToLoad({
                    currentCount: childs.length,
                    totalCount,
                    maxOffset,
                    limit,
                }),
            },
        })
    );

    onSuccess?.();
}

function* loadGroupMore(action: ReturnType<typeof loadUserQuotaGroupMore>) {
    const {
        payload: { offset },
    } = action;
    const { groupId } = yield select(getCurrentGroup) ?? {};
    if (!groupId) {
        return;
    }

    yield put(loadUserQuotaGroup({ groupId, offset }));
}

function* getGroup(action: ReturnType<typeof getUserQuotaGroup>) {
    const {
        payload: { groupId, year, onSuccess },
    } = action;
    const yearConfig = yield select(getYearGroupConfig, groupId);
    const hasYearChanged = year !== yearConfig?.currentYear;

    if (year) {
        yield put(
            setYearGroupConfig({
                groupId,
                value: {
                    ...yearConfig,
                    currentYear: year,
                },
            })
        );

        yield put(startLoadingGroup({ groupId, groupType: getGroupType(groupId), hasYearChanged }));
        yield put(loadUserQuotaGroup({ groupId, onSuccess }));
    } else {
        yield put(loadUserQuotaGroupFromList({ groupId, onSuccess }));
    }
}

function* onDeleteUpdate(action: ReturnType<typeof reloadGroupAfterDelete>) {
    const {
        payload: { ids, itemIds, groupId, size, isGroupReset },
    } = action;
    yield put(resetSelect());
    yield call(updateYearGroupConfig, { deletedCount: ids.length, deletedSize: size, itemIds });

    yield put(deleteUserQuotaCleanerItemsFromStore({ deleteIds: ids }));
    yield put(setMessagesRemove({ value: false }));

    yield put(loadUserQuotaGroup({ groupId, isGroupReset }));
}

function* deleteItems(action: ReturnType<typeof deleteUserQuotaCleanerItems>) {
    const {
        payload: { ids, itemIds, items, groupId, groupType, size, onDeleteSuccess },
    } = action;
    try {
        yield put(setIsItemsDeleteProcess({ value: true }));

        const { data } = yield quotaCleanerItemsDelete(ids);
        if (data.status === 'fail') {
            throw new Error(data);
        }

        const isMailBinDisabled = yield select(UserQuotaSelectors.getIsMailBinDisabled);
        const isLetterGroup = groupType === UserQuotaGroupType.Letter;
        if (isLetterGroup && !isMailBinDisabled) {
            yield removeLettersSuccess({ count: ids.length, groupId });
            return;
        }

        yield put(
            deleteUserQuotaCleanerItemsSuccess({
                count: ids.length,
                groupType,
                groupId,
                ids,
                itemIds,
                items,
                size,
                onDeleteSuccess,
            })
        );
    } catch (error) {
        yield deleteItemsError({ error, groupId, ids, itemIds, size });
    }
}

function* removeLettersSuccess(action: { count: number; groupId: UserQuotaGroupId }) {
    const { count, groupId } = action;
    const isMobile = yield select(QuotaLandingSelectors.isMobile);

    yield put(setMessagesRemove({ value: true }));
    yield put(setNeedsGroupReload({ value: true }));
    yield put(setIsItemsDeleteProcess({ value: false }));

    if (!isMobile) {
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerRemoveLettersSuccess',
                type: SnackbarTypes.success,
                text: getRemoveSnackbarText(count),
                closable: true,
            })
        );
    }

    xray.send(`quota-cln-delete-scc${isMobile ? '-mob' : ''}`, { i: groupId });
}

function* loadItemView(action: ReturnType<typeof openUserQuotaItemView>) {
    const {
        payload: { id, groupId, isMobile },
    } = action;
    const item = groupId ? yield select(getItemByGroup, id, groupId) : yield select(getItemById, id);
    if (!item) {
        return;
    }

    if (item.isVirus) {
        return yield put(showVirusDlg({ items: [item] }));
    }

    openItemView(item, isMobile);
}

export function* watchUserQuotaCleaner() {
    yield takeEvery(loadUserQuotaCleanerList.toString(), loadQuotaCleanerList);
    // eslint-disable-next-line max-lines
    yield takeEvery(loadUserQuotaGroup.toString(), loadGroup);
    yield takeEvery(loadUserQuotaGroupMore.toString(), loadGroupMore);
    yield takeEvery(deleteUserQuotaCleanerItems.toString(), deleteItems);
    yield takeEvery(openUserQuotaItemView.toString(), loadItemView);
    yield takeEvery(deleteUserQuotaCleanerItemsSuccess.toString(), deleteItemsSuccess);
    yield takeEvery(deleteLettersFromQuota.toString(), deleteLetters);
    yield takeEvery(reloadGroupAfterDelete.toString(), onDeleteUpdate);
    yield takeEvery(loadUserQuotaGroupFromList.toString(), loadGroupFromList);
    yield takeEvery(getUserQuotaGroup.toString(), getGroup);
    yield takeEvery(goToCleanerAndShowQuotaCleaner.toString(), handleGoToCleanerAndShowQuotaCleaner);
}
