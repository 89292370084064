import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon16ArrowRightOutline } from '@vkontakte/icons';
import { ReactComponent as ArrowLeftIcon } from 'img/icons/vkui/arrow_left_outline_24.svg';
import { ReactComponent as ArrowRightIcon } from 'img/icons/vkui/arrow_right_outline_24.svg';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { abDecreaseBaseQuota } from 'reactApp/appHelpers/featuresHelpers';
import { WelcomeTrialParams } from 'reactApp/appHelpers/featuresHelpers/features.types';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { CreateBlock } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/CreateBlock';
import { PhoneAppBlock } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/PhoneAppBlock';
import { TrialBlock } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/TrialBlock/TrialBlock';
import { UploaderBlock } from 'reactApp/ui/WelcomePromo/WelcomeNewPromo/UploaderBlock';
import { useWelcomePromoLogic } from 'reactApp/ui/WelcomePromo/WelcomePromo.hooks';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './WelcomeNewPromo.css';

interface Props {
    showSubscriptions: boolean;
    space?: string;
    trialData?: WelcomeTrialParams | null;

    onSubscriptionsClick(): void;
}

export const WelcomeNewPromoComponent = memo(({ showSubscriptions, space, onSubscriptionsClick, trialData }: Props): ReactElement => {
    const isNewbie = useSelector(UserSelectors.isNewbie);
    // tempexp_16521-next-line
    const quota = abDecreaseBaseQuota && isNewbie ? bytesToNDigits(SPACE_LIST.gb_4, 3).value : space;

    const { isLoaded: isSingleQuotaLoaded } = useSelector(UserQuotaSelectors.getLoadingState);

    return (
        <div className={styles.root}>
            <div className={styles.textBlock}>
                <div className={styles.header}>Добро пожаловать в Облако!</div>
                <div className={styles.description}>
                    {/** tempexp_16521-next-line */}
                    {isSingleQuotaLoaded && (
                        <>
                            Теперь у вас есть <span className={styles.quota}>{quota}</span>.
                        </>
                    )}{' '}
                    Загружайте фото, делитесь файлами
                    <br /> и работайте вместе с другими пользователями.{' '}
                    {showSubscriptions && (
                        <span className={styles.tariffs} onClick={onSubscriptionsClick}>
                            Тарифы <Icon16ArrowRightOutline display="inline" />
                        </span>
                    )}
                </div>
            </div>
            <div className={styles.actionBlock}>
                <div className={styles.slider}>
                    <Swiper
                        slidesPerView="auto"
                        spaceBetween={16}
                        navigation={{
                            nextEl: `.${styles.arrowNext}`,
                            prevEl: `.${styles.arrowPrev}`,
                            disabledClass: styles.arrowDisabled,
                        }}
                        modules={[Navigation, FreeMode]}
                    >
                        {trialData && (
                            <SwiperSlide key="trial" className={styles.slide}>
                                <TrialBlock data={trialData} />
                            </SwiperSlide>
                        )}
                        <SwiperSlide key="1" className={styles.slide}>
                            <UploaderBlock />
                        </SwiperSlide>
                        <SwiperSlide key="2" className={styles.slide}>
                            <CreateBlock />
                        </SwiperSlide>
                        <SwiperSlide key="3" className={styles.slide}>
                            <PhoneAppBlock />
                        </SwiperSlide>
                    </Swiper>
                    <div className={styles.arrowPrev}>
                        <ArrowLeftIcon />
                    </div>
                    <div className={styles.arrowNext}>
                        <ArrowRightIcon />
                    </div>
                </div>
            </div>
        </div>
    );
});

WelcomeNewPromoComponent.displayName = 'WelcomeNewPromoComponent';

export const WelcomeNewPromo = memo((): ReactElement => {
    const userTotalSpace = useSelector(UserQuotaSelectors.getTotal);

    const { trialData, handleSubscriptionsClick, showSubscriptions } = useWelcomePromoLogic({ forNewUser: true });

    return (
        <WelcomeNewPromoComponent
            showSubscriptions={showSubscriptions}
            onSubscriptionsClick={handleSubscriptionsClick}
            space={userTotalSpace?.value}
            trialData={trialData}
        />
    );
});

WelcomeNewPromo.displayName = 'WelcomeNewPromo';
