import { xray } from 'lib/xray';
import { IOS_BUILD_VERSION, IS_IOS_MOBILE, IS_MAIL_APP_IOS, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';

export const sendWebviewRadar = (eventCategory: string, t: string, isMobilePayAvailable: boolean): void => {
    // шлем радары только из webview ios
    if ((!IS_WEBVIEW && !IS_MAIL_APP_IOS) || !IS_IOS_MOBILE) {
        return;
    }

    const appbuild = IOS_BUILD_VERSION;

    const safeParameter = !isMobilePayAvailable ? '_safe' : '_unsafe';
    const deviceParameter = '_ios';
    const appbuildParameter = appbuild ? `_${appbuild}` : '';

    xray.send(`${eventCategory}_${t}${deviceParameter}${safeParameter}${appbuildParameter}`, {
        dwh: {
            appbuild,
        },
    });
};
