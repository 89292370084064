import { PayloadAction } from '@reduxjs/toolkit';
import { editor } from 'Cloud/Application/Editor';
import config from 'Cloud/config';
import { ALL_DOCUMENTS_FOLDER_ID, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureABCreateFolderNewFlow } from 'reactApp/modules/features/features.selectors';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { createFolderAction, inlineEditStart } from 'reactApp/modules/modifying/modifying.actions';
import { createError } from 'reactApp/modules/modifying/modifying.constants';
import { ICreateNewItem } from 'reactApp/modules/modifying/modifying.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isDomainFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { scrollToItemAction } from 'reactApp/modules/storage/storage.module';
import { getCurrentFolder as getCurrentFolderAction, hasParentsDomainFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { renderSelectFolderDialog } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.toolkit';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { channel } from 'redux-saga';
import { select, take } from 'redux-saga/effects';
import { put } from 'typed-redux-saga';

import { createFolderRequest } from './createFolder.saga';

const MAX_PATH_SIZE = 1024 - config.get('ITEM_NAME_MAX_LENGTH');

function assertMaxFoldersPath(folderPath: string, maxPathSize = MAX_PATH_SIZE) {
    return new TextEncoder().encode(folderPath).length > maxPathSize;
}

export function* handleCreateNewItem(action: PayloadAction<ICreateNewItem>) {
    const { type, from, showShareOnlyButton, gaPrefix = '', inHomeRoot = false } = action.payload;
    let showShareButton = action.payload.showShareButton;

    const isFolder = type === 'folder';

    const currentStorage = yield select(getCurrentStorage);
    const { isSearch, isSharedLinks, isTrashBin, isHome, isStart, isAllDocuments } = getStorage(currentStorage);
    const folder = yield select(getCurrentFolderAction);
    const hasParentsDmnFolder = yield select(hasParentsDomainFolder, folder);

    const { isFull } = yield select(UserSelectors.getCloudSpace);

    const ignoreUserFullSpace = isDomainFolder(folder) || hasParentsDmnFolder;

    // tempexp-14737-start
    const isPhone = yield select(EnvironmentSelectors.isPhone);
    const isNewFlowEnabled = yield select(getFeatureABCreateFolderNewFlow);
    // tempexp-14737-end

    const isNewbie = yield select(UserSelectors.isNewbie);
    if (isNewbie) {
        return;
    }

    if (isFull && !ignoreUserFullSpace) {
        openDisabledFeaturePopupHelper({ disabledFeature: isFolder ? DisabledFeature.newFolder : DisabledFeature.newFile });
        return;
    }

    let currentFolder = ROOT_FOLDER_ID;

    if (isHome) {
        currentFolder = folder?.id && folder?.id !== ROOT_FOLDER_ID ? folder?.id : ROOT_FOLDER_ID;
    } else if (inHomeRoot || isStart) {
        currentFolder = ROOT_FOLDER_ID;
    } else if (isAllDocuments) {
        currentFolder = `/${ALL_DOCUMENTS_FOLDER_ID}`;

        const documentsFolder: ReturnType<typeof getHomeItemById> = yield select(getHomeItemById, currentFolder);

        if (!documentsFolder) {
            yield createFolderRequest({
                folderName: ALL_DOCUMENTS_FOLDER_ID,
                share: false,
                parent: ROOT_FOLDER_ID,
                storage: EStorageType.home,
                gaPrefix: 'docs',
            });
        }
    } else {
        showShareButton = false;
        const selectChannel = channel();
        renderSelectFolderDialog({
            title: 'Где создать?',
            onSuccess: (answer) => {
                if (answer.action === 'create') {
                    currentFolder = answer.destination;
                    selectChannel.put(true);
                    return;
                }
                selectChannel.close();
            },
        });

        yield take(selectChannel);
        selectChannel.close();
    }

    const storage = isSearch || isSharedLinks || isTrashBin || isStart || isHome ? EStorageType.home : undefined;

    if (isFolder) {
        // tempexp-14737-next-line
        const createChannel = channel();

        sendPaymentGa({
            eventCategory: ECategoryGa.created,
            action: 'created-folder',
            type_folder: showShareOnlyButton ? 'common-folder' : 'folder',
            source: from,
        });

        yield put(
            createFolderAction({
                parentFolderId: currentFolder,
                storage,
                showCreateButton: !showShareOnlyButton,
                showShareButton,
                gaPrefix,
                // tempexp-14737-start
                onCreateFolder: createChannel.put,
            })
        );

        // tempexp-14737-start
        if (!isPhone && isNewFlowEnabled) {
            const folderName = yield take(createChannel);
            yield put(scrollToItemAction(folderName));

            yield put(
                showSnackbarAction({
                    closable: true,
                    id: 'create-folder-success',
                    text: 'Папка создана',
                    type: SnackbarTypes.success,
                })
            );

            yield put(inlineEditStart(folderName));
        }

        createChannel.close();
        // tempexp-14737-end
        return;
    }

    if (assertMaxFoldersPath(currentFolder, MAX_PATH_SIZE)) {
        yield put(
            showSnackbarAction({
                id: 'create-error-path-too-long',
                type: SnackbarTypes.failure,
                text: createError.getMessage('path_too_long'),
                closable: true,
            })
        );
        return;
    }

    editor.createNew(type, null, currentFolder, from);

    sendPaymentGa({
        eventCategory: ECategoryGa.created,
        action: 'created-files',
        type_file: type,
        source: from,
    });
}
