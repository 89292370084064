import { Spacing } from '@vkontakte/vkui';
import { xray } from 'lib/xray';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_DEV } from 'reactApp/appHelpers/configHelpers';
import { isHidePayMethodsMenu, tariffBuyCustomDescription, tariffBuyCustomTitle } from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { VkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { initProducts } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import Content from 'reactApp/ui/Content/Content';
import { TariffBuyConnected } from 'reactApp/ui/TariffBuy/TariffBuy';
import { isFramed } from 'reactApp/utils/isFramed';
import opener from 'reactApp/utils/opener';
import { getPeriodName } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';
import { sendPostMessage } from 'reactApp/utils/windowHelper';

import styles from './Buy.css';
import { useTariff } from './useTariff';

interface BuyIframeQueryParams {
    productId?: string;
    viewMode?: 'mobile' | 'desktop';
    title?: string;
    description?: string;
    hideSidebar?: 'true';
    hideOtherTariffs?: 'true';
}

const {
    productId: queryProductId,
    viewMode,
    title: titleFromQuery,
    description: descriptionFromQuery,
    hideSidebar: hideSidebarFromQuery,
    hideOtherTariffs: hideOtherTariffsFromQuery,
}: BuyIframeQueryParams = getQueryParams();

enum EPostMessageType {
    size = 'SIZE',
    close = 'CLOSE',
    success = 'SUCCESS',
}

const sendFrameMessage = ({ type, ...data }) => {
    sendPostMessage({ key: 'cloud-fast-checkout', type, ...data });
};

// eslint-disable-next-line max-lines-per-function, sonarjs/cognitive-complexity
export const BuyIframe = memo((): ReactElement | null => {
    const dispatch = useDispatch();
    const titleRef = useRef<HTMLDivElement>(null);

    const { product, tariff } = useTariff(queryProductId);

    const isPhone: boolean = (() => {
        switch (viewMode) {
            case 'mobile':
                return true;
            case 'desktop':
                return false;
            default:
                return EnvironmentSelectors.isPhone();
        }
    })();

    const hideSidebar = hideSidebarFromQuery === 'true';
    const hideOtherTariffs = hideOtherTariffsFromQuery === 'true';

    const { isLoading, isLoaded, hasError } = useSelector(ProductsSelectors.getLifeCycleState);

    const onDmrResizeEvent = useCallback((height) => {
        // Высота заголовка и spacing'ов вокруг него
        const titleHeight = titleRef.current ? titleRef.current.offsetHeight + 18 + 6 : 0;
        sendFrameMessage({ type: EPostMessageType.size, height: height + titleHeight });
    }, []);

    const onClose = useCallback(() => {
        sendFrameMessage({ type: EPostMessageType.close });
    }, []);

    const onSuccess = useCallback(() => {
        sendFrameMessage({ type: EPostMessageType.success });
    }, []);

    const title = useMemo(() => {
        if (titleFromQuery && tariffBuyCustomTitle?.hasOwnProperty(titleFromQuery)) {
            return tariffBuyCustomTitle[titleFromQuery];
        }

        if (isPhone && product) {
            const { discountPrice, discountPeriod, period, space, price, hasTrial, trialPeriod } = product || {};

            const priceText = hasTrial ? 'бесплатно' : ` за ${formatPrice(discountPrice || price)} ₽`;

            return `Отключение рекламы\nи ${space.value} на ${getPeriodName(
                trialPeriod || discountPeriod || period,
                false,
                true
            )} ${priceText}`;
        }

        return 'Никакой рекламы и больше места в Почте и Облаке';
    }, [isPhone, product]);

    useEffect(() => {
        xray.send(`buy-frame_view_${isPhone ? 'touch' : 'web'}`);
        dispatch(initProducts());
    }, []);

    useEffect(() => {
        if (hasError || (isLoaded && !product)) {
            xray.send(`buy-frame_error_${isPhone ? 'touch' : 'web'}`);
        }
    }, [hasError, isLoaded, isPhone, product]);

    useEffect(() => {
        if (isPhone) {
            return;
        }

        const buyIframeMinWidth = isHidePayMethodsMenu || hideSidebar ? 840 : 1200;
        sendFrameMessage({ type: EPostMessageType.size, width: buyIframeMinWidth });
    }, [isPhone, hideSidebar]);

    if (!isFramed() && !IS_DEV) {
        xray.send(`buy-frame_error_not_frame`);
        opener('/', true);
    }

    const content = useMemo(() => {
        const isCustomDescription = descriptionFromQuery && tariffBuyCustomDescription?.hasOwnProperty(descriptionFromQuery);
        const customDescription = isCustomDescription ? tariffBuyCustomDescription[descriptionFromQuery] : undefined;

        if (!product) {
            return;
        }

        if (isPhone) {
            return (
                <TariffBuyConnected
                    id={product.id}
                    onClose={onClose}
                    onSuccess={onSuccess}
                    isMobile
                    closable={false}
                    source="buy-frame"
                    title={title}
                    paymentDescription={customDescription}
                    eventCategory="touchBuyFrame"
                />
            );
        }

        return (
            <>
                <Spacing size={18} />
                <div className={styles.title} ref={titleRef}>
                    {title}
                </div>
                <Spacing size={6} />

                <VkBuyModalWithSidebar
                    productId={product?.id}
                    tariff={tariff}
                    isFrame
                    onClose={onClose}
                    onSuccess={onSuccess}
                    type={EPaymentModalType.fastcheckout}
                    onDmrResize={onDmrResizeEvent}
                    withoutSidebar={hideSidebar}
                    paymentDescription={customDescription}
                    hideOtherTariffs={hideOtherTariffs}
                />
            </>
        );
    }, [isPhone, onClose, onDmrResizeEvent, onSuccess, product, tariff, title, hideSidebar]);

    return (
        <div className={styles.root}>
            <Content wrapClass={styles.content} isLoading={isLoading} hasError={hasError || (isLoaded && !product)}>
                {content}
            </Content>
        </div>
    );
});

BuyIframe.displayName = 'BuyIframe';
