import { ReactComponent as VKIDIcon } from 'img/icons/vk-id.svg';
import { xray } from 'lib/xray';
import React from 'react';
import { autologinTimeout } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getAccounts } from 'reactApp/modules/ph/ph.helpers';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import opener from 'reactApp/utils/opener';
import { getTrimmedText } from 'reactApp/utils/textHelpers';
import { delay } from 'redux-saga/effects';
import { call, put, select } from 'typed-redux-saga';

const AUTOLOGIN_PROMO_ID = 'autologinPromo';

const getSafeURL = (): URL | undefined => {
    try {
        return new URL(window.location.href);
    } catch {
        return undefined;
    }
};

const sendXray = (chain: string) => {
    const currentUserEmail = window.__PH?.activeUser();

    xray.send(chain, { dwh: { current_email: currentUserEmail } });
};

export function* initAutologinPromos() {
    const currentUserEmail = window.__PH?.activeUser();
    if (!currentUserEmail) {
        return;
    }

    const url = getSafeURL();
    if (!url) {
        return;
    }

    const autologin = url.searchParams.get('autologin');
    url.searchParams.delete('autologin');
    window.history.replaceState({ path: url.toString() }, '', url);

    if (!autologin || autologin === 'no') {
        return;
    }

    yield* put(
        addPromoToShowQueue({
            type: EPromoType.autologin,
            promoId: AUTOLOGIN_PROMO_ID,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.autologin));
                storeHelper.markAsShown(AUTOLOGIN_PROMO_ID);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.autologin));
                storeHelper.markAsShown(AUTOLOGIN_PROMO_ID);
            },
        })
    );
}

export function* showAutologinPromos() {
    const autologinPromo = yield* select(PromoSelectors.getPromo(EPromoType.autologin));
    const isPhone = yield* select(EnvironmentSelectors.isPhone);

    const userDomain = yield* select(UserSelectors.getDomain);
    const userLogin = yield* select(UserSelectors.getLogin);

    const url = getSafeURL();
    if (!url) {
        return;
    }

    const accounts = yield* call(getAccounts);

    const currentUserAccount = accounts.find(({ active }) => active);
    if (!currentUserAccount) {
        return;
    }

    const { email, firstName, lastName } = currentUserAccount;
    const fullName = [firstName, lastName].join(' ');

    let trimmedEmail = email;
    const maxEmailLength = 34;
    if (trimmedEmail.length > maxEmailLength) {
        const maxLoginLength = maxEmailLength - userDomain.length - 2;
        trimmedEmail = [getTrimmedText(userLogin, maxLoginLength), userDomain].join('@');
    }

    const enterText = firstName ? `${firstName}, вы вошли ` : 'Вы вошли ';

    const showAutologinSnackbar = () => {
        return showSnackbarAction({
            id: 'autologin-message',
            closable: true,
            hideCloseIcon: true,
            icon: <Avatar size={32} email={email} name={fullName} />,
            iconSize: 'flex',
            title: (
                <>
                    <span>{enterText}</span>
                    <span style={{ whiteSpace: 'nowrap' }}>
                        <span style={{ marginRight: '4px' }}>с помощью</span>
                        <span style={{ verticalAlign: 'middle' }}>
                            <VKIDIcon width={33} height={16} />
                        </span>
                    </span>
                </>
            ),
            wrapTitle: true,
            text: trimmedEmail,
            buttonText: 'Сменить',
            buttonRight: true,
            onButtonClick: () => {
                sendXray('autologin_snack_change_account');
                store.dispatch(authPopup({ closable: true, loginRequest: true, successPage: url.toString() }));
            },
            closeTimeout: autologinTimeout,
            onShow: () => {
                autologinPromo?.onShow();
                sendXray('autologin_snack');
            },
        });
    };

    const showUserAgreementSnackbar = () => {
        return showSnackbarAction({
            id: 'autologin-user-agreement',
            closable: true,
            hideCloseIcon: true,
            title: 'Вы можете ознакомиться',
            buttonText: 'с лицензионным соглашением',
            onButtonClick: () => {
                sendXray('autologin_snack_terms');
                opener('https://help.mail.ru/legal/terms/cloud/la?ysclid=lw4vu7a5hc108891796');
            },
            closeTimeout: autologinTimeout,
            onClose: autologinPromo?.onClose,
        });
    };

    yield* put(showAutologinSnackbar());

    if (isPhone) {
        yield delay(autologinTimeout + 500);
    }

    yield* put(showUserAgreementSnackbar());
}
