import { ServerError } from 'reactApp/errors';

export type R7Mode = 'edit' | 'view';

export type R7ErrorHandler = ({ data }: { data: unknown }) => void;

export type R7Options = {
    mode?: R7Mode;
    onDocumentReady: () => void;
    onError: R7ErrorHandler;
};

export const enum R7Theme {
    light = '1',
    dark = '2',
}

interface ClientEditorError extends ServerError {
    response: {
        fields: [string, string];
    };
}

export const isClientEditorError = (error: unknown): error is ClientEditorError =>
    error instanceof ServerError && error.status >= 400 && error.status < 500 && error?.response?.fields?.length > 1;
