import store from 'lib/store';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLastFilesForWidget } from 'reactApp/modules/feed/feed.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { HeaderWidget } from 'reactApp/ui/HeaderWidget/HeaderWidget';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';

import styles from './LastFilesWidget.css';

export const LAST_FILES_WIDGET_LOCAL_STORAGE_KEY = 'lastfilescollapsed';

export interface LastFilesWidgetProps {
    onExpand?: (expanded: boolean) => void;
}

export const LastFilesWidget = memo(function LastFilesWidget({ onExpand = noop }: LastFilesWidgetProps) {
    const [isClosed, setClosed] = useState(store.get(LAST_FILES_WIDGET_LOCAL_STORAGE_KEY));

    const lastFiles = useSelector(getLastFilesForWidget);

    const handleFeedSeeAll = useCallback(() => {
        opener('/feed', true);
    }, []);

    const handleExpand = useCallback(
        (expanded: boolean) => {
            setClosed(!expanded);
            onExpand(expanded);
        },
        [setClosed, onExpand]
    );

    const header = useMemo(() => {
        return (
            <div className={styles.header}>
                {!isClosed && (
                    <div className={styles.leftControls}>
                        <div className={styles.feedAll} onClick={handleFeedSeeAll}>
                            Посмотреть все
                        </div>
                    </div>
                )}
            </div>
        );
    }, [isClosed, handleFeedSeeAll]);

    const body = useMemo(() => {
        return (
            <div className={styles.lastFiles}>
                <DataList
                    goTo={noop}
                    storage={EStorageType.feed}
                    viewMode={EViewMode.thumbs}
                    gaSuffix="wjfeed"
                    disableCenteredLoader
                    limitItemsNumber={10}
                    disableLoadOnScroll
                    disableTips
                    itemsList={lastFiles?.map((item) => item.id)}
                    storiesWidget
                />
            </div>
        );
    }, [lastFiles]);

    return (
        <HeaderWidget
            title="Последние файлы"
            header={header}
            body={body}
            expandLocalstoreKey={LAST_FILES_WIDGET_LOCAL_STORAGE_KEY}
            onExpand={handleExpand}
        />
    );
});
