import { document } from '@perf-tools/keeper';
import workdiskLogo from 'img/workdisk_logo.png';
import { R7ConvertAPICall } from 'reactApp/api/R7ConvertAPICall';
import { R7EditApiAPICall } from 'reactApp/api/R7EditAPICall';
import { R7ViewApiAPICall } from 'reactApp/api/R7ViewAPICall';
import { IS_PUBLIC, IS_STOCK } from 'reactApp/appHelpers/configHelpers';
import { readyEditor } from 'reactApp/modules/editor/editor.module';
import { store as reduxStore } from 'reactApp/store';

import { R7ErrorHandler, R7Mode, R7Options } from './types';

const getData = (itemPath: string) => {
    if (IS_STOCK) {
        return { stock: itemPath };
    }

    if (IS_PUBLIC) {
        return { public: itemPath };
    }

    return { home: itemPath };
};

const callR7ApiViewAPICall = (itemPath: string) => new R7ViewApiAPICall().makeRequest(getData(itemPath));

const callR7ApiEditAPICall = (itemPath: string) => new R7EditApiAPICall().makeRequest(getData(itemPath));

export const callApiR7Convert = (itemPath: string) => new R7ConvertAPICall().makeRequest(getData(itemPath));

const apiR7 = (url: string, mode: R7Mode) => (mode === 'edit' ? callR7ApiEditAPICall(url) : callR7ApiViewAPICall(url));

const initR7Doc = async (url: string, { mode = 'view', onDocumentReady, onError }: R7Options) => {
    try {
        const { data } = await apiR7(url, mode);
        const handleOnDocumentReady = () => {
            onDocumentReady();
            reduxStore.dispatch(readyEditor());
        };

        const parentFolder = reduxStore.getState().editor.item?.parent;
        const backLink = `${window.location.origin}${parentFolder ? `/home${parentFolder}` : ''}`;
        return {
            ...data,
            editorConfig: {
                ...data.editorConfig,
                customization: {
                    ...data.editorConfig.customization,
                    macros: true,
                    macrosMode: 'warn',
                    loaderName: 'WorkDisk',
                    logo: {
                        image: workdiskLogo,
                        url: backLink,
                    },
                },
            },
            type: 'desktop',
            events: {
                onDocumentReady: handleOnDocumentReady,
                onError,
            },
        };
    } catch (error) {
        onError({ data: error });
    }
};

let r7EditorApi: null | ReturnType<DocsAPI['DocEditor']> = null;

const loadR7Doc = <T>(
    placeholderId: string,
    {
        data,
        onError,
    }: {
        data: T;
        onError: R7ErrorHandler;
    }
) => {
    try {
        if (r7EditorApi) {
            r7EditorApi.destroyEditor();
        }

        // eslint-disable-next-line new-cap
        r7EditorApi = window.DocsAPI.DocEditor(placeholderId, data);
    } catch (error) {
        onError({ data: error });
    }
};

/**
 * Удаляет параметры из пути
 * @example /Новый%20документ.docx?weblink=W7PE/Ew7agqNHD -> /Новый%20документ.docx
 */
const getPathFromUrl = (url: string): string => {
    return url.replace(/(\?weblink=(\w+)\/(\w+))$/, '');
};

export const initAndLoadR7 = async (
    url: string,
    {
        mode,
        placeholderId,
        container,
        onDocumentReady,
        onError,
    }: {
        placeholderId: string;
        container: HTMLElement;
    } & R7Options
) => {
    try {
        const data = await initR7Doc(getPathFromUrl(url), { mode, onDocumentReady, onError });
        const loadR7Props = {
            data,
            onError,
        };

        if (typeof window.DocsAPI === 'undefined') {
            const scriptObj = document.createElement('script');
            scriptObj.type = 'text/javascript';
            scriptObj.src = `${(data && data.api) || '/r7'}/web-apps/apps/api/documents/api.js`;
            container.appendChild(scriptObj);
            scriptObj.addEventListener?.('load', () => {
                loadR7Doc(placeholderId, loadR7Props);
            });
        } else {
            loadR7Doc(placeholderId, loadR7Props);
        }
    } catch (error) {
        onError({ data: error });
    }
};
