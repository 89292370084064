import { filter, find, path, pathEq, sortBy, values } from 'ramda';
import { createSelector } from 'reselect';

// tempexp_16481-next-line
const AUTOUPLOAD_10TB_GIFT_ID = 'G10T_single_quota_2023';

// tempexp_16481-next-line
const notDiscount = (gift) => !gift.discount && gift.id !== AUTOUPLOAD_10TB_GIFT_ID;
const isDiscount = (gift) => gift.discount;

const getGiftsState = (state) => state.receivedGifts;

const getGifts = (state) => state.receivedGifts.list;
export const getRawGiftsList = (state) => state.receivedGifts.rawList;

export const filterNonDiscountGifts = createSelector(getGifts, (list) => list && filter(notDiscount, list));

export const filterDiscountGifts = createSelector(getGifts, (list) => list && filter(isDiscount, list));

export const sortReceivedGiftsByStart = createSelector(filterNonDiscountGifts, (list) => sortBy(path(['start']), values(list)));

export const getDiscountGiftList = createSelector(filterDiscountGifts, values);

export const getDiscountGiftByProductId = createSelector(
    getDiscountGiftList,
    (state, productId) => productId,
    (discountGifts, productId) => find(pathEq(['discount', 'web'], productId), values(discountGifts))
);

export const getReceivedGiftsLoadingState = createSelector(
    getGiftsState,
    (state) => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

// tempexp_16481-start
export const hasAutoupload10TbGift = createSelector(
    getRawGiftsList,
    (list) => {
        return Boolean(Object.values(list).find(item => item.gift_id === AUTOUPLOAD_10TB_GIFT_ID));
    }
);
// tempexp_16481-end