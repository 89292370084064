import { logger } from 'lib/logger';
import { saveMainPageSettings } from 'reactApp/api/axios.corsapi';
import { changeTheme } from 'reactApp/modules/theme/theme.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { select, takeLatest } from 'redux-saga/effects';

function* handleChangeTheme(action: ReturnType<typeof changeTheme>) {
    const theme = action.payload;

    const isAnonymous = yield select(UserSelectors.isAnonymous);
    const email = yield select(UserSelectors.getEmail);

    if (!isAnonymous && email) {
        try {
            yield saveMainPageSettings({ email, settings: { theme, version: 'v1' } });
        } catch (error) {
            logger.error('Ошибка переключения темы: ', error);
        }
    }
    window.location.reload();
}

export function* watchTheme() {
    yield takeLatest(changeTheme.toString(), handleChangeTheme);
}
