import { Button, ButtonGroup, Spacing, Text, Title } from '@vkontakte/vkui';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { abRequiredSignUpWhenDownloadingTouchSelector } from 'reactApp/appHelpers/featuresHelpers';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { isFolder as checkIsFolder } from 'reactApp/modules/file/utils';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { REQUIRED_SIGNUP_POPUP_SHOWED_C } from 'reactApp/modules/requiredAuthorization/constants';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { reDownloadController } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { ERequiredSignUpTouchAnalytics, requiredSignUpTouchAnalytics } from 'reactApp/utils/requiredSignUpTouchGa';

import styles from './RequiredSignUpDialog.css';

const link = chooseVariant(() => abRequiredSignUpWhenDownloadingTouchSelector(false), {
    control: 'https://trk.mail.ru/c/kj3vg2',
    variant1: 'https://trk.mail.ru/c/kuj3p7',
    variant2: 'https://trk.mail.ru/c/xk6g56',
});

export const RequiredSignUpDialog: FC<IPropsWithPopup> = ({ onClose }) => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const routeId = useSelector(getCurrentRouteId);
    const abVariant = useMemo(() => abRequiredSignUpWhenDownloadingTouchSelector(), []);
    const item = useSelector((state: RootState) => getItemById(state, routeId));
    const isFolder = checkIsFolder(item);

    useEffect(() => {
        requiredSignUpTouchAnalytics(ERequiredSignUpTouchAnalytics.SHOW_POPUP, { type_public: isFolder ? 'folder' : 'file' });

        storeHelper.setValue(REQUIRED_SIGNUP_POPUP_SHOWED_C, true);
    }, [isFolder]);

    const handleDownloadApp = useCallback(() => {
        requiredSignUpTouchAnalytics(ERequiredSignUpTouchAnalytics.DOWNLOAD_APP_CLICK, { type_public: isFolder ? 'folder' : 'file' });
    }, [isFolder]);

    const handleClose = useCallback(() => {
        if (abVariant === 'variant2') {
            requiredAuthorizationHelpers.setShowDownloadTip();
        }
        onClose();
    }, [onClose, abVariant]);

    const handleSignUp = useCallback(() => {
        const successPage = requiredAuthorizationHelpers.getSuccessPage();

        dispatch(authPopup({ successPage, requiredAuthWhenDownload: '1' }));
        reDownloadController.resetDownloadHappened();

        requiredSignUpTouchAnalytics(ERequiredSignUpTouchAnalytics.SIGNUP_CLICK, { type_public: isFolder ? 'folder' : 'file' });
    }, [dispatch, isFolder]);

    const title = useMemo(
        () =>
            chooseVariant(abRequiredSignUpWhenDownloadingTouchSelector, {
                control: (
                    <>
                        Установите Облако
                        <br />
                        или войдите в аккаунт
                    </>
                ),
                variant1: (
                    <>
                        Установите Облако или
                        <br />
                        войдите, чтобы скачать {isFolder ? 'папку' : 'файл'}
                    </>
                ),
                variant2: (
                    <>
                        Установите Облако
                        <br />
                        или войдите в аккаунт
                    </>
                ),
            }),
        [isFolder]
    );

    const subtitle = useMemo(
        () =>
            chooseVariant(abRequiredSignUpWhenDownloadingTouchSelector, {
                control: (
                    <>
                        В Облаке можно удобно
                        <br />
                        просматривать и хранить файлы
                    </>
                ),
                variant1: (
                    <>
                        В Облаке можно удобно
                        <br />
                        просматривать и хранить файлы
                    </>
                ),
                variant2: (
                    <>
                        В Облаке удобно скачивать,
                        <br />
                        просматривать и хранить файлы
                    </>
                ),
            }),
        []
    );

    return (
        <MobileDialog
            id="required-sign-up-dialog"
            onClose={handleClose}
            mod="mobileV4"
            closeMode="round"
            topmost
            closeOnDimmerClick
            scrollRef={ref}
        >
            <div className={styles.root} ref={ref}>
                <Title className={styles.title}>{title}</Title>
                <Spacing size={8} />
                <Text className={styles.subtitle}>{subtitle}</Text>
                <Spacing size={24} />
                <ButtonGroup mode="vertical">
                    <Button
                        Component="a"
                        href={`${link}?mt_deeplink=${window.location.href}`}
                        onClick={handleDownloadApp}
                        className={styles.btn}
                        size="l"
                        stretched
                        appearance="accent"
                        before={<span className={styles.icon} />}
                        activeMode={styles.active}
                        hasActive={false}
                    >
                        <Text className={styles.btnText}>Установить приложение</Text>
                    </Button>
                    <Button className={styles.btn} size="l" stretched appearance="neutral" onClick={handleSignUp}>
                        <Text className={styles.btnText}>Войти в аккаунт</Text>
                    </Button>
                </ButtonGroup>
            </div>
        </MobileDialog>
    );
};
