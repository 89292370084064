import config from 'Cloud/config';

const opener = function (url, currentPage = false) {
    let win;
    const target = currentPage ? '_self' : '_blank';

    try {
        if (config.get('IS_DESKTOP_CHROME')) {
            win = window.open(url, target, 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes, noopener=true');
        } else {
            win = window.open(url, target);
            if (win) {
                win.focus();
            }
        }

        if (win) {
            win.opener = null;
        }
    } catch (error) {
        console.error(error);
    }
};

export const openerDelayed = (url, delayTimeMs = 200, currentPage = false) => {
    setTimeout(() => opener(url, currentPage), delayTimeMs);
};

export default opener;
