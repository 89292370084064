import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { MediaSelectors } from 'reactApp/modules/media/media.selectors';
import { State } from 'reactApp/modules/quotaLanding/quotaLanding.types';
import { RootState } from 'reactApp/store';

const getState = (state: RootState): State => state.quotaLanding as State;

const isMobile = (state: RootState): boolean => {
    if (MediaSelectors.getMediaState(state)) {
        return Boolean(MediaSelectors.isMobile(state));
    }

    return EnvironmentSelectors.isMobile();
};

export const QuotaLandingSelectors = {
    getState,
    isMobile,
};
