import { parse } from 'qs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENABLE_FULL_RESPONSIVE, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { attachesChangePath } from 'reactApp/modules/attaches/attaches.actions';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { historyPush, routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { changeSearchHistory } from 'reactApp/modules/search/search.actions';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { resetQuerySearchParams, setQueryParams, sidebarToggle } from 'reactApp/modules/settings/settings.module';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useAlbumId } from 'reactApp/sections/AlbumsPage/hooks/useAlbumId';
import { scrollToTop } from 'reactApp/utils/helpers';

const routesNeedId = [EStorageType.alldocuments, EStorageType.public, EStorageType.stock];

// TODO: routing: move here all router logic from Router.js and History.js
// TODO: Reduce number of different 'routing' action types to short and clear list.
export const useRouteChangeProcessing = ({
    storage,
    path = '',
    search = '',
}: {
    storage?: EStorageType | null;
    path?: string;
    search?: string;
}) => {
    const dispatch = useDispatch();
    const currentStorage = useSelector(getCurrentStorage);
    const { albumId } = useAlbumId();

    useEffect(() => {
        dispatch(resetSelect());

        if (ENABLE_FULL_RESPONSIVE) {
            dispatch(sidebarToggle(false));
        }
    }, [path, dispatch]);

    useEffect(() => {
        if (storage === EStorageType.pdfEdit) {
            return;
        }

        if (storage !== currentStorage && !routesNeedId.includes(storage as EStorageType)) {
            scrollToTop();
            dispatch(
                routeChangeSuccess({
                    id: ROOT_FOLDER_ID,
                    storage: storage || null,
                    params: {},
                    __isFolder: true,
                    url: '',
                    __parent: '',
                })
            );
            dispatch(historyPush({ id: '' }));
        }

        if (routesNeedId.includes(storage as EStorageType)) {
            if (currentStorage !== storage) {
                scrollToTop();
            }
            dispatch(
                routeChangeSuccess({
                    id: decodeURIComponent(path),
                    storage: storage || null,
                    params: {},
                    __isFolder: true,
                    url: '',
                    __parent: '',
                })
            );
            dispatch(historyPush({ id: storage === EStorageType.alldocuments ? '' : path, search }));
        }

        // Чтобы не раскидывать обработку роутинга, а делать все в одном месте
        if (storage === EStorageType.home || isIntegrationStorage(storage)) {
            dispatch(changeHomeHistory({ id: path, search }));
        }

        // Эффект выше бросается после корректной смены параметров в альбоме
        // В результате перетирает значения. Поэтому такой хак 😢
        if (storage !== currentStorage && storage === EStorageType.albums) {
            dispatch(
                routeChangeSuccess({
                    id: albumId || '/',
                    storage: EStorageType.albums,
                    params: {},
                    __isFolder: true,
                    url: '',
                    __parent: '',
                })
            );
        }

        if (IS_PHONE_BROWSER && storage === EStorageType.search) {
            dispatch(changeSearchHistory({ id: path, search }));
        }
        if (path && (storage === EStorageType.attaches || storage === EStorageType.viewerAttaches)) {
            dispatch(attachesChangePath({ path }));
        }
    }, [storage, currentStorage, path, albumId]);

    useEffect(() => {
        if (!search) {
            // TODO: routing: все сбрасывать нельзя, так еще есть необработанные параметры для подсветки элемента в даталисте после перехода
            dispatch(resetQuerySearchParams());
            return;
        }
        dispatch(setQueryParams(parse(search, { ignoreQueryPrefix: true }))); // TODO: routing ??? for attaches, process in another place!
    }, [search]);
};
