import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';
import browser from 'Cloud/browser';
import { ALLOW_ANONYM_DOWNLOAD_PUBLIC, MAIL_HOST } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import type {
    FeatureEditingNonEditableFormatsData,
    FeatureEditingNoneditableFormatsInit,
    FeatureMarketingPromoButton,
    FeatureSaveSubscription,
    FeatureSaveSubscriptionTransit,
    TemplateVariantString,
} from 'reactApp/modules/features/features.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { AstraMetaProcessorIDsMapType } from 'reactApp/utils/astraPluginHelpers';
import { createSelector } from 'reselect';

import { ALL_DOCUMENTS_AVAILABLE_EXTENSIONS } from '../allDocuments/allDocuments.constants';

const getFeaturesStore = (state: RootState) => state.features;

export const getFeatureByName = createSelector(
    getFeaturesStore,
    (_: RootState, name: string) => name,
    (_, name) => {
        return isFeature(name)
            ? {
                  name,
                  data: getFeature(name),
              }
            : null;
    }
);

export const getFeatureAsInt = createSelector(
    (state, name) => getFeatureByName(state, name),
    (feature) => {
        if (feature?.data) {
            // eslint-disable-next-line radix
            return Number.parseInt(feature.data, 10);
        }

        return null;
    }
);

export const getAttachesCacheTtlMs = createSelector(
    (state) => getFeatureByName(state as RootState, 'attaches-cache-ttl-ms'),
    (feature) => {
        if (feature && feature.data) {
            return feature.data;
        }

        return null;
    }
);

// Альбомы
export const getFeatureAlbumsPage = (state) => getFeatureByName(state, 'albums-page');
export const getFeatureCreatePublicAlbum = (state) => getFeatureByName(state, 'public-album');
// Временно альбомы открываются по урлу /album/ вместо /publiс/
export const getFeaturePublicAlbumTemporaryUrl = (state) => Boolean(getFeatureByName(state, 'public-album-temporary-url'));

export const getFeatureAdOnVideo = (state) => !!getFeatureByName(state, 'video-player-ad');
// tempexp_13600-next-line
export const getFeatureABSubNewCard = (state) => !!getFeatureByName(state, 'ab-subs-touch-newcart')?.data;
export const getFeaturePublicDomestic = (state) => getFeatureByName(state, 'public-domestic');
export const getFeaturePublicAutoDelete = (state) => getFeatureByName(state, 'public-autodelete');
export const getFeatureAbAutoDelete = (state) => getFeatureByName(state, 'ab-autodelete');
export const getFeaturePublicAutoDeletePromo = (state) => getFeatureByName(state, 'public-autodelete-promo');
export const getFeaturePublicDomesticByDefault = (state) => getFeatureByName(state, 'public-domestic-by-default');
export const getFeatureStartPage = (state) => getFeatureByName(state, 'start-page');
export const getFeatureStartPageRoot = (state) => getFeatureByName(state, 'start-page-root');
export const getFeatureDocuments = (state) => getFeatureByName(state, 'personal-documents');
export const getFeatureAbNewCardsData = (state) => getFeatureByName(state, 'ab-new-cards')?.data;
export const getFeatureIsNewTariffSectionEnabled = (state) => !!getFeatureByName(state, 'subscriptions-new-tariff-section');
export const getFeatureGalleryViewMode = (state) => getFeatureByName(state, 'gallery-view-mode');
export const getFeaturePublicGalleryViewMode = (state) => getFeatureByName(state, 'public-gallery-view-mode');
export const getFeatureAttachesEnabled = (state) => getFeatureByName(state, 'attaches-enabled');
export const getFeatureShowStories = (state) => Boolean(getFeatureByName(state, 'show-stories'));
export const getFeatureVkCommunityMessages = (state) => getFeatureByName(state, 'vk-community-messages')?.data;
export const getFeatureAbOverquota = (state) => getFeatureByName(state, 'ab-overquota')?.data;
export const getAbTrialPopupGroup = (state) => getFeatureByName(state, 'ab-trial-popup')?.data;
export const getAbOpenBrowserPayment = (state) => getFeatureByName(state, 'ab-open-browser-payment')?.data;
export const getFeatureMyOfficeViewerPromo = (
    state
):
    | {
          hideDays?: number;
          mouseLeaveDelay?: number;
          mouseEnterDelay?: number;
          cloudLink: string;
          attachLink: string;
          vkLink: string;
          adLink: string;
      }
    | undefined => getFeatureByName(state, 'myoffice-viewer-promo')?.data;
export const getFeatureMyOfficeEditorPromo = (
    state
):
    | {
          mouseLeaveDelay?: number;
          mouseEnterDelay?: number;
          link: string;
          adLink: string;
      }
    | undefined => getFeatureByName(state, 'myoffice-editor-promo')?.data;
export const getFeatureMyOfficeForMailAttaches = (state) => !!getFeatureByName(state, 'myoffice-viewer-mail-attaches');
/** Фича для включения рейтинга в промо моего офиса */
export const getFeatureMyOfficePromoRating = (state: RootState) => !!getFeatureByName(state, 'myoffice-promo-rating');
export const getFeatureMyOfficeForPublicAndStock = (state) => !!getFeatureByName(state, 'myoffice-viewer-public-and-stock');
/**
 * Фича для включения моего офиса для тача (временно исключаем сафари)
 *
 * {@link https://jira.vk.team/browse/CLOUDWEB-13905 Задача}
 */
export const getMyOfficeTouchFeature = (
    state: RootState
):
    | {
          editablePages: string[];
          viewablePages: string[];
      }
    | undefined => (!browser.isMobileSafari() ? getFeatureByName(state, 'myoffice-touch')?.data : undefined);

export const getFeatureWopiForSafari = (state) => (browser.isSafari() ? Boolean(getFeatureByName(state, 'wopi-safari')?.data) : true);
export const getFeatureWopiOld = (state) => getFeatureWopiForSafari(state) && !!getFeatureByName(state, 'wopi-old');
export const getFeatureWopiStock = (state) => Boolean(getFeatureByName(state, 'allow-wopi-stock'));
export const getFeatureWopi = (state) => {
    const storage = getCurrentStorage(state);
    const allowOnStock = storage !== EStorageType.stock || getFeatureWopiStock(state);

    return allowOnStock && getFeatureWopiForSafari(state) && Boolean(getFeatureByName(state, 'wopi'));
};
export const getFeatureWopiCoEdit = (state) => !!getFeatureWopi(state) && !!getFeatureByName(state, 'wopi-co-edit');
export const getFeatureCoEditAlertPopup = (state) => getFeatureWopiCoEdit(state) && !!getFeatureByName(state, 'wopi-co-edit-public-alert');
export const getFeatureCoEditSharePopup = (state) => getFeatureWopiCoEdit(state) && !!getFeatureByName(state, 'wopi-co-edit-share-popup');
export const getFeatureMyOfficeCesCoEditPublicDisabled = (state: RootState) =>
    getFeatureByName(state, 'myoffice-ces-co-edit-public-disabled');
export const getMyOfficeProgressOn = (state) => getFeatureByName(state, 'myoffice-progress-on')?.data;

export const getFeaturePdfEdit = (state) => Boolean(getFeatureByName(state, 'pdf-edit'));

// CLOUDWEB-16265: Редактор PDF. Работа со страницами
export const getFeaturePdfWorkWithPages = (state) => Boolean(getFeatureByName(state, 'pdf-work-with-pages'));
export const getFeatureCSATServey = (state) => getFeatureByName(state, 'csat-survey-cloud')?.data;
export const getAstraMetaFeature = (state: RootState): AstraMetaProcessorIDsMapType | undefined =>
    getFeatureByName(state, 'astra-meta')?.data;
export const getStickyButtonExtentionList = () => getFeature('sticky-button-extention-list') || [];

// tempexp_13536-next-line
export const getFeatureABDisableAdsSnackbar = (state) => {
    const storage = getCurrentStorage(state);
    const isPhone = EnvironmentSelectors.isPhone();

    if (storage !== EStorageType.public || isPhone) {
        return false;
    }

    return getFeatureByName(state, 'ab-disable-ads-snackbar')?.data;
};

// tempexp_13695-next-line
export const getFeatureVkFest = (state) => Boolean(getFeatureByName(state, 'vk-fest'));
export const getMyOfficeProgressConfig = (state) =>
    getFeatureByName(state, 'myoffice-progress-config')?.data || {
        progressCoeff: 1.2,
        startTime: 5,
        closeTimeout: 1000,
        link: 'https://myoffice.ru/home/?utm_source=mail_ru&utm_medium=partner&utm_campaign=amr',
    };
export const getFeatureFaces = (state) => Boolean(getFeatureByName(state, 'faces'));
export const getFeaturePublicDatalistAdv = (state) => getFeatureByName(state, 'public-datalist-adv');
export const getFeatureAbDownloadAppPublic = (state) => getFeatureByName(state, 'ab-download-app-public')?.data;
export const getFeatureDownloadAppModalDetails = (
    state
): {
    title: string;
    description: string;
    buttonText: string;
    imgUrl: string;
    buttonLink?: string;
    noMtParameter?;
    boolean;
} =>
    getFeatureByName(state, 'download-app-modal-details')?.data || {
        title: 'В приложении удобнее!',
        description: 'Скачайте приложение, чтобы смотреть файлы из Облака в удобном формате',
        buttonText: 'Установить приложение',
        noMtParameter: true,
    };

export const getFeatureMyOfficeForMailWopi = (state) => !!getFeatureByName(state, 'myoffice-viewer-mail-wopi');

// CLOUDWEB-13583: Единая квота. Ссылка на очистку ящика для Почты
export const getFeatureQuotaMailSpaceFreeLink = (state) =>
    getFeatureByName(state, 'quota-mail-space-free-link')?.data || `https://${MAIL_HOST}/inbox?action=open-cleanmailbox`;
// CLOUDWEB-13459 Таймаут для отключения айфрейма моего офиса (по умолчанию 3 часа - 10800 сек)
export const getFeatureIframeDisableTimeout = (state) => getFeatureAsInt(state, 'my-office-iframe-timeout') || 10800;
export const getFeatureNewAdFormat = (state) => Boolean(getFeatureByName(state, 'ad-new-worm'));
// FMAIL-16298: Единая квота. Включашка чистилки для десктопа
export const getFeatureQuotaCleanerWeb = (state) => Boolean(getFeatureByName(state, 'quota-cleaner-web'));
// CLOUDWEB-13634 Единая квота. Включашка чистилки для мобилок
export const getFeatureQuotaCleanerMob = (state) => Boolean(getFeatureByName(state, 'quota-cleaner-mob'));
// CCLOUDWEB-13770 Единая квота. Включашка облачных групп чистилки
export const getFeatureQuotaCleanerCloud = (state: RootState) => Boolean(getFeatureByName(state, 'quota-cleaner-cloud'));
// CLOUDWEB-13634 Единая квота. Включашка почтовых групп чистилки
export const getFeatureQuotaCleanerLetters = (state) => Boolean(getFeatureByName(state, 'quota-cleaner-letters'));
// CLOUDWEB-13634 Единая квота. Max offset для запроса /user/quota/group
export const getFeatureQuotaCleanerItemsMaxOffset = (state) => getFeatureAsInt(state, 'quota-cleaner-items-max-offset') || 800;
export const getFeatureQuotaTouchLink = (state) => getFeatureByName(state, 'quota-touch-link')?.data || 'https://touch.mail.ru';
export const getFeatureQuotaCleanerLimit = (state: RootState) => getFeatureAsInt(state, 'quota-cleaner-items-limit') || 200;
export const getFeatureShowEditButtonForAttach = (state) => Boolean(getFeatureByName(state, 'show-edit-button-attach'));
export const getFeatureShowRepeatingAttaches = (state: RootState): null | { days?: number } =>
    getFeatureByName(state, 'show-repeating-welcome-attaches')?.data;
export const getFeatureViewerEbookExtraTags = (state): string[] => getFeatureByName(state, 'viewer-ebook-extra-tags')?.data || [];

export const getFeatureWopiFallbackConfig = (state) =>
    getFeatureByName(state, 'wopi-fallback-config')?.data || {
        hostReg: '^https:\\/\\/(?:docs\\-mo|docs\\-myoffice)\\.datacloudmail\\.ru',
        enableFallback: false,
    };
// CLOUDWEB-13408: Фича, из которой берутся реглярки для айдишников аттачей разных типов для @mail/cross-url-sharing
export const getFeatureAttachesIdRegExps = (state) => getFeatureByName(state, 'viewer-attaches-id-reg-exps')?.data || {};
// CLOUDWEB-13408: Фича, из которой берутся реглярки для айдишников писем для @mail/cross-url-sharing
export const getFeatureMessagesIdRegExps = (state) => getFeatureByName(state, 'viewer-message-id-reg-exps')?.data || {};
// CLOUDWEB-13408: Фича, из которой берется реглярка для определения eml письма @mail/cross-url-sharing
export const getEmlIdRegExp = (state) => getFeatureByName(state, 'viewer-eml-id-reg-exps')?.data;
/**
 *  Фича, которая включает отправку детализированных радаров на открытие файлов в Моем Офисе
 *
 * {@link https://jira.vk.team/browse/CLOUDWEB-13944 Задача}
 */
export const getFeatureMyOfficeSendDetailRadars = (state) => getFeatureByName(state, 'myoffice-send-detail-radars')?.data;
// CLOUDWEB-13281: Новая сетка для галеры
export const getFeatureMobileGalleryGrid3 = (state) => getFeatureByName(state, 'touch-gallery-grid-3-items');
// CLOUDWEB-13951: Единая квота. Включашка промо покупки тарифа в попапе удаления
export const getFeatureQuotaCleanerDeletePromo = (state) => Boolean(getFeatureByName(state, 'quota-cleaner-delete-promo'));
// CLOUDWEB-13947: Единая квота. Включашки групп
export const getFeatureQuotaCleanerGroups = (state) => getFeatureByName(state, 'quota-cleaner-groups')?.data || [];
// CLOUDWEB-13947: Единая квота. Заголовки групп
export const getFeatureQuotaCleanerGroupTitles = (state) => getFeatureByName(state, 'quota-cleaner-group-titles')?.data || {};
// CLOUDWEB-13973: Единая квота. Max число писем для разрешения загрузки облачных групп
export const getFeatureQuotaCleanerMaxLettersCount = (state) => getFeatureAsInt(state, 'quota-cleaner-letters-max-count');
// CLOUDWEB-13984: Добавить этап винбека с капчей // tempexp_13984-next-line
export const getFeatureAbCancelSubsWithCaptcha = (state) => getFeatureByName(state, 'ab-cancel-subs-with-captcha')?.data;
/**
 *  Единая квота. Получение значения фичи подсказки групп
 *
 * {@link https://jira.vk.team/browse/CLOUDWEB-14166 Задача}
 */
export const getFeatureQuotaCleanerGroupHints = (state: RootState) => getFeatureByName(state, 'quota-cleaner-group-hints')?.data ?? {};
// CLOUDWEB-14117: Фича, которая включает показ кнопки редактирования для файлов в шаренных папках
export const getFeatureShowEditBtnForSharedFolder = (state) => Boolean(getFeatureByName(state, 'show-edit-btn-shared-folder'));
/* CLOUDWEB-14040 Единая квота. Включашка фильтра по годам в чистилке */
export const getFeatureQuotaCleanerYearFilter = (state: RootState) => Boolean(getFeatureByName(state, 'quota-cleaner-year-filter'));
export const getFeaturePdfRenderOnTouch = (state) =>
    getFeatureByName(state, 'pdf-on-touch')?.data as unknown as undefined | { maxViewSize: number };
// CLOUDWEB-14268: Фича, которая задает скин для ДМР окна привязки карты на десктопе
export const getFeatureAddCardSkin = (state: RootState) => getFeatureByName(state, 'add-card-skin');
// CLOUDWEB-14268: Фича, которая задает скин для ДМР окна привязки карты на таче
export const getFeatureAddCardSkinTouch = (state: RootState) => getFeatureByName(state, 'add-card-skin-touch');
// CLOUDWEB-14278: AБ тест блока квоты
export const getFeatureAbSpace = (state: RootState) => getFeatureByName(state, 'ab-space')?.data;
/** CLOUDWEB-14438: Фича, которая показывает тултипы 'Завести облако' на декстоп-пабликах. */
export const getFeatureAbRegisterCloudTooltip = (state: RootState) => getFeatureByName(state, 'ab-public-create-cloud-tooltip')?.data;
/** CLOUDWEB-14314: Выключает свитчер в редактирование в настройках пабликов для mount файлов */
export const getFeatureShareMountEditAccessDisable = (state: RootState) => getFeatureByName(state, 'share-mount-edit-access-disable')?.data;
/** CLOUDWEB-14278: Фича, которая включает отображение пункта чистилки в меню блока квоты на таче */
export const getHideCleanerInSpaceFeature = (state: RootState) => Boolean(getFeatureByName(state, 'hide-qleaner-in-touch-space'));
/** CLOUDWEB-14713: Поп-апы спасения отвалившейся подписки на вебе */
export const getFeatureSaveSubscriptionPromo = (state: RootState): FeatureSaveSubscription =>
    getFeatureByName(state, 'save-subscription-promo')?.data;
export const getFeatureSaveSubscriptionTariffs = (state: RootState) => getFeatureByName(state, 'save-subs-tariff')?.data;
export const getFeatureSaveSubscriptionTransit = (state: RootState): FeatureSaveSubscriptionTransit =>
    getFeatureByName(state, 'save-subscription-transit')?.data;
// tempexp_14649-next-line
/** CLOUDWEB-14649: АБ Убрать Про тарифы */
export const getFeatureAbProTab = (state: RootState) => getFeatureByName(state, 'ab-pro-tab')?.data;
// tempexp_14549-next-line
/** CLOUDWEB-15390 Запуск баннера про безопасность */
export const getFeatureSafetyBannerPromo = (state: RootState) => getFeatureByName(state, 'safety-banner-promo')?.data || false;
/** CLOUDWEB-14826 информация о тарифах, чтобы показывать их анонимам */
export const getTariffListData = (state: RootState) => getFeatureByName(state, 'tariffs-list-data')?.data;
/** CLOUDWEB-15206 АБ на промо триала в просмоторщике аттачей */
export const getFeaturesAttachesTrialPromo = (state: RootState) => Boolean(getFeatureByName(state, 'ab-attaches-trial-promo'));
// B2BCLOUD-43: Убрать функционал создания нового файла/папки в папке которая доступна только на просмотр
export const getFeatureHideCreateUploadInRoFolders = (state: RootState) => !!getFeatureByName(state, 'hide-create-upload-in-ro-folders');
// CLOUDWEB-15025: Показывать каунтер новых сторисов
export const getFeatureShowNewStoriesCounter = (state: RootState) => !!getFeatureByName(state, 'show-story-counter');
// CLOUDWEB-15011: Фича, из которой берется реглярка для списка доступных подарков
export const getGiftsRegExp = (state: RootState) => getFeatureByName(state, 'gift-list-reg-exps')?.data || '.*_single_quota2022';
// tempexp_14812-next-line
/** CLOUDWEB-14812: Запустить эмптистейты на вебе под А/Б */
export const getFeatureNewEmptyStatesTouch = (state: RootState): boolean => getFeatureByName(state, 'new-empty-states-touch')?.data;
// tempexp_15071-start
/** CLOUDWEB-15071 Аб: subscriptions против promo/quota на вебе в меню блока квоты */
export const getAbSpaceMenuLink = (state: RootState) => Boolean(getFeatureByName(state, 'ab-space-menu-link'));
// tempexp_15071-end
// tempexp_14729-next-line
/** CLOUDWEB-14729: Новая сетка ТАБЛИЦЕЙ с удобным выделением на плитках и новыми иконками */
export const getFeatureNewContentTable = (state: RootState): boolean => getFeatureByName(state, 'new-content-table-in-web')?.data;
// tempexp_14737-next-line
/** CLOUDWEB-14737: Новое флоу создания папки */
export const getFeatureABCreateFolderNewFlow = (state: RootState): boolean => getFeatureByName(state, 'ab-new-folder-create-flow')?.data;
/** CLOUDWEB-15204: Доработка акции Mail Space **/
export const getFeatureMarketingPromoFastcheckout = (state: RootState) => Boolean(getFeatureByName(state, 'marketing-promo-fastcheckout'));
// tempexp_15078-next-line
/** CLOUDWEB-15078: Эксперимент с колокольчиком на вебе */
export const getFeatureABAnimatedBell = (state: RootState): boolean => getFeatureByName(state, 'ab-animated-bell')?.data;
// tempexp_15217-next-line
/** CLOUDWEB-15217: АБ заменить тариф в кнопке на лп Mail Space */
export const getFeatureQuotaMenuTariff = (state: RootState): string => getFeatureByName(state, 'ab-quota-lp-menu-tariff')?.data;
export const getFeatureMarketingPromoButton = (state: RootState): FeatureMarketingPromoButton | undefined =>
    getFeatureByName(state, 'marketing-promo-button')?.data;

/** CLOUDWEB-15235: Редактирование нередактируемых форматов */
export const getFeatureEditingNonEditableFormatsData = (state: RootState): FeatureEditingNonEditableFormatsData =>
    getFeatureByName(state, 'editing-non-editable-formats')?.data || {};
export const getFeatureEditingNoneditableFormats = (
    state: RootState,
    { file, storage }: FeatureEditingNoneditableFormatsInit
): { storage: EStorageType; ext: string } | undefined => {
    const { formats, activePages, pagesReplacedToHome, maxConvertSize }: FeatureEditingNonEditableFormatsData =
        getFeatureEditingNonEditableFormatsData(state);
    const ext = formats?.[file?.ext];
    const isFeatureEnabled = Boolean(activePages?.includes(storage));

    if (!isFeatureEnabled || !ext || (maxConvertSize && file.size > maxConvertSize)) {
        return undefined;
    }

    const converterStorage = pagesReplacedToHome?.includes(storage) ? EStorageType.home : storage;

    return { storage: converterStorage, ext };
};
// tempexp_15344-next-line
// CLOUDWEB-15344: [ab] [web] обязательная регистрация при скачивании файла
export const getFeatureRequiredSignUpWhenDownloading = (state: RootState): TemplateVariantString | boolean => {
    const { data } = getFeatureByName(state, 'ab-required-sign-up-when-downloading') || {};
    const isPhone = EnvironmentSelectors.isPhone();

    if (data === 'b' && !ALLOW_ANONYM_DOWNLOAD_PUBLIC && !isPhone) {
        return 'variant1';
    }

    return false;
};
/** CLOUDWEB-15226 Показывать щит касперского только для определённых типов файлов */
export const getFeatureKasperskyVerifiedExts = (state: RootState): Record<string, string[]> =>
    getFeatureByName(state, 'kaspersky-verified-exts-config')?.data;
/** CLOUDWEB-15544 Досбор способов связи у неру авторизаций_веб */
export const getFeatureAskPhoneDomains = (
    state: RootState
): undefined | Record<string, { text: string; showFreqDays?: number; closable?: boolean }> =>
    getFeatureByName(state, 'ask-phone-domains')?.data;

// Страница "Документы"
/** CLOUDWEB-14810 Новая страница Документы */
// TODO заменить getFeatureAllDocuments и getFeatureAllDocumentsHideSections на одну фичу с полем hidden в рамках CLOUDWEB-16604
export const getFeatureAllDocuments = (state: RootState) =>
    getFeatureByName(state, 'alldocuments')?.data || ALL_DOCUMENTS_AVAILABLE_EXTENSIONS;

/** CLOUDWEB-14995 Скрытие подразделов в разделе документы */
// TODO заменить getFeatureAllDocuments и getFeatureAllDocumentsHideSections на одну фичу с полем hidden в рамках CLOUDWEB-16604
export const getFeatureAllDocumentsHideSections = (state: RootState): string[] =>
    getFeatureByName(state, 'alldocuments-hide-sections')?.data || [];

/** CLOUDWEB-15467: Промо для ебуков */
export const getFeatureAbEbookPromo = (state: RootState): string => getFeatureByName(state, 'ab-ebook-promo')?.data;

/** CLOUDWEB-15961: Фича, которая содержит текст для попапа не разрешенных форматов и предупреждения в UploadList в разделе Документы */
export const getFeatureAllDocumentsUploadText = (state: RootState) => getFeatureByName(state, 'alldocuments-upload-text')?.data || {};
