import { Text } from '@vkontakte/vkui';
import sidebarTrialPromoImageSmall from 'img/sidebarTrialPromoImageSmall.png';
import sidebarTrialPromoImageSmallDark from 'img/sidebarTrialPromoImageSmallDark.png';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_AUTO_TEST_HIDE, IS_FAMILY_USER, PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { isDarkTheme, sidebarTrialPromo } from 'reactApp/appHelpers/featuresHelpers';
import { loadProductsAndOpenFastCheckout } from 'reactApp/modules/payment/payment.module';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './TrialPromo.css';

interface IProps {
    text: string;
    href: string;
    onShow?: () => void;
    onClick?: () => void;
    onClose?: () => void;
}

const SIDEBAR_TRIAL_PROMO_ID = 'sidebar-trial-banner';
const SIDEBAR_TRIAL_PROMO_SHOW_COUNT = `${SIDEBAR_TRIAL_PROMO_ID}-count`;

function markPromoShown(dispatch) {
    dispatch(promoShown(EPromoType.sidebarTrialPromo));
    storeHelper.markAsShown(SIDEBAR_TRIAL_PROMO_ID);
    dispatch(removePromo(EPromoType.sidebarTrialPromo));
}

export const TrialPromo = memo<IProps>(({ text }) => {
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isBizUser = useSelector(UserSelectors.isBizUser);

    const dispatch = useDispatch();
    const shownCount = Number(dispatch(UserStorageActions.get(SIDEBAR_TRIAL_PROMO_SHOW_COUNT)) || 0);
    const periodInDays = shownCount < 5 ? 7 : 14;
    const periodPassed =
        storeHelper.getValue(SIDEBAR_TRIAL_PROMO_ID) && storeHelper.isPassed(SIDEBAR_TRIAL_PROMO_ID, { daysCount: periodInDays });

    if (periodPassed) {
        storeHelper.clearStore(SIDEBAR_TRIAL_PROMO_ID);
    }

    const needToHide =
        sidebarTrialPromo !== 'b' ||
        storeHelper.getValue(SIDEBAR_TRIAL_PROMO_ID) ||
        IS_AUTO_TEST_HIDE ||
        isCorpUser ||
        isBizUser ||
        IS_FAMILY_USER;

    useEffect(() => {
        if (!needToHide) {
            emitAnalyticEvent(AnalyticEventNames.SIDEBAR_TRIAL_BANNER_SHOW);
            sendDwh({ eventCategory: 'mail-space-trial-banner-show' });
            dispatch(UserStorageActions.set(SIDEBAR_TRIAL_PROMO_SHOW_COUNT, shownCount + 1));
        }
    }, []);

    if (needToHide) {
        return null;
    }

    const handleClick = () => {
        markPromoShown(dispatch);
        emitAnalyticEvent(AnalyticEventNames.SIDEBAR_TRIAL_BANNER_CLICK);
        sendDwh({ eventCategory: 'mail-space-trial-banner-click' });
        sendPaymentGa({ eventCategory: ECategoryGa.banner, action: 'click', name_banner: SIDEBAR_TRIAL_PROMO_ID });
        dispatch(
            loadProductsAndOpenFastCheckout({
                productId: PROMO_TARIFFS?.default1TbTrial,
                paySource: 'sidebar_promo_trial',
                title: 'Подписка Mail Space: место для файлов без рекламы',
                hideOtherTariffs: true,
            })
        );
    };

    return (
        <div className={styles.root}>
            <div
                className={styles.wrapper}
                onClick={handleClick}
                style={{
                    backgroundColor: isDarkTheme ? '#133D05' : '#CFF0C4',
                }}
            >
                <Text className={styles.text}>{text}</Text>
                <div className={styles.iconWrapper}>
                    <img
                        className={styles.icon}
                        src={isDarkTheme ? sidebarTrialPromoImageSmallDark : sidebarTrialPromoImageSmall}
                        alt="Cover image"
                    />
                </div>
            </div>
        </div>
    );
});

TrialPromo.displayName = 'TrialPromo';
