import React, { FC } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { Destination, SizeType } from 'reactApp/ui/DataListItemCommonComponents/Destination/Destination';
import { Download } from 'reactApp/ui/DataListItemCommonComponents/Download/Download';
import { Date } from 'reactApp/ui/DataListItemRow/components/Date/Date';
import { ItemName } from 'reactApp/ui/DataListItemRow/components/ItemName/ItemName';
import { Size } from 'reactApp/ui/DataListItemRow/components/Size/Size';
import { TableColumn } from 'reactApp/ui/DataListItemRow/components/TableColumn/TableColumn';
import { AttachItemProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { useCompactView } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from '../../DataListItemRow.new.css';
import { BizItemName } from '../BizItemName/BizItemName';

export const AttachesItem: FC<AttachItemProps> = (props) => {
    const {
        id,
        storage,
        isFolder,
        thumbUrl,
        isPublic,
        name,
        size,
        mtime,
        ext,
        isSelected,
        onCheckboxClick,
        onDownload,
        openAttachLetter,
        onClone,
        subject,
        owner,
    } = props;

    const isCompact = useCompactView();
    const downloadFlexBasis = ENABLE_FULL_RESPONSIVE ? 40 : 100;

    return (
        <>
            <TableColumn>
                <Checkbox viewType={EViewMode.list} className={styles.check} onCheckboxClick={onCheckboxClick} isSelected={isSelected} />
                <FilePic isFolder={isFolder} thumbUrl={thumbUrl} isVirus={false} ext={ext || ''} isPublic={isPublic} />
            </TableColumn>
            {isCompact ? (
                <TableColumn width="100%" overflow="hidden">
                    <div className={styles.nameWrapper}>
                        <BizItemName name={name} ext={ext || ''} showTooltip />
                        <Destination
                            destination={subject}
                            author={owner}
                            openAttachLetter={openAttachLetter}
                            isAttaches
                            size={SizeType.s}
                        />
                        <span className={styles.responsiveDateWrapper}>
                            <Size size={size} isInline />
                            {!!mtime && <>&nbsp;•&nbsp;</>}
                            <Date date={mtime} className={styles.date} />
                        </span>
                    </div>
                </TableColumn>
            ) : (
                <>
                    <TableColumn width="60%" overflow="hidden">
                        <ItemName name={name} ext={ext || ''} id={id} />
                    </TableColumn>
                    <TableColumn width="40%" overflow="hidden">
                        <Destination destination={subject} author={owner} openAttachLetter={openAttachLetter} isAttaches />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="flex-end">
                        <Date date={mtime} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="center">
                        <Size size={size} onSaveToCloud={() => onClone('attaches-autodelete-tooltip')} />
                    </TableColumn>
                </>
            )}
            <TableColumn flexBasis={downloadFlexBasis} flexShrink={0} justifyContent="flex-end" gap={20}>
                <Download onClick={onDownload} ext={ext} size={size} id={id} storage={storage} viewType={EViewMode.list} />
            </TableColumn>
        </>
    );
};
