import { captureException } from '@sentry/browser';
import { logger } from 'lib/logger';
import { EMBEDDED, IS_FRAME_PAGES, IS_PUBLIC, IS_REACT_PROMO_NEW_YEAR } from 'reactApp/appHelpers/configHelpers';
import { welcomeDefaultTariff } from 'reactApp/appHelpers/featuresHelpers';
import { renderLicenseAgreementDialog } from 'reactApp/components/LicenseAgreement/licenseAgreement.toolkit';
import { attachesPageStart, setIsFromAttaches } from 'reactApp/modules/attaches/attaches.actions';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { loadProductsSuccess } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { checkUpdateLicenseCompleted } from 'reactApp/modules/profile/profile.module';
import { getIsCheckUpdateLicenseCompleted } from 'reactApp/modules/profile/profile.selectors';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { reDownloadController } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { closeViewer } from 'reactApp/modules/viewer/viewer.module';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { isWelcomeDialogShown, renderWelcomeHelper, renderWelcomeRepeatingHelper } from 'reactApp/modules/welcome/utils';
import { showWelcome } from 'reactApp/modules/welcome/welcome.module';
import { RootState } from 'reactApp/store';
import { channel } from 'redux-saga';
import { cancel, select, take, takeEvery } from 'redux-saga/effects';

function* handleShowWelcome() {
    try {
        const isSocialUser = yield select(UserSelectors.isSocialUser);
        const isSuccessAuthWhenDownload = yield select(requiredAuthorizationHelpers.isSuccessAuthWhenDownload);
        const hideLAOnPublic = IS_PUBLIC && (!isSuccessAuthWhenDownload || reDownloadController.isDownloadHappened());

        let isNewbie = yield select(UserSelectors.isNewbie);

        if (
            !isNewbie ||
            hideLAOnPublic ||
            isWelcomeDialogShown() ||
            IS_REACT_PROMO_NEW_YEAR ||
            isSocialUser ||
            EMBEDDED ||
            IS_FRAME_PAGES
        ) {
            return;
        }

        let newDialog;
        const storage = yield select(getCurrentStorage);
        const isViewerOpening = yield select(ViewerSelectors.isViewerOpening);

        if (isViewerOpening) {
            yield take(closeViewer);

            isNewbie = yield select(UserSelectors.isNewbie);
            if (!isNewbie) {
                return;
            }
            const { fromCloud = false } = yield select(SettingsSelectors.getQueryParams);
            if (!fromCloud) {
                yield take(setIsFromAttaches);
                newDialog = true;
            }
            // tempexp-15346-end
        }

        // tempexp_17086-start
        if (welcomeDefaultTariff?.tariff && storage === EStorageType.home) {
            yield take(loadProductsSuccess);
            const product = yield select((state: RootState) => ProductsSelectors.getProductById(state, welcomeDefaultTariff?.tariff));
            renderWelcomeHelper({ storage, newDialog, product });
            return;
        }
        // tempexp_17086-end

        renderWelcomeHelper({ storage, newDialog });
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* attachesRepeatWelcome() {
    const isSocialUser = yield select(UserSelectors.isSocialUser);

    if (isWelcomeDialogShown() || IS_PUBLIC || IS_REACT_PROMO_NEW_YEAR || isSocialUser) {
        return;
    }

    const isViewerOpening = yield select(ViewerSelectors.isViewerOpening);
    if (isViewerOpening) {
        yield take(closeViewer);
    }

    const isNewbie = yield select(UserSelectors.isNewbie);
    const isCheckUpdateLicenseCompleted = yield select(getIsCheckUpdateLicenseCompleted);

    if (isNewbie) {
        return;
    }
    if (!isCheckUpdateLicenseCompleted) {
        yield take(checkUpdateLicenseCompleted);
    }
    renderWelcomeRepeatingHelper({ storage: EStorageType.attaches });
}

export function* askLicense() {
    const licenseChannel = channel();
    const storage = yield select(getCurrentStorage);
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    if ([EStorageType.attaches, EStorageType.viewerAttaches].includes(storage)) {
        renderWelcomeHelper({
            storage,
            onClose: () => licenseChannel.put(false),
            onSuccess: () => licenseChannel.put(true),
        });
    } else {
        renderLicenseAgreementDialog({
            place: storage,
            source: isPhone ? 'touch' : 'la-dialog',
            onClose: () => licenseChannel.put(false),
            onSuccess: () => licenseChannel.put(true),
        });
    }

    const isAgreedWithTheLa = yield take(licenseChannel);
    yield licenseChannel.close();

    return isAgreedWithTheLa;
}

export function* watchWelcomeRoot() {
    yield takeEvery(showWelcome.toString(), handleShowWelcome);
    yield takeEvery(attachesPageStart.toString(), attachesRepeatWelcome);
}
