import { xray } from 'lib/xray';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { getSnackbarText } from 'reactApp/modules/modifying/modifying.helpers';
import { ESnackbarType } from 'reactApp/modules/modifying/modifying.types';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { historyPush } from 'reactApp/modules/router/router.module';
import { startSelecting } from 'reactApp/modules/selections/selections.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getDeleteSnackbarText } from 'reactApp/modules/userQuotaCleaner/helpers/getDeleteSnackbarText';
import {
    deleteUserQuotaCleanerItemsSuccess,
    reloadGroupAfterDelete,
    setIsItemsDeleteProcess,
    setNeedsGroupReload,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { getCurrentGroupTotalCount } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { store } from 'reactApp/store';
import { UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import { sendPostMessage } from 'reactApp/utils/windowHelper';
import { put, select } from 'redux-saga/effects';

import { QuotaCleanerItem } from '../userQuotaCleaner.types';

export function* deleteItemsSuccess(action: ReturnType<typeof deleteUserQuotaCleanerItemsSuccess>) {
    const {
        payload: { count, groupType, groupId, ids, itemIds, items, size, isMsgRemove, onDeleteSuccess },
    } = action;
    const isMobile = yield select(QuotaLandingSelectors.isMobile);
    yield put(setIsItemsDeleteProcess({ value: false }));

    onDeleteSuccess?.();
    const totalCount = yield select(getCurrentGroupTotalCount);

    if (totalCount) {
        const filesCount = items ? (items as QuotaCleanerItem[]).filter((item) => !item.isFolder).length : 0;
        const foldersCount = items ? (items as QuotaCleanerItem[]).filter((item) => item.isFolder).length : 0;

        const snackbarAction =
            groupType === UserQuotaGroupType.Cloud
                ? {
                      type: SnackbarTypes.trashbin,
                      text: getSnackbarText({
                          items,
                          filesCount,
                          foldersCount,
                          type: ESnackbarType.MOVE_TO_TRASHBIN,
                      }),
                      buttonText: 'Перейти в корзину',
                      onButtonClick: () => {
                          store.dispatch(historyPush({ id: `/${EStorageType.trashbin}` }));
                      },
                  }
                : {};

        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteSuccess',
                type: SnackbarTypes.success,
                text: getDeleteSnackbarText(count, groupType, groupId),
                closable: true,
                ...snackbarAction,
            })
        );
    } else {
        yield put(
            showSnackbarAction({
                id: 'quotaCleanerDeleteAllSuccess',
                type: SnackbarTypes.success,
                text: 'Хранилище очищено',
                closable: true,
            })
        );
    }

    if (IS_WEBVIEW) {
        sendPostMessage({
            files_deleted: count,
            files_size: size,
        });
    }

    xray.send(`quota-cln-delete-${isMsgRemove ? 'msg-' : ''}scc${isMobile ? '-mob' : ''}`, { i: groupId });

    yield put(setNeedsGroupReload({ value: true }));
    yield put(reloadGroupAfterDelete({ ids, itemIds, groupId, size }));
    if (isMobile) {
        yield put(startSelecting());
    }
}
