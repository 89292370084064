import mailSpaceTrialPromoImage64 from 'img/mailSpaceTrialPromoImage64.png';
import mailSpaceTrialPromoImage128 from 'img/mailSpaceTrialPromoImage128.png';
import { IS_AUTO_TEST_HIDE, IS_FAMILY_USER } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { mailSpaceTrialPromo } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { productsController } from 'reactApp/modules/products/products.controller';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { EAsidePromoModalTheme } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.types';
import { sendDwh } from 'reactApp/utils/ga';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const MAILSPACE_TRIAL_PROMO_ID = 'mailspace-trial-promo';
const MAILSPACE_TRIAL_PROMO_SHOW_COUNT = `${MAILSPACE_TRIAL_PROMO_ID}-count`;
const MAILSPACE_64GB_TARIFF = 'W64GB_1m_mail_modal_window_C_trial_force_single_quota_cloud';
const MAILSPACE_128GB_TARIFF = 'W128GB_1m_refund_payers_trial_force_single_quota_cloud';

function markPromoShown() {
    reduxStore.dispatch(promoShown(EPromoType.mailspaceTrialPromo));
    storeHelper.markAsShown(MAILSPACE_TRIAL_PROMO_ID);
    reduxStore.dispatch(removePromo(EPromoType.mailspaceTrialPromo));
}

export function* initMailSpaceTrialPromoModal() {
    const storage = yield* select(getCurrentStorage);
    const isCorpUser = yield* select(UserSelectors.isCorpUser);
    const isBizUser = yield* select(UserSelectors.isBizUser);
    const isPaidUser = yield* select(UserSelectors.isPaidUser);
    const shownCount = yield put(UserStorageActions.get(MAILSPACE_TRIAL_PROMO_SHOW_COUNT)) || 0;

    const { isPublic } = getStorage(storage);

    // Показываем промо сначала 1 месяц раз в неделю далее 1 раз в 2 недели
    const periodInDays = shownCount < 5 ? 7 : 14;
    const periodPassed =
        storeHelper.getValue(MAILSPACE_TRIAL_PROMO_ID) && storeHelper.isPassed(MAILSPACE_TRIAL_PROMO_ID, { daysCount: periodInDays });

    if (periodPassed) {
        storeHelper.clearStore(MAILSPACE_TRIAL_PROMO_ID);
    }

    if (
        !isPublic ||
        !mailSpaceTrialPromo ||
        mailSpaceTrialPromo === 'a' ||
        storeHelper.getValue(MAILSPACE_TRIAL_PROMO_ID) ||
        IS_AUTO_TEST_HIDE ||
        isCorpUser ||
        isBizUser ||
        isPaidUser ||
        IS_FAMILY_USER
    ) {
        return;
    }

    yield productsController.loadProducts();

    yield put(
        addPromoToShowQueue({
            type: EPromoType.mailspaceTrialPromo,
            promoId: MAILSPACE_TRIAL_PROMO_ID,
            onClick: () => {
                markPromoShown();
                emitAnalyticEvent(AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLICK);
                sendDwh({ eventCategory: 'mail-space-trial-promo_click' });
                reduxStore.dispatch(
                    openTariffBuy({
                        productId: mailSpaceTrialPromo === 'b' ? MAILSPACE_64GB_TARIFF : MAILSPACE_128GB_TARIFF,
                        paySource: 'ballon_trial',
                    })
                );
            },
            onShow: () => {
                markPromoShown();
                emitAnalyticEvent(AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_SHOW);
                sendDwh({ eventCategory: `mail-space-trial-promo_show` });
            },
            onClose: () => {
                markPromoShown();
                emitAnalyticEvent(AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLOSE);
                sendDwh({ eventCategory: `mail-space-trial-promo_close` });
            },
        })
    );
}

export function* showMailSpaceTrialPromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.mailspaceTrialPromo));
    const shownCount = yield put(UserStorageActions.get(MAILSPACE_TRIAL_PROMO_SHOW_COUNT)) || 0;
    const isMobile = EnvironmentSelectors.isMobile();

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        if (!isMobile) {
            yield openAsidePromoModal({
                title: 'Подписка Mail Space',
                text: 'Загружайте фото и видео в Облако\n и делитесь с друзьями',
                buttonText: 'Попробовать за 1 ₽',
                imageUrl: mailSpaceTrialPromo === 'b' ? mailSpaceTrialPromoImage64 : mailSpaceTrialPromoImage128,
                bottom: 114,
                qaId: 'mailspace-trial-promo',
                onShow,
                onClick,
                onClose,
                theme: EAsidePromoModalTheme.modalDark,
            });
        }

        yield put(UserStorageActions.set(MAILSPACE_TRIAL_PROMO_SHOW_COUNT, shownCount + 1));
    }
}
