import { Icon20Check, Icon20ChevronUpOutline } from '@vkontakte/icons';
import { Button, List, SimpleCell, SizeType } from '@vkontakte/vkui';
import { Popper } from '@vkontakte/vkui/unstable';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isRebranding } from 'reactApp/appHelpers/featuresHelpers';
import { useClickOutsideTarget } from 'reactApp/hooks/useClickOutsideTarget';
import { changeSelectType } from 'reactApp/modules/editor/editor.module';
import { getLlmDialogState } from 'reactApp/modules/editor/editor.selectors';
import { LLMAnalyticsType, useGetLLMAnalytics } from 'reactApp/ui/EditorLLM/analytics/EditorLlmAnalytics';
import { SCENARIO_SELECT_OPTIONS } from 'reactApp/ui/EditorLLM/EditorLLMDialog/constants/EditorLlmDialog.constants';
import { MessageCategory } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';
import { DropdownOutlineIcon } from 'reactApp/ui/VKUIIcons';

import styles from './EditorLLMDialogSelect.css';

export const EditorLLMDialogSelect: FC = () => {
    const dispatch = useDispatch();
    const sendAnalytics = useGetLLMAnalytics();
    const { selectType, isOpen: isDialogOpen } = useSelector(getLlmDialogState);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isFirstOpen, setIsFirstOpen] = useState(true);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    const onSelect = (value: MessageCategory, label: string) => {
        sendAnalytics({ action: LLMAnalyticsType.SCENARIO_CHANGE, dwh: { scenario: selectType.value, scenario_change: value } });

        dispatch(changeSelectType({ value, label }));

        handleOpen();
    };

    const onClickAway = () => {
        setIsOpen(false);
    };

    useClickOutsideTarget({ rootRef: buttonRef, ref: dropdownRef, onClickAway });

    useEffect(() => {
        if (isDialogOpen && buttonRef?.current && isFirstOpen) {
            setTimeout(() => {
                setIsOpen(true);
                setIsFirstOpen(false);
            }, 310);
        }
    }, [isDialogOpen, isFirstOpen]);

    return (
        <>
            <Button
                className={styles.button}
                getRootRef={buttonRef}
                size="s"
                mode="primary"
                appearance="neutral"
                sizeY={SizeType.COMPACT}
                onClick={handleOpen}
                after={isOpen ? <DropdownOutlineIcon className={styles.icon} /> : <Icon20ChevronUpOutline className={styles.icon} />}
            >
                {selectType?.label}
            </Button>
            {isOpen && (
                <Popper forcePortal={false} targetRef={buttonRef} placement="top-start" offsetDistance={16}>
                    <div ref={dropdownRef}>
                        <List className={styles.container}>
                            {SCENARIO_SELECT_OPTIONS.map(({ value, label, icon }) => (
                                <SimpleCell
                                    after={
                                        selectType?.value === value && (
                                            <Icon20Check
                                                style={{ color: isRebranding ? 'var(--vkui--color_background_border)' : '#2688eb' }}
                                            />
                                        )
                                    }
                                    key={value}
                                    before={icon}
                                    onClick={() => onSelect(value, label)}
                                    value={value}
                                    label={label}
                                    className={styles.cell}
                                >
                                    {label}
                                </SimpleCell>
                            ))}
                        </List>
                    </div>
                </Popper>
            )}
        </>
    );
};
