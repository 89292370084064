import { AnalyticEvent, defineAnalyticEvents } from 'reactApp/utils/experimentAnalytic';

import { AnalyticEventNames } from './eventNames';
import {
    BuyDisableAdsModalShownType,
    BuyDisableAdsModalSwitchPeriodType,
    BuyDisableAdsModalTariffClickType,
    BuyFastCheckoutClickArgsType,
    BuyFastCheckoutViewArgsType,
    FastCheckoutShowArgsType,
    MobileTariffShowEventArgsType,
    TariffBuyEventArgsType,
    TariffClickEventArgsType,
    TariffShowEventArgsType,
} from './types';

export const { emitAnalyticEvent, registerExperiment } = defineAnalyticEvents({
    [AnalyticEventNames.QUOTA_LANDING_SHOWN]: new AnalyticEvent('landing_show'),
    [AnalyticEventNames.MOBILE_LANDING_SHOWN]: new AnalyticEvent('mob_landing_show'),
    [AnalyticEventNames.TARIFFS_SHOWN]: new AnalyticEvent('tariffs_show'),
    [AnalyticEventNames.TARIFF_SHOWN]: new AnalyticEvent<TariffShowEventArgsType>('tariff_shown'),
    [AnalyticEventNames.MOBILE_TARIFF_SHOWN]: new AnalyticEvent<MobileTariffShowEventArgsType>('m_tariff_shown'),
    [AnalyticEventNames.TARIFF_ADVANTAGES_CLICK]: new AnalyticEvent('advnt_click'),
    [AnalyticEventNames.MODAL_TARIFF_ADVANTAGES_SHOW]: new AnalyticEvent('advnt_modal_show'),
    [AnalyticEventNames.TARIFF_CLICK]: new AnalyticEvent<TariffClickEventArgsType>('tariff_click'),
    [AnalyticEventNames.FAST_CHECKOUT_SHOWN]: new AnalyticEvent<FastCheckoutShowArgsType>('checkout'),
    [AnalyticEventNames.TARIFF_BUY]: new AnalyticEvent<TariffBuyEventArgsType>('buy'),

    [AnalyticEventNames.BIG_FILE_DIALOG_SHOW]: new AnalyticEvent('big-file-show'),
    [AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_SHOW]: new AnalyticEvent('big-file-payment-show'),
    [AnalyticEventNames.BIG_FILE_DIALOG_PAYMENT_BUY]: new AnalyticEvent<{ tariff: 'trial' | 'tariff' }>('big-file-payment-buy'),

    [AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_SHOW]: new AnalyticEvent('promo_show'),
    [AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLICK]: new AnalyticEvent('promo_click'),
    [AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLOSE]: new AnalyticEvent('promo_close'),

    [AnalyticEventNames.MAIL_POPUP_SHOW]: new AnalyticEvent('mail-popup-show'),
    [AnalyticEventNames.MAIL_POPUP_CLICK]: new AnalyticEvent('mail-popup-click'),
    [AnalyticEventNames.MAIL_POPUP_PAYMENT_SHOW]: new AnalyticEvent('mail-popup-payment-show'),
    [AnalyticEventNames.MAIL_POPUP_PAYMENT_SUCCESS]: new AnalyticEvent('mail-popup-payment-buy'),
    [AnalyticEventNames.MAIL_POPUP_PAYMENT_FAILURE]: new AnalyticEvent('mail-popup-payment-fail'),

    [AnalyticEventNames.DISABLE_BUTTON_CLICK]: new AnalyticEvent('disable_click'),
    [AnalyticEventNames.DISABLE_BUTTON_SHOW_CHECKOUT]: new AnalyticEvent('disable_checkout'),
    [AnalyticEventNames.DISABLE_BUTTON_PAYMENT]: new AnalyticEvent('disable_payment'),

    [AnalyticEventNames.SIDEBAR_TRIAL_PROMO_SHOW]: new AnalyticEvent('promo-show'),
    [AnalyticEventNames.SIDEBAR_TRIAL_PROMO_CLICK]: new AnalyticEvent('promo-click'),
    [AnalyticEventNames.SIDEBAR_TRIAL_PROMO_CLOSE]: new AnalyticEvent('promo-close'),
    [AnalyticEventNames.SIDEBAR_TRIAL_BANNER_SHOW]: new AnalyticEvent('banner-show'),
    [AnalyticEventNames.SIDEBAR_TRIAL_BANNER_CLICK]: new AnalyticEvent('banner-click'),

    [AnalyticEventNames.QUOTA_TOOLTIP_SHOW]: new AnalyticEvent('quota-tooltip_show'),
    [AnalyticEventNames.QUOTA_TOOLTIP_CLOSE]: new AnalyticEvent('quota-tooltip_close'),
    [AnalyticEventNames.QUOTA_BUTTON_SHOW]: new AnalyticEvent('quota-button_show'),
    [AnalyticEventNames.QUOTA_BUTTON_CLICK]: new AnalyticEvent('quota-button_click'),

    [AnalyticEventNames.BUY_FAST_CHECKOUT_VIEW]: new AnalyticEvent<BuyFastCheckoutViewArgsType>('buy-fastcheckout-view'),
    [AnalyticEventNames.BUY_FAST_CHECKOUT_CLICK_PAY]: new AnalyticEvent<BuyFastCheckoutClickArgsType>('buy-fastcheckout-click-pay'),
    // tempexp_17127-start
    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_SHOWN]: new AnalyticEvent<BuyDisableAdsModalShownType>('buy_disable_ads_modal_shown'),
    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_PERIOD_CHANGE]: new AnalyticEvent<BuyDisableAdsModalSwitchPeriodType>(
        'buy_disable_ads_modal_period_change'
    ),
    [AnalyticEventNames.BUY_DISABLE_ADS_MODAL_TARIFF_CLICK]: new AnalyticEvent<BuyDisableAdsModalTariffClickType>(
        'buy_disable_ads_modal_tariff_click'
    ),
    // tempexp_17127-end

    [AnalyticEventNames.DATALIST_ITEM_CLICK_PUBLISH]: new AnalyticEvent('datalist_item_click_publish'),
    [AnalyticEventNames.NICE_LINK_TOOLTIP_SHOW]: new AnalyticEvent('nice-link-tooltip_show'),
    [AnalyticEventNames.NICE_LINK_TOOLTIP_CLICK]: new AnalyticEvent('nice-link-tooltip_click'),
    [AnalyticEventNames.NICE_LINK_TOOLTIP_CLOSE]: new AnalyticEvent('nice-link-tooltip_close'),
    [AnalyticEventNames.NICE_LINK_THANKS_TOOLTIP_CLICK]: new AnalyticEvent('nice-link-thanks-tooltip_click'),
    [AnalyticEventNames.NICE_LINK_THANKS_TOOLTIP_SHOW]: new AnalyticEvent('nice-link-thanks-tooltip_show'),
    // tempexp_17086-start
    [AnalyticEventNames.WELCOME_DEFAULT_TARIFFS_SHOW]: new AnalyticEvent('welcome_show'),
    [AnalyticEventNames.WELCOME_DEFAULT_TARIFFS_CLICK_FREE]: new AnalyticEvent('click_free'),
    // tempexp_17086-end

    [AnalyticEventNames.OVERQUOTA_POPUP_SHOW]: new AnalyticEvent('overquota-show'),
    [AnalyticEventNames.OVERQUOTA_POPUP_CLICK]: new AnalyticEvent('overquota-click'),
    [AnalyticEventNames.QUOTA_BLOCK_OVER_VIEW]: new AnalyticEvent('quota-over-view'),
    [AnalyticEventNames.QUOTA_BLOCK_OVER_CLICK]: new AnalyticEvent('quota-over-click'),
    // tempexp_17125-start
    [AnalyticEventNames.BLOCK_OVERQUOTA_VIEW]: new AnalyticEvent('block-over-view'),
    [AnalyticEventNames.BLOCK_OVERQUOTA_CLICK]: new AnalyticEvent('block-over-click'),
    [AnalyticEventNames.BLOCK_OVERQUOTA_FILES_VIEW]: new AnalyticEvent('block-file-view'),
    [AnalyticEventNames.BLOCK_OVERQUOTA_FILES_CLICK]: new AnalyticEvent('block-file-click'),
    // tempexp_17125-end
});
