import { Icon20InfoCircleOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as WarningIcon } from 'img/icons/uploader/warning.svg';
import React, { memo } from 'react';
import { Hint } from 'reactApp/ui/Hint/Hint';
import styles from 'reactApp/ui/UploadList/Item/Item.css';

export const MessageIcon = memo(
    ({ showInfoIcon, isErrorIcon, showMessageHandler }: { showInfoIcon: boolean; isErrorIcon: boolean; showMessageHandler(event) }) => {
        let icon;
        let text;

        if (isErrorIcon) {
            text = 'Показать ошибку';
            icon = (
                <div className={classNames({ [styles.message]: true, [styles.warning]: true })} onClick={showMessageHandler}>
                    <WarningIcon />
                </div>
            );
        } else if (showInfoIcon) {
            text = 'Показать сообщение';
            icon = (
                <div className={classNames({ [styles.message]: true, [styles.info]: true })} onClick={showMessageHandler}>
                    <Icon20InfoCircleOutline />
                </div>
            );
        }

        if (!icon) {
            return null;
        }

        return <Hint text={text}>{icon}</Hint>;
    }
);

MessageIcon.displayName = 'MessageIcon';
