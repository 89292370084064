import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';

import { ProgressData, TUploadTypeClick, UploaderState, UploaderUIState } from './upload.model';

const initialUIState: UploaderUIState = {
    hideUploader: false,
    isExpanded: false,
    isUploadPanelOpen: false,
    showInput: false,
    buttonTypeClick: undefined,
};

const initialUploaderState: UploaderState = {
    total: null,
    progressTotalPercent: null,
    canceled: null,
    failed: null,
    finished: null,
    uploaded: null,
    inProgress: null,
    uploadFrom: undefined,
    // fixme:  Нужно только для метрик с провокацией
    isProvocationShown: false,
};

export const toggleUploaderAction = createAction('uploader/toggleUploaderAction');
export const showUploaderAction = createAction('uploader/showUploaderAction');
export const hideUploaderAction = createAction('uploader/hideUploaderAction');
export const closeUploadPanelAction = createAction('uploader/closeUploadPanelAction');
export const openUploadPanelAction = createAction('uploader/openUploadPanelAction');
export const expandUploadPanelAction = createAction('uploader/expandUploadPanelAction');
export const setShowInputAction = createAction<boolean>('uploader/setShowInputAction');
export const setNotAllowedExtensionsAction = createAction('uploader/setNotAllowedExtensionsAction');
export const setButtonTypeClickAction = createAction<TUploadTypeClick>('uploader/setButtonTypeClick');
export const setUploadFromAction = createAction<string>('uploader/setUploadFrom');
export const setProvocationShowAction = createAction<boolean>('uploader/setProvocationShow');

export const uploaderTotalProgress = createAction<ProgressData>('uploader/uploaderTotalProgress');
// после получения и показа результата сбрасываем состояние.
export const resetProgress = createAction('uploader/resetProgress');

export const uploadReducer = createReducer({ ...initialUploaderState, ...initialUIState } as UploaderState & UploaderUIState, {
    [resetProgress.type]: (state) => {
        return {
            ...state,
            ...initialUploaderState,
        };
    },
    [hideUploaderAction.type]: (state) => {
        return {
            ...state,
            hideUploader: true,
        };
    },
    [toggleUploaderAction.type]: (state) => {
        return {
            ...state,
            hideUploader: !state.hideUploader,
        };
    },
    [showUploaderAction.type]: (state) => {
        return {
            ...state,
            hideUploader: false,
        };
    },
    [openUploadPanelAction.type]: (state) => {
        return {
            ...state,
            isUploadPanelOpen: true,
        };
    },
    [expandUploadPanelAction.type]: (state) => {
        return {
            ...state,
            isExpanded: true,
        };
    },
    [closeUploadPanelAction.type]: (state) => {
        return {
            ...state,
            isExpanded: true,
            isUploadPanelOpen: false,
        };
    },
    [setShowInputAction.type]: (state, action: PayloadAction<boolean>) => {
        const { payload } = action;

        return {
            ...state,
            showInput: payload,
        };
    },
    [setButtonTypeClickAction.type]: (state, action: PayloadAction<TUploadTypeClick>) => {
        const { payload } = action;

        return {
            ...state,
            buttonTypeClick: payload,
        };
    },
    [setUploadFromAction.type]: (state, action: PayloadAction<string>) => {
        state.uploadFrom = action.payload;
    },
    [setProvocationShowAction.type]: (state, action: PayloadAction<boolean>) => {
        state.isProvocationShown = action.payload;
    },
});
