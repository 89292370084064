import classNames from 'clsx';
import React from 'react';
import { noop } from 'reactApp/utils/helpers';

import styles from './DisableButton.css';

interface IProps {
    text?: string;
    className?: string;
    onClick?: (e) => void;
    id?: string;
    href: string;
    theme?: 'light' | 'dark';
}

export const DisableButtonComponent = ({ text, className = '', onClick = noop, href, theme = 'light' }: IProps) => {
    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            className={classNames(styles.disable, styles[`disable_theme_${theme}`], className)}
            href={href}
            onClick={onClick}
            target="_blank"
        >
            {text}
        </a>
    );
};
