import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const enum StandardPrefix {
    OVIDIUS = 'ui_ovidius',
    PDF = 'viewer_pdf',
    DOC_FB = 'doc-fb',
}

export const enum OvidiusEvents {
    RENDER = 'render',
    VIEW_ERR = 'view_err',
    VIEW_OPEN = 'view_open',
    FIRST_CONTENT = 'first_content',
    FULL_CONTENT = 'full_content',
    IFRAME_TIMEOUT = 'iframe_timeout',
    IFRAME_ERR = 'iframe_err',
    NO_URL = 'no-url',
    NO_INFO = 'no-info',
    NO_HASH = 'no-hash',
    INFO = 'info',
    NO_PART = 'no-part',
    NO_F_PART = 'no-f-part',
    ALL_PARTS = 'all-parts',
    ONE_PART = 'one-part',
    FIRST_PART = 'first-part',
}

export const enum PdfError {
    IFRAME = 'iframe',
    TIMEOUT = 'timeout',
}

export const enum PdfEvents {
    LOAD_TIME = 'load-time',
}

export type StandardPrefixMap = Record<StandardPrefix, string> & {
    [StandardPrefix.OVIDIUS]: OvidiusEvents;
    [StandardPrefix.PDF]: PdfEvents | PdfError;
};

interface File {
    attachType?: EAttachTypes;
    storage?: EStorageType;
    ext?: string;
    size?: number;
}

export interface StandardProps {
    prefix: StandardPrefix;
    event: string;
    file?: File;
    storage?: EStorageType;
    isErrorEvent?: boolean;
    error?: any;
    time?: number;
}
