import classNames from 'clsx';
import React, { memo } from 'react';
import { FOOTER, IS_MY_TEAM, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ON_PREMISE } from 'server/constants/environment';

import styles from './Footer.css';

interface IProps {
    storage: EStorageType;
    isAbsolutePos?: boolean;
    withMargins?: boolean;
    bottom?: number;
    right?: number;
}

export const Footer = memo(({ storage, isAbsolutePos = false, withMargins = false, bottom, right }: IProps) => {
    const { isAllDocuments, isEmbedded, isIntegration, isInlineIntegration } = getStorage(storage);

    // На брендировании показыается старый футер (рендер в SSR)
    const showFooter =
        !isAllDocuments && !ON_PREMISE && !IS_MY_TEAM && !PUBLIC_SHOVE && !isEmbedded && !isIntegration && !isInlineIntegration;

    if (!showFooter) {
        return null;
    }

    const style: Record<string, string> = {};

    if (typeof bottom === 'number') {
        style.bottom = `${bottom}px`;
    }

    if (typeof right === 'number') {
        style.right = `${right}px`;
    }

    return (
        <div
            style={style}
            className={classNames(styles.root, { [styles.root_absolute]: isAbsolutePos, [styles.root_withMargins]: withMargins })}
            dangerouslySetInnerHTML={{ __html: FOOTER }}
        />
    );
});

Footer.displayName = 'Footer';
