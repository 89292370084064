import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import { DATA_LIST_BREADCRUMBS_ID, DATA_LIST_EXTRABLOCKS_ID, DATA_LIST_STORIES_WIDGET_ID } from 'reactApp/ui/Datalist/DataList';
import { AdvBanner } from 'reactApp/ui/Mobile/Datalist/AdvBanner/AdvBanner';
import { VIRTUAL_LIST_LOADER_ID } from 'reactApp/ui/VirtualList/VirtualList.new';

import styles from './VirtualList.new.css';

export const VirtualListRow = memo(function VirtualListRow(props: ListChildComponentProps): ReactElement | null {
    const { index, style, data } = props;
    const { list, itemActiveIndex, renderItem, dividerSize, indexes } = data;
    const key = typeof list[index] === 'string' ? list[index] : list[index].id;
    const handleRef = index === itemActiveIndex ? data.handleRef : null;

    const startIndex = indexes[index].start;
    const lastIndex = Math.min(indexes[index].end, list.length);

    const singleRowItem =
        startIndex === lastIndex &&
        [DATA_LIST_EXTRABLOCKS_ID, VIRTUAL_LIST_LOADER_ID, DATA_LIST_BREADCRUMBS_ID, DATA_LIST_STORIES_WIDGET_ID].includes(
            list[startIndex]
        );

    if (list[index].divider) {
        return (
            <div
                style={style}
                key={key + index}
                className={classNames(styles.dividerContainer, styles.dividerList, {
                    [styles.dividerAdv]: list[index].showAdv,
                })}
                data-qa-divider={list[index].text}
            >
                <div
                    className={classNames(styles.divider, {
                        [styles[`divider_${dividerSize}`]]: !!dividerSize,
                    })}
                >
                    {list[index].text}
                    {list[index].showAdv && <AdvBanner />}
                </div>
            </div>
        );
    }

    let styleLastItem;
    if (singleRowItem) {
        styleLastItem = { height: '100%' };
    }

    return (
        <div style={style} key={key + index} ref={handleRef}>
            <div className={styles.compensateScroll} style={styleLastItem}>
                {renderItem(data.list[index], { index })}
            </div>
        </div>
    );
}, areEqual);
