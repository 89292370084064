import { Icon16ChainOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { FC } from 'react';
import { downloadItem } from 'reactApp/appHelpers/appDownload';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { useR7Stage } from 'reactApp/hooks/useR7Stage';
import { inlineHeader } from 'reactApp/modules/features/features.helpers';
import { CloudFile } from 'reactApp/modules/storage/storage.types';
import { ShareButtonCoEditPopup } from 'reactApp/ui/EditorHeader/BlueEditorHeader/ShareButtonCoEditPopup';
import { getBackLink } from 'reactApp/ui/EditorHeader/helpers/EditorHeader.helpers';
import { Button } from 'reactApp/ui/EditorHeader/InlineEditorToolbar/components/Button/Button';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { ChevronLeftOutlineIcon, DownloadIcon } from 'reactApp/ui/VKUIIcons';
import { createGaSender } from 'reactApp/utils/ga';
import { isDocumentsDomain } from 'server/helpers/isDocumentsDomain';

import styles from './InlineEditorToolbar.css';

type EditorHeaderProps = {
    file: CloudFile;
    isPublic: boolean;
    isWopiFeatureEnabled: boolean;
    onBackToCloud: () => void;
    backLink: string;
    gaCategory: string;
};

export const InlineEditorToolbar: FC<EditorHeaderProps> = ({ file, isPublic }) => {
    const { ready, error } = useR7Stage();

    const isDocDomain = isDocumentsDomain(window.location.hostname);

    const backLink = getBackLink(file);

    const { buttons } = inlineHeader;
    const { backTo, share, download } = buttons;

    const gaSender = createGaSender(`'r7-edtr'-${isPublic ? 'public' : 'home'}`);

    const handlePublish = () => {
        gaSender('publish', 'click', { type: 'button' });

        publishHelper({ item: file, gaSuffix: '_header' });
    };

    const handleDownload = () => {
        gaSender('download', 'click');

        downloadItem({ itemOrId: file });
    };

    const handleClick = () => {
        location.href = backLink;
    };

    return (
        <div className={classNames(styles.root, { [styles.show]: ready && !error })}>
            {backTo?.visible && (
                <Button className={styles.leftGroup} onClick={handleClick} icon={<ChevronLeftOutlineIcon />}>
                    {isDocDomain ? 'Документы' : 'Назад в Облако'}
                </Button>
            )}

            <div className={styles.rightGroup}>
                {download && (
                    <Hint text="Скачать">
                        <Button icon={<DownloadIcon width={16} height={16} />} onClick={handleDownload} />
                    </Hint>
                )}

                {share?.visible && (
                    <ShareButtonCoEditPopup>
                        <Button className={styles.shareButton} icon={<Icon16ChainOutline />} onClick={handlePublish}>
                            {share?.text}
                        </Button>
                    </ShareButtonCoEditPopup>
                )}
            </div>
        </div>
    );
};

InlineEditorToolbar.displayName = 'InlineEditorToolbar';
