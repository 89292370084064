import { checkApiResponseDataEquals } from 'reactApp/api/apiHelpers';
import { GiftReceivedAPICall } from 'reactApp/api/billing/gift/GiftReceivedAPICall';
import { ReceivedGiftsUpdate } from 'reactApp/modules/giftReceived/giftReceived.constants';
import ping from 'reactApp/utils/ping';

const request = () => ({
    type: ReceivedGiftsUpdate.REQUEST,
});

export const receivedGiftsUpdateSuccess = (list) => ({
    type: ReceivedGiftsUpdate.SUCCESS,
    list,
});

export const receivedGiftsUpdateFailure = (error) => ({
    type: ReceivedGiftsUpdate.FAILURE,
    error,
});

const callApi = () => new GiftReceivedAPICall().makeRequest();

export const updateReceivedGifts = () => (dispatch, getState) => {
    dispatch(request());
    const initialState = getState().receivedGifts.rawList;

    return new Promise((resolve, reject) => {
        ping({ request: callApi, initialState, check: checkApiResponseDataEquals(initialState) })
            .then(({ data }) => resolve(dispatch(receivedGiftsUpdateSuccess(data))))
            .catch((error) => reject(dispatch(receivedGiftsUpdateFailure(error))));
    });
};
