import React, { useCallback, useState } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { getNameById } from 'reactApp/modules/file/utils';

import { IProps } from './SharingNewReject.types';

export const SharingNewReject: React.FC<IProps> = ({ info, onClose, onReject }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const folderName = info.name || getNameById(info.home || '');

    const onConfirm = useCallback(() => {
        setLoading(true);
        onReject();
    }, [onReject]);

    const renderHeader = useCallback(() => <div>Отклонить приглашение</div>, []);

    const renderContent = useCallback(() => {
        return (
            <div>
                Вы действительно хотите отказаться от доступа {info.access === 'read_only' ? '(только просмотр) ' : '(редактирование) '}
                к&nbsp;папке «{folderName}» пользователя «{info.owner.email}»?
            </div>
        );
    }, [info.access, folderName, info.owner.email]);

    return (
        <BaseConfirmDialog
            renderHeader={renderHeader}
            successButtonText="Отказаться"
            cancelButtonText="Отменить"
            buttons={[EButtonType.accept, EButtonType.cancel]}
            onClose={onClose}
            onSuccess={onConfirm}
            renderContent={renderContent}
            loading={loading}
        />
    );
};
