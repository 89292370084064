import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon20MoreVertical } from '@vkontakte/icons';
import { Caption, Headline, Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_PROMO, IS_ACTION_USER, IS_AUTO_TEST_PROMO } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { abDecreaseBaseQuota, quotaTooltip, summerPromotion } from 'reactApp/appHelpers/featuresHelpers';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { canShowUserQuota } from 'reactApp/modules/family/family.selectors';
import { getReceivedGiftsLoadingState, hasAutoupload10TbGift } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { useCallbacks } from 'reactApp/ui/Space/SingleSpace/hooks/useCallbacks';
import { useContent } from 'reactApp/ui/Space/SingleSpace/hooks/useContent';
import { useQuotaInfo } from 'reactApp/ui/Space/SingleSpace/hooks/useQuotaInfo';
import { useSendSpaceAnalytics } from 'reactApp/ui/Space/SingleSpace/SingleMenu.analytics';
import { SingleSpaceMenu } from 'reactApp/ui/Space/SingleSpace/SingleSpaceMenu/SingleSpaceMenu';
import { SingleSpaceProgress } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/SingleSpaceProgress';
import { useSpace } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/useSpace';
import { useUserAndFamilySpace } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/useUserAndFamilySpace';
import { Props } from 'reactApp/ui/Space/Space.types';
import { SpaceCountdown } from 'reactApp/ui/Space/SpaceCountdown/SpaceCountdown';
import { SpaceLoader } from 'reactApp/ui/Space/SpaceLoader/SpaceLoader';
import { sendDwh } from 'reactApp/utils/ga';
import { noopVoid } from 'reactApp/utils/helpers';

import { QuotaTooltip } from './QuotaTooltip/QuotaTooltip';
import styles from './SingleSpace.css';

export const SingleSpace = memo<Props>(({ isSubscriptionsPage = false, isMobile = false, className }): ReactElement => {
    const showQuotaWithFamily = useSelector(canShowUserQuota);

    const { goToSubscriptions } = useGoToSubscriptions();

    const { total, usedTotal, isQuotaLoaded, isFull } = useQuotaInfo(showQuotaWithFamily);

    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isNewbie = useSelector(UserSelectors.isNewbie);
    const isPaid = useSelector(UserSelectors.isPaidUser);
    const isDefaultContent = useSelector(isDefaultRootContent);
    const withTooltip = quotaTooltip && quotaTooltip !== 'a' && quotaTooltip !== 'd' && !isDefaultContent;

    // tempexp_16481-start
    const has10TbAutoupload = useSelector(hasAutoupload10TbGift);
    const totalQuota = has10TbAutoupload && total?.original ? bytesToNDigits(total.original - SPACE_LIST.tb_10, 3).value : total?.value;
    const { isLoaded: giftsLoaded } = useSelector(getReceivedGiftsLoadingState);
    const areGiftsLoaded = isPaid ? giftsLoaded : true;
    // tempexp_16481-end

    const countdownPromo = useSelector(PromoSelectors.getPromo(EPromoType.space));

    const isMarketingPromo = IS_ACTION_USER && !isFull && (!!ACTION_PROMO || IS_AUTO_TEST_PROMO);

    const isCountdownPromo = Boolean(countdownPromo) && !isSubscriptionsPage;
    const { warning, buttonText, buttonState, hideButton, isMySubscription, hideMenu, showFamilyButton } = useContent({
        isMarketingPromo,
        isSubscriptionsPage,
    });
    const sendAnalytics = useSendSpaceAnalytics({
        isCountdownPromo,
        isMarketingPromo,
        skipAnalytics: hideButton || isBizUser,
        summerPromotion,
    });
    const { onPayment, onFamilyClick, updateQuota } = useCallbacks({
        isMobile,
        countdownTariff: (isCountdownPromo && countdownPromo?.data?.productId) || '',
        goToSubscriptions,
        sendAnalytics,
        isMarketingPromo,
        showFamilyButton,
    });

    const wasShown = useRef<boolean>(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const [isShowMenu, setShowMenu] = useState<boolean>(false);

    const showMenu = () => {
        if (isBizUser) {
            return;
        }

        setShowMenu(true);
    };

    const closeMenu = (event?: MouseEvent) => {
        event?.stopPropagation();
        setShowMenu(false);
    };

    const onButtonClick = useCallback(
        (event) => {
            event?.stopPropagation();
            closeMenu(event);
            if (showFamilyButton) {
                onFamilyClick?.();
            } else {
                onPayment();
            }
            if (quotaTooltip) {
                emitAnalyticEvent(AnalyticEventNames.QUOTA_BUTTON_CLICK);
                sendDwh({ eventCategory: 'quota-tooltip_quota-button-click' });
            }
        },
        [onPayment, onFamilyClick, showFamilyButton]
    );

    const button = useMemo(
        () => (
            <div className={styles.buttonBlock}>
                <Spacing size={16} />
                <div className={styles.buttonWrapper} ref={buttonRef}>
                    <Button
                        {...buttonState}
                        fluid
                        theme="octavius"
                        sizeMode={isMobile ? ButtonSizeMode.big : ButtonSizeMode.small}
                        onClick={isCountdownPromo ? noopVoid : onButtonClick}
                    >
                        <div className={styles.buttonText}>{buttonText}</div>
                    </Button>
                </div>
            </div>
        ),
        [buttonState, buttonText, isCountdownPromo, isMarketingPromo, isMobile, onButtonClick]
    );

    const tooltip = (
        <QuotaTooltip
            targetRef={buttonRef}
            onClose={() => {
                emitAnalyticEvent(AnalyticEventNames.QUOTA_TOOLTIP_CLOSE);
                sendDwh({ eventCategory: 'quota-tooltip_quota-tooltip-close' });
            }}
            onShow={() => {
                emitAnalyticEvent(AnalyticEventNames.QUOTA_TOOLTIP_SHOW);
                sendDwh({ eventCategory: 'quota-tooltip_quota-tooltip-show' });
            }}
        />
    );
    useEffect(() => {
        if (isQuotaLoaded && !wasShown.current) {
            wasShown.current = true;

            sendAnalytics('view', false, summerPromotion ? 'summer_action' : undefined);

            if (isFull) {
                emitAnalyticEvent(AnalyticEventNames.QUOTA_BLOCK_OVER_VIEW);
                sendAnalytics('view', false, `overquota_space_${isPaid ? 'paid' : 'free'}`);
            }
        }
    }, [isQuotaLoaded, sendAnalytics, isFull, summerPromotion, isPaid]);

    useEffect(() => {
        if (wasShown.current && showQuotaWithFamily) {
            updateQuota();
        }
    }, [showQuotaWithFamily]);

    useEffect(() => {
        if (isMarketingPromo && !buttonState.error && quotaTooltip) {
            emitAnalyticEvent(AnalyticEventNames.QUOTA_BUTTON_SHOW);
            sendDwh({ eventCategory: 'quota-tooltip_quota-button-show' });
        }
    }, []); // CLOUDWEB-16521: Показываем скелетом до тех пор пока юзер не принял лс и пока не отработало присвоение новой квоты.
    // tempexp_16521-next-line
    if (!isQuotaLoaded || !areGiftsLoaded || (abDecreaseBaseQuota && isNewbie)) {
        return (
            <>
                <div className={classNames(styles.spaceBlock, styles.spaceBlock_loading)}>
                    <SpaceLoader withButton={!hideButton} isCompact />
                </div>
                <Spacing size={isMobile ? 4 : 8} />
            </>
        );
    }

    const containsDarkTheme = document.body.classList.contains(VK_UI_DARK_THEME);
    return (
        <div className={classNames(className)}>
            <div
                className={classNames(styles.spaceBlock, {
                    [styles.spaceBlock_overquota]: isFull,
                    [styles.spaceBlock_marketingPromo]: isMarketingPromo,
                    [styles.spaceBlock_summerPromo]: summerPromotion,
                    [styles.spaceBlock_summerPromoDarkTheme]: summerPromotion && containsDarkTheme,
                    [styles.spaceBlock_mobile]: isMobile,
                })}
                onClick={showMenu}
            >
                <div className={styles.info}>
                    <div className={styles.titleBlock}>
                        <Headline level={isMobile ? '1' : '2'}>
                            Занято {usedTotal?.value} из {totalQuota}
                        </Headline>
                        {!hideMenu && (
                            <div
                                className={classNames(styles.menuIcon, {
                                    [styles.menuIcon_active]: isShowMenu,
                                })}
                                ref={menuRef}
                                onClick={isShowMenu ? closeMenu : showMenu}
                            >
                                <Icon20MoreVertical />
                            </div>
                        )}
                    </div>
                    {/* tempexp_16481-next-line */}
                    {has10TbAutoupload && (
                        <Caption level="2" className={styles.autoupload}>
                            +10 ТБ для телефона
                        </Caption>
                    )}
                    <Spacing size={12} />
                    <SingleSpaceProgress
                        isWrap={showQuotaWithFamily}
                        isMobile={isMobile}
                        useSpaceHook={showQuotaWithFamily ? useUserAndFamilySpace : useSpace}
                    />
                    {Boolean(warning) && !isMobile && (
                        <>
                            <Spacing size={12} />
                            <div className={styles.warning}>{warning}</div>
                        </>
                    )}
                </div>
                {!hideButton && !isCountdownPromo && button}
                {!hideButton && !isCountdownPromo && isMarketingPromo && !buttonState.error && withTooltip && tooltip}
            </div>

            {isCountdownPromo && (
                <>
                    <Spacing size={12} />
                    <div className={styles.promo}>
                        <SpaceCountdown onClick={onButtonClick} button={button} />
                        {isMarketingPromo && !buttonState.error && withTooltip && tooltip}
                    </div>
                    <Spacing size={4} />
                </>
            )}

            <Spacing size={isMobile ? 4 : 8} />

            {isShowMenu && (
                <SingleSpaceMenu
                    isMobile={isMobile}
                    isSubscriptionsPage={isSubscriptionsPage}
                    parentRef={menuRef}
                    onClose={closeMenu}
                    isMySubscription={isMySubscription}
                />
            )}
        </div>
    );
});

SingleSpace.displayName = 'SingleSpace';
