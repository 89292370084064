import classNames from 'clsx';
import { xray } from 'lib/xray';
import throttle from 'lodash.throttle';
import React, { forwardRef, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_PROMO, IS_TABLET_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    abDecreaseBaseQuota,
    abEternalPromoYearTariffs,
    crossedFullPriceInTariffCard,
    favorableTariffs,
    horizontalTariffs,
    mobileUpsaleMonthTariffPromo,
    moreBenefitsInExpensiveTariffs,
    preferredTariffs,
    sendStartEventTimer,
    summerPromotion,
    yearlyPriceOnMonthlyTariffButtonTouch,
} from 'reactApp/appHelpers/featuresHelpers';
import { START_EVENT_TIMER_MS } from 'reactApp/constants';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { MediaSelectors } from 'reactApp/modules/media/media.selectors';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { showLandingTariffsAction } from 'reactApp/modules/quotaLanding/quotaLanding.module';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';
import { TariffCard } from 'reactApp/sections/MobileSubscriptionsPage/TariffCard/TariffCard';
import { MobileTariffCardTheme } from 'reactApp/sections/MobileSubscriptionsPage/TariffCard/TariffCard.types';
import { Container } from 'reactApp/sections/QuotaLanding/Container/Container';
import { useIntersecObsrWithParams } from 'reactApp/sections/QuotaLanding/hooks/useIntersecObsrWithParams';
import { ETabs } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { sendQuotaBlockViewDWH, sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { TariffPeriodSwitch } from 'reactApp/sections/QuotaLanding/TariffsList/TariffPeriodSwitch/TariffPeriodSwitch';
import { MobileBuyClick } from 'reactApp/sections/QuotaLanding/TariffsList/TariffsList.types';
import { TariffPlansCard } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/TariffPlansCard';
import { useBuyClick } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/useBuyClick';
import { getMaxTariffDiscount, getTariffsList } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffsPlans.selectors';
import { RootState } from 'reactApp/store';
import { SwiperNavigator } from 'reactApp/types/SwiperNavigator';
import { DiscountLabel } from 'reactApp/ui/DiscountLabel/DiscountLabel';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { sendIosFavorableTariffsAnalytics, sendProfitableTariffsFirstAnalytics } from 'reactApp/utils/analytics';
import { isMonthPeriod } from 'reactApp/utils/Period';
import { EffectCoverflow, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { MobileTariffCardLoader } from './MobileTariffCardLoader/MobileTariffCardLoader';
import { NavButtons } from './NavButtons/NavButtons';
import { TariffCardLoader } from './TariffCardLoader/TariffCardLoader';
import styles from './TariffsList.css';

interface TariffsPlansProps {
    changeTab?: (tab) => void;
    isIOSPromo?: boolean;
    isAndroidPromo?: boolean;
    setCarouselLoaded?: (state: boolean) => void;
    headerHeight?: number;
    isMobilePayAvailable: boolean;
    isMobilePayFromGetParam: boolean;
    isAnonymous: boolean;
    isTariffsPage?: boolean;
    isPhone?: boolean;
    autouploadPromo?: boolean;
    activeInitTab?: ETabsName;
}

const ACTION_PROMO_MAX_DISCOUNT = 80;

export const TariffsList = forwardRef<HTMLDivElement | null, TariffsPlansProps>(
    // eslint-disable-next-line max-lines-per-function
    (
        {
            isAnonymous,
            changeTab,
            isIOSPromo,
            isAndroidPromo,
            setCarouselLoaded,
            headerHeight = 0,
            isMobilePayAvailable,
            isMobilePayFromGetParam,
            isTariffsPage,
            isPhone = false,
            // tempexp_16481-next-line
            autouploadPromo = false,
            activeInitTab,
        },
        ref
    ): ReactElement => {
        const dispatch = useDispatch();

        const isMobileSelector = useSelector(QuotaLandingSelectors.isMobile);
        const isMobile = isPhone || isMobileSelector;
        const isTablet = useSelector(MediaSelectors.isTablet);

        const maxTariffDiscount = useSelector((state: RootState) => getMaxTariffDiscount(state, ACTION_PROMO ?? '', isMobile));
        let discount = maxTariffDiscount;
        // tempexp_16215-start
        const isEternalPromo = abEternalPromoYearTariffs.length;
        // tempexp_16373-next-line tempexp_16521-next-line
        const isFavorableTariffs = favorableTariffs.length && (!abDecreaseBaseQuota || abDecreaseBaseQuota === 'b');

        // tempexp_16373-next-line
        if (ACTION_PROMO && !isEternalPromo && !isFavorableTariffs) {
            discount = ACTION_PROMO_MAX_DISCOUNT;
        }
        // tempexp_16215-end

        const [activeTab, setActiveTab] = useState<ETabsName>(activeInitTab || ETabsName.year);
        const defaultAction = summerPromotion ? 'marketingPromo' : ''; // Хак для летней акции, так как на мобильных платформах не приходит подборка выгодных тарифов, получаем тарифы из прошлой промки
        const tariffList = useSelector((state: RootState) =>
            getTariffsList(state, ACTION_PROMO ?? defaultAction, autouploadPromo, isMobile)
        );
        const { isLoading, isLoaded } = useSelector(ProductsSelectors.getLifeCycleState);
        const firstLoad = useRef<boolean>(false);

        const [navState, setNavState] = useState({ isStart: true, isLast: false });
        const [navigator, setNavigator] = useState<SwiperNavigator | null>(null);

        const onBuyClick = useBuyClick({
            isMobilePayAvailable,
            isMobilePayFromGetParam,
            isIOSPromo,
            isAndroidPromo,
        });

        const onMobileBuyClick: MobileBuyClick = useCallback(
            (selectedProduct, yearProduct, yearSavingDiscount) => {
                const sendAnalytics = () => {
                    sendIosFavorableTariffsAnalytics(['buy_click']);
                    sendProfitableTariffsFirstAnalytics(['buy_click']);
                };

                if (isTariffsPage && mobileUpsaleMonthTariffPromo && isMonthPeriod(selectedProduct.period) && yearProduct) {
                    openPopupHelper({
                        popupName: popupNames.UPSALE_MONTH_TARIFF,
                        data: {
                            isMobile,
                            monthProduct: selectedProduct,
                            yearProduct,
                            yearSavingDiscount: yearSavingDiscount || 0,
                            activePeriod: mobileUpsaleMonthTariffPromo,
                            resolve: (productId: string, price: number) => {
                                // tempexp_16481-next-line
                                onBuyClick({
                                    productId,
                                    price,
                                    paySource: 'modal_choose_tariff',
                                    onClick: sendAnalytics,
                                    space: selectedProduct.space.space,
                                });
                            },
                        },
                    });
                } else {
                    onBuyClick({
                        productId: selectedProduct.id,
                        price: selectedProduct.price || 0,
                        onClick: sendAnalytics,
                        // tempexp_16481-next-line
                        space: selectedProduct.space.space,
                    });
                }
            },
            [onBuyClick, isMobile, isTariffsPage]
        );

        const updateNavState = useCallback(() => {
            setNavState({ isStart: navigator?.isFirst() as boolean, isLast: navigator?.isLast() as boolean });
        }, [navigator, setNavState]);

        const onClickSwitch = useCallback((tab) => {
            sendQuotaGa({ action: 'change', label: 'period', name_button: tab });
            setActiveTab(tab);
        }, []);

        const handleSetNavigator = useCallback(
            (navigator) => {
                setNavigator(navigator);
            },
            [setNavigator, updateNavState]
        );

        // отправляем событие с периодом из фичи или раз в 10 минут
        const sendStartViewEvent = throttle(() => {
            dispatch(showLandingTariffsAction());
        }, sendStartEventTimer || START_EVENT_TIMER_MS);

        const onIntersecting = useCallback(() => {
            if (sendStartEventTimer) {
                sendStartViewEvent();
            }

            emitAnalyticEvent(AnalyticEventNames.TARIFFS_SHOWN);
            sendQuotaGa({ action: 'tariff', label: 'view' });
            sendQuotaBlockViewDWH('tariffs');
            changeTab?.(ETabs.tariffs);
        }, [changeTab]);

        const refIntersection = useIntersecObsrWithParams({
            onIntersecting,
            isMobile,
            headerHeight,
        });

        useEffect(() => {
            xray.send('quota_show_tariffs_list');
        }, []);

        useEffect(() => {
            if (isLoaded && !firstLoad.current) {
                firstLoad.current = true;
            }
        }, [isLoaded]);

        useEffect(() => {
            updateNavState();
        }, [navigator, updateNavState, tariffList?.length]);

        const cardRef = useRef<HTMLDivElement>(null);
        const lgBreackPoint = tariffList.length * ((cardRef?.current?.scrollWidth ?? 0) + 16);
        const [lgBreakpointHit, _, setBreackPoint] = useMinWidthBreakpoint(lgBreackPoint);
        useEffect(() => {
            setBreackPoint(lgBreackPoint);
        }, [lgBreackPoint]);
        const displayInSwiper = !isFavorableTariffs || !lgBreakpointHit;

        /* tempexp_16800-start */
        const calcCardStyles = (style: string) =>
            classNames(style, {
                [styles.tariffCard_large]: moreBenefitsInExpensiveTariffs === 'c' || moreBenefitsInExpensiveTariffs === 'd',
            });
        /* tempexp_16800-end */

        const onSlideChange = useCallback(
            (swiper) => {
                const currentTariff = tariffList[swiper.realIndex];
                if (currentTariff) {
                    sendQuotaGa({
                        action: 'block-view',
                        blockName: currentTariff.space.space,
                        horizontalView: !!horizontalTariffs.tariffs?.length,
                    });
                }
            },
            [tariffList]
        );

        if (horizontalTariffs.tariffs?.length) {
            return (
                <div ref={refIntersection}>
                    <div ref={ref}>
                        <Swiper
                            effect={'coverflow'}
                            coverflowEffect={{
                                depth: 100,
                                rotate: 0,
                                slideShadows: false,
                            }}
                            modules={[EffectCoverflow]}
                            spaceBetween={32}
                            centeredSlides
                            loop
                            slidesPerView={1.2}
                            initialSlide={horizontalTariffs.default}
                            onSlideChange={onSlideChange}
                        >
                            {tariffList.map((tariff) => (
                                <SwiperSlide key={tariff.tariffId}>
                                    {({ isActive }) => (
                                        <TariffCard
                                            {...tariff}
                                            isCardActive={isActive}
                                            horizontalMobile
                                            onClick={onMobileBuyClick}
                                            alwaysExpand
                                            theme={
                                                isTariffsPage && !summerPromotion ? MobileTariffCardTheme.grey : MobileTariffCardTheme.white
                                            }
                                            isSingleQuota
                                            // tempexp_16215-next-line
                                            showPriceByMonth={isEternalPromo}
                                            skipBenefitsFeature={isTariffsPage}
                                            setCarouselLoaded={setCarouselLoaded}
                                            crossFullPrice={crossedFullPriceInTariffCard}
                                            // tempexp_16948-next-line
                                            moveYearlyFullPriceToMonthlyButton={yearlyPriceOnMonthlyTariffButtonTouch === 'b'}
                                        />
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <Container isNarrow>
                            {isLoading && !firstLoad.current && (
                                <div className={classNames(styles.mobileTariff, styles.swiperSkeletonWrapper)}>
                                    <MobileTariffCardLoader isExpand={true} isDark={isTariffsPage} />
                                </div>
                            )}
                        </Container>
                    </div>
                </div>
            );
        }

        if (isMobile) {
            return (
                <div ref={refIntersection}>
                    <div ref={ref}>
                        <Container isNarrow>
                            <div className={styles.mobileList}>
                                {tariffList.map((tariff, index) => (
                                    <div
                                        className={classNames(styles.mobileTariff, {
                                            [styles.summerPromo]: summerPromotion,
                                        })}
                                        key={tariff.products?.[0].id}
                                    >
                                        <TariffCard
                                            {...tariff}
                                            onClick={onMobileBuyClick}
                                            openAbCard={index === 0 || isTablet || IS_TABLET_BROWSER}
                                            alwaysExpand={isTablet || IS_TABLET_BROWSER}
                                            theme={
                                                isTariffsPage && !summerPromotion ? MobileTariffCardTheme.grey : MobileTariffCardTheme.white
                                            }
                                            isSingleQuota
                                            // tempexp_16215-next-line
                                            showPriceByMonth={isEternalPromo || yearlyPriceOnMonthlyTariffButtonTouch === 'c'}
                                            skipBenefitsFeature={isTariffsPage}
                                            tariffTypeLabel={
                                                summerPromotion && tariff.space.original === SPACE_LIST.gb_256 ? 'Выгодно' : undefined
                                            }
                                            setCarouselLoaded={setCarouselLoaded}
                                            crossFullPrice={crossedFullPriceInTariffCard}
                                            // tempexp_17020-start
                                            showPriceByYear={yearlyPriceOnMonthlyTariffButtonTouch === 'b'}
                                            // tempexp_17020-start
                                            skipDiscountPrice={Boolean(preferredTariffs.length)}
                                        />
                                    </div>
                                ))}
                                {isLoading &&
                                    !firstLoad.current &&
                                    Array.from(new Array(6)).map((_, idx) => (
                                        <div className={styles.mobileTariff} key={`mobileCard_${idx}`}>
                                            <MobileTariffCardLoader isExpand={idx === 0 || Boolean(isTablet)} isDark={isTariffsPage} />
                                        </div>
                                    ))}
                            </div>
                        </Container>
                    </div>
                </div>
            );
        }

        let desktopLoadersCount = favorableTariffs.length;
        if (summerPromotion) {
            desktopLoadersCount++;
        }

        return (
            <div ref={refIntersection}>
                <div
                    className={classNames(styles.root, { [styles.root_anonymous]: isAnonymous, [styles.root_compact]: !lgBreakpointHit })}
                    ref={ref}
                >
                    <div className={styles.header}>
                        <div className={styles.switch}>
                            {Boolean(discount) && Boolean(ACTION_PROMO) && (
                                <div className={styles.discountLabel}>
                                    <DiscountLabel discount={discount} isAction={Boolean(ACTION_PROMO)} />
                                </div>
                            )}
                            {Boolean(discount) && !ACTION_PROMO && (summerPromotion || activeTab === ETabsName.year) && (
                                <div
                                    className={classNames(styles.savingLabel, {
                                        [styles.accentBackground]: summerPromotion,
                                    })}
                                >
                                    -{discount}%
                                </div>
                            )}
                            <TariffPeriodSwitch checkedOption={activeTab} onClickSwitch={onClickSwitch} />
                        </div>
                        {/* tempexp_16373-next-line */}
                        {displayInSwiper && (
                            <div className={styles.navButtons}>
                                <NavButtons
                                    isLast={navState.isLast}
                                    isStart={navState.isStart}
                                    onNext={navigator?.navigateToNext}
                                    onPrev={navigator?.navigateToPrev}
                                    updateNavState={updateNavState}
                                />
                            </div>
                        )}
                    </div>

                    {/* tempexp_16373-next-line */}
                    {!displayInSwiper ? (
                        <div className={styles.favorableTariffsList}>
                            {tariffList.map((tariff, idx) => (
                                <div ref={cardRef} className={calcCardStyles(styles.favorableTariffCard)} key={idx}>
                                    <TariffPlansCard
                                        activeTab={activeTab}
                                        tariff={tariff}
                                        isIOSPromo={isIOSPromo}
                                        isAndroidPromo={isAndroidPromo}
                                        isMobilePayAvailable={isMobilePayAvailable}
                                        isMobilePayFromGetParam={isMobilePayFromGetParam}
                                        setCarouselLoaded={setCarouselLoaded}
                                        isNewLanding
                                    />
                                </div>
                            ))}
                            {isLoading &&
                                !firstLoad.current &&
                                Array.from(new Array(desktopLoadersCount)).map((_, idx) => (
                                    <div ref={cardRef} className={calcCardStyles(styles.favorableTariffCard)} key={`tariffCard_${idx}`}>
                                        <TariffCardLoader />
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div className={styles.list}>
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={16}
                                threshold={20}
                                pagination={{
                                    clickable: true,
                                }}
                                onSwiper={(swiper) => {
                                    handleSetNavigator({
                                        navigateToNext: () => swiper?.slideTo(swiper?.activeIndex + 2),
                                        navigateToPrev: () => swiper?.slideTo(swiper?.activeIndex - 2),
                                        isFirst: () => swiper?.isBeginning,
                                        isLast: () => swiper?.isEnd,
                                    });
                                }}
                                onActiveIndexChange={updateNavState}
                                direction="horizontal"
                                mousewheel={{
                                    forceToAxis: true,
                                    sensitivity: 2,
                                }}
                                modules={[Mousewheel]}
                            >
                                {tariffList.map((tariff, idx) => (
                                    <SwiperSlide key={tariff.tariffId} virtualIndex={idx} className={calcCardStyles(styles.slide)}>
                                        {() => {
                                            return (
                                                <div ref={cardRef} className={calcCardStyles(styles.carouselCard)}>
                                                    <TariffPlansCard
                                                        activeTab={activeTab}
                                                        tariff={tariff}
                                                        isIOSPromo={isIOSPromo}
                                                        isAndroidPromo={isAndroidPromo}
                                                        isMobilePayAvailable={isMobilePayAvailable}
                                                        isMobilePayFromGetParam={isMobilePayFromGetParam}
                                                        setCarouselLoaded={setCarouselLoaded}
                                                        isNewLanding
                                                    />
                                                </div>
                                            );
                                        }}
                                    </SwiperSlide>
                                ))}
                                {isLoading &&
                                    !firstLoad.current &&
                                    Array.from(new Array(6)).map((_, idx) => (
                                        <SwiperSlide
                                            key={`tariffCard_${idx}`}
                                            virtualIndex={idx + 1}
                                            className={calcCardStyles(styles.slide)}
                                        >
                                            {() => {
                                                return (
                                                    <div ref={cardRef} className={calcCardStyles(styles.carouselCard)}>
                                                        <TariffCardLoader />
                                                    </div>
                                                );
                                            }}
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

TariffsList.displayName = 'TariffsList';
