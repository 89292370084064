import { parse, stringify } from 'qs';
import React, { memo, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendPromocodesAnalytics } from 'reactApp/ui/PresentLanding/PresentLanding.analytics';
import { activatePromocode } from 'reactApp/ui/PresentLanding/PresentLanding.helpers';

// костыль только для аб
/** tempexp_150202-start */
export const PresentLandingReloader = ({ location }) => {
    useEffect(() => {
        window.location.assign(`/promo/present${location.search}`);
    }, []);

    return <></>;
};
/** tempexp_150202-end */

export const PresentLanding = memo(function PresentLanding({ type }: { type: 'mail' | 'cloud' }): ReactElement {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const email = useSelector(UserSelectors.getEmail);
    const dispatch = useDispatch();

    useEffect(() => {
        sendPromocodesAnalytics({ action: 'open', version: 'new', product: type });

        if ((isAnonymous || !email) && type === 'cloud') {
            const query = parse(window.location.search, { ignoreQueryPrefix: true }) || {};
            const { action } = query;
            const params = Object.keys(query).length ? `&${stringify(query)}` : '';
            const actionActivate = !action ? `${params ? '&' : '?'}action=activate` : '';
            const successPage = `${window.location.href}${actionActivate}`;

            dispatch(
                authPopup({
                    closable: false,
                    loginRequest: true,
                    successPage,
                })
            );
        }

        (window as any).activatePromocode = activatePromocode;
        (window as any).sendLandingAnalytics = sendPromocodesAnalytics;
    }, [type, isAnonymous, email, dispatch]);

    return <div id="react-modals" />;
});
