/* eslint-disable max-lines-per-function */
import { EditorID } from 'Cloud/Application/Editor/types';
import classNames from 'clsx';
import EmptyAllDocsIcon from 'img/icons/emptyAllDocs.png';
import { ReactComponent as EmptyAttachIcon } from 'img/icons/emptyAttach.svg';
import { ReactComponent as EmptyBinIcon } from 'img/icons/emptyBin.svg';
import EmptyBinNewIcon from 'img/icons/emptyBinNew.png';
import { ReactComponent as LoopIcon } from 'img/icons/loop.svg';
import { ReactComponent as EmptyFolderIcon } from 'img/icons/uploadStroke.svg';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EDITORS_CONFIG, ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { useViewContext } from 'reactApp/hocs/withViewContext';
import { useIsTutoriaIntegration } from 'reactApp/hooks/useIsTutoriaIntegration';
import { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { getBizCurrentCategory } from 'reactApp/modules/bizCategories/bizCategories.selectors';
import { BizCategoryType } from 'reactApp/modules/bizCategories/bizCategories.types';
import { getDomainFoldersFilterActive } from 'reactApp/modules/home/home.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { setButtonTypeClickAction } from 'reactApp/modules/upload/upload.module';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { LinkIcon } from 'reactApp/ui/VKUIIcons';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';
import { sendGa } from 'reactApp/utils/ga';

import styles from './Empty.css';
import { Empty as EmptyNew } from './new/Empty';

interface HomeProps {
    type: 'folder';
    storage: EStorageType;
    canUpload?: boolean;
    canCoshare?: boolean;
    weblink?: string;
    id?: string;
    currentAllDocsType?: EAllDocumentsType | null;
    isNewTrashbin?: boolean;
    domainFoldersFilterActive?: boolean;
}

type Props = {
    viewMode?: EViewMode;
} & (
    | HomeProps
    | {
          type: 'gallery';
          searchType?: string;
          storage: EStorageType;
          id?: string;
          canUpload?: boolean;
          isNewTrashbin?: boolean;
      }
);

const getDocTypeName = (type?: EAllDocumentsType | null, isPlural = true) => {
    switch (type) {
        case EAllDocumentsType.document:
            return isPlural ? 'документы' : 'документ';
        case EAllDocumentsType.spreadsheet:
            return isPlural ? 'таблицы' : 'таблицу';
        case EAllDocumentsType.presentation:
            return isPlural ? 'презентации' : 'презентацию';
        case EAllDocumentsType.pdf:
            return isPlural ? 'PDF-файлы' : 'PDF-файл';
        default:
            return '';
    }
};

const getDocTypeText = (type?: EAllDocumentsType | null) => {
    switch (type) {
        case EAllDocumentsType.document:
            return 'Редактируйте их онлайн вместе\nс другими людьми';
        case EAllDocumentsType.spreadsheet:
            return 'Редактируйте их онлайн вместе\nс другими людьми';
        case EAllDocumentsType.presentation:
            return 'Редактируйте их онлайн';
        case EAllDocumentsType.pdf:
            return 'Загрузите файлы, и они станут доступны\nдля редактирования';
        default:
            return '';
    }
};

const getIcon = (props: Props) => {
    const { storage, isNewTrashbin, canUpload } = props;

    if (storage === EStorageType.feed || storage === EStorageType.quotaCleaner) {
        return <LoopIcon />;
    }

    if (storage === EStorageType.trashbin) {
        return isNewTrashbin ? <img src={EmptyBinNewIcon} alt="Пустая корзина" width="310" /> : <EmptyBinIcon />;
    } else if (storage === EStorageType.attaches) {
        return <EmptyAttachIcon />;
    } else if (storage === EStorageType.gallery) {
        return null;
    } else if (storage === EStorageType.alldocuments) {
        return <img src={EmptyAllDocsIcon} alt="Пустой раздел документов" width="280" />;
    } else if (canUpload) {
        return <EmptyFolderIcon />;
    }
};

const getTitle = (props: Props, bizCurrentCategory?: BizCategoryType) => {
    const bizTitle = 'По выбранным параметрам ничего не найдено';
    const emptyBizCategory = !bizCurrentCategory || bizCurrentCategory === BizCategoryType.all;

    if (props.type === 'gallery') {
        const { searchType } = props;

        if (!emptyBizCategory) {
            return bizTitle;
        }

        return searchType === 'mobile' ? 'Сейчас у вас нет мобильных фотографий' : 'Сейчас у вас нет фотографий';
    }

    const { storage, currentAllDocsType, isNewTrashbin } = props;

    if (!emptyBizCategory && !props.domainFoldersFilterActive) {
        return bizTitle;
    }

    if (storage === EStorageType.trashbin) {
        return isNewTrashbin ? 'В корзине пусто' : 'Корзина пуста';
    } else if (storage === EStorageType.attaches) {
        return 'Сейчас у вас нет файлов из почты';
    } else if (storage === EStorageType.feed) {
        return 'По запросу ничего не найдено';
    } else if (storage === EStorageType.alldocuments) {
        return `${currentAllDocsType === EAllDocumentsType.pdf ? 'Редактируйте' : 'Создавайте'} ${getDocTypeName(currentAllDocsType)}`;
    } else if (storage === EStorageType.sharedIncoming) {
        return (
            <>
                С вами пока ничем не поделились
                <div
                    className={classNames(styles.text, {
                        [styles.textResponsive]: ENABLE_FULL_RESPONSIVE,
                    })}
                >
                    здесь появятся файлы и папки, которыми с вами поделятся
                </div>
            </>
        );
    }
    return 'Папка пока пуста';
};

const getText = (props: Props, onUploadClick, inputRef) => {
    const { storage, canUpload, isNewTrashbin } = props;

    if (storage === EStorageType.trashbin) {
        return isNewTrashbin
            ? 'Перенесите ненужные документы в корзину.\nФайлы хранятся тут 14 дней, а затем удаляются'
            : 'Вы всегда можете удалить файлы из корзины, чтобы освободить место';
    } else if (storage === EStorageType.attaches) {
        return 'Все файлы из ваших почтовых переписок будут автоматически попадать сюда';
    } else if (storage === EStorageType.alldocuments && props.type === 'folder') {
        return getDocTypeText(props.currentAllDocsType);
    } else if (canUpload) {
        return (
            <>
                Перенесите файлы сюда или нажмите
                <br />
                на кнопку{' '}
                <ButtonLink onClick={onUploadClick} fontInherit primary>
                    Загрузить
                </ButtonLink>
                <input type="file" multiple className={styles.input} ref={inputRef} />
            </>
        );
    }
};

const CreateTypeMap = {
    document: 'docx',
    spreadsheet: 'xlsx',
    presentation: 'pptx',
};

export const EmptyComponent = memo((props: Props): ReactElement => {
    const { storage = EStorageType.search, isNewTrashbin, type, viewMode } = props;
    const { canCoshare, weblink, id = '', currentAllDocsType } = props as HomeProps;
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const domainFoldersFilterActive = useSelector(getDomainFoldersFilterActive);
    const viewContext = useViewContext();
    const bizCurrentCategory = useSelector(getBizCurrentCategory);
    const isR7Editor = EDITORS_CONFIG[0]?.id === EditorID.R7_WOPI || EDITORS_CONFIG[0]?.id === EditorID.R7;
    const canCreatePresentation =
        isR7Editor && EDITORS_CONFIG[0]?.edit_extensions.includes('pptx') && EDITORS_CONFIG[0]?.create_extensions.includes('pptx');

    useEffect(() => {
        sendEmptyStatesAnalitics({ action: 'show' });
    }, []);

    const canCreateDoc =
        storage === EStorageType.alldocuments &&
        (currentAllDocsType === EAllDocumentsType.document ||
            currentAllDocsType === EAllDocumentsType.spreadsheet ||
            (currentAllDocsType === EAllDocumentsType.presentation && canCreatePresentation));

    const isTutoriaIntegration = useIsTutoriaIntegration();

    const onUploadClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, [inputRef.current]);

    const handlePublishing = useCallback(() => {
        sendGa('empty-foldr', 'click', 'publish');
        publishHelper({ item: { id } });
    }, []);

    const handleCopy = useCallback(() => {}, []);

    const handleCreateDocs = useCallback(
        () => toolbarActions.create(currentAllDocsType ? CreateTypeMap[currentAllDocsType] : 'docx', 'empty', true, 'docs'),
        []
    );

    const { subscribeToInput, unsubscribeOfInput } = useUploadInputHandlers();

    const fileIconType = useMemo(() => {
        if (storage === EStorageType.gallery) {
            return 'image';
        }

        if (viewContext.isVKEmbedded) {
            return type;
        }

        return null;
    }, [storage, type, viewContext.isVKEmbedded]);

    useEffect(() => {
        subscribeToInput(inputRef.current);

        return () => {
            unsubscribeOfInput(inputRef.current);
        };
    }, ['canUpload' in props && props.canUpload]);

    useEffect(() => {
        sendGa('empty-foldr', 'show');
        dispatch(setButtonTypeClickAction('click'));
    }, [id]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.rootNewBin]: isNewTrashbin,
                [styles.rootEmbedded]: viewContext.isVKEmbedded,
                [styles.rootIntegration]: isTutoriaIntegration,
            })}
        >
            <div className={styles.file}>
                {storage !== EStorageType.alldocuments && !isNewTrashbin && (
                    <FileIcon className={styles.unknownIconUrl} view={viewMode} type={fileIconType} />
                )}
                <div className={styles.icon}>{getIcon(props)}</div>
            </div>
            <div className={classNames(styles.title, { [styles.titleEmbedded]: viewContext.isVKEmbedded })}>
                {getTitle(props, bizCurrentCategory)}
            </div>
            <div
                className={classNames(styles.text, {
                    [styles.textResponsive]: ENABLE_FULL_RESPONSIVE,
                    [styles.textIntegration]: isTutoriaIntegration,
                })}
            >
                {getText(props, onUploadClick, inputRef)}
            </div>
            {storage === EStorageType.home && !bizCurrentCategory && canCoshare && !domainFoldersFilterActive && (
                <div className={styles.controls}>
                    {weblink ? (
                        <CopyWeblink publicUrl={weblink} onCopy={handleCopy} onClick={handlePublishing} theme="folder" />
                    ) : (
                        <>
                            <div className={styles.link}>
                                <LinkIcon />
                            </div>
                            <div className={styles.publish} onClick={handlePublishing}>
                                Поделиться папкой по ссылке
                            </div>
                        </>
                    )}
                </div>
            )}
            {canCreateDoc && (
                <div className={styles.controls}>
                    <Button theme="vk" sizeMode={ButtonSizeMode.small} onClick={handleCreateDocs}>
                        Создать {getDocTypeName(currentAllDocsType, false)}
                    </Button>
                </div>
            )}
        </div>
    );
});

EmptyComponent.displayName = 'Empty';

export const Empty = Object.assign(EmptyComponent, { New: EmptyNew });
