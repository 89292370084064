import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { loadUserQuotaRequest } from 'reactApp/modules/userQuota/userQuota.module';
import {
    loadUserQuotaCleanerList,
    resetUserQuotaCurrentGroup,
    setMessagesRemove,
} from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { getNeedGroupsReload, getYearCurrentGroupConfig } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.selectors';
import { quotaCleanerPageManager } from 'reactApp/ui/QuotaCleanerPage/quotaCleanerPage.toolkit';
import { sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useCloseCleanerPage = () => {
    const dispatch = useDispatch();
    const needGroupsReload = useSelector(getNeedGroupsReload);
    const yearConfig = useSelector(getYearCurrentGroupConfig);

    const onClose = useCallback(() => {
        sendPaymentGa({ eventCategory: 'cleaner-page', action: 'back-button', label: 'click' });

        quotaCleanerPageManager.close();
        dispatch(resetUserQuotaCurrentGroup());
        dispatch(resetSelect());
        dispatch(setMessagesRemove({ value: false }));

        // обвновляем список групп по флагу или если группа с фильтрами по годам
        if (needGroupsReload || yearConfig) {
            dispatch(loadUserQuotaRequest());
            dispatch(loadUserQuotaCleanerList());
        }
    }, [dispatch, needGroupsReload, yearConfig]);

    const forceClose = useCallback(() => {
        quotaCleanerPageManager.close();
        dispatch(resetUserQuotaCurrentGroup());
        dispatch(resetSelect());
        dispatch(setMessagesRemove({ value: false }));
    }, [dispatch]);

    return { onClose, forceClose };
};
