import hotkeys from 'hotkeys-js';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_FREE_BIZ_SAAS_USER } from 'reactApp/appHelpers/configHelpers';
import { welcomeTrialParamsNew, welcomeTrialParamsOverquota } from 'reactApp/appHelpers/featuresHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { KeyCodes } from 'reactApp/constants/KeyCodes';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { useGoToSubscriptions } from 'reactApp/hooks/useGoToSubscriptions';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { loadPaidInfoRequest } from 'reactApp/modules/paidInfo/paidInfo.module';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { initProducts } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { hideWelcomePromo, showWelcomePromo } from 'reactApp/modules/welcome/welcome.module';
import { sendGa } from 'reactApp/utils/ga';

import { gaHotkeyCategory } from '../Datalist/DataList.constants';

const HOTKEY_SCOPE = 'welcomepromo';

const useHandleHotkeys = () => {
    const isModalOpen = useSelector(isDialogVisible);
    const storage = useSelector(getCurrentStorage) as EStorageType;

    useEffect(() => {
        if (isModalOpen || IS_FREE_BIZ_SAAS_USER) {
            return;
        }

        hotkeys.setScope(HOTKEY_SCOPE);

        hotkeys('shift+f,shift+t,shift+s', HOTKEY_SCOPE, (e) => {
            e.preventDefault();
            const actionTypes = {
                [KeyCodes.DOM_VK_F]: 'folder',
                [KeyCodes.DOM_VK_T]: 'docx',
                [KeyCodes.DOM_VK_S]: 'xlsx',
            };
            const type = actionTypes[e.which || e.keyCode];
            toolbarActions.create(type, HOTKEY_SCOPE, false);
            sendGa(gaHotkeyCategory, `shift+${type}`);
            sendGa('hotkey', `shift+${type}`);
        });

        return () => {
            hotkeys.deleteScope(HOTKEY_SCOPE);
        };
    }, [storage, isModalOpen]);
};

export const useWelcomePromoLogic = ({ forNewUser = false, isOverquota = false }) => {
    const dispatch = useDispatch();

    let TRIAL_DATA = welcomeTrialParamsOverquota;
    if (forNewUser) {
        TRIAL_DATA = welcomeTrialParamsNew;
    }

    const isB2BUser = useSelector(UserSelectors.isB2BUser);
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const isUserWithoutPayment = useSelector(PaidInfoSelectors.isUserWithoutPayment);
    const isTrialAvailable = useSelector((state) => ProductsSelectors.isTrialAvailable(state, TRIAL_DATA.productId));

    const showTrial = !isB2BUser && !isPaidUser && isTrialAvailable && isUserWithoutPayment;
    const trialId = showTrial ? TRIAL_DATA.productId : null;
    const showSubscriptions = !isB2BUser;
    const trialData = showTrial ? TRIAL_DATA : null;

    const { goToSubscriptions } = useGoToSubscriptions();

    const handleSubscriptionsClick = useCallback(
        () => goToSubscriptions({ query: isOverquota ? PaymentUTM.welcomeOverquotaTariffs : PaymentUTM.welcomePromo }),
        [goToSubscriptions]
    );

    useHandleHotkeys();

    useEffect(() => {
        dispatch(showWelcomePromo());
        /* Убираем промку, так как WelcomePromo приоритетнее */
        dispatch(removePromo(EPromoType.space));

        if (!isB2BUser && !isPaidUser) {
            dispatch(loadPaidInfoRequest());
            dispatch(initProducts());
        }

        sendGa('welcome-promo', 'show');

        return () => {
            dispatch(hideWelcomePromo());
        };
    }, []);

    return { trialId, handleSubscriptionsClick, showSubscriptions, trialData };
};
