import config from 'Cloud/config';
import { ACTION_PROMO, IS_B2B_BIZ_USER, IS_PHONE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { getUtmObject } from 'reactApp/appHelpers/settingsHelpers';
import { summerPromotionDwh } from 'reactApp/constants/dwh';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { ReceivedGiftsFetch } from 'reactApp/modules/giftReceived/giftReceived.constants';
import { getDiscountGiftList } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { getLoadingState } from 'reactApp/modules/loading/loading.selectors';
import { promoTariffsStart } from 'reactApp/modules/promoTariffs/promoTariffs.module';
import { sortDiscountPromoBySpace } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { store as reduxStore } from 'reactApp/store';
import { sendDwh } from 'reactApp/utils/ga';

export interface IPaymentGa extends Record<string, any> {
    action?: EPaymentGa | string;
    label?: string;
    group?: string;
    eventCategory?: string;
}

export enum EPaymentGa {
    space = 'standart-more-quota',
    spaceDiscount = 'discount-more-quota',
    spaceAction = 'action-quota',
    promocodesSection = 'section-promocodes',
    overquotaBanner = 'part-data-delete',
    overquotaMobile = 'overquota-mobile',
    expiredSubsMobile = 'expired-subs-mobile',
    quotaBanner = 'few-quota',
    offerBanner = 'present-gb',
    bigFileModal = 'heavy-file',
    filesHistory = 'history-files',
    filesHistoryPaid = 'history-files-paid',
    fastCheckout = 'fastcheckout',
    tariffsModal = 'popup-cloud-tariff',
    subscriptions = 'screen-cloud-tariff',
    trialBuyModal = 'popup-discount',
    payment = 'popup-payment',
    trialWindow = 'trial-window',
    saveInCloud = 'save-in-cloud',
    openPublic = 'open-public',
    popup = 'pop-up',
    touchSubs = 'touch-subs',
    openContent = 'open-content',
    close = 'close',
    unionQuota = 'union-quota',
    saveSubs = 'save-subs',
    saveSubsTransit = 'save-subs-transit',
    attachesTrial = 'attachesTrial',
    familySubscription = 'family-sub',
}

export enum ECategoryGa {
    payment = 'payment',
    entered = 'entered',
    basket = 'basket',
    favorites = 'favorites',
    new_year = 'new-year',
    nine_may = '9-may',
    favoritesSearch = 'favorites-search',
    public = 'public',
    publicSearch = 'public-search',
    download = 'download',
    downloadSearch = 'download-search',
    upload = 'upload',
    created = 'created',
    sharedFolder = 'shared-folder',
    banner = 'banner',
    storiesAb = 'stories_ab',
    onboarding = 'onboarding',
    toolbar = 'toolbar',
    toolbar_search = 'toolbar-search',
    disable_ads_button = 'disable-ads-button',
    splashscreen_alert = 'splashscreen_alert',
    album = 'album',
    unionQuota = 'union_quota',
    viewer = 'viewer',
    viewer_search = 'viewer-search',
    story = 'story',
    '2gb' = '2gb',
    search = 'search',
    search_history = 'search_history',
    securityShield = 'security-shield',
    security = 'security',
    search_results = 'search-results',
    pdfEditor = 'pdf-editor',
    pdfEditorSearch = 'pdf-editor-search',
    bell = 'bell',
    promoDiskO = 'promo-disko',
    kaspersky = 'kaspersky',
    choose = 'choose',
    quotaTariffs = 'union-quota',
    landingBirthday = 'landing-birthday',
    promocodes = 'landing-promocodes',
    docs = 'docs',
    addPhone = 'adding_phone_number',
    archiveSearch = 'archive-search',
    photoEditor = 'photo-editor',
    context_search = 'context-search',
    snack = 'snack',
    gpt = 'gpt',
    fakedoorSafe = 'fakedoor-safe',
    docsEditor = 'docs_editor',
    errorShow = 'error-show',
    editor = 'editor',
    ballon = 'ballon',
    informationAlert = 'information-alert',
}

const isPaidUser = config.get('PAID_USER');
const isProUser = config.get('IS_PRO_USER');
const isPhone = Boolean(config.get('PHONE_BROWSER'));
const userSpace = config.get('user')?.cloud?.space;

const isValue = (val) => {
    return val !== undefined && val !== null;
};

export const sendPaymentGa = ({ action, label, group = 'None', eventCategory = ECategoryGa.payment, ...restData }: IPaymentGa): void => {
    const state = reduxStore.getState();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isLoadingTrial = getLoadingState(state, [promoTariffsStart.type]);
    const trial = sortDiscountPromoBySpace(state)?.length;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isLoadingDiscount = getLoadingState(state, [ReceivedGiftsFetch.NAME]);
    const discount = getDiscountGiftList(state)?.length;

    const { place, source, ...rest } = Object.entries(restData).reduce(
        (acc, [key, value]) => (isValue(value) ? { ...acc, [key]: value } : acc),
        {} as typeof restData
    );

    const platform = isPhone ? 'touch' : 'web';

    const utms = getUtmObject();

    const dwhData = {
        size_quota: userSpace?.bytes_total,
        free_quota: userSpace?.bytes_total - userSpace?.bytes_used,
        free_user: !isPaidUser,
        paid_user: isPaidUser,
        pro_user: isProUser,
        campaign: !!ACTION_PROMO,
        promocode_trial: isLoadingTrial ? 'None' : !!trial,
        promocode_discount: isLoadingDiscount ? 'None' : !!discount,
        group,
        place: place ? `${place}-${platform}` : platform,
        source: source && isPhone ? `touch-${source}` : source || platform,
        isPhone,
        platform: IS_PHONE_BROWSER ? String(EnvironmentSelectors.getMobileType()).toLowerCase() : 'web',
        webview: IS_WEBVIEW,
        iz_biz: IS_B2B_BIZ_USER,
        ...summerPromotionDwh,
        ...utms,
        ...rest,
    };

    sendDwh({ eventCategory, action, label, dwhData });
};
