import React, { memo, useCallback, useState } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

interface IDeletePageModalProps {
    pageToDelete: number | number[];
}

const popupName = popupNames.DELETE_PAGE_REJECTED_MODAL;

export const DeletePageRejectedModal = memo(({ pageToDelete }: IDeletePageModalProps) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        closePopupHelper(popupName);
    }, [isOpen, setIsOpen]);

    const renderHeader = useCallback(() => <div>Нельзя удалять все страницы</div>, [pageToDelete]);

    const renderContent = useCallback(() => {
        return <div>Оставьте в документе хотя бы одну</div>;
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <BaseConfirmDialog
            dataQAId="delete-page-rejected-modal"
            renderHeader={renderHeader}
            successButtonText="Вернуться"
            buttons={[EButtonType.accept]}
            onSuccess={handleClose}
            renderContent={renderContent}
            onClose={handleClose}
            size={'small'}
        />
    );
});

DeletePageRejectedModal.displayName = 'DeletePageRejectedModal';
