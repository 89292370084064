import React from 'react';
import { FeatureCrossedFullPriceInTariffCard } from 'reactApp/appHelpers/featuresHelpers/features.types';
import { Product } from 'reactApp/types/Billing';
import { getFirstSomePeriodsAsWord, getPeriodName, isMonthPeriod, isYearPeriod } from 'reactApp/utils/Period';

export const useButtonAdditionalText = (product: Product, useDiscountPeriod?: FeatureCrossedFullPriceInTariffCard) => {
    const isMonth = isMonthPeriod(product.period);
    const isYear = isYearPeriod(product.period);

    if (isMonth && useDiscountPeriod?.month && product.discountPeriodCount) {
        return getFirstSomePeriodsAsWord(product.discountPeriodCount, 'm');
    }

    if (isYear && useDiscountPeriod?.year) {
        return getFirstSomePeriodsAsWord(product?.discountPeriodCount || 1, 'y');
    }

    return <>{isMonth ? '' : `при покупке на ${getPeriodName(product.period)}`}</>;
};
