import classNames from 'clsx';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { getErrorState } from 'reactApp/modules/error/error.selectors';
import { getFavorites } from 'reactApp/modules/favorites/favorites.actions';
import { EFavoritesFetch } from 'reactApp/modules/favorites/favorites.constants';
import { FavoritesSelectors } from 'reactApp/modules/favorites/favorites.selectors';
import { getLoadingState } from 'reactApp/modules/loading/loading.selectors';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import Content from 'reactApp/ui/Content/Content';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import BizPageTabMenu from 'reactApp/ui/DataListItemRow/components/BizPageTabMenu/BizPageTabMenu';
import { createGaSender } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './FavoritesPage.css';

const sendGa = createGaSender('favorites');

const mapStateToProps = (state) => {
    return {
        // @ts-ignore
        isLoading: getLoadingState(state, [EFavoritesFetch.NAME]),
        hasMoreToLoad: FavoritesSelectors.hasMoreToLoad(state),
        // @ts-ignore
        hasError: getErrorState(state, [EFavoritesFetch.NAME]),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getFavorites: (data = {}) => dispatch(getFavorites(data)),
});

interface IProps {
    initialItemId: string;

    getFavorites(): Promise<void>;

    hasError: boolean;
}

const FavoritesPageComponent: React.VFC<IProps> = ({ getFavorites, hasError, initialItemId }) => {
    const statistic = useSelector(FavoritesSelectors.getFavoritesStatistics);
    const showBizPageTabMenu = statistic.all > 0;

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'favorites' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'favorites' });

        getFavorites()
            .then(() => {
                sendGa('show');
            })
            .catch(() => {
                sendGa('show_fail');
            });
    }, []);

    return (
        <Content
            hasError={hasError}
            wrapClass={classNames(styles.root, {
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <div className={classNames({ [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                <BreadcrumbsContainer />
                {showBizPageTabMenu && <BizPageTabMenu noMarginBottom />}
            </div>
            <DataList initialItemId={initialItemId} selectableClassName={styles.datalistSelectable} />
        </Content>
    );
};

export const FavoritesPage = connect(mapStateToProps, mapDispatchToProps)(FavoritesPageComponent);
