import { Button } from '@vkontakte/vkui';
import AlbumIcon from 'img/empty-state/album.png';
import AttachesIcon from 'img/empty-state/attaches.png';
import FavoritesIcon from 'img/empty-state/favorites.png';
import FeedAllIcon from 'img/empty-state/feed_all.png';
import FeedDocumentIcon from 'img/empty-state/feed_document.png';
import FeedImageIcon from 'img/empty-state/feed_image.png';
import FeedMusicIcon from 'img/empty-state/feed_music.png';
import FeedVideoIcon from 'img/empty-state/feed_video.png';
import GalleryAllIcon from 'img/empty-state/gallery_all.png';
import GalleryMobileIcon from 'img/empty-state/gallery_mobile.png';
import SharedAutodeleteIcon from 'img/empty-state/shared-autodelete.png';
import SharedIncomingIcon from 'img/empty-state/shared-incoming.png';
import SharedLinkIcon from 'img/empty-state/shared-links.png';
import TrashIcon from 'img/empty-state/trash.png';
import React from 'react';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { FeedCategoryType } from 'reactApp/modules/feed/feed.types';
import { GalleryCategoryType } from 'reactApp/modules/gallery/gallery.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { onClickCreateNew } from 'reactApp/ui/Datalist/UserActions/onClickAction';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';

import { Albums } from './components/Albums';
import { SharedAutoDelete } from './components/SharedAutoDelete';
import styles from './Empty.css';
import { CategoryType, Config } from './Empty.types';

const configByStorage = new Map<EStorageType, Config | Map<CategoryType | undefined, Config>>()
    .set(EStorageType.sharedLinks, {
        icon: SharedLinkIcon,
        title: 'Делитесь с друзьями',
        description: 'Создавайте общие папки и отправляйте ссылки на файлы тем, с кем хотите поделиться',
        BottomContent: () => (
            <Button
                mode="secondary"
                appearance="neutral"
                size="m"
                className={styles.button}
                onClick={(e) => {
                    sendEmptyStatesAnalitics({ action: 'shared-links' });

                    onClickCreateNew(e, { gaSuffix: '', storage: EStorageType.sharedLinks });
                }}
            >
                Создать общую папку
            </Button>
        ),
    })
    .set(EStorageType.sharedIncoming, {
        icon: SharedIncomingIcon,
        title: 'Файлы, которыми с вами поделились',
        description: (
            <>
                Они все безопасны — их проверяет антивирус Касперского.{' '}
                <a
                    data-qa-id="empty-link"
                    href="https://whatsnew.mail.ru/promo/security?utm_source=cloud&utm_medium=emptystate&utm_campaign=sharedwithyou"
                    onClick={() => {
                        sendEmptyStatesAnalitics({ action: 'shared-incoming' });
                    }}
                >
                    Подробнее о безопасности
                </a>
            </>
        ),
    })
    .set(
        EStorageType.feed,
        new Map<CategoryType | undefined, Config>()
            .set(undefined, {
                icon: FeedAllIcon,
                // eslint-disable-next-line sonarjs/no-duplicate-string
                title: 'Загрузите первый файл',
                description: 'Здесь вы быстро найдёте всё, что добавили, в хронологическом порядке',
            })
            .set(FeedCategoryType.all, {
                icon: FeedAllIcon,
                title: 'Загрузите первый файл',
                description: 'Здесь вы быстро найдёте всё, что добавили, в хронологическом порядке',
            })
            .set(FeedCategoryType.image, {
                icon: FeedImageIcon,
                title: 'Фотографии, картинки, скриншоты',
                description: 'Загружайте любые изображения в Облако и быстро находите нужное по дате добавления',
            })
            .set(FeedCategoryType.document, {
                icon: FeedDocumentIcon,
                title: 'Документы, архивы, книги',
                description: 'Открывайте файлы в PDF, ZIP, EPUB и других форматах. Конвертеры не понадобятся!',
            })
            .set(FeedCategoryType.audio, {
                icon: FeedMusicIcon,
                title: 'Музыка, подкасты, аудиокниги',
                description: 'Слушайте аудио и плейлисты во всех популярных форматах',
            })
            .set(FeedCategoryType.video, {
                icon: FeedVideoIcon,
                title: 'Удобный видеоплеер',
                description: 'Смотрите видео разных форматов, управляйте качеством картинки и скоростью',
            })
    )
    .set(EStorageType.favorites, {
        icon: FavoritesIcon,
        title: 'Избранные файлы',
        description: 'Ставьте сердечко, чтобы выделить важные и просто любимые файлы',
        BottomContent: () => (
            <Button
                mode="secondary"
                appearance="neutral"
                size="m"
                className={styles.button}
                Component="a"
                href="/home"
                onClick={() => {
                    sendEmptyStatesAnalitics({ action: 'favorites-click' });
                }}
            >
                Ко всем файлам
            </Button>
        ),
    })
    .set(EStorageType.albums, {
        icon: AlbumIcon,
        title: 'Альбомы для фото и видео',
        description: 'Соберите тематическую коллекцию и поделитесь ссылкой на неё с близкими',
        BottomContent: Albums.BottomContent,
    })
    .set(EStorageType.attaches, {
        icon: AttachesIcon,
        title: 'Копии файлов из Почты',
        description: (
            <>
                Быстрый доступ к вложениям из писем в вашем ящике.{' '}
                <a data-qa-id="empty-link" href={getQuotaPromoUrl({ query: PaymentUTM.emptyAttaches })}>
                    Подробнее
                </a>
            </>
        ),
    })
    .set(EStorageType.trashbin, {
        icon: TrashIcon,
        title: 'В корзине пусто',
        description: 'Файлы хранятся в корзине 14 дней, а затем удаляются',
    })
    .set(EStorageType.sharedAutodelete, {
        icon: SharedAutodeleteIcon,
        title: 'Автоудаляемые папки',
        description: SharedAutoDelete.Description,
        BottomContent: SharedAutoDelete.BottomContent,
    });

export const useConfigByStorage = ({ isMobile }: { isMobile?: boolean }) => {
    if (isMobile) {
        configByStorage
            .set(EStorageType.gallery, {
                icon: GalleryAllIcon,
                // eslint-disable-next-line sonarjs/no-duplicate-string
                title: 'Галерея ваших фото и видео',
                description: 'Загружайте файлы с телефона, чтобы освободить память для новых снимков',
            })
            .set(EStorageType.home, {
                icon: FeedAllIcon,
                title: 'Загрузите первый файл',
                description: 'Чтобы быстро находить нужное, пользуйтесь сортировкой и поиском',
            });

        return configByStorage;
    }

    configByStorage
        .set(
            EStorageType.gallery,
            new Map<GalleryCategoryType | undefined, Config>()
                .set(GalleryCategoryType.all, {
                    icon: GalleryAllIcon,
                    title: 'Галерея ваших фото и видео',
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    description: 'Сохраняйте файлы с телефона и компьютера и смотрите истории с лучшими моментами',
                })
                .set(GalleryCategoryType.images, {
                    icon: GalleryAllIcon,
                    title: 'Галерея ваших фото и видео',
                    description: 'Сохраняйте файлы с телефона и компьютера и смотрите истории с лучшими моментами',
                })
                .set(GalleryCategoryType.videos, {
                    icon: GalleryAllIcon,
                    title: 'Галерея ваших фото и видео',
                    description: 'Сохраняйте файлы с телефона и компьютера и смотрите истории с лучшими моментами',
                })
                .set(GalleryCategoryType.desktop, {
                    icon: GalleryAllIcon,
                    title: 'Загружайте фото и видео',
                    description: 'Они будут надёжно защищены и доступны на всех ваших устройствах',
                })
                .set(GalleryCategoryType.mobile, {
                    icon: GalleryMobileIcon,
                    title: <>Установите Облако на&nbsp;смартфон</>,
                    description: 'Включите автозагрузку фото и видео, и в смартфоне появится больше свободного места',
                })
        )
        .set(EStorageType.documents, {
            icon: GalleryMobileIcon,
            title: <>Установите Облако на&nbsp;смартфон</>,
            description: 'Включите автозагрузку фото и видео, и в смартфоне появится больше свободного места',
        });

    return configByStorage;
};
