import { Button } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { FC, useCallback, useRef } from 'react';
import { Portal } from 'react-portal';
import { useSelector } from 'react-redux';
import { useDisclosure } from 'reactApp/hooks/useDisclosure';
import { getFeatureAbAutoDelete } from 'reactApp/modules/features/features.selectors';
import { isAutoDeleteEnabled } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { EmptyComponentProps } from 'reactApp/ui/Empty/new/Empty.types';
import { PromoProTooltip } from 'reactApp/ui/PromoProTooltip/PromoProTooltip';
import { createGaSender } from 'reactApp/utils/ga';

import { useCreateAutoDeleteFolderHandler } from '../hooks';
import styles from './BottomContent.css';

interface BottomContentProps extends EmptyComponentProps {
    className?: string;
}

const sendGa = createGaSender('autodelete-empty');

export const BottomContent: FC<BottomContentProps> = ({ className }) => {
    const { createFolder } = useCreateAutoDeleteFolderHandler({ sendGa });

    const promoProDisclosure = useDisclosure();
    const autodeleteAb = useSelector(getFeatureAbAutoDelete)?.data;
    const isAutodeleteFeatureEnabled = !!useSelector(isAutoDeleteEnabled);

    const createButtonRef = useRef<HTMLDivElement>(null);

    const handleCreateClick: React.MouseEventHandler<HTMLElement> = useCallback(() => {
        if (!isAutodeleteFeatureEnabled) {
            return promoProDisclosure.onOpen();
        }

        createFolder();
    }, [createFolder, isAutodeleteFeatureEnabled, promoProDisclosure]);

    return (
        <>
            <div ref={createButtonRef}>
                <Button
                    className={classNames(styles.root, className)}
                    mode="secondary"
                    appearance="neutral"
                    size="m"
                    onClick={handleCreateClick}
                >
                    Создать папку
                </Button>
            </div>
            {promoProDisclosure.isOpen && (
                <Portal>
                    <PromoProTooltip
                        target={createButtonRef}
                        onClose={promoProDisclosure.onClose}
                        gaLabel="autodelete-empty-tooltip"
                        tariffsType={autodeleteAb}
                        bottom={true}
                    />
                </Portal>
            )}
        </>
    );
};
