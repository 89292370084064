import { path } from 'ramda';
import React, { memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { PARTNER_TITLE } from 'reactApp/modules/subscriptions/subscriptions.data';
import { Partner } from 'reactApp/modules/subscriptions/subscriptions.types';
import { SubscriptionFooter } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionFooter/SubscriptionFooter';
import { SubscriptionInfoBlock } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionInfo/SubscriptionInfoBlock/SubscriptionInfoBlock';
import { useInfo } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionInfo/useInfo';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { useContent } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/useContent';
import { useSubscription } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/useSubscription';
import { RootState } from 'reactApp/store';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';

import styles from './SubscriptionModal.css';

interface IProps {
    onClose: () => void;
    subscriptionId: string;
}

export const SubscriptionModal = memo(({ subscriptionId, onClose }: IProps): ReactElement | null => {
    const subscription = useSubscription({ id: subscriptionId });
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, subscription?.['productId']));
    const partner = path<Partner>(['partner'], subscription);

    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);

    const { header, warning } = useContent({ subscription, product, partner });
    const subscriptionsInfos = useInfo({ subscription, product, partner, sendAnalytics });

    const onPartnerClick = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.partnerClick);
    }, [sendAnalytics]);

    const footer = useMemo(() => {
        if (warning && partner && subscription?.accountLink) {
            return (
                <div className={styles.warning}>
                    {warning}&nbsp;
                    <a target="_blank" className={styles.link} href={subscription.accountLink} rel="noreferrer" onClick={onPartnerClick}>
                        {PARTNER_TITLE[partner]}
                    </a>
                </div>
            );
        }

        if (warning) {
            return <div className={styles.warning}>{warning}</div>;
        }

        return product && <SubscriptionFooter subscription={subscription} product={product} sendAnalytics={sendAnalytics} />;
    }, [warning, partner, product, subscription, sendAnalytics, onPartnerClick]);

    useEffect(() => {
        if (subscription) {
            sendAnalytics(ESubscriptionModalAction.open);
        }
    }, [subscription?.id]);

    if (!subscription) {
        return null;
    }

    return (
        <Dialog
            open
            header={<div className={styles.header}>{header}</div>}
            id="subscription-modal"
            onCancel={onClose}
            className={styles.root}
            footer={footer}
            size="tiny"
        >
            <Content isModal wrapClass={styles.content}>
                {subscriptionsInfos.map((info) => (
                    <SubscriptionInfoBlock title={info.title} key={info.id} id={info.id}>
                        {info.content}
                    </SubscriptionInfoBlock>
                ))}
            </Content>
        </Dialog>
    );
});

SubscriptionModal.displayName = 'SubscriptionModal';
