/* eslint-disable sonarjs/no-identical-functions */
import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon16GiftOutline } from '@vkontakte/icons';
import { Spacing, Text, Title } from '@vkontakte/vkui';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASEQUOTA, X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { openFastCheckout } from 'reactApp/modules/payment/payment.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Screen } from 'reactApp/ui/Screen/Screen';
import { ETariffLabelKind } from 'reactApp/ui/TariffCardNew/components/TariffLabel.types';
import { TariffCard } from 'reactApp/ui/TariffCardNew/TariffCard';
import { ETariffCardWidth } from 'reactApp/ui/TariffCardNew/TariffCard.types';

import { Skeleton } from './Skeleton/Skeleton';
import { sendWelcomeDefaultTariffAnalytics } from './WelcomeDefaultTariffSplash.anatytics';
import styles from './WelcomeDefaultTariffSplash.css';
import { WelcomeDefaultTariffsFeatures } from './WelcomeDefaultTariffSplash.data';
import { WelcomeDefaultTariffSplashProps } from './WelcomeDefaultTariffSplash.types';

export const WelcomeDefaultTariffSplash = memo<WelcomeDefaultTariffSplashProps>(
    ({ onContinue, onClose, onContinueFree, onContinuePaid, onSuccess, product }) => {
        const dispatch = useDispatch();
        const { base, paid } = WelcomeDefaultTariffsFeatures;
        const isNewbie = useSelector(UserSelectors.isNewbie);

        useEffect(() => {
            emitAnalyticEvent(AnalyticEventNames.WELCOME_DEFAULT_TARIFFS_SHOW);
            sendWelcomeDefaultTariffAnalytics('tariff_plan_choose', { tariff: product.id, reg_source: 'cloud' });
        }, []);

        const handleContinueFree = useCallback(() => {
            emitAnalyticEvent(AnalyticEventNames.WELCOME_DEFAULT_TARIFFS_CLICK_FREE);
            sendWelcomeDefaultTariffAnalytics('free_continue', { tariff: product.id });
            onContinueFree?.();
            onClose?.();
            if (isNewbie) {
                onContinue?.();
            }
        }, [onContinue, onClose, product.id, isNewbie]);

        const handleClose = useCallback(() => {
            onClose?.();
            if (isNewbie) {
                onContinue?.();
            }
        }, [onContinue, onClose, isNewbie]);

        const handleContinuePaid = useCallback(() => {
            emitAnalyticEvent(AnalyticEventNames.TARIFF_CLICK, { paySource: 'cloud_registr' });

            dispatch(
                openFastCheckout({
                    title: 'Отключение рекламы и больше функций c Mail Space',
                    productId: product.id,
                    paySource: 'cloud_registr',
                    onSuccess: () => {
                        onSuccess?.();
                        onClose?.();
                        emitAnalyticEvent(AnalyticEventNames.TARIFF_BUY, { paySource: 'cloud_registr' });

                        if (isNewbie) {
                            onContinue?.();
                        }
                    },
                    hideOtherTariffs: true,
                })
            );
            sendWelcomeDefaultTariffAnalytics('subs_continue', { tariff: product.id });
            onContinuePaid?.();
        }, [dispatch, product.id, onContinue, onClose, isNewbie]);

        return (
            <Screen onClose={handleClose} withLogo={false} aboveAll={false}>
                <div className={styles.root}>
                    <Title level="2" className={styles.title}>
                        Больше функций с подпиской Mail Space
                    </Title>
                    <Spacing size={16} />
                    <Text className={styles.text}>Выберите подходящий тарифный план</Text>
                    <Spacing size={40} />
                    <div className={styles.tariffs}>
                        {product?.id ? (
                            <>
                                <TariffCard
                                    tariffId="base"
                                    buttonText="Продолжить без подписки"
                                    onClickButton={handleContinueFree}
                                    space={{
                                        space: bytesToNDigits(BASEQUOTA, 3).space,
                                        units: bytesToNDigits(BASEQUOTA, 3).units,
                                    }}
                                    infoBlock={
                                        <>
                                            <Spacing size={24} />
                                            <div className={styles.infoBlock}>Бесплатно</div>
                                        </>
                                    }
                                    showSpaceWithPlus={false}
                                    width={ETariffCardWidth.fluid}
                                    featureListOptions={base}
                                    buttonInFooterGap={48}
                                    compact
                                    buttonInFooter
                                    accentFeature
                                    bordered
                                />
                                <TariffCard
                                    tariffId="base"
                                    buttonText="Продолжить c подпиской"
                                    onClickButton={handleContinuePaid}
                                    space={{
                                        space: product?.space?.space,
                                        units: product?.space?.units,
                                    }}
                                    infoBlock={
                                        <>
                                            <Spacing size={24} />
                                            <div className={styles.infoBlock}>
                                                <Icon16GiftOutline />
                                                30 дней в подарок
                                            </div>
                                        </>
                                    }
                                    width={ETariffCardWidth.fluid}
                                    featureListOptions={paid}
                                    buttonInFooterGap={48}
                                    label="Mail Space"
                                    labelKind={ETariffLabelKind.blue}
                                    compact
                                    focused
                                    buttonInFooter
                                    buttonPrimary
                                    accentFeature
                                    focusedWithoutShadow
                                />
                            </>
                        ) : (
                            <>
                                <Skeleton free />
                                <Skeleton />
                            </>
                        )}
                    </div>
                    {isNewbie && (
                        <Text className={styles.caption}>
                            Продолжая, вы соглашаетесь с использованием{' '}
                            <a href="https://help.mail.ru/legal/terms/cloud/recommendation" target="_blank" rel="noreferrer">
                                рекомендательных технологий
                            </a>
                            , и принимаете условия{' '}
                            <a
                                href={`https://help.mail.ru/legal/terms/cloud/LA?signupid=${X_PAGE_ID.toLowerCase()}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Лицензионного соглашения
                            </a>{' '}
                            и{' '}
                            <a href="https://help.mail.ru/legal/terms/cloud/privacy" target="_blank" rel="noreferrer">
                                Политики конфиденциальности
                            </a>
                        </Text>
                    )}
                </div>
            </Screen>
        );
    }
);

WelcomeDefaultTariffSplash.displayName = 'WelcomeDefaultTariffSplash';
