import { Space } from '@mail/cross-sizes-utils';
import React, { ReactNode } from 'react';
import { Product } from 'reactApp/types/Billing';
import { getPeriodName } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from '../SplashScreen.css';

type TGetSaveSubsInfo = (data: { source: string; product: Product; oldSubscriptionSpace?: Space; isTransit?: boolean }) => {
    text: ReactNode;
    buttonText: ReactNode;
    title: string;
    paymentTitle: string;
};

export const getInfoForSaveSubscriptionsPromo: TGetSaveSubsInfo = ({ source, product, oldSubscriptionSpace, isTransit }) => ({
    title: `Не ${isTransit ? 'потеряйте свой ' : 'упустите'} выгодный тариф`,
    text: (
        <>
            Мы не {isTransit ? 'сможем' : 'смогли'} продлить ваш {isTransit ? 'годовой' : ''} тариф на{' '}
            {oldSubscriptionSpace && oldSubscriptionSpace.value} из-за ограничений в{' '}
            {source === 'ios' ? <>App&nbsp;Store</> : <>Google&nbsp;Play</>}.
            {product.isForceDiscountTrial
                ? ` ${isTransit ? 'Переоформите его' : 'Подключите годовой тариф'} по скидочной цене и получите ${getPeriodName(
                      product.trialPeriod || '',
                      true
                  )} бесплатно.`
                : ''}
            {isTransit ? ' Тариф начнёт действовать после окончания предыдущего.' : ''}
        </>
    ),
    buttonText: (
        <>
            {isTransit ? 'Сохраните' : 'Подключить'} за&nbsp;
            {product.isForceDiscountTrial ? (
                <>
                    <span className={styles.oldPriceText}>{formatPrice(product?.price)} ₽</span>&nbsp;
                    {formatPrice(product?.discountPrice)} ₽
                </>
            ) : (
                `${formatPrice(product?.price)} ₽`
            )}
        </>
    ),
    paymentTitle: `Не ${isTransit ? 'потеряйте' : 'упустите'} тариф из-за ограничений в ${source === 'ios' ? 'App Store' : 'Google Play'}`,
});
