import { PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { CLOUD_SOURCE, PaymentUTM } from 'reactApp/constants/paymentUTM';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { getSubscriptionsUrl } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.constants';
import { ADS_STORAGE } from 'reactApp/ui/AdvBanners/Adv.types';
import { getUtmString } from 'reactApp/utils/urlHelper';

export const getAdvDisableLink = ({ isAnonymous, storage }) => {
    if (PUBLIC_SHOVE) {
        return isAnonymous ? getQuotaPromoUrl({ query: PaymentUTM.advDisableLinkAnonym }) : getSubscriptionsUrl(PaymentUTM.advDisableLink);
    }

    const utm = getUtmString({
        utm_source: CLOUD_SOURCE,
        utm_medium: 'web_subscriptions',
        utm_campaign: `disable_ads_${ADS_STORAGE[storage] || ''}`,
    });

    return isAnonymous ? getQuotaPromoUrl({ query: PaymentUTM.advDisableLineLinkAnonym }) : getSubscriptionsUrl(utm);
};
