import { Product } from 'reactApp/types/Billing';
import { usePrice } from 'reactApp/ui/TariffSectionNew/hooks/usePrice';
import { isMonthPeriod } from 'reactApp/utils/Period';

interface useProductPriceProps {
    product: Product;
}

export const useProductPrice = ({ product }: useProductPriceProps) => {
    const [_, discount, __, productMonthPriceAsText] = usePrice({
        product,
        periodDivider: 'в',
    });

    const trialPrice = isMonthPeriod(product.period) ? 'Попробовать за 1 ₽' : '1 ₽ в первый месяц';
    const priceText = product.hasTrial ? trialPrice : productMonthPriceAsText;
    return { discount, priceText };
};
