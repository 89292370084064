import { EAttachesItemContentType } from 'reactApp/modules/attaches/attaches.constants';
import { WeblinkDownloadable } from 'reactApp/modules/public/public.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Kind, SubKind, Url } from 'reactApp/types/Tree';

export interface Flags {
    hidden: boolean;
    unsafe: boolean;
    eml?: boolean;
}

export enum EAttachTypes {
    cloudStock = 'cloud_stock',
    temporary = 'temporary',
    attach = 'attach',
    cloud = 'cloud',
}

type EditorData = {
    url: string;
};

export type AttachEditors = Record<'docs' | 'myoffice' | 'myoffice_wopi' | 'ms' | 'hancom' | 'ovidius' | 'cloud', EditorData>;

export interface AttachesItem extends WeblinkDownloadable {
    contentTypeId: EAttachesItemContentType;
    folder_id: string;
    message_id?: string;
    size: number;
    id: string;
    name: string;
    ext: string;
    type: string;
    nameWithoutExt: string;
    parent: string;
    mtime: number;
    storage: EStorageType.attaches | EStorageType.viewerAttaches;
    isFolder: false;
    url?: Url;
    content_type: string;
    authorName: string;
    authorEmail: string;
    authorAvatar: string;
    kind: Kind;
    subject: string;
    subKind: SubKind;
    flags: Flags;
    attachType: EAttachTypes;
    thumbnails: {
        [key: string]: {
            default: string;
            [key: string]: string;
        };
    };
    editors?: AttachEditors;
    /*
     * Когда склонированили аттач к себе в облако, тут будет путь к этому файлу в хомяке
     * */
    home?: string;
    pos?: number;
}

export interface AttachesBreadcrumb {
    id: string;
    name: string;
    kind: Kind;
    storage: EStorageType.attaches;
    href?: string;
    search?: string;
}

export type AttachesFolderType =
    | 'inbox'
    | 'sent'
    | 'drafts'
    | 'templates'
    | 'spam'
    | 'trash'
    | 'archive'
    | 'user'
    | 'social'
    | 'promotions'
    | 'newsletters'
    | 'outbox';

export interface AttachesFolderItem {
    id: string;
    /** "-1" if no parent */
    parent: string;
    name: string;
    /** Признак, что папка дочерняя (boolean) */
    child: boolean;
    /** Признак, что у папки есть подпапки (boolean) */
    children: boolean;
    /** Количество писем, содержащих вложения (number) */
    messages_with_attachments: number;
    open: boolean;
    /** Признак запароленности */
    security: boolean;
    system: boolean;
    only_web: boolean;
    archive: boolean;
    type: AttachesFolderType;
    messages_total: number;
}

export interface AttachFolderTreeItem {
    href: string;
    text: string;
    icon: string;
    id: string;
    parent: string;
    storage: string;
    foldings?: 'open' | 'close';
    items: AttachFolderTreeItem[];
    active: boolean;
    type: AttachesFolderType;
    child: boolean;
}

export interface SearchParams {
    query?: string;
    type?: 'image' | 'document' | 'audio' | 'video' | 'other';
}

export interface State {
    childs: string[];
    id: string;
    attaches: Record<string, AttachesItem>;
    viewerAttaches: Record<string, AttachesItem>;
    loadedMessagesIds: string[];
    folders: Record<string, AttachesFolderItem>;
    after: string;
    hasMoreToLoad: boolean;
    isLoading: boolean;
    isLoaded: boolean;
    error: string;
    search: SearchParams | null;
    openedFoldersIdxs: Record<string, boolean>;
    allMessagesTotalCount: number;
    isFromAttaches: boolean;
    attachesIdViewed?: string | null;
}

export interface AttachesSearchParams extends SearchParams {
    folderId?: number;
    contentType?: string;
    after?: string;
    limit?: number;
    extraThumbs?: { image: string; doc: string };
}
