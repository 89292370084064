import {
    Icon20Cards2Outline,
    Icon20CloudOutline,
    Icon20DeleteOutline,
    Icon20HelpOutline,
    Icon24Cards2Outline,
    Icon24CloudOutline,
    Icon24DeleteOutline,
    Icon24HelpOutline,
} from '@vkontakte/icons';
import React, { ReactElement } from 'react';
import { IS_USER_WITH_ONLY_CLOUD } from 'reactApp/appHelpers/configHelpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { getSubscriptionsUrl } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.constants';

export enum EMenuItemId {
    cleaner = 'clear',
    subscription = 'subscription',
    quota = 'more',
    space = 'space',
}

export interface IMenuItem {
    id: EMenuItemId;
    icon: ReactElement;
    iconMobile: ReactElement;
    text: string;
    url: string;
}

const SPACE_MENU_LIST: IMenuItem[] = [
    {
        id: EMenuItemId.cleaner,
        icon: <Icon20DeleteOutline />,
        iconMobile: <Icon24DeleteOutline />,
        text: 'Освободить место',
        url: getQuotaPromoUrl({ hash: 'cleaner', query: PaymentUTM.spaceMenuCleaner }),
    },
    {
        id: EMenuItemId.subscription,
        icon: <Icon20Cards2Outline />,
        iconMobile: <Icon24Cards2Outline />,
        text: 'Мои подписки',
        url: getSubscriptionsUrl(PaymentUTM.spaceMenuSubs),
    },
    {
        id: EMenuItemId.space,
        icon: <Icon20CloudOutline />,
        iconMobile: <Icon24CloudOutline />,
        text: 'Увеличить место',
        url: getSubscriptionsUrl(PaymentUTM.spaceMenuBuy),
    },
    {
        id: EMenuItemId.quota,
        icon: <Icon20HelpOutline />,
        iconMobile: <Icon24HelpOutline />,
        text: 'Подробнее',
        url: getQuotaPromoUrl({ hash: 'start', query: PaymentUTM.spaceMenuUnionQuota }),
    },
];

export const getSpaceMenuList = ({
    isSubscriptionsPage,
    hideCleaner,
    isMySubscription,
}: {
    isSubscriptionsPage: boolean;
    hideCleaner: boolean;
    isMySubscription: boolean;
}): IMenuItem[] => {
    let list = SPACE_MENU_LIST;

    if (hideCleaner) {
        list = list.filter((item) => item.id !== EMenuItemId.cleaner);
    }

    // пользователем без единой квоты, не надо показывать пункты про ек
    if (IS_USER_WITH_ONLY_CLOUD) {
        list = list.filter((item) => item.id !== EMenuItemId.cleaner && item.id !== EMenuItemId.quota);
    }

    // если тултип на /subs убираем пукнты ведущие на него
    if (isSubscriptionsPage) {
        return list.filter((item) => item.id !== EMenuItemId.subscription && item.id !== EMenuItemId.space);
    }

    // если в блоке квоты кнопка "Мои подписки", показываем в меню "Увеличить место"
    // и наоборот
    list = list.filter((item) => (isMySubscription ? item.id !== EMenuItemId.subscription : item.id !== EMenuItemId.space));

    return list;
};
