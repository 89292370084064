import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon20CloudOutline } from '@vkontakte/icons';
import { Caption, Spacing, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import { once } from 'ramda';
import React, { memo, ReactEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_PROMO, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import {
    crossedFullPriceInTariffCard,
    moreBenefitsInExpensiveTariffs,
    summerPromotion,
    upsaleMonthTariffPromo,
} from 'reactApp/appHelpers/featuresHelpers';
import { checkAdditionalBenefitsInSpace } from 'reactApp/constants/products/benefits.bySpace';
import { getAdditionalBenefitsCountText } from 'reactApp/constants/products/quotaFeatures';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { useMergeRefs } from 'reactApp/hooks/useMergeRefs';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { AUTOUPLOAD_10TB_REGEX } from 'reactApp/modules/products/products.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { AUTOUPLOAD_TOOLTIP_ID, AutouploadTooltip } from 'reactApp/sections/QuotaLanding/AutouploadTooltip/AutouploadTooltip';
import {
    BUY_BUTTON_TARIFF_BENEFITS_MODAL_ID,
    MODAL_TARIFF_BENEFITS_PAY_SOURCE,
} from 'reactApp/sections/QuotaLanding/DetailedTariffBenefitsPopup/DetailedTariffBenefitsPopup.constants';
import { useIntersecObsrWithParams } from 'reactApp/sections/QuotaLanding/hooks/useIntersecObsrWithParams';
import { sendQuotaBlockViewDWH, sendQuotaGa, sendXrayWithPlatform } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { useBuyClick } from 'reactApp/sections/QuotaLanding/TariffsPlans/TariffPlansCard/useBuyClick';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { TariffCard } from 'reactApp/ui/TariffCardNew/TariffCard';
import { ETariffCardSizeButtonSize, ETariffCardWidth } from 'reactApp/ui/TariffCardNew/TariffCard.types';
import { useButtonAdditionalText } from 'reactApp/ui/TariffSectionNew/hooks/useButtonAdditionalText';
import { getFeaturesBySpace, useFeatures } from 'reactApp/ui/TariffSectionNew/hooks/useFeatures';
import { usePrice } from 'reactApp/ui/TariffSectionNew/hooks/usePrice';
import { useSwitchProducts } from 'reactApp/ui/TariffSectionNew/hooks/useSwitchProducts';
import { useTariffLabel } from 'reactApp/ui/TariffSectionNew/hooks/useTariffLabel';
import { noopVoid } from 'reactApp/utils/helpers';
import { isMonthPeriod, isYearPeriod } from 'reactApp/utils/Period';

import styles from './TariffPlansCard.css';
import { Props } from './TariffPlansCard.types';

export const TariffInfoBlock = ({
    newQuota,
    isBase,
    isMobile,
    isSkeleton = false,
    summerPromo = false,
}: {
    newQuota: number;
    isBase?: boolean;
    isMobile?: boolean;
    isSkeleton?: boolean;
    summerPromo?: boolean;
}) => (
    <>
        {/* tempexp_16800-next_line  moreBenefitsInExpensiveTariffs === 'd' ? 12 : */}
        {!isMobile && <Spacing size={moreBenefitsInExpensiveTariffs === 'd' ? 12 : 24} />}
        {isBase ? (
            <Spacing size={32} />
        ) : (
            <div className={styles.infoBlock}>
                {isSkeleton ? (
                    <div className={styles.skeleton} />
                ) : (
                    <>
                        {(!summerPromo || IS_WEBVIEW) && (
                            <>
                                <Icon20CloudOutline />{' '}
                            </>
                        )}
                        <Caption
                            level={summerPromo ? '2' : '1'}
                            weight="2"
                            className={classNames({ [styles.summerPromoMobile]: summerPromo && isMobile })}
                        >
                            Ваш объём составит {bytesToNDigits(newQuota, 3).value}
                        </Caption>
                    </>
                )}
            </div>
        )}
        {isMobile && <Spacing size={30} />}
    </>
);

export const TariffPlansCard = memo<Props>(
    ({
        tariff,
        isVisible = true,
        isIOSPromo = false,
        isAndroidPromo = false,
        setCarouselLoaded,
        isMobilePayAvailable,
        isMobilePayFromGetParam,
        activeTab,
        isNewLanding = false,
    }) => {
        // tempexp_16521-start
        const { isLoaded: isQuotaLoaded } = useSelector(UserQuotaSelectors.getLoadingState);
        const userQuota = useSelector(UserQuotaSelectors.getTotalOrBase);
        const isAnonymous = useSelector(UserSelectors.isAnonymous);
        // tempexp_16521-end
        const isMobile = EnvironmentSelectors.isMobile();

        const { tariffId, products, isBase, isRecommended, tariffPromoLabelType } = tariff;

        const { showSwitch, product, onClickSwitch, switchChecked, switchOptions, yearSavingDiscount, monthSavingDiscount } =
            useSwitchProducts({
                products,
                activeTab,
                withSwitch: !isNewLanding,
            });
        const monthDiscountPercent =
            (crossedFullPriceInTariffCard.month || summerPromotion) && isMonthPeriod(product.period) ? monthSavingDiscount : 0;

        // tempexp_16481-start
        const dispatch = useDispatch();
        const [showTooltip, setShowTooltip] = useState<boolean>(false);
        const tooltipRef = useRef<HTMLDivElement | null>(null);
        const isProductTooltip = AUTOUPLOAD_10TB_REGEX.test(product.id);
        const initTooltip = showTooltip && isProductTooltip && !isMobile;
        const tooltipShown = dispatch(UserStorageActions.get(AUTOUPLOAD_TOOLTIP_ID));
        // tempexp_16481-end

        const [priceAsText, discount, discountPriceAsText, productMonthPriceAsText, defaultMonthPrice] = usePrice({
            product,
            periodDivider: isNewLanding ? 'в' : '/',
        });
        const [showLabel, label, labelKind] = useTariffLabel({
            product,
            skipDefaultLabel: true,
            isRecommended: isRecommended && !discount,
            primaryLabel: isRecommended && isNewLanding ? 'Выгодно' : '',
            isBase: isBase && isNewLanding,
            showDiscount: !isNewLanding,
            tariffPromoLabelType,
        });

        let [featureList] = useFeatures(
            product,
            isNewLanding,
            isNewLanding,
            /* tempexp_16800-start */
            isNewLanding && !isMobile ? getFeaturesBySpace() : undefined
            /* tempexp_16800-end */
        );

        const buttonAdditionalText = useButtonAdditionalText(product, crossedFullPriceInTariffCard);

        const showCrossedPrice =
            (isYearPeriod(product.period) && crossedFullPriceInTariffCard.year && product?.discountPrice) ||
            (isMonthPeriod(product.period) && crossedFullPriceInTariffCard.month && product?.discountPrice);

        const onBuyClick = useBuyClick({
            isMobilePayAvailable,
            isMobilePayFromGetParam,
            isIOSPromo,
            isAndroidPromo,
        });

        const onButtonClick: ReactEventHandler = useCallback(
            (event) => {
                event.stopPropagation();

                if (upsaleMonthTariffPromo && isMonthPeriod(product.period)) {
                    openPopupHelper({
                        popupName: popupNames.UPSALE_MONTH_TARIFF,
                        data: {
                            monthProduct: products.monthProduct,
                            yearProduct: products.yearProduct,
                            yearSavingDiscount,
                            activePeriod: upsaleMonthTariffPromo,
                            resolve: (productId: string, price: number) => {
                                // tempexp_16481-next-line
                                onBuyClick({ productId, price, paySource: 'modal_choose_tariff', space: product.space.space });
                            },
                        },
                    });
                } else {
                    const target = event.target as HTMLButtonElement;
                    let paySource;
                    if (target.id === BUY_BUTTON_TARIFF_BENEFITS_MODAL_ID) {
                        paySource = MODAL_TARIFF_BENEFITS_PAY_SOURCE;
                    }
                    onBuyClick({
                        productId: product.id,
                        price: product.price,
                        // tempexp_16481-next-line
                        space: product.space.space,
                        paySource,
                    });
                }
            },
            [product.period, product.id, product.price, products.monthProduct, products.yearProduct, yearSavingDiscount, onBuyClick]
        );

        const sendAdvantagesClickAnalytic = () => {
            emitAnalyticEvent(AnalyticEventNames.TARIFF_ADVANTAGES_CLICK);
            sendQuotaGa({
                action: 'tariff',
                label: 'benefits_click',
                tariff: product.id,
                platform: 'web',
            });
        };
        if (isNewLanding && !isMobile && moreBenefitsInExpensiveTariffs === 'a') {
            featureList = featureList.map((feature) => {
                if (feature.key === 'benefits') {
                    return {
                        ...feature,
                        text: (
                            <>
                                И другие{'\u00A0'}
                                <a href="#benefits" onClick={sendAdvantagesClickAnalytic}>
                                    возможности
                                </a>
                            </>
                        ),
                    };
                }
                return feature;
            });
        }
        const hasAdditionalBenefits = checkAdditionalBenefitsInSpace(product.space.original);
        // tempexp_16800-start
        if (isNewLanding && !isMobile && moreBenefitsInExpensiveTariffs === 'b' && hasAdditionalBenefits) {
            const clickHandler = () => {
                sendAdvantagesClickAnalytic();
                const popupHelper = openPopupHelper({
                    popupName: popupNames.DETAILED_TARIFF_BENEFITS,
                    data: {
                        product,
                        buttonText: `Купить за ${productMonthPriceAsText}`,
                        onButtonClick: (e) => {
                            onButtonClick(e);
                            popupHelper?.closeDialog();
                        },
                    },
                });
            };
            featureList = featureList.map((feature) => {
                if (feature.key === 'benefits') {
                    return {
                        ...feature,
                        text: (
                            <>
                                Дополнительно ещё
                                <br />
                                <ButtonLink
                                    onClick={clickHandler}
                                    hoverUnderline={false}
                                    fontInherit
                                    primary
                                    className={styles.benefitsLink}
                                >
                                    {getAdditionalBenefitsCountText(product)}
                                </ButtonLink>
                            </>
                        ),
                    };
                }
                return feature;
            });
        }
        const buttonInFooter = !isBase && moreBenefitsInExpensiveTariffs !== 'c';
        // tempexp_16800-end

        useEffect(() => {
            /** Это сделано именно здесь для того, чтобы дожидаться рендера тарифов со всем контентом внутри
             * для корректного скролла по якорю из ссылки на лендинг единой квоты
             * В родительских элементах useEffect срабатывает слишком рано, до формирования листа тарифов */
            if (isMobile) {
                return;
            }

            // tempexp_16481-start
            if (!tooltipShown && product.space.original === SPACE_LIST.tb_2) {
                setTimeout(() => {
                    setShowTooltip(true);
                }, 3000);
            }

            if (isProductTooltip) {
                sendQuotaBlockViewDWH(product.id);
                sendXrayWithPlatform(['autoupload-10tb', `tariff-view-${product.space.space}`]);
            }
            // tempexp_16481-end

            setCarouselLoaded?.(true);
        }, []);

        const onIntersecting = useCallback(
            once(() => emitAnalyticEvent(AnalyticEventNames.TARIFF_SHOWN, { product })),
            [product.id]
        );

        const refIntersection = useIntersecObsrWithParams({
            onIntersecting,
            isMobile,
        });

        return (
            <div
                className={classNames(styles.tariffCard, {
                    [styles.tariffCardMobile]: isMobile && !isNewLanding,
                    [styles.tariffCard_invisible]: !isVisible,
                })}
                ref={useMergeRefs(tooltipRef, refIntersection)}
            >
                {/* tempexp_16481-next-line */}
                {initTooltip && <AutouploadTooltip ref={tooltipRef} onClose={setShowTooltip} />}
                <TariffCard
                    onClick={noopVoid}
                    dataQa="QuotaLpTariffCard"
                    tariffId={tariffId}
                    width={ETariffCardWidth.fluid}
                    focused={isRecommended}
                    buttonPrimary={(!isBase && !isNewLanding) || (isNewLanding && isRecommended)}
                    buttonDisabled={isBase}
                    buttonText={isBase ? 'Уже включено' : `Купить за ${discountPriceAsText || priceAsText}`}
                    onClickButton={onButtonClick}
                    space={{
                        space: product.space.space,
                        units: product.space.units,
                    }}
                    featureListOptions={featureList}
                    {...(isNewLanding && isRecommended && Boolean(ACTION_PROMO)
                        ? {
                              action: true,
                          }
                        : null)}
                    {...(isBase
                        ? {
                              headerPrimaryText: 'Бесплатный',
                              headerPrimaryTextLarge: true,
                              showSpaceWithPlus: false,
                          }
                        : null)}
                    {...(isNewLanding
                        ? {
                              buttonText: (
                                  <div>
                                      {showCrossedPrice && (
                                          <span
                                              className={classNames(styles.crossedOldPrice, {
                                                  [styles.crossedOldPriceFocuced]: isRecommended,
                                              })}
                                          >
                                              {defaultMonthPrice}
                                          </span>
                                      )}
                                      {productMonthPriceAsText}
                                  </div>
                              ),
                              compact: true,
                              accentFeature: true,
                              disable: Boolean(isBase),
                              withoutButton: Boolean(isBase),
                              buttonPrimaryLight: true,
                              // tempexp_16800-start
                              compactFeatureList: moreBenefitsInExpensiveTariffs === 'c' || moreBenefitsInExpensiveTariffs === 'd',
                              stretchInfoBlock: moreBenefitsInExpensiveTariffs === 'c' || moreBenefitsInExpensiveTariffs === 'd',
                              buttonInInfoBlock: moreBenefitsInExpensiveTariffs === 'c',
                              buttonInFooter,
                              buttonInFooterGap: buttonInFooter
                                  ? moreBenefitsInExpensiveTariffs === 'b'
                                      ? hasAdditionalBenefits
                                          ? 36
                                          : 54
                                      : moreBenefitsInExpensiveTariffs === 'd'
                                      ? 23
                                      : 36
                                  : 36,
                              // tempexp_16800-end
                              buttonSize: ETariffCardSizeButtonSize.big,
                              buttonAdditionalText,
                              footerText: isBase ? (
                                  <div>
                                      <Spacing size={42} />
                                      <Title level="3">Бесплатно</Title>
                                      <Spacing size={20} />
                                  </div>
                              ) : (
                                  <></>
                              ),
                              infoBlock: (
                                  <TariffInfoBlock
                                      isBase={isBase}
                                      summerPromo={summerPromotion}
                                      newQuota={userQuota + product.space.original}
                                      isSkeleton={!isAnonymous && !isQuotaLoaded}
                                  />
                              ),
                              savingInButton: isMonthPeriod(product.period) ? monthDiscountPercent : yearSavingDiscount,
                              headerPrimaryText: '',
                          }
                        : null)}
                    {...(showSwitch
                        ? {
                              onClickSwitch,
                              switchChecked,
                              switchOptions,
                          }
                        : null)}
                    {...(showLabel
                        ? {
                              label,
                              labelKind,
                          }
                        : null)}
                    {...(discount && !isNewLanding
                        ? {
                              buttonAdditionalText: (
                                  <>
                                      Вместо <span className={styles.crossedOutPrice}>{priceAsText}</span>
                                  </>
                              ),
                              buttonSize: ETariffCardSizeButtonSize.big,
                          }
                        : null)}
                    {...(isProductTooltip && { onLabelClick: setShowTooltip })}
                />
            </div>
        );
    }
);

TariffPlansCard.displayName = 'TariffPlansCard';
