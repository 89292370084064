import { xray } from 'lib/xray';
import { HomeItem } from 'reactApp/modules/home/home.types';
import { sendSuccess } from 'reactApp/modules/integration/communicator';
import { getIntegrationState } from 'reactApp/modules/integration/integration.selectors';
import type { File, IntegrationState } from 'reactApp/modules/integration/integration.types';
import { getSelectedItems } from 'reactApp/modules/storage/storage.selectors';
import { select } from 'redux-saga/effects';
import { getHashThumbnails } from 'server/helpers/getHashThumbnail';

export function* handleAttachSelected() {
    const state: IntegrationState = yield select(getIntegrationState);

    const selectedItems: HomeItem[] = yield select(getSelectedItems);
    if (!selectedItems?.length) {
        return;
    }

    const files: File[] = selectedItems.map((item) => {
        return {
            name: item.name,
            thumb: getHashThumbnails(item),
            path: item.home,
            size: item.size,
        };
    });

    sendSuccess(state.parentOrigin, files);

    xray.send('attach-ok');
}
