import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { IS_IOS_MOBILE } from 'reactApp/appHelpers/configHelpers';
import { getOpenPopups } from 'reactApp/modules/popup/popup.selectors';
import { store } from 'reactApp/store';

const IOS_VERSION_REGEX = /OS (\d+)/;
const IOS_MAJOR_VERSION_WITHOUT_SCROLL_LOCK = 15;

/**
 * Проверяем, поддерживает ли текущая версия iOS устройства блокировку скролла.
 *
 * @returns Возвращает true если версия iOS меньше указанной major версии, иначе false.
 */
const checkIsIOSVersion = () => {
    const userAgent = window.navigator?.userAgent;
    const iOSVersion = IOS_VERSION_REGEX.exec(userAgent)?.[1];

    return Boolean(iOSVersion && parseInt(iOSVersion) > IOS_MAJOR_VERSION_WITHOUT_SCROLL_LOCK);
};

export const isIosDevice = () => {
    if (typeof window === 'undefined') {
        return false;
    }

    const isIos =
        /iP(ad|hone|od)/.test(window?.navigator?.platform) ||
        (window?.navigator?.platform === 'MacIntel' && window?.navigator?.maxTouchPoints > 1);

    return isIos || Boolean(IS_IOS_MOBILE);
};

function findScrollLockIgnoreParent(el) {
    let targetEl = el;
    while (targetEl && targetEl !== document.body) {
        if (typeof targetEl.getAttribute === 'function' && targetEl.getAttribute('body-scroll-lock-ignore')) {
            return true;
        }
        targetEl = targetEl.parentNode;
    }
}

// https://bugs.webkit.org/show_bug.cgi?id=153852#c43
// ios игнорирует overflow на body
// (update: сейчас воспроизводятся случаи, где overflow на body работает как ожидается в ios,
//  смотри ссылку на баг ниже)
export const lockScrollOnIos = () => {
    if (!isIosDevice()) {
        return;
    }

    /** См. https://github.com/willmcpo/body-scroll-lock/issues/200, аналогичная проблема возникла у нас в https://jira.vk.team/browse/CLOUDWEB-17266
     *
     * В коде самой body-scroll-lock библиотеки есть принудительный зажим установки overflow: hidden на body
     * и лок скролла через position:fixed  (https://github.com/willmcpo/body-scroll-lock/blob/v3.0.1/src/bodyScrollLock.js#L141)
     *
     *  Однако на последних весиях ios установка overflow: hidden на body работает и решает указанную выше проблему.
     *
     */
    setTimeout(() => {
        document.body.style.overflow = 'hidden';
    });

    if (checkIsIOSVersion()) {
        return;
    }

    const offsetY = window.pageYOffset;

    document.body.classList.add('body_fixed');

    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.top = `${-offsetY}px`;
    // fix для ios 15
    document.documentElement.style.minHeight = '100vh';
};

export const unlockScrollOnIos = () => {
    if (!isIosDevice()) {
        return;
    }

    /** См. https://github.com/willmcpo/body-scroll-lock/issues/200, аналогичная проблема возникла у нас в https://jira.vk.team/browse/CLOUDWEB-17266
     *
     * В коде самой body-scroll-lock библиотеки есть принудительный зажим установки overflow: hidden на body
     * и лок скролла через position:fixed  (https://github.com/willmcpo/body-scroll-lock/blob/v3.0.1/src/bodyScrollLock.js#L141)
     *
     *  Однако на последних весиях ios установка overflow: hidden на body работает и решает указанную выше проблему.
     *  Здесь мы отменяем установленный выше скролл лок, с учетом вложенных модальных окон
     */

    const popups = getOpenPopups(store.getState());

    if (!popups.length) {
        document.body.style.removeProperty('overflow');
    }

    if (checkIsIOSVersion()) {
        return;
    }

    /**
     * На таче есть шторки, которые открываеются из другой шторки. При этом
     * при закрытии, родительская шторка остается открытой. В таком случае, нам не нужно
     * вызывать scrollUnlock. Для этого и проверяем - есть ли открытые попапы (шторки).
     */
    if (!popups.length) {
        const top = String(document.body.style.top || 0);
        const offsetY = Math.abs(parseInt(top));

        document.body.classList.remove('body_fixed');

        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('width');
        document.body.style.removeProperty('top');
        document.documentElement.style.removeProperty('minHeight');

        window.scrollTo(0, offsetY || 0);
    }
};

export function scrollLock(
    bodyScrollLockEl: HTMLElement | null | undefined,
    { allowTouchMove = false, reserveScrollBarGap = true }: { allowTouchMove?: boolean; reserveScrollBarGap?: boolean } = {}
) {
    if (!bodyScrollLockEl) {
        return;
    }

    disableBodyScroll(bodyScrollLockEl, {
        allowTouchMove: allowTouchMove ? findScrollLockIgnoreParent : null,
        reserveScrollBarGap,
    });

    lockScrollOnIos();
}

export function scrollUnlock(bodyScrollLockEl) {
    if (!bodyScrollLockEl) {
        return;
    }

    enableBodyScroll(bodyScrollLockEl);
    unlockScrollOnIos();
}
