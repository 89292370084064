import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { AUTOUPLOAD_10TB_REGEX } from 'reactApp/modules/products/products.helpers';
import { Product } from 'reactApp/types/Billing';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { useProduct } from 'reactApp/ui/PaymentFooter/useProduct';

import styles from './PaymentFooter.css';

type Props = {
    productId: string;
    isMobile?: boolean;
    product?: Product;
};

export const PaymentFooter = memo<Props>(({ productId, isMobile, product }): ReactElement => {
    const text = useProduct({ productId, product });

    return (
        <div className={classNames(styles.root, { [styles.root_mobile]: isMobile })}>
            {text} Оформляя её, вы принимаете{' '}
            <ButtonLink size="medium" primary inline href="/LA/" target="_blank" fontInherit underline={false}>
                Условия использования
            </ButtonLink>{' '}
            и&nbsp;предоставляете нам право взимать плату на&nbsp;регулярной основе. Отменить автоплатёж можно в&nbsp;любое время
            в&nbsp;настройках подписки. C&nbsp;привязанной карты будет списываться автоплатеж для всех активных подписок, приобретаемых
            на&nbsp;сайте.
            {/* tempexp_16481-next-line */}
            {AUTOUPLOAD_10TB_REGEX.test(productId) && (
                <>
                    {' '}
                    Акция «+10 ТБ для телефона» ограничена во времени — смотрите{' '}
                    <ButtonLink
                        size="medium"
                        primary
                        inline
                        href="https://promo-rules.hb.ru-msk.vkcs.cloud/10-tb-rules.pdf"
                        target="_blank"
                        fontInherit
                        underline={false}
                    >
                        условия акции
                    </ButtonLink>
                </>
            )}
        </div>
    );
});

PaymentFooter.displayName = 'PaymentFooter';
