import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

export const sendWelcomeDefaultTariffAnalytics = (action: string, data: Record<string, string>) => {
    sendDwh({
        eventCategory: ECategoryGa.entered,
        action,
        dwhData: {
            plarform: 'web',
            source: 'desktop',
            ...data,
        },
    });
};
