import { useSelector } from 'react-redux';
import { crossedFullPriceInTariffCard } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getPretextPeriodForProduct } from 'reactApp/modules/products/products.helpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { RootState } from 'reactApp/store';
import { Product } from 'reactApp/types/Billing';
import { getPeriodName, getPeriodNameAsWord, isMonthPeriod, isYear2Period } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

export const useProduct = ({ productId, product: incomingProduct }: { productId: string; product?: Product }) => {
    // TODO: если id невалидный, то product будет undefined и всё посыпется. Стоит предусмотреть и убрать as Product
    const product = useSelector((state: RootState) => incomingProduct || ProductsSelectors.getProductById(state, productId)) as Product;

    const price = product?.discountPrice || product?.price || 0;
    const space = product?.space?.value || '';
    let promoPeriod = (!product?.isForceDiscountTrial && product?.trialPeriod) || product?.discountPeriod;

    const formattedPrice = `${formatPrice(price)}\u00A0₽`;
    const fullPrice = `${formatPrice(product?.price)}\u00A0₽`;

    const isMonth = isMonthPeriod(product.period);

    if (isMonth && product?.discountPeriodCount && crossedFullPriceInTariffCard.month) {
        promoPeriod = `${product.discountPeriodCount}m`;
    }

    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    const trialString =
        product?.hasTrial && Boolean(product?.trialPeriod) && !isPhone ? `Для проверки карты мы спишем и сразу же вернем 1 ₽. ` : '';

    if (promoPeriod && price) {
        return `${trialString}${
            product?.isForceDiscountTrial && product?.isTransit ? 'Тариф начнет действовать после окончания предыдущей подписки. ' : ''
        }${getPretextPeriodForProduct(promoPeriod)} ${getPeriodNameAsWord(promoPeriod, true)} оплата составит ${fullPrice} ${
            isYear2Period(product.period) ? 'за' : 'в'
        } ${getPeriodName(product.period)}.`;
    }

    return `${trialString}Подписка на ${space} ${price && `за ${formattedPrice}`} продлевается автоматически через ${getPeriodName(
        product.period
    )} на аналогичный период.`;
};
