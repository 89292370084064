import { useCallback, useEffect, useState } from 'react';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { dispatch } from 'reactApp/react-into-toolkit-block-wrapper/renderComponentInExternalDom';

interface UseShowTooltipProps {
    tooltipId: string;
    preventShow?: boolean;
    autoShow?: boolean;
}

export function useShowTooltip({ tooltipId, preventShow = false, autoShow = false }: UseShowTooltipProps) {
    const [needShowTooltip, setNeedShowTooltip] = useState<boolean>(false);
    const isTooltipShown = Boolean(dispatch(UserStorageActions.get(tooltipId)));

    const openTooltip = useCallback(() => {
        if (preventShow) {
            return;
        }

        setNeedShowTooltip(true);
        if (!isTooltipShown) {
            dispatch(UserStorageActions.set(tooltipId, new Date().valueOf()));
        }
    }, [preventShow, isTooltipShown, dispatch]);

    const closeTooltip = useCallback(() => {
        setNeedShowTooltip(false);
    }, []);

    useEffect(() => {
        if (!isTooltipShown && autoShow) {
            // setTimeout чтобы тултип располагался правильно после анимации
            setTimeout(() => openTooltip(), 300);
        }
    }, [isTooltipShown, openTooltip, autoShow]);

    return { needShowTooltip, openTooltip, closeTooltip };
}
