/* eslint-disable simple-import-sort/imports */
import { watchAdv } from 'reactApp/modules/adv/adv.saga';
import { watchAlbums } from 'reactApp/modules/albums/albums.saga';
import { watchAttchesRoot } from 'reactApp/modules/attaches/attaches.saga';
import { watchBuySubscription } from 'reactApp/modules/buySubscription/buySubscription.saga';
import { watchContacts } from 'reactApp/modules/contacts/contacts.saga';
import { watchCreditCard } from 'reactApp/modules/creditCard/creditCard.saga';
import { watchCreditCardDmrLink } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.saga';
import { watchDwh } from 'reactApp/modules/dwh/dwh.saga';
import { watchBilling } from 'reactApp/modules/billing/billing.saga';
import { watchFaces } from 'reactApp/modules/faces/faces.saga';
import { watchFeed } from 'reactApp/modules/feed/feed.saga';
import { watchFileHistory } from 'reactApp/modules/fileHistory/fileHistory.saga';
import { watchAccessControlList } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.saga';
import { watchGallery } from 'reactApp/modules/gallery/gallery.saga';
import { watchRouteChange } from 'reactApp/modules/home/home.saga';
import { initSagas } from 'reactApp/modules/init/init.saga';
import { watchModifying } from 'reactApp/modules/modifying/modifying.saga';
import { watchPaidInfo } from 'reactApp/modules/paidInfo/paidInfo.saga';
import { watchPayment } from 'reactApp/modules/payment/payment.saga';
import { watchLoadDocuments } from 'reactApp/modules/personalDocuments/personalDocuments.saga';
import { watchDialogsRoot } from 'reactApp/modules/popup/popup.saga';
import { watchProductsRoot } from 'reactApp/modules/products/products.saga';
import { watchProfile } from 'reactApp/modules/profile/profile.saga';
import { promoRoot } from 'reactApp/modules/promo/promo.saga';
import { watchPromoTariffsRoot } from 'reactApp/modules/promoTariffs/promoTariffs.saga';
import { watchPublic } from 'reactApp/modules/public/public.saga';
import { watchQuotaLanding } from 'reactApp/modules/quotaLanding/quotaLanding.saga';
import { watchRouterRoot } from 'reactApp/modules/router/router.saga';
import { watchSearch } from 'reactApp/modules/search/search.saga';
import { watchSettings } from 'reactApp/modules/settings/settings.saga';
import { watchShared } from 'reactApp/modules/shared/shared.saga';
import { watchSharedAutoDelete } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.saga';
import { watchSmsSender } from 'reactApp/modules/smsSender/smsSender.saga';
import { watchSortRoot } from 'reactApp/modules/sort/sort.saga';
import { watchLoadStart } from 'reactApp/modules/start/start.saga';
import { watchStock } from 'reactApp/modules/stock/stock.saga';
import { watchStoriesRoot } from 'reactApp/modules/stories/stories.saga';
import { watchSubscriptionsRoot } from 'reactApp/modules/subscriptions/subscriptions.saga';
import { watchTrashbin } from 'reactApp/modules/trashbin/trashbin.saga';
import { watchUploaderRoot } from 'reactApp/modules/upload/upload.saga';
import { watchContinueUploadRoot } from 'reactApp/modules/uploadList/continueUpload/continueUpload.saga';
import { watchUploadListRoot } from 'reactApp/modules/uploadList/uploadList.saga';
import { watchUserQuota } from 'reactApp/modules/userQuota/userQuota.saga';
import { watchViewerRoot } from 'reactApp/modules/viewer/viewer.saga';
import { watchWelcomeRoot } from 'reactApp/modules/welcome/welcome.saga';
import { watchUserQuotaCleaner } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.saga';
import { all, fork } from 'redux-saga/effects';
import { watchIncoming } from 'reactApp/modules/incoming/incoming.saga';
import { watchFilesFilter } from 'reactApp/modules/filesFilter/filesFilter.saga';
import { watchSelections } from 'reactApp/modules/selections/selection.saga';
import { watchEmbedded } from 'reactApp/modules/embedded/embedded.saga';
import { watchUploadingRoot } from 'reactApp/modules/uploading/uploading.saga';
import { watchAllDocuments } from 'reactApp/modules/allDocuments/allDocuments.saga';
import { watchPromocodes } from 'reactApp/modules/promocode/promocode.saga';
import { watchIntegration } from 'reactApp/modules/integration/integration.saga';
import { watchDomainFolders } from 'reactApp/modules/domainFolders/domainFolders.saga';
import { watchFamily } from 'reactApp/modules/family/family.saga';
import { watchCopyOfNoneEditableFile } from 'reactApp/components/CreateCopyOfNoneditableFileModal/useCreateCopyOfFile.saga';
import { watchUserAndFamilyQuota } from 'reactApp/modules/userAndFamilyQuota/userAndFamilyQuota.saga';
import { watchLlmDialog } from 'reactApp/modules/editor/sagas/llmDialog.saga';
import { watchEbook } from 'reactApp/modules/ebook/ebook.saga';
import { watchActivities } from 'reactApp/modules/activities/activities.saga';
import { watchTheme } from 'reactApp/modules/theme/theme.saga';

export function* rootSaga(): any {
    yield all([
        fork(watchRouteChange),
        fork(watchPublic),
        fork(watchStock),
        fork(watchSearch),
        fork(watchTrashbin),
        fork(watchFilesFilter),
        fork(watchFeed),
        fork(watchModifying),
        fork(watchAttchesRoot),
        fork(watchLoadStart),
        fork(watchViewerRoot),
        fork(watchUploaderRoot),
        fork(watchUploadListRoot),
        fork(watchContinueUploadRoot),
        fork(watchContacts),
        fork(watchGallery),
        fork(watchPaidInfo),
        fork(watchSettings),
        fork(watchSharedAutoDelete),
        fork(watchShared),
        fork(watchCreditCard),
        fork(watchCreditCardDmrLink),
        fork(watchLoadDocuments),
        fork(watchFileHistory),
        fork(watchDialogsRoot),
        fork(watchSmsSender),
        fork(watchProfile),
        fork(watchWelcomeRoot),
        fork(promoRoot),
        fork(watchSubscriptionsRoot),
        fork(watchUserQuota),
        fork(watchQuotaLanding),
        fork(watchBuySubscription),
        fork(watchAccessControlList),
        fork(watchStoriesRoot),
        fork(watchAlbums),
        fork(watchSortRoot),
        fork(watchRouterRoot),
        fork(initSagas),
        fork(watchFaces),
        fork(watchIncoming),
        fork(watchProductsRoot),
        fork(watchPromoTariffsRoot),
        fork(watchPayment),
        fork(watchUserQuotaCleaner),
        fork(watchSelections),
        fork(watchDwh),
        fork(watchAdv),
        fork(watchEmbedded),
        fork(watchUploadingRoot),
        fork(watchAllDocuments),
        fork(watchPromocodes),
        fork(watchIntegration),
        fork(watchDomainFolders),
        fork(watchFamily),
        fork(watchCopyOfNoneEditableFile),
        fork(watchUserAndFamilyQuota),
        fork(watchLlmDialog),
        fork(watchEbook),
        fork(watchBilling),
        fork(watchActivities),
        fork(watchTheme),
    ]);
}
