import { Space } from '@mail/cross-sizes-utils';
import { Spacing, Text, Title } from '@vkontakte/vkui';
import format from 'date-fns/format';
import React, { memo, ReactElement, useMemo } from 'react';
import { Product } from 'reactApp/types/Billing';
import { getInfoForSaveSubscriptionsPromo } from 'reactApp/ui/Mobile/SplashScreen/helpers/SaveSubscriptionPromo.helpers';
import { defaultDateFormat } from 'reactApp/utils/formatDate';
import { getTrialOrDiscountDateEnd } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import styles from './SaveSubscriptionSidebar.css';

export interface ISaveSubscriptionProps {
    oldSubscriptionSpace: Space;
    source: string;
    isTransit: boolean;
}

interface Props extends ISaveSubscriptionProps {
    product: Product;
}

export const SaveSubscriptionSidebar = memo(({ product, oldSubscriptionSpace, source, isTransit }: Props): ReactElement => {
    const { trialPeriod } = product ?? {};

    const { paymentTitle, text } = getInfoForSaveSubscriptionsPromo({ source, product, oldSubscriptionSpace, isTransit });

    const paymentInfo = useMemo(
        () => (
            <>
                Для проверки карты мы спишем и сразу же вернем 1 руб. Стоимость тарифа {formatPrice(product?.discountPrice)} руб. начиная с{' '}
                {Boolean(trialPeriod) && format(getTrialOrDiscountDateEnd(trialPeriod), defaultDateFormat)}. Подписка продлевается
                автоматически, её можно отменить в любой момент.
            </>
        ),
        [product?.discountPrice, trialPeriod]
    );

    return (
        <div className={styles.root}>
            <div className={styles.tariffInfo}>
                <Title level="1" weight="2" className={styles.title}>
                    {paymentTitle}
                </Title>
                <Spacing size={16} />
                <Text className={styles.title}>{text}</Text>
            </div>
            <div>
                <div className={styles.price}>
                    {isTransit ? 'Сохранить' : 'Подключить'} тариф
                    <br />
                    за&nbsp;
                    <span className={styles.oldPriceText}>{formatPrice(product?.price)} ₽</span>&nbsp;
                    <span className={styles.discountPrice}>{formatPrice(product?.discountPrice)} ₽</span>
                </div>
                <Spacing size={24} />
                <div className={styles.paymentInfo}>{paymentInfo}</div>
            </div>
        </div>
    );
});

SaveSubscriptionSidebar.displayName = 'TrialPromoSidebar';
