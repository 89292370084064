import React, { useMemo } from 'react';
import { FastCheckoutSidebar } from 'reactApp/components/VkBuyModalWithSidebar/FastCheckoutSidebar/FastCheckoutSidebar';
import { SaveSubscriptionSidebar } from 'reactApp/components/VkBuyModalWithSidebar/SaveSubscriptionSidebar/SaveSubscriptionSidebar';
import { TrialPromoSidebar } from 'reactApp/components/VkBuyModalWithSidebar/TrialPromoSidebar/TrialPromoSidebar';
import styles from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.css';
import { EPaymentModalType, Options } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { Tariff } from 'reactApp/types/Billing';
import { PaymentFooter } from 'reactApp/ui/PaymentFooter/PaymentFooter';

interface HookProps {
    type: EPaymentModalType;
    product: any;
    changeProduct: (id: string) => void;
    onClickTariffs: () => void;
    tariff: Tariff;
    isMarketingPromo?: boolean;
    sidebarProps: any;
    options?: Options;
    featuresList?: string[];
    isFrame?: boolean;
    title?: string;
    hideOtherTariffs?: boolean;
}

export const useContent = ({
    type,
    product,
    changeProduct,
    onClickTariffs,
    sidebarProps,
    tariff,
    isMarketingPromo,
    options,
    featuresList,
    isFrame,
    title,
    hideOtherTariffs = false,
}: HookProps) => {
    const sidebar = useMemo(() => {
        if (type === EPaymentModalType.trial || type === EPaymentModalType.attachesTrial) {
            return <TrialPromoSidebar product={product} options={options} />;
        }

        if (type === EPaymentModalType.fastcheckout) {
            return (
                <FastCheckoutSidebar
                    product={product}
                    onProductChange={changeProduct}
                    onClickTariffs={onClickTariffs}
                    tariff={tariff}
                    isMarketingPromo={isMarketingPromo}
                    featuresList={featuresList}
                    isBuy={isFrame}
                    hideOtherTariffs={hideOtherTariffs}
                />
            );
        }

        if (type === EPaymentModalType.saveSubscription) {
            return <SaveSubscriptionSidebar product={product} {...sidebarProps} />;
        }
    }, [type, product, changeProduct, onClickTariffs, tariff, isMarketingPromo, isFrame, sidebarProps, hideOtherTariffs]);

    const header = useMemo(() => {
        if (title) {
            return <div className={styles.header}>{title}</div>;
        }
        if (type === EPaymentModalType.fastcheckout) {
            return <div className={styles.header}>Подписка на единый тариф Почты и Облака</div>;
        }
    }, [type, title]);

    const footer = useMemo(() => {
        if (type === EPaymentModalType.fastcheckout) {
            return <PaymentFooter productId={product?.id} />;
        }
    }, [product?.id, type]);

    return {
        sidebar,
        header,
        footer,
    };
};
