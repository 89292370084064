import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { OvidiusEvents } from 'reactApp/utils/analytics/types';

export const enum AttachError {
    Info = 'attach_no_parts_info',
    Url = 'attach_no_url',
    Hash = 'attach_no_hash',
}

export const enum FileError {
    Info = 'no_parts_info',
    FirstPart = 'no_first_part',
    SecondPart = 'no_new_part',
}

export type UseOvidiusError = `api_${string}` | AttachError | FileError;

export interface UseOvidiusProps {
    file: CloudFile;
    storage: EStorageType;
    sendRadar: (event: OvidiusEvents, withTimer?: boolean, isErrorEvent?: boolean, error?: any) => void;
}

export interface UseOvidiusData {
    content: ArrayBuffer[];
    partsAmount: number;
    error: UseOvidiusError | null;
}
