import { ServerError } from './ServerError';

class WrongResponseError extends ServerError {
    static radarName = 'failure_{SOURCE}_wrong-response';

    isRetryable = true;
    retryTimeout = 1000;

    className = 'WrongResponseError';
}

export { WrongResponseError };
