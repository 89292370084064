/* eslint-disable react/display-name, sonarjs/no-duplicate-string, sonarjs/no-identical-functions */
import app from 'Cloud/Application/app';
import folderCreator from 'Cloud/Application/FolderCreator';
import classNames from 'clsx';
import React from 'react';
import { getCurrentFolder } from 'reactApp/appHelpers/appHelpers';
import { ENABLE_FULL_RESPONSIVE, IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { copyRequest, moveRequest } from 'reactApp/modules/modifying/modifying.actions';
import { addFolderHelper } from 'reactApp/modules/modifying/modifying.helpers';
import { EModifyReason } from 'reactApp/modules/modifying/modifying.types';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getStorageItemById, isFolderActionDisabled } from 'reactApp/modules/storage/storage.selectors';
import { CloudItem } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';
import { EActionId, ESelectFolderDialogMod } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.types';
import { noop, noopVoid, promisifyDeferredCall } from 'reactApp/utils/helpers';

import styles from './SelectFolderDialog.css';

const confirmDialogClassName = classNames(styles.confirmDialog, {
    [styles.confirmDialogResponsive]: ENABLE_FULL_RESPONSIVE,
});

export const onMakeNewFolder = ({ id, storage, autoCreateFolderId = '' }): Promise<string> =>
    new Promise(function (resolve) {
        let parentFolder = id;
        if (!parentFolder) {
            parentFolder = getCurrentFolder().id;
        }
        let folderCreateApi;
        if (app.addFolder) {
            folderCreateApi = (...args) => promisifyDeferredCall(app.addFolder, ...args);
        } else {
            folderCreateApi = (...args) => {
                const [item, parent, skipXhr, conflictMode, storageName] = args;

                return addFolderHelper({
                    item,
                    parent,
                    storage: storageName,
                    skipXhr,
                    conflictMode,
                    reason: EModifyReason.add,
                }).then((item) => item.id);
            };
        }
        if (autoCreateFolderId) {
            folderCreateApi(autoCreateFolderId, parentFolder, undefined, undefined, storage)
                .then(resolve)
                .catch(function () {
                    resolve('');
                });
            return;
        }
        folderCreator
            .openNewFolderDialogAndCreate({
                parentFolder,
                storageName: storage,
                folderCreateApi,
                showShareButton: false,
            })
            .then(({ data, result }: { data?: any; result?: boolean } = {}) => {
                if (!result || !data?.folderName) {
                    resolve('');
                    return;
                }

                resolve(data?.folderName);
            })
            .catch(() => {
                resolve('');
            });
    });

const renderMoveDialog = ({ items = [], onSuccess = noopVoid }: { items?: CloudItem[] | null; onSuccess?(): void } = {}): void => {
    const onActionClick = ({ items, destination }) => {
        onSuccess();
        reduxStore.dispatch(moveRequest({ items, destination }));
    };

    openPopupHelper({
        popupName: popupNames.SELECT_FOLDER_DIALOG,
        data: {
            actionId: EActionId.MOVE,
            title: 'Перемещение',
            selectButtonText: 'Переместить',
            waiterText: 'Ожидайте, идёт перемещение',
            alertApproveTitle: 'Переместить',
            actionPart: 'переноса',
            disableSelectDirectParentOfSelected: true,
            selectedItems: items,
            onMakeNewFolder,
            onActionClick,
            isPhone: EnvironmentSelectors.isPhone(),
        },
    });
};

function renderSelectAnotherFolderDialog({
    onSuccess = noopVoid,
    forbiddenFolderName = '',
}: {
    onSuccess: (answer: { action: 'cancel' } | { action: 'move'; destination: string }) => void;
    forbiddenFolderName: string;
}): void {
    openPopupHelper({
        popupName: popupNames.SELECT_FOLDER_DIALOG,
        data: {
            actionId: EActionId.SELECT_FOLDER_PATH,
            fullTitle: (
                <>
                    <div className={styles.title}>Загрузка в папку «{forbiddenFolderName}» невозможна.</div>
                    <div className={styles.description}>
                        Папка <span className={styles.bold}>{forbiddenFolderName}</span> доступна только для чтения. Загрузите файлы в
                        другую папку:
                    </div>
                </>
            ),
            selectButtonText: 'Переместить',
            closeOnAction: true,
            skipRoFolders: true,
            selectedId: '/',
            onClosePopup: ({ onClosePopup }) => (
                <BaseConfirmDialog
                    onClose={() => {
                        onClosePopup();
                    }}
                    onSuccess={() => {
                        onSuccess({ action: 'cancel' });
                        onClosePopup();
                    }}
                    successButtonText={'Отменить загрузку'}
                    cancelButtonText={'Выбрать другую папку'}
                    className={confirmDialogClassName}
                    renderHeader={() => 'Отменить загрузку?'}
                    renderContent={() =>
                        'Вы не можете загрузить файлы в выбранную папку, т.к. у вас к ней доступ только на просмотр. Укажите другую папку для загрузки.'
                    }
                />
            ),
            onMakeNewFolder,
            onActionClick: ({ destination }) => {
                onSuccess({ action: 'move', destination });
            },
        },
    });
}

function renderSelectUploadFolderDialog({
    onSuccess = noopVoid,
}: {
    onSuccess: (answer: { action: 'cancel' } | { action: 'upload'; destination: string }) => void;
}): void {
    openPopupHelper({
        popupName: popupNames.SELECT_FOLDER_DIALOG,
        data: {
            actionId: EActionId.SELECT_FOLDER_PATH_TO_UPLOAD,
            fullTitle: 'Куда загрузить файлы?',
            selectButtonText: 'Загрузить',
            alertApproveTitle: 'Загрузить',
            closeOnAction: true,
            actionPart: 'загрузки',
            skipRoFolders: true,
            disableSelectSameId: false,
            selectedId: '/',
            onClosePopup: ({ onClosePopup }) => (
                <BaseConfirmDialog
                    onClose={noop}
                    onSuccess={() => {
                        onSuccess({ action: 'cancel' });
                        onClosePopup();
                    }}
                    successButtonText={'Отменить загрузку'}
                    cancelButtonText={'Выбрать другую папку'}
                    className={confirmDialogClassName}
                    renderHeader={() => 'Отменить загрузку?'}
                    renderContent={() => 'Вы не указали место, куда надо загрузить файлы.'}
                />
            ),
            onMakeNewFolder,
            onActionClick: ({ destination }) => {
                onSuccess({ action: 'upload', destination });
            },
            isPhone: EnvironmentSelectors.isPhone(),
        },
    });
}

const renderCopyDialog = ({ items = [], onSuccess = noopVoid }: { items?: CloudItem[] | null; onSuccess?(): void } = {}): void => {
    const onActionClick = ({ items, destination }) => {
        onSuccess();
        reduxStore.dispatch(copyRequest({ items, destination }));
    };

    openPopupHelper({
        popupName: popupNames.SELECT_FOLDER_DIALOG,
        data: {
            selectedItems: items,
            actionId: EActionId.COPY,
            title: 'Копирование',
            selectButtonText: 'Скопировать',
            waiterText: 'Ожидайте, идёт копирование',
            alertApproveTitle: 'Копировать',
            actionPart: 'копирования',
            onMakeNewFolder,
            onActionClick,
            isPhone: EnvironmentSelectors.isPhone(),
        },
    });
};

function renderSelectRestoreDialog({
    selectedItems = null,
    selectedId = '',
    onRestore = noop,
}: {
    selectedItems?;
    selectedId?: string;
    onRestore?(destination, onEnd);
}): void {
    openPopupHelper({
        popupName: popupNames.SELECT_FOLDER_DIALOG,
        data: {
            title: 'Восстановление',
            actionId: EActionId.RESTORE,
            selectButtonText: 'Восстановить',
            waiterText: 'Ожидайте, идёт восстановление',
            selectedId,
            selectedItems,
            dialogMod: ESelectFolderDialogMod.trashBin,
            onMakeNewFolder,
            isPhone: EnvironmentSelectors.isPhone(),
            closeOnAction: true,
            onActionClick: ({ _items, destination }) => onRestore(destination, noop),
        },
    });
}

function renderCreateShareDialog({ onSuccess = (_item) => {}, isReactPage = false }): void {
    let destinationFolder = '';

    openPopupHelper({
        popupName: popupNames.SELECT_FOLDER_DIALOG,
        data: {
            title: 'Настроить доступ к папке',
            actionId: EActionId.CREATE_SHARE,
            selectButtonText: 'Настроить доступ',
            waiterText: 'Ожидайте, идёт сохранение',
            dialogMod: ESelectFolderDialogMod.createShare,
            closeOnAction: true,
            onClose: () => {
                if (!destinationFolder) {
                    return;
                }

                if (isReactPage) {
                    const state = reduxStore.getState();
                    const homeItem = getHomeItemById(state, destinationFolder);
                    onSuccess(homeItem);
                }
            },
            onMakeNewFolder,
            onSelect: ({ id, storage }) =>
                new Promise(function (resolve) {
                    const state = reduxStore.getState();
                    const item = getStorageItemById(state, storage, id);

                    const disableAction = !id || id === ROOT_FOLDER_ID || isFolderActionDisabled(state, item);

                    resolve({ disableAction });
                }),
            onActionClick: ({ destination }) => {
                destinationFolder = destination;
            },
        },
    });
}

function renderCloneToCloudDialog({
    items,
    isPhone,
    isMobile,
    selectedId,
    autoCreateSelectedId,
    onActionClick,
    onClose,
}: {
    items: CloudItem[];
    isPhone: boolean;
    isMobile: boolean;
    selectedId?: string;
    autoCreateSelectedId: boolean;
    onActionClick({ destination }: { destination: string });
    onClose();
}): void {
    openPopupHelper({
        popupName: popupNames.SELECT_FOLDER_DIALOG,
        onClose,
        data: {
            title: 'Сохранение в Облако',
            actionId: EActionId.CLONE,
            selectButtonText: 'Сохранить',
            waiterText: 'Ожидайте, идёт сохранение',
            isPhone,
            isMobile,
            selectedId,
            autoCreateSelectedId,
            selectedItems: items,
            onMakeNewFolder,
            onActionClick,
            dialogMod: IS_BIZ_USER,
        },
    });
}

function renderSelectFolderDialog({
    onSuccess = noopVoid,
    title = 'Выберите папку',
    selectButtonText = 'Создать',
    alertApproveTitle = 'Создать',
}: {
    onSuccess: (answer: { action: 'cancel' } | { action: 'create'; destination: string }) => void;
    title?: string;
    selectButtonText?: string;
    alertApproveTitle?: string;
}): void {
    openPopupHelper({
        popupName: popupNames.SELECT_FOLDER_DIALOG,
        data: {
            actionId: EActionId.SELECT_FOLDER_PATH_COMMON,
            fullTitle: title,
            selectButtonText,
            alertApproveTitle,
            closeOnAction: true,
            actionPart: 'создание',
            skipRoFolders: true,
            disableSelectSameId: false,
            selectedId: '/',
            onMakeNewFolder,
            onActionClick: ({ destination }) => {
                onSuccess({ action: 'create', destination });
            },
            isPhone: EnvironmentSelectors.isPhone(),
        },
    });
}

export {
    renderMoveDialog,
    renderCopyDialog,
    renderSelectRestoreDialog,
    renderCreateShareDialog,
    renderSelectAnotherFolderDialog,
    renderSelectUploadFolderDialog,
    renderCloneToCloudDialog,
    renderSelectFolderDialog,
};
