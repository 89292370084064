import { bytesToNDigits } from '@mail/cross-sizes-utils';
import config from 'Cloud/config';
import { CLOUD_SOURCE } from 'reactApp/constants/paymentUTM';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getSubscriptionsUrl } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.constants';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { noop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { getUtmString } from 'reactApp/utils/urlHelper';

export const openDisabledFeaturePopupHelper = ({ disabledFeature }: { disabledFeature: DisabledFeature }) => {
    const { bytes_used, bytes_total } = config.get('user.cloud.space') || {};
    const isBizUser = Boolean(config.get('BIZ_USER'));
    const over = bytesToNDigits(bytes_used >= bytes_total ? bytes_used - bytes_total : 0, 3);

    const subsLink = getSubscriptionsUrl(
        getUtmString({ utm_source: CLOUD_SOURCE, utm_medium: 'subs', utm_campaign: `disable_feature_${disabledFeature}` })
    );

    openPopupHelper({
        popupName: popupNames.DISABLED_FEATURE,
        onClose: noop,
        data: {
            onBuy: () => opener(subsLink),
            disabledFeature,
            overquotaSize: `${over.space} ${over.units}`,
            isBizUser,
        },
    });
};
