import { createAction, createReducer } from '@reduxjs/toolkit';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

import { EbookFont, EbookState, EbookTheme, Options } from './ebook.types';

const initialState: EbookState = {
    isActiveBookmark: false,
    isOptionDlgShown: false,
    options: {
        theme: EbookTheme.LIGHT,
        size: 16,
        fontFamily: EbookFont.Arial,
    },
};

export const setOptionsConfig = createAction<Options>('ebook/setOptionsConfig');
export const setIsActiveBookmark = createAction<boolean>('ebook/setIsActiveBookmark');
export const setIsOptionDlgShown = createAction<boolean>('ebook/setIsOptionDlgShown');
export const setDownloadEbookClick = createAction<boolean>('ebook/setDownloadEbookClick');
export const cloneEpubFromPublic = createAction<{
    data: { source: string; id?: string };
    onSuccess?: (result: { idx: string[]; home: string[]; storageFrom: EStorageType }) => void;
    onFailure?: () => void;
}>('ebook/cloneEpubFromPublic');

export const ebookReducer = createReducer(initialState, {
    [setOptionsConfig.type]: (state, action: ReturnType<typeof setOptionsConfig>) => {
        state.options = action.payload;
    },
    [setIsActiveBookmark.type]: (state, action: ReturnType<typeof setIsActiveBookmark>) => {
        state.isActiveBookmark = action.payload;
    },
    [setIsOptionDlgShown.type]: (state, action: ReturnType<typeof setIsOptionDlgShown>) => {
        state.isOptionDlgShown = action.payload;
    },
    [setDownloadEbookClick.type]: (state, action: ReturnType<typeof setDownloadEbookClick>) => {
        state.downloadEbookClick = action.payload;
    },
});
