import rustoreIcon from 'img/icons/rustore.svg?url';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { rustoreApkSnackbar } from 'reactApp/appHelpers/featuresHelpers';
import { isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { loadPublicFileSuccess, loadPublicPage } from 'reactApp/modules/public/public.actions';
import { getPublicItemById } from 'reactApp/modules/public/public.selectors';
import { PublicItem } from 'reactApp/modules/public/public.types';
import { rustoreSnackbarCancel, rustoreSnackbarShow } from 'reactApp/modules/public/sagas/rustoreSnackbar.actions';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { checkViewerAtIdChange } from 'reactApp/modules/viewer/viewer.module';
import { sendDwh } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';

const RUSTORE_SNACKBAR_KEY = 'rustore-snackbar';
const APK_FILE_EXT = 'apk';
const TARGET_URL = 'https://www.rustore.ru/?rsm=1&mt_link_id=hiz8b3';

enum EventPublicType {
    folder = 'folder',
    file = 'file',
}

enum RustoreSnackBarDwhAction {
    showSnackbar = 'snack_rustore',
    clickButton = 'snack_rustore_action',
}

function* showSnackbar(isFolder?: boolean) {
    yield delay(5000);
    yield put(
        showSnackbarAction({
            id: 'rustore-apk-message',
            type: SnackbarTypes.success,
            title: 'Установите RuStore',
            text: 'И&nbsp;получите безопасный доступ к&nbsp;приложениям для Android',
            buttonText: 'Перейти в RuStore',
            thumb: {
                data: { src: rustoreIcon },
            },
            onButtonClick: () => {
                sendDwh({
                    action: RustoreSnackBarDwhAction.clickButton,
                    eventCategory: 'public',
                    dwhData: {
                        type_public: isFolder ? EventPublicType.folder : EventPublicType.file,
                    },
                });
                opener(TARGET_URL);
            },
            onShow: () => {
                sendDwh({
                    action: RustoreSnackBarDwhAction.showSnackbar,
                    eventCategory: 'public',
                    dwhData: {
                        type_public: isFolder ? EventPublicType.folder : EventPublicType.file,
                    },
                });
                storeHelper.markAsShown(RUSTORE_SNACKBAR_KEY);
            },
            closable: true,
        })
    );
}

function* watchShowSnackbarAction(action) {
    yield race({
        task: call(showSnackbar, action.payload.isFolder),
        cancel: take(rustoreSnackbarCancel),
    });
}

function* watchForViewerItemIdChange(action) {
    const storage = yield select(getCurrentStorage);
    const item = yield select(getPublicItemById, action?.payload?.id);

    if (storage !== EStorageType.public && !item) {
        return;
    }

    yield call(watchPublicUpdates, item);
}

function* watchPublicFolderDataUpdates() {
    const storage = yield select(getCurrentStorage);
    const weblinkId = yield select(getCurrentRouteId);
    const item = yield select(getPublicItemById, weblinkId);

    if (storage !== EStorageType.public && !item) {
        return;
    }

    yield call(watchPublicUpdates, item);
}

function* watchPublicUpdates(item: PublicItem) {
    const lastTime = storeHelper.getLastTime(RUSTORE_SNACKBAR_KEY);
    const isSnackNeeded = !lastTime || storeHelper.isPassed(RUSTORE_SNACKBAR_KEY, { daysCount: 90 });

    if (!isSnackNeeded) {
        return;
    }

    // Показываем снекбар, если публичная папка содержит .apk файл или .apk файл был открыт по прямой ссылке
    if (
        (item?.isFolder && item?.childs?.some((child) => child.endsWith(`.${APK_FILE_EXT}`))) ||
        (!item?.isFolder && item?.ext === APK_FILE_EXT)
    ) {
        yield put(rustoreSnackbarShow({ isFolder: item?.isFolder }));
    } else {
        yield put(rustoreSnackbarCancel());
    }
}

export function* watchRustoreSnackbar() {
    if (rustoreApkSnackbar && !IS_WEBVIEW && !isViewContentOnly) {
        yield takeLatest([loadPublicPage, loadPublicFileSuccess], watchPublicFolderDataUpdates);
        yield takeLatest(checkViewerAtIdChange, watchForViewerItemIdChange);
        yield takeLatest(rustoreSnackbarShow, watchShowSnackbarAction);
    }
}
