/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import {
    Icon20AddCircleOutline,
    Icon20Chain,
    Icon20CopyOutline,
    Icon20DeleteOutline,
    Icon20DocumentListOutline,
    Icon20DocumentPlusOutline,
    Icon20DocumentStatsOutline,
    Icon20DocumentTextOutline,
    Icon20DownloadOutline,
    Icon20FolderSimpleArrowRightOutlune,
    Icon20FolderSimpleOutline,
    Icon20FolderSimplePlusOutline,
    Icon20Grid4UnevenVerticalOutline,
    Icon20HistoryBackwardOutline,
    Icon20Like,
    Icon20LikeOutline,
    Icon20LockOutline,
    Icon20MailOutline,
    Icon20PictureOutline,
    Icon20PicturePlusOutline,
    Icon20Rectangle2HorizontalOutline,
    Icon20Square4Outline,
    Icon20UserAddOutline,
    Icon20UserOutline,
    Icon20UsersOutline,
    Icon20WriteSquareOutline,
    Icon24ExternalLinkOutline,
} from '@vkontakte/icons';
import app from 'Cloud/Application/app';
import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import { isFeature } from 'Cloud/Application/FeaturesEs6';
import { user } from 'Cloud/Application/User';
import { ReactComponent as Icon20ArrowDownSmallOutline } from 'img/icons/vkui/arrow_down_small_outline_20.svg';
import { ReactComponent as SocialsShareIcon } from 'mrg-icons/src/mailru/actions/share.svg';
import { ReactComponent as SocialsShareMmIcon } from 'mrg-icons/src/mailru/social/moimir.svg';
import { ReactComponent as SocialsShareOkIcon } from 'mrg-icons/src/mailru/social/ok.svg';
import { ReactComponent as SocialsShareTwIcon } from 'mrg-icons/src/mailru/social/twitter.svg';
import { ReactComponent as SocialsShareVkIcon } from 'mrg-icons/src/mailru/social/vk.svg';
import { path, pathOr } from 'ramda';
import React from 'react';
import { getDownloadItemSize, isDomainFolder, isReadOnly } from 'reactApp/appHelpers/appHelpers';
import {
    IS_BIZ_USER,
    IS_DOCUMENTS_DOMAIN,
    IS_FREE_BIZ_SAAS_USER,
    IS_MY_TEAM,
    IS_ONPREMISE,
    IS_OWN_PUBLIC,
    IS_PUBLIC_ALBUM,
    platform,
} from 'reactApp/appHelpers/configHelpers';
import { isFileForOpenInR7Editor, openEditor } from 'reactApp/appHelpers/editorHelpers';
import { activitiesFeature, isActivitiesFeatureEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { publishHelper, unPublishHelper } from 'reactApp/appHelpers/publishHelper';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { MAX_EMAIL_URL_LENGTH, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { VIEW_MODE_THUMBS_TEXT } from 'reactApp/constants/viewMode';
import { isAddToAlbumsEnabled, isCreatePublicAlbumsEnabled } from 'reactApp/modules/albums/albums.selector';
import { isAlbum } from 'reactApp/modules/albums/albums.types';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { getCreateDescriptions, getEditorsByExtension } from 'reactApp/modules/editor/editor.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureEditingNoneditableFormats } from 'reactApp/modules/features/features.selectors';
import { isMediaFolderAndGalleryEnabled } from 'reactApp/modules/file/utils';
import { filesFilterAll, filesFilterOne } from 'reactApp/modules/filesFilter/filesFilter.module';
import { getCurrentFilter } from 'reactApp/modules/filesFilter/filesFilter.selectors';
import { getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { getCurrentFolderHome, getDomainFoldersFilterActive } from 'reactApp/modules/home/home.selectors';
import { addToFavorites, removeFromFavorites } from 'reactApp/modules/modifying/modifying.actions';
import { getGoToAndScrollUrl } from 'reactApp/modules/modifying/modifying.helpers';
import { isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { EActionSearch } from 'reactApp/modules/search/search.types';
import { settingsController } from 'reactApp/modules/settings/settings.controller';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getSort } from 'reactApp/modules/sort/sort.selectors';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { isMountedFolder, isSharedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorageConfig } from 'reactApp/modules/storage/storage.config';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    getFolderAuthors,
    getStorageCurrentFolder,
    hasParentMountedOrSharedFolder,
    hasParentsDomainFolder,
    isMountedOrSharedFolder,
} from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { isUploadFolderAllowed } from 'reactApp/modules/upload/upload.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { openUserQuotaItemView } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.actions';
import { EDocsAction, EDocSubKind } from 'reactApp/sections/AllDocuments/allDocuments.types';
import { store } from 'reactApp/store';
import { DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { ExternalLinkIcon, FileSizeIcon, Icon20CalendarOutline, TextIcon, UploadIcon as SaveToCloudIcon } from 'reactApp/ui/VKUIIcons';
import { canCloneWeblink } from 'reactApp/utils/cloneHelpers';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';
import { sendDwh } from 'reactApp/utils/ga';
import { noop, noopVoid } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { getTrimmedText } from 'reactApp/utils/textHelpers';

import { ContextMenuItem } from './ContextMenu';
import { getPathToNewTab, makeFlatDividedList } from './ContextMenu.helpers';

const isMobile = EnvironmentSelectors.isMobile() || EnvironmentSelectors.isIpadOs();
const sendViaMailEnabled = isFeature('attaches-enabled');

export interface Item {
    id: string;
    href?: string;
    home?: string;
    weblink: undefined | string;
    isFolder: boolean;
    isInFavorites: boolean;
    sizeTitle: string;

    [key: string]: unknown;

    parentFolder?: string;
    parent?: string;
    ext?: string;
    subKind?: string;
}

const CONTEXT_MENU = 'context-menu';

export const contextMenuItems: { [key: string]: ContextMenuItem } = {
    create: {
        id: 'create',
        text: 'Создать',
        icon: <Icon20AddCircleOutline data-qa-id="create" />,
        mod: 'new',
    },
    createFolder: {
        id: 'createFolder',
        text: 'Папку',
        icon: <Icon20FolderSimpleOutline data-qa-id="createFolder" />,
        onClick: (): void => toolbarActions.create('folder', 'ctx'),
        mod: 'new',
        hotkey: 'Shift+F',
    },
    createDoc: {
        id: 'createDoc',
        text: 'Документ',
        icon: <Icon20DocumentTextOutline data-qa-id="createDoc" />,
        onClick: (): void => toolbarActions.create('docx', 'ctx'),
        mod: 'new',
        hotkey: 'Shift+T',
    },
    createCell: {
        id: 'createCell',
        text: 'Таблицу',
        icon: <Icon20DocumentListOutline data-qa-id="createCell" />,
        onClick: (): void => toolbarActions.create('xlsx', 'ctx'),
        mod: 'new',
        hotkey: 'Shift+S',
    },
    createPpt: {
        id: 'createPpt',
        text: 'Презентацию',
        icon: <Icon20DocumentStatsOutline data-qa-id="createPpt" />,
        onClick: (): void => toolbarActions.create('pptx', 'ctx'),
        mod: 'new',
        hotkey: 'Shift+P',
    },
    view: {
        id: 'view',
        text: 'Вид',
        icon: <Icon20Square4Outline data-qa-id="view" />,
    },
    viewThumbs: {
        text: VIEW_MODE_THUMBS_TEXT,
        id: 'viewThumbs',
        icon: <Icon20Square4Outline data-qa-id="viewThumbs" />,
        withStatus: true,
        onClick: () => toolbarActions.view('thumbs'),
    },
    viewList: {
        text: 'Списком',
        id: 'viewList',
        icon: <Icon20Rectangle2HorizontalOutline data-qa-id="viewList" />,
        withStatus: true,
        onClick: () => toolbarActions.view('list'),
    },
    viewGallery: {
        text: 'Галереей',
        id: 'viewGallery',
        icon: <Icon20Grid4UnevenVerticalOutline />,
        withStatus: true,
        onClick: () => toolbarActions.view('gallery'),
    },
    sort: {
        id: 'sort',
        text: 'Сортировка',
        icon: <Icon20ArrowDownSmallOutline data-qa-id="sort" />,
    },
    sortName: {
        text: 'По алфавиту',
        id: 'sortName',
        withStatus: true,
        icon: <TextIcon data-qa-id="sortName" />,
        onClick: (asc: boolean) => toolbarActions.sort(`name-${asc ? ESortOder.asc : ESortOder.desc}`),
    },
    sortSize: {
        text: 'По размеру файла',
        id: 'sortSize',
        withStatus: true,
        icon: <FileSizeIcon data-qa-id="sortSize" />,
        onClick: (asc: boolean) => toolbarActions.sort(`size-${asc ? ESortOder.asc : ESortOder.desc}`),
    },
    sortDate: {
        text: `По дате${IS_BIZ_USER ? ' изменения' : ' создания'}`,
        id: 'sortDate',
        withStatus: true,
        icon: <Icon20CalendarOutline data-qa-id="sortDate" />,
        onClick: (asc: boolean) => toolbarActions.sort(`mtime-${asc ? ESortOder.asc : ESortOder.desc}`),
    },
    socialsShare: {
        id: 'socialsShare',
        text: 'Поделиться',
        icon: <SocialsShareIcon data-qa-id="socialsShare" />,
    },
    socialsShareVk: {
        text: 'VK',
        id: 'socialsShareVk',
        icon: <SocialsShareVkIcon data-qa-id="socialsShareVk" />,
    },
    socialsShareOk: {
        text: 'Одноклассники',
        id: 'socialsShareOk',
        icon: <SocialsShareOkIcon data-qa-id="socialsShareOk" />,
    },
    socialsShareTw: {
        text: 'Twitter',
        id: 'socialsShareTw',
        icon: <SocialsShareTwIcon data-qa-id="socialsShareTw" />,
    },
    socialsShareMm: {
        text: 'Мой мир',
        id: 'socialsShareMm',
        icon: <SocialsShareMmIcon data-qa-id="socialsShareMm" />,
    },
    newTab: {
        text: 'Открыть в новой вкладке',
        id: 'newTab',
        icon: <Icon24ExternalLinkOutline data-qa-id="newTab" />,
    },
    parentFolder: {
        text: 'Показать в папке',
        id: 'parentFolder',
        icon: <Icon20FolderSimpleOutline data-qa-id="parentFolder" />,
    },
    showInCloud: {
        text: 'Показать в Облаке',
        id: 'showInCloud',
        divider: true,
        icon: <ExternalLinkIcon data-qa-id="showInCloud" />,
    },
    publish: {
        text: 'Открыть доступ по ссылке',
        id: 'publish',
        icon: <Icon20Chain data-qa-id="publish" />,
    },
    publishAlbum: {
        text: 'Поделиться',
        id: 'publishAlbum',
        icon: <Icon20PictureOutline data-qa-id="publish-album" />,
        onClick: toolbarActions.createAndPublishAlbum,
    },
    addToAlbum: {
        text: 'Добавить в альбом',
        id: 'add-to-album',
        icon: <Icon20PicturePlusOutline data-qa-id="add-to-album" />,
        onClick: toolbarActions.addToAlbum,
    },
    publishGetLink: {
        text: 'Настроить доступ по ссылке',
        id: 'publishGetLink',
        icon: <Icon20Chain data-qa-id="publishGetLink" />,
    },
    unPublish: {
        text: 'Закрыть доступ по ссылке',
        id: 'unPublish',
        icon: IS_BIZ_USER ? <Icon20LockOutline /> : <Icon20Chain data-qa-id="unPublish" />,
    },
    share: {
        text: 'Пригласить пользователей',
        id: 'share',
        icon: <Icon20UserAddOutline data-qa-id="share" />,
    },
    manageAccess: {
        text: 'Управление доступом',
        id: 'manageAccess',
        icon: <Icon20UsersOutline data-qa-id="manageAccess" />,
    },
    mountFolder: {
        text: 'Подключить к Облаку',
        id: 'mountFolder',
        icon: <Icon20UsersOutline data-qa-id="mountFolder" />,
        onClick: toolbarActions.mount,
    },
    sendViaMail: {
        text: 'Отправить по почте',
        id: 'sendViaMail',
        icon: <Icon20MailOutline data-qa-id="sendViaMail" />,
        onClick: toolbarActions.forward,
    },
    saveToCloud: {
        text: 'Сохранить в Облако',
        id: 'saveToCloud',
        icon: <SaveToCloudIcon data-qa-id="saveToCloud" />,
        onClick: (id) => toolbarActions.clone({ id, source: CONTEXT_MENU }),
    },
    authors: {
        text: 'Авторы',
        id: 'authors',
        icon: <Icon20UsersOutline data-qa-id="authors" />,
    },
    showAuthorFiles: {
        text: 'Все файлы этого автора',
        id: 'authorFiles',
        icon: <Icon20UsersOutline data-qa-id="authorFiles" />,
    },
    showAllAuthorFiles: {
        text: 'Файлы от всех авторов',
        id: 'allAuthorFiles',
        icon: <Icon20UsersOutline data-qa-id="allAuthorFiles" />,
    },
    showMyFilesOnly: {
        text: 'Только мои файлы',
        id: 'showMyFilesOnly',
        withStatus: true,
        icon: <Icon20UserOutline data-qa-id="showMyFilesOnly" />,
    },
    rename: {
        text: 'Переименовать',
        id: 'rename',
        icon: <Icon20WriteSquareOutline data-qa-id="rename" />,
        onClick: toolbarActions.rename,
    },
    addToFavorite: {
        text: 'Добавить в избранные',
        id: 'addToFavorite',
        icon: <Icon20LikeOutline data-qa-id="addToFavorite" />,
    },
    removeFromFavorites: {
        text: 'Убрать из избранных',
        id: 'removeFromFavorites',
        icon: <Icon20Like color="var(--vkui--color_background_negative)" data-qa-id="removeFromFavorites" />,
    },
    copy: {
        text: 'Копировать',
        id: 'copy',
        icon: <Icon20CopyOutline data-qa-id="copy" />,
        onClick: toolbarActions.copy,
    },
    viewHistory: {
        text: 'Посмотреть историю',
        id: 'viewHistory',
        icon: <Icon20HistoryBackwardOutline data-qa-id="viewHistory" />,
        onClick: () => toolbarActions.viewHistory(true),
    },
    move: {
        text: 'Переместить в папку',
        id: 'move',
        icon: <Icon20FolderSimpleArrowRightOutlune data-qa-id="move" />,
        onClick: toolbarActions.move,
    },
    remove: {
        text: 'Переместить в корзину',
        id: 'remove',
        icon: <Icon20DeleteOutline data-qa-id="remove" />,
        onClick: toolbarActions.remove,
    },
    removeFromAlbums: {
        text: 'Удалить',
        id: 'remove',
        icon: <Icon20DeleteOutline data-qa-id="remove" />,
        onClick: toolbarActions.remove,
    },
    download: {
        text: 'Скачать',
        id: 'download',
        icon: <Icon20DownloadOutline data-qa-id="download" />,
        onClick: toolbarActions.download,
    },
    upload: {
        text: 'Загрузить файлы',
        id: 'upload',
        icon: <Icon20DocumentPlusOutline data-qa-id="upload" width={16} height={16} />,
        onClick: noop,
        mod: 'new',
        hotkey: 'Shift+I',
    },
    uploadFolder: {
        text: 'Загрузить папку',
        id: 'uploadFolder',
        icon: <Icon20FolderSimplePlusOutline data-qa-id="uploadFolder" width={16} height={16} />,
        onClick: noop,
        mod: 'new',
        hotkey: 'Shift+U',
    },
};

const currentFolder = (storage): any => (storage ? getStorageCurrentFolder(store.getState(), storage) : app.currentFolder());
const isAuthorized = (): boolean => user.isAuthorized();
const filterAllHandler = () => store.dispatch(filesFilterAll());
const filterOneHandler = (author) => store.dispatch(filesFilterOne(author));

export const isItemsEmailUrlOk = (items) => {
    if (!items?.length) {
        return true;
    }

    let length = 2; // 2 array brackets
    items.forEach((item) => {
        const itemLength = encodeURIComponent(item?.id).length + 3; // + 2 commas + 1 comma: "id",
        length += itemLength;
    });

    return length <= MAX_EMAIL_URL_LENGTH && items.length <= 100;
};

const getDownloadItem = (items): ContextMenuItem => {
    let text = '';
    const size = getDownloadItemSize(items);
    const textSize = size ? ` (${size})` : '';

    if (items.length === 1) {
        const ext = getTrimmedText(items[0].ext?.toLowerCase(), 10);
        const extText = ext ? `в .${ext}` : '';
        text = `Скачать ${items[0].isFolder ? 'в .zip' : extText}${textSize}`;
    } else {
        text = `Скачать в .zip${textSize}`;
    }

    if (items.length === 1 && downloadAsJpeg.isAvailable({ ext: items[0].ext, storage: items[0].storage })) {
        return {
            ...contextMenuItems.download,
            id: 'downloadAsJpeg',
            text,
            borderedArrow: true,
            openNestedByArrow: true,
            theme: DropdownTheme.jpeg,
            list: downloadAsJpeg.getDropdownItems({
                itemOrId: items[0],
                gaSuffix: CONTEXT_MENU,
                ext: items[0].ext,
            }),
        };
    }

    return {
        ...contextMenuItems.download,
        onClick: contextMenuItems.download.onClick,
        text,
    };
};

const createDefaultMenu = ({ storage, section }: { storage: string; section: string }) => {
    const storageHelper = getStorage(storage);
    const sectionHelper = getStorage(section);
    const state = store.getState();
    const currentView = settingsController.getViewMode();
    const folder = getStorageCurrentFolder(state, storage as EStorageType);
    const sort = getSort(state, folder, storage);
    const { type: sortType, order } = storageHelper.isGallery || sectionHelper.isStart ? { order: null, type: null } : sort;
    const showOnlyDomainFolders = getDomainFoldersFilterActive(state);
    const showDomainFoldersForBizUsers = IS_BIZ_USER && showOnlyDomainFolders;
    const asc = order === ESortOder.asc;
    const availableCreateEditors = getCreateDescriptions(state);
    const isMediaFolder = isMediaFolderAndGalleryEnabled(state, folder, storage as EStorageType);
    const userEmail = UserSelectors.getEmail(state);
    // @ts-ignore
    const authors = getFolderAuthors(state, folder);
    const isWritable = folder && 'weblinkAccessRights' in folder && folder?.weblinkAccessRights === 'rw';
    const storageConfig = getStorageConfig(storage as EStorageType);

    const showDownload = storageHelper.isPublic;
    const showCreate =
        !sectionHelper.isStart &&
        storageHelper.isHome &&
        !isReadOnly(folder) &&
        !storageHelper.isSearch &&
        !storageHelper.isSharedIncoming &&
        !showDomainFoldersForBizUsers;
    const showAuthors =
        ((storageHelper.isPublic && isWritable) || storageHelper.isHome) &&
        isAuthorized() &&
        (authors?.length > 1 || (authors[0] && userEmail === authors[0])) &&
        !showDomainFoldersForBizUsers;
    const showEmail = sendViaMailEnabled && storageHelper.isPublic && isAuthorized();
    const showViewMode = !isMobile && !IS_MY_TEAM;
    const showSort: boolean = storageConfig.sort;
    const isAddToAlbumsAvailable = isAddToAlbumsEnabled(state, []);

    const [group1, group2]: ContextMenuItem[][] = [[], []];

    if (storageHelper.isSharedIncoming || (sectionHelper.isStart && storageHelper.isFeed)) {
        return [];
    }

    if (storageHelper.isAlbums && isAddToAlbumsAvailable) {
        group1.push(contextMenuItems.addToAlbum);
    }

    if (showDownload) {
        group2.push(getDownloadItem([currentFolder(storage)]));
    }

    if (showEmail) {
        group2.push(contextMenuItems.sendViaMail);
    }

    if (showCreate) {
        const createList = [contextMenuItems.createFolder];
        const folder = currentFolder(storage);
        const hasParentsDomain = hasParentsDomainFolder(state, folder);
        const owner = getOwner(state);
        const isUserAllowedToCreateDocument =
            IS_FREE_BIZ_SAAS_USER && folder?.isMounted && owner?.is_paid && folder?.kind !== 'domain-folder' && !hasParentsDomain;
        if (!IS_FREE_BIZ_SAAS_USER || isUserAllowedToCreateDocument) {
            createList.push(
                ...availableCreateEditors.reduce((acc, cur) => {
                    if (cur.name === 'docx') {
                        return [...acc, contextMenuItems.createDoc];
                    } else if (cur.name === 'xlsx') {
                        return [...acc, contextMenuItems.createCell];
                    } else if (cur.name === 'pptx') {
                        return [...acc, contextMenuItems.createPpt];
                    }
                    return acc;
                }, [] as ContextMenuItem[])
            );
        }

        group1.push({
            ...contextMenuItems.create,
            list: createList,
        });
    }

    if (showAuthors) {
        const currentFilter = getCurrentFilter(state);
        // @ts-ignore
        const itemClickHandler = (id): void => {
            if (id === 'showMyFilesOnly') {
                filterOneHandler(userEmail);
            } else if (id === 'allAuthorFiles' && currentFilter !== 'all') {
                filterAllHandler();
            }
        };
        const subItems = [
            {
                ...contextMenuItems.showAllAuthorFiles,
                withStatus: true,
                active: currentFilter === 'all',
                onClick: itemClickHandler,
            },
            {
                ...contextMenuItems.showMyFilesOnly,
                active: !!userEmail && Array.isArray(currentFilter) && currentFilter.includes(userEmail) && currentFilter.length === 1,
                onClick: itemClickHandler,
            },
        ];

        group2.push({
            ...contextMenuItems.authors,
            list: subItems,
        });
    }

    if (showViewMode) {
        const viewItem = {
            ...contextMenuItems.view,
            list: [
                {
                    ...contextMenuItems.viewList,
                    active: currentView === EViewMode.list,
                },
                {
                    ...contextMenuItems.viewThumbs,
                    active: currentView === EViewMode.thumbs,
                },
            ],
        };

        if (storageHelper.isGallery || isMediaFolder) {
            viewItem.list.push({
                ...contextMenuItems.viewGallery,
                active: currentView === EViewMode.gallery,
            });
        }

        group2.push(viewItem);
    }

    if (showSort) {
        group2.push({
            // eslint-disable-next-line max-lines
            ...contextMenuItems.sort,
            list: IS_BIZ_USER
                ? [
                      {
                          ...contextMenuItems.sortSize,
                          active: sortType === ESortTypes.size,
                          onClick: () =>
                              toolbarActions.sort(`size-${sortType !== ESortTypes.size || !asc ? ESortOder.asc : ESortOder.desc}`),
                          asc,
                      },
                      {
                          ...contextMenuItems.sortName,
                          active: sortType === ESortTypes.name,
                          onClick: () =>
                              toolbarActions.sort(`name-${sortType !== ESortTypes.name || !asc ? ESortOder.asc : ESortOder.desc}`),
                          asc,
                      },
                      {
                          ...contextMenuItems.sortDate,
                          active: sortType === ESortTypes.mtime,
                          onClick: () =>
                              toolbarActions.sort(`mtime-${sortType !== ESortTypes.mtime || !asc ? ESortOder.asc : ESortOder.desc}`),
                          asc,
                      },
                  ]
                : [
                      {
                          ...contextMenuItems.sortDate,
                          active: sortType === ESortTypes.mtime,
                          onClick: () =>
                              toolbarActions.sort(`mtime-${sortType !== ESortTypes.mtime || !asc ? ESortOder.asc : ESortOder.desc}`),
                          asc,
                      },
                      {
                          ...contextMenuItems.sortSize,
                          active: sortType === ESortTypes.size,
                          onClick: () =>
                              toolbarActions.sort(`size-${sortType !== ESortTypes.size || !asc ? ESortOder.asc : ESortOder.desc}`),
                          asc,
                      },
                      {
                          ...contextMenuItems.sortName,
                          active: sortType === ESortTypes.name,
                          onClick: () =>
                              toolbarActions.sort(`name-${sortType !== ESortTypes.name || !asc ? ESortOder.asc : ESortOder.desc}`),
                          asc,
                      },
                  ],
        });
    }

    return makeFlatDividedList([group1, group2]);
};

const createSingleMenu = ({ storage, item }: { storage: EStorageType; item: Item }) => {
    const state = store.getState();
    const isSharedOrMounted = isMountedOrSharedFolder(state, item);
    const isMounted: boolean = isSharedOrMounted.isMounted;
    const isDomainItem: boolean = isDomainFolder(item);
    const storageHelper = getStorage(storage);
    const isOwner = pathOr(true, ['isOwner'], item);
    const isOwnItem = Boolean(item?.home) || (IS_PUBLIC_ALBUM && IS_OWN_PUBLIC);
    const folder = currentFolder(storage);
    const readOnly = isReadOnly(folder);
    const userEmail = UserSelectors.getEmail(state);
    const fileAuthor = path(['author'], item);
    const isWritable = isPublicUploadEnabled(state) || IS_PUBLIC_ALBUM;
    const currentFilter = getCurrentFilter(state);
    const { isAlbums, isAllDocuments } = storageHelper;
    // @ts-ignore
    const authors = getFolderAuthors(state, folder);
    const storageConfig = getStorageConfig(storage as EStorageType);
    // Элемент в альбоме
    const isAlbumItem = isAlbums && !isAlbum(item);
    const isPublicAlbumEnabled = isCreatePublicAlbumsEnabled(state, [item]);
    const hasParentMountedOrShared = hasParentMountedOrSharedFolder(state, [item as unknown as CloudItem]);
    const currentFolderIsMounted = IS_BIZ_USER && isMounted && item.id === isSharedOrMounted.folderId;

    if (activitiesFeature.trashbin && storageHelper.isTrashBin) {
        return [contextMenuItems.viewHistory];
    }

    const showOpenInNewTab = (!storageHelper.isSharedIncoming || isOwner || isMounted) && !storageHelper.isTrashBin;
    const openParentFolder = storageConfig.openParentFolder;
    const showPublish = (isAlbums && isPublicAlbumEnabled) || (!isAlbums && storageConfig.publish);
    const showShare: boolean =
        storageConfig.share &&
        !isDomainItem &&
        item.isFolder &&
        (isOwner || isMounted) &&
        (!(isSharedOrMounted.isMounted || isSharedOrMounted.isShared) || item.id === isSharedOrMounted.folderId) &&
        !(storageHelper.isSearch && hasParentMountedOrShared);
    const showAuthorFiles =
        (storageHelper.isHome || (storageHelper.isPublic && isWritable)) &&
        isAuthorized() &&
        pathOr(0, ['length'], authors) > 1 &&
        !item.isFolder;
    const showMyFiles = !item.isFolder && isWritable && storageHelper.isPublic && authors?.length === 1;
    const showSaveToCloud = (storageHelper.isPublic || storageHelper.isStock) && isAuthorized() && canCloneWeblink(isAuthorized(), storage);
    const showEmail =
        sendViaMailEnabled &&
        (!item.isFolder || storageHelper.isPublic || storageHelper.isStock) &&
        isAuthorized() &&
        !storageHelper.isQuotaCleaner;
    const showAddToFavorites =
        item.id !== ROOT_FOLDER_ID &&
        !readOnly &&
        !isDomainFolder(folder) &&
        (item.isFolder || !item.isMounted) &&
        storageConfig.favorites &&
        !isAlbum(item) &&
        !hasParentMountedOrShared;
    const showRename = !isAlbumItem && !readOnly && !isDomainItem && storageConfig.rename;
    const showCopy = storageConfig.copy;
    const showMountFolder = storageHelper.isSharedIncoming && !isMounted;
    const showRemove =
        !readOnly &&
        !isDomainItem &&
        storageConfig.remove &&
        (!storageHelper.isPublic || (isWritable && !item.isFolder && (fileAuthor === userEmail || isOwnItem)));
    const showDownload = isOwner || isMounted;
    const showViewHistory = ((!item.isFolder && !readOnly) || isActivitiesFeatureEnabled) && storageConfig.history;
    const showMove = !readOnly && !isDomainItem && !currentFolderIsMounted && storageConfig.move;
    const isAddToAlbumsAvailable = isAddToAlbumsEnabled(state, [item]);

    const [group1, group2, group3, group4]: ContextMenuItem[][] = [[], [], [], []];

    if (showOpenInNewTab) {
        const path = getPathToNewTab(item, storage, userEmail);

        let onOpenNewTab = () => {
            if (storage === EStorageType.search) {
                const dwhData = {
                    eventCategory: ECategoryGa.search,
                    action: EActionSearch.openInNewTab,
                };
                const items = [
                    {
                        file_name: item.nameWithoutExt,
                        type: item.kind || item.subkind,
                        pos: item.pos,
                        file_id: item.id,
                        extension: item.isFolder ? 'folder' : item.ext,
                    },
                ];
                store.dispatch(
                    dispatchNewSearchRadar({
                        dwhData,
                        items,
                    })
                );
            }

            if (storage === EStorageType.alldocuments) {
                const { subKind = '', size = 0, ext: extension = '' } = item || {};
                const isConvertable =
                    !!item && !!storage ? getFeatureEditingNoneditableFormats(state, { file: item as any, storage }) : undefined;
                const isEditable = getEditorsByExtension(state, (isConvertable?.ext || item.ext) as string).length > 0;

                sendDwh({
                    eventCategory: ECategoryGa.docs,
                    action: EDocsAction.open,
                    dwhData: {
                        platform,
                        extension,
                        size_file: size,
                        type_content: EDocSubKind[subKind] ?? '',
                        source: EDocSubKind[subKind] ?? '',
                        is_edit: isEditable,
                        type_open: 'show',
                    },
                });
            }

            const host = IS_DOCUMENTS_DOMAIN ? changeDocumentDomainToCloud(window.location.origin) : window.location.origin;

            return isFileForOpenInR7Editor(item) ? openEditor(item as unknown as CloudItem) : window.open(`${host}/${path}`);
        };

        if (storageHelper.isQuotaCleaner) {
            onOpenNewTab = () => {
                store.dispatch(openUserQuotaItemView({ id: item.id }));
            };
        }

        group1.push({
            ...contextMenuItems.newTab,
            onClick: onOpenNewTab,
        });
    }

    if (openParentFolder) {
        group1.push({
            ...(isAllDocuments ? contextMenuItems.showInCloud : contextMenuItems.parentFolder),
            onClick: () => {
                if (storage === EStorageType.search) {
                    const dwhData = {
                        eventCategory: ECategoryGa.viewer_search,
                        action: 'view-in-folder',
                    };
                    const items = [
                        {
                            file_name: item.nameWithoutExt,
                            type: item.kind || item.subkind,
                            pos: item.pos,
                            file_id: item.id,
                            extension: item.isFolder ? 'folder' : item.ext,
                        },
                    ];
                    store.dispatch(
                        dispatchNewSearchRadar({
                            dwhData,
                            items,
                            isSearchPhrase: false,
                        })
                    );
                }

                const path = getGoToAndScrollUrl(item.parentFolder || item.parent || '', item.id);
                const host = IS_DOCUMENTS_DOMAIN ? changeDocumentDomainToCloud(window.location.origin) : window.location.origin;
                const url = `${host}${path}`;
                /**
                 * Это костыль для ВКТ в https://jira.vk.team/browse/B2BCLOUD-847
                 * По хорошему надо открывать так:
                 * const [id, search] = url.split('?');
                 * store.dispatch(historyPush({ id, search }));
                 * чтобы не перерендеривать полностью приложение, но search параметр не перехватывается
                 * и не скроллится к файлу к которому переходим. Необходима более серьезная доработка.
                 * Задачка для исследования и исправления уже есть: https://jira.vk.team/browse/CLOUDWEB-13172
                 */
                opener(url, IS_MY_TEAM);
            },
        });
    }

    if (showPublish) {
        group2.push({
            ...(item.weblink ? contextMenuItems.publishGetLink : contextMenuItems.publish),
            onClick: () => publishHelper({ item, publishFrom: 'ctx', itemStorage: storage }),
        });
    }

    if (showShare) {
        group2.push({
            ...(isSharedFolder(item) || isMounted ? contextMenuItems.manageAccess : contextMenuItems.share),
            onClick: toolbarActions.share,
        });
    }

    const downloadAndSaveItems: ContextMenuItem[] = [];
    if (showDownload) {
        downloadAndSaveItems.push(getDownloadItem([item]));
    }

    if (showSaveToCloud) {
        downloadAndSaveItems.push(contextMenuItems.saveToCloud);
    }

    const itemsRemove: ContextMenuItem[] = [];
    if (showRemove) {
        itemsRemove.push(isAlbums ? contextMenuItems.removeFromAlbums : contextMenuItems.remove);
    }

    const itemsAuthors: ContextMenuItem[] = [];
    if (showAuthorFiles || showMyFiles) {
        const itemClickHandler = (): void => {
            if (currentFilter !== 'all') {
                filterAllHandler();
            } else {
                filterOneHandler(fileAuthor || userEmail);
            }
        };

        itemsAuthors.push({
            ...(currentFilter !== 'all'
                ? contextMenuItems.showAllAuthorFiles
                : showMyFiles
                ? {
                      ...contextMenuItems.showMyFilesOnly,
                      withStatus: false,
                      onClick: itemClickHandler,
                  }
                : contextMenuItems.showAuthorFiles),
            onClick: itemClickHandler,
        });
    }

    if (storageHelper.isPublic) {
        group3.push(...downloadAndSaveItems);
        group3.push(...itemsAuthors);
        group4.push(...itemsRemove);
    }

    if (showEmail) {
        group2.push(contextMenuItems.sendViaMail);
    }

    if (isAddToAlbumsAvailable) {
        group3.push(contextMenuItems.addToAlbum);
    }

    if (showAddToFavorites) {
        group3.push({
            ...(item.isInFavorites ? contextMenuItems.removeFromFavorites : contextMenuItems.addToFavorite),
            onClick: () => {
                if (item.isInFavorites) {
                    store.dispatch(removeFromFavorites({ items: [item as unknown as CloudItem] }));
                    return;
                }

                store.dispatch(addToFavorites({ items: [item as unknown as CloudItem], from: CONTEXT_MENU }));
            },
        });
    }
    if (showRename) {
        group3.push(contextMenuItems.rename);
    }

    if (showCopy) {
        group3.push(contextMenuItems.copy);
    }

    if (showMove) {
        group3.push(contextMenuItems.move);
    }

    if (showViewHistory) {
        group3.push(contextMenuItems.viewHistory);
    }

    if (!storageHelper.isPublic && showDownload) {
        group3.push(...downloadAndSaveItems);
    }

    if (showMountFolder) {
        group3.push(contextMenuItems.mountFolder);
    }

    if (!storageHelper.isPublic) {
        group4.push(...itemsRemove);
    }

    if (storageHelper.isHome) {
        group3.push(...itemsAuthors);
    }

    const list: ContextMenuItem[] = makeFlatDividedList([group1, group2, group3, group4]);

    if (IS_MY_TEAM) {
        return list.filter((item) => item.id !== 'newTab');
    }

    return list;
};

const createMultiMenu = ({ storage, items }): ContextMenuItem[] => {
    const folder = currentFolder(storage);
    const readOnly: boolean = isReadOnly(folder);
    const isCurrentFolderMounted: boolean = app.isMounted && app.isMounted(folder);
    const mountedItems = items.filter((item) => 'isMounted' in item && item.isMounted && !item.isFolder);
    const isCurrentFolderDomain: boolean = isDomainFolder(folder);
    const onlyFiles: boolean = items.every((item) => !item.isFolder);
    const hasDomainItem: boolean = !onlyFiles && items.some(isDomainFolder);
    const storageHelper = getStorage(storage);
    const state = store.getState();
    const userEmail = UserSelectors.getEmail(state);
    const isWritable = isPublicUploadEnabled(state) || IS_PUBLIC_ALBUM;
    const storageConfig = getStorageConfig(storage as EStorageType);
    const isPublicAlbumEnabled = isCreatePublicAlbumsEnabled(state, items);
    const isAddToAlbumsAvailable = isAddToAlbumsEnabled(state, items);
    const isOwnFiles = items.every((item) => path(['home'], item)) || (IS_PUBLIC_ALBUM && IS_OWN_PUBLIC);

    if (storageHelper.isSharedIncoming && items.length > 1 && items.some((item): boolean => !isMountedFolder(item))) {
        return [];
    }

    const canClone = items.every(() => canCloneWeblink(isAuthorized(), storage));
    const showPublish = storageConfig.publish;
    const showUnpublish = items.some((item) => item.weblink) && storageConfig.publish;
    const showSaveToCloud = (storageHelper.isPublic || storageHelper.isStock) && isAuthorized() && canClone;

    const showEmail =
        (((folder.home ||
            storageHelper.isGallery ||
            storageHelper.isFeed ||
            storageHelper.isDocuments ||
            (IS_BIZ_USER && storageHelper.isSearch) ||
            (storageHelper.isPublic && IS_ONPREMISE)) &&
            !storageHelper.isStock &&
            !(isCurrentFolderDomain && readOnly)) ||
            storageHelper.isSharedLinks ||
            storageHelper.isAttaches) &&
        onlyFiles &&
        sendViaMailEnabled &&
        isAuthorized() &&
        isItemsEmailUrlOk(items);
    const showFavorites = !readOnly && !isCurrentFolderDomain && !isCurrentFolderMounted && storageConfig.favorites && !mountedItems.length;
    const showCopy = storageConfig.copy;
    const showDownload = !storageHelper.isQuotaCleaner;
    const showMove = !readOnly && !hasDomainItem && storageConfig.move && !(isCurrentFolderDomain && readOnly);
    const showRemove =
        !readOnly &&
        !hasDomainItem &&
        storageConfig.remove &&
        (!storageHelper.isPublic ||
            (isWritable && onlyFiles && (items.every((item) => path(['author'], item) === userEmail) || isOwnFiles))) &&
        !storageHelper.isSharedLinks &&
        !storageHelper.isSharedAutodelete &&
        !(isCurrentFolderDomain && readOnly) &&
        !(storageHelper.isSharedIncoming && items.length > 1);

    const [group1, group2, group3]: ContextMenuItem[][] = [[], [], []];

    if (showPublish && isPublicAlbumEnabled) {
        group1.push(contextMenuItems.publishAlbum);
    }

    if (showUnpublish) {
        group1.push({
            ...contextMenuItems.unPublish,
            onClick: () => unPublishHelper({ items }),
        });
    }

    const downloadAndSaveItems: ContextMenuItem[] = [];
    if (showDownload) {
        downloadAndSaveItems.push(getDownloadItem(items));
    }

    if (showSaveToCloud) {
        downloadAndSaveItems.push(contextMenuItems.saveToCloud);
    }

    if (storageHelper.isPublic) {
        group2.push(...downloadAndSaveItems);
    }

    if (showEmail) {
        group1.push(contextMenuItems.sendViaMail);
    }

    if (isAddToAlbumsAvailable) {
        group2.push(contextMenuItems.addToAlbum);
    }

    if (showFavorites) {
        const isAllInFav = items.every((item) => item.isInFavorites);

        group2.push({
            ...(isAllInFav ? contextMenuItems.removeFromFavorites : contextMenuItems.addToFavorite),
            onClick: () => {
                if (isAllInFav) {
                    store.dispatch(removeFromFavorites({ items }));
                    return;
                }

                store.dispatch(addToFavorites({ items, from: CONTEXT_MENU }));
            },
        });
    }

    if (showCopy) {
        group2.push(contextMenuItems.copy);
    }

    if (showMove) {
        group2.push(contextMenuItems.move);
    }

    if (!storageHelper.isPublic) {
        group2.push(...downloadAndSaveItems);
    }

    if (showRemove) {
        group3.push(storageHelper.isAlbums ? contextMenuItems.removeFromAlbums : contextMenuItems.remove);
    }

    const list: ContextMenuItem[] = makeFlatDividedList([group1, group2, group3]);

    return list.map((item) => ({
        ...item,
        text: item.id === 'sendViaMail' ? item.text : `${item.text} (${items.length})`,
    }));
};

export const createFolderMenu = (createFrom) => [
    { ...contextMenuItems.createFolder, onClick: () => toolbarActions.create('folder', createFrom) },
];

export const createDocumentMenu = (createFrom) => {
    const state = store.getState();
    const availableCreateEditors = getCreateDescriptions(state);
    const folder = getCurrentFolderHome(state);
    const hasParentsDomain = folder ? hasParentsDomainFolder(state, folder) : false;
    const owner = getOwner(state);
    const isUserAllowedToCreateDocument =
        IS_FREE_BIZ_SAAS_USER &&
        folder?.isMounted &&
        owner?.is_paid &&
        !folder?.isReadOnly &&
        folder?.kind !== 'domain-folder' &&
        !hasParentsDomain;
    if (IS_FREE_BIZ_SAAS_USER && !isUserAllowedToCreateDocument) {
        return [] as ContextMenuItem[];
    }
    const createDocument = (type) => toolbarActions.create(type, createFrom);

    return availableCreateEditors.reduce((acc, cur) => {
        if (cur.name === 'docx') {
            return [
                ...acc,
                {
                    ...contextMenuItems.createDoc,
                    onClick: () => createDocument('docx'),
                },
            ];
        } else if (cur.name === 'xlsx') {
            return [
                ...acc,
                {
                    ...contextMenuItems.createCell,
                    onClick: () => createDocument('xlsx'),
                },
            ];
        } else if (cur.name === 'pptx') {
            return [
                ...acc,
                {
                    ...contextMenuItems.createPpt,
                    onClick: () => createDocument('pptx'),
                },
            ];
        }

        return acc;
    }, [] as ContextMenuItem[]);
};

const createUploadMenu = (isUpload: boolean, uploadHandler) => {
    const res = isUpload
        ? [
              {
                  ...contextMenuItems.upload,
                  onClick: uploadHandler,
                  disablePreventDefault: true,
              },
          ]
        : [];

    const state = store.getState();
    if (isUpload && isUploadFolderAllowed(state)) {
        res.push({ ...contextMenuItems.uploadFolder, onClick: uploadHandler, disablePreventDefault: true });
    }

    return res;
};

export const createCreateNewMenu = ({ createFrom = '', isUpload = false, uploadHandler = noopVoid } = {}) => {
    return [...createUploadMenu(isUpload, uploadHandler), ...createFolderMenu(createFrom), ...createDocumentMenu(createFrom)];
};

export const createContextMenu = ({
    storage,
    section,
    items,
    renderDefault,
}: {
    storage: string;
    section?: string;
    items?: Item[];
    renderDefault?: boolean;
}): ContextMenuItem[] => {
    if (renderDefault || !items || !items.length) {
        return createDefaultMenu({ storage, section: section || '' });
    }

    if (items && items.length === 1) {
        return createSingleMenu({ storage: storage as EStorageType, item: items[0] });
    }
    return createMultiMenu({ storage, items });
};
