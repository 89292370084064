import { ReactComponent as AdIcon } from 'img/icons/subscriptions/ad-icon.svg';
import { ReactComponent as AvailableSpaceIcon } from 'img/icons/subscriptions/available-space-icon.svg';
import { ReactComponent as CallIcon } from 'img/icons/subscriptions/call-icon.svg';
import { ReactComponent as FilesIcon } from 'img/icons/subscriptions/files-icon.svg';
import { ReactComponent as MailIcon } from 'img/icons/subscriptions/mail-icon.svg';
import React, { forwardRef } from 'react';
import { AUTOUPLOAD_10TB_REGEX } from 'reactApp/modules/products/products.helpers';

import styles from './PrivilegeList.css';

const iconProps = {
    width: 32,
    height: 32,
} as const;

const privileges = [
    // tempexp_16460-next-line
    {
        Icon: AvailableSpaceIcon,
        getText: (s: string, productId: string) => {
            if (AUTOUPLOAD_10TB_REGEX.test(productId)) {
                return `${s} тарифа и 10 ТБ на автозагрузку`;
            }

            return `${s} в Почте и Облаке`;
        },
    },
    { Icon: AdIcon, getText: () => 'Отключение рекламы' },
    { Icon: FilesIcon, getText: () => 'Возможность сохранять в Облаке файлы более 2 ГБ' },
    { Icon: MailIcon, getText: () => 'Возможность отправлять файлы более 2 ГБ' },
    { Icon: CallIcon, getText: () => 'Приоритетную поддержку' },
] as const;

type Props = {
    currentSpace: string;
    productId: string;
};

// tempexp_16460-next-line
export const PrivilegeList = forwardRef<HTMLDivElement, Props>(({ currentSpace, productId }, ref) => (
    <div className={styles.root} ref={ref}>
        {privileges.map(({ Icon, getText }, idx) => (
            <div key={idx} className={styles.item}>
                <div className={styles.icon}>
                    <Icon {...iconProps} />
                </div>
                <span>{getText(currentSpace, productId)}</span>
            </div>
        ))}
    </div>
));

PrivilegeList.displayName = 'PrivilegeList';
