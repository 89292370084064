import { Spaces } from '@mail/cross-sizes-utils';
import { useSelector } from 'react-redux';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';

export const useQueryParams = () => {
    // max_size - вес самого тяжелого файла
    // total_size - сумма всех файлов
    // afterReload - флаг, по которому открываем в поп-апе сразу оплату
    // isCloudStock - флаг, по которому определяем является ли аттач cloud_stock
    // isAcceptanceLA - флаг, по которому понимаем что у юзера не принято LA
    // attach_size_limit - максимальный размер аттача, при превышении которого откроется MailPopup (2 ГБ для всех по умолчанию, но может быть и меньше в рамках экспа)
    // attach_size_limit_force - флаг, по которому MailPopup должен сделать вид, что он не умеет загружать файлы размером больше attach_size_limit
    // productId - тариф, который стоит показать в первую очередь, если он существует и удовлетворяет требованиям
    const { max_size, total_size, afterReload, from, isCloudStock, isAcceptanceLA, productId, attach_size_limit, attach_size_limit_force } =
        useSelector(SettingsSelectors.getQueryParams);

    const maxUploadSize = Number(max_size) || 0;
    const totalUploadSize = Number(total_size) || 0;
    const isFromDnD = from === 'dnd';
    const attachSizeLimit = Number(attach_size_limit) || 2 * Spaces.ONE_GB;
    // tempexp_17072-start
    const attachSizeLimitForceAb = attach_size_limit_force === '1';
    // tempexp_17072-end

    return {
        maxUploadSize,
        totalUploadSize,
        isFromDnD,
        afterReload,
        isCloudStock: !!isCloudStock,
        isAcceptanceLA,
        attachSizeLimit,
        productId,
        // tempexp_17072-start
        attachSizeLimitForceAb,
        // tempexp_17072-end
    } as const;
};
