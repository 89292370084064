import { requestPayment } from 'Cloud/Application/Payment';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { quotaTooltip, summerPromotion } from 'reactApp/appHelpers/featuresHelpers';
import { renderBizMigrationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { initFamilyInviteModal, initFamilyOnboarding } from 'reactApp/modules/family/family.actions';
import { isUserFamilyOwner } from 'reactApp/modules/family/family.selectors';
import { getFeatureMarketingPromoFastcheckout } from 'reactApp/modules/features/features.selectors';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUserQuotaRequest } from 'reactApp/modules/userQuota/userQuota.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { TSendAnalytics } from 'reactApp/ui/Space/SingleSpace/SingleMenu.analytics';
import { TGoToSubs } from 'reactApp/ui/Space/Space.types';
import { sendXray } from 'reactApp/utils/ga';

type TUseCallbacks = (props: {
    isMobile: boolean;
    countdownTariff?: string;
    goToSubscriptions: TGoToSubs;
    sendAnalytics: TSendAnalytics;
    isMarketingPromo?: boolean;
    showFamilyButton?: boolean;
}) => {
    onPayment: () => void;
    onFamilyClick?: () => void;
    updateQuota: () => void;
};

export const useCallbacks: TUseCallbacks = ({ isMobile, countdownTariff, goToSubscriptions, sendAnalytics, isMarketingPromo }) => {
    const isOldBizUser = useSelector(UserSelectors.isOldBizUser);
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const isAlmostFull = useSelector(UserQuotaSelectors.isAlmostFull);
    const isFull = useSelector(UserQuotaSelectors.isFull);
    const showFastCheckout = useSelector(getFeatureMarketingPromoFastcheckout);

    const dispatch = useDispatch();
    const isFamilyOwner = useSelector(isUserFamilyOwner);

    const onPayment = useCallback(() => {
        if (isOldBizUser) {
            renderBizMigrationDialog();
            return;
        }
        const isOverquota = isAlmostFull || isFull;
        let query = '';
        if (quotaTooltip && !isOverquota) {
            query = 'utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=space_free_special_offer';
        }
        sendAnalytics('click', false, isOverquota ? `overquota_space_${isPaidUser ? 'paid' : 'free'}` : undefined);

        if (isOverquota) {
            emitAnalyticEvent(AnalyticEventNames.QUOTA_BLOCK_OVER_CLICK);
            goToSubscriptions({
                isNewTariffsPage: true,
                query: isPaidUser ? PaymentUTM.spacePaidOverquota : PaymentUTM.spaceFreeOverquota,
            });
            return;
        }

        // для мобилок пока открываем /subs
        if (isMobile) {
            goToSubscriptions({
                isNewTariffsPage: summerPromotion,
                query: summerPromotion ? PaymentUTM.spaceAction : PaymentUTM.spaceMobile,
            });
            closePopupHelper(popupNames.MOBILE_LEFT_MENU);
            return;
        }

        if (summerPromotion) {
            goToSubscriptions({
                isNewTariffsPage: true,
                query: `utm_source=cloud&utm_medium=web_union_quota_landing&utm_campaign=space_free_summer_action`,
            });
            return;
        }

        // если есть countdown promo, то поднимаем оплату
        if (countdownTariff) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            requestPayment({
                tariff: countdownTariff,
                forceFastCheckout: isMarketingPromo && showFastCheckout,
                goToSubscriptions,
            });
            return;
        }

        const isPaid = isPaidUser && !(isAlmostFull || isFull);

        goToSubscriptions({ isNewTariffsPage: !isPaid, query: query || isPaid ? PaymentUTM.spacePaid : PaymentUTM.spaceFree });
    }, [
        isOldBizUser,
        sendAnalytics,
        isMobile,
        countdownTariff,
        isPaidUser,
        isAlmostFull,
        isFull,
        goToSubscriptions,
        isMarketingPromo,
        showFastCheckout,
    ]);

    const onFamilyClick = useCallback(() => {
        sendAnalytics('click', true);

        closePopupHelper(popupNames.MOBILE_LEFT_MENU);

        if (isFamilyOwner) {
            dispatch(initFamilyInviteModal());
        } else {
            dispatch(initFamilyOnboarding());
        }
    }, [isFamilyOwner, dispatch, sendAnalytics]);

    const updateQuota = useCallback(() => {
        sendXray(['space', 'reload', 'family', 'quota']);
        dispatch(loadUserQuotaRequest());
    }, []);

    return { onPayment, onFamilyClick, updateQuota };
};
