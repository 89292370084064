import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect } from 'react';
import { advExpId } from 'reactApp/appHelpers/featuresHelpers';
import { WelcomeTrialParams } from 'reactApp/appHelpers/featuresHelpers/features.types';
import { openVkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.helpers';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { Button } from 'reactApp/ui/Button/Button';
import { sendXray } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from '../WelcomeNewPromo.css';
import { useContent } from './useContent';

interface TrialBlockProps {
    data: WelcomeTrialParams;
    forQuota?: boolean;
}

export const TrialBlock = memo(({ data, forQuota }: TrialBlockProps) => {
    const { productId, bannerName, infoOldStyle = false, useMonthPrice = false } = data;

    const onClick = useCallback(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.banner, action: 'click', name_banner: bannerName });
        sendXray(ECategoryGa.banner, `click_${advExpId}`);
        openVkBuyModalWithSidebar({
            productId,
            type: EPaymentModalType.trial,
            options: {
                dmrWithPaddings: !infoOldStyle,
                sidebarNewContent: !infoOldStyle,
            },
        });
    }, [productId, bannerName]);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.banner, action: 'view', name_banner: bannerName });
        sendXray(ECategoryGa.banner, `view_${advExpId}`);
    }, []);

    const content = useContent({ productId, infoOldStyle, useMonthPrice, forQuota: Boolean(forQuota) });
    if (!content) {
        return null;
    }
    const { space, units, ctaText, info } = content;

    return (
        <div className={classNames(styles.block, { [styles.block_quota]: forQuota })} data-qa-id="trial">
            <Title className={styles.tariff_text} level="2">
                Увеличьте хранилище
            </Title>
            <Spacing size={forQuota ? 54 : 38} />
            <div className={styles.tariff}>
                {space}
                <span>{units}</span>
            </div>
            <Text className={styles.tariff_text}>{forQuota ? 'Чтобы места хватило на все' : 'Места хватит на всё'}</Text>
            <Spacing size={forQuota ? 24 : 38} className={classNames({ [styles.fillFreeSpace]: !infoOldStyle && !forQuota })} />
            <div>
                <Button data-name="welcome-trial" theme="vk" primary onClick={onClick} fluid>
                    <Text className={styles.tariff_button}>{ctaText}</Text>
                </Button>
            </div>
            <Spacing size={forQuota ? 16 : 12} />
            <Text className={infoOldStyle ? styles.tariff_price : styles.tariff_price_new}>{info}</Text>
        </div>
    );
});

TrialBlock.displayName = 'TrialBlock';
