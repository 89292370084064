import { IS_FAMILY_USER, IS_PAID_USER } from 'reactApp/appHelpers/configHelpers';
import { isFamilySubsAvailable, isTouchSearchContentEnabled, isWebSearchContentEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { setEditor } from 'reactApp/modules/editor/editor.saga';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { familyLoadRequest } from 'reactApp/modules/family/family.actions';
import { initFamilyMemberQuotaUpdataIntervals } from 'reactApp/modules/family/family.helpers';
import { loadReceivedGiftsAction } from 'reactApp/modules/giftReceived/giftReceived.actions';
import { setTree } from 'reactApp/modules/home/home.saga';
import { initMyTeam } from 'reactApp/modules/myTeam/myTeam.saga';
import { watchAskPhone } from 'reactApp/modules/recovery/askPhone.saga';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { initContentSearch } from 'reactApp/modules/search/search.saga';
import { initConsoleApi, reportFeatures, setQueryParams } from 'reactApp/modules/settings/settings.saga';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { socialUserConfirmEmail } from 'reactApp/modules/socialUser/socialUser.saga';
import { initSortSaga } from 'reactApp/modules/sort/sort.saga';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { checkContinueUpload } from 'reactApp/modules/uploadList/continueUpload/continueUpload.saga';
import { userEnable2fa } from 'reactApp/modules/user/user.saga';
import { renderMobileLeftMenu } from 'reactApp/ui/Mobile/LeftMenu/LeftMenu.helpers';
import { sendAuthAnalytics } from 'reactApp/utils/sendAuthAnalytics';
import { call, fork, put, select } from 'redux-saga/effects';

export function* initSagas() {
    yield fork(userEnable2fa);
    yield fork(watchAskPhone);
    yield call(setQueryParams);
    yield fork(setEditor);
    yield call(setTree);
    yield call(initSortSaga); // TODO: routing: now it is for home only
    yield call(checkContinueUpload);
    yield fork(socialUserConfirmEmail);
    yield call(initConsoleApi);
    yield call(reportFeatures);
    yield call(initMyTeam);

    yield call(sendAuthAnalytics);

    // tempexp_16481-next-line
    if (IS_PAID_USER) {
        yield put(loadReceivedGiftsAction());
    }

    if (isWebSearchContentEnabled || isTouchSearchContentEnabled) {
        yield call(initContentSearch);
    }

    const { action: queryParam } = yield select(SettingsSelectors.getQueryParams);
    const isPhone = yield select(EnvironmentSelectors.isPhone);
    const isWebview = yield select(EnvironmentSelectors.isWebview);

    const storage = yield select(getCurrentStorage);
    const { isFamilyPage, isPublic } = getStorage(storage);

    // на странице семьи грузим данные отдельно
    if (!isFamilyPage && !isPublic && (IS_FAMILY_USER || IS_PAID_USER)) {
        yield put(familyLoadRequest({}));
    }

    if (IS_FAMILY_USER && isFamilySubsAvailable) {
        yield call(initFamilyMemberQuotaUpdataIntervals);
    }

    // Открываем левое меню после возвращения со страницы логина на таче.
    if (isPhone && !isWebview && queryParam === 'show-left-menu') {
        yield call(renderMobileLeftMenu, { animated: false });
    }
}
