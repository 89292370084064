import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { noopVoid } from 'reactApp/utils/helpers';

import { IProps } from './VkBuyModalWithSidebar.types';

interface OpenBuyModalWithSidebarHookProps extends Omit<IProps, 'onClose'> {
    onClose?: IProps['onClose'];
}

export const openVkBuyModalWithSidebar = ({ onClose = noopVoid, ...data }: OpenBuyModalWithSidebarHookProps) =>
    openPopupHelper({
        popupName: popupNames.VKBUY_MODAL,
        data,
        onClose,
    });
