import { IS_PHONE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { getUtmObject } from 'reactApp/appHelpers/settingsHelpers';
import { FamilySettingDropdownListItemIDs } from 'reactApp/sections/FamilyPage/FamilySettingsDropdown/FamilySettingsDropdown.types';
import { sendDwh } from 'reactApp/utils/ga';

export enum FamilyAnalyticsCategory {
    onboarding = 'family-creator-onboarding',
    pageOwner = 'family-page',
    pageGuest = 'guest-family-page',
}

export enum FamilyAnalyticsOnboardingActions {
    try = 'click-try',
    show = 'show',
    close = 'click-close',
}

export enum FamilyAnalyticsPageActions {
    showButtonTry = 'show-button-try',
    clickButtonTry = 'click-button-try',
    showTabInvite = 'show-tab-invite',
    clickTabInvite = 'click-tab-invite',
    showButtonUpgradeStorage = 'show-button-upgrade',
    clickButtonUpgradeStorage = 'click-button-upgrade',
    showButtonClear = 'show-button-clear',
    clickButtonClear = 'click-button-clear',
    showButtonSubscriptions = 'show-button-subs',
    clickButtonSubscriptions = 'click-button-subs',
    showButtonDeleteFamily = 'show-button-delete-family',
    clickButtonDeleteFamily = 'click-button-delete-family',
    showButtonDeleteUser = 'show-button-delete-user',
    clickButtonDeleteUser = 'click-button-delete-user',
    showButtonLeaveFamily = 'show-button-leave',
    clickButtonLeaveFamily = 'click-button-leave',
    showButtonMore = 'show-button-more',
    clickButtonMore = 'click-button-more',
}

export const sendFamilyAnalytics = ({ eventCategory, action, ...restData }) => {
    const isPhone = Boolean(IS_PHONE_BROWSER);
    const utms = getUtmObject();

    const platform = isPhone ? 'touch' : 'web';

    const dwhData = {
        platform,
        webview: IS_WEBVIEW,
        ...utms,
        ...restData,
    };

    sendDwh({ eventCategory, action, dwhData });
};

export const FamilySettingDropdownMapToAnalytics: Record<
    FamilySettingDropdownListItemIDs,
    { eventCategory: FamilyAnalyticsCategory; action: FamilyAnalyticsPageActions }
> = {
    [FamilySettingDropdownListItemIDs.space]: {
        eventCategory: FamilyAnalyticsCategory.pageOwner,
        action: FamilyAnalyticsPageActions.showButtonUpgradeStorage,
    },
    [FamilySettingDropdownListItemIDs.subscriptionsOwner]: {
        eventCategory: FamilyAnalyticsCategory.pageOwner,
        action: FamilyAnalyticsPageActions.showButtonSubscriptions,
    },
    [FamilySettingDropdownListItemIDs.deleteAll]: {
        eventCategory: FamilyAnalyticsCategory.pageOwner,
        action: FamilyAnalyticsPageActions.showButtonDeleteFamily,
    },
    [FamilySettingDropdownListItemIDs.subscriptions]: {
        eventCategory: FamilyAnalyticsCategory.pageGuest,
        action: FamilyAnalyticsPageActions.showButtonSubscriptions,
    },
    [FamilySettingDropdownListItemIDs.more]: {
        eventCategory: FamilyAnalyticsCategory.pageGuest,
        action: FamilyAnalyticsPageActions.showButtonMore,
    },
    [FamilySettingDropdownListItemIDs.leave]: {
        eventCategory: FamilyAnalyticsCategory.pageGuest,
        action: FamilyAnalyticsPageActions.showButtonLeaveFamily,
    },
    [FamilySettingDropdownListItemIDs.deleteMember]: {
        eventCategory: FamilyAnalyticsCategory.pageOwner,
        action: FamilyAnalyticsPageActions.showButtonDeleteUser,
    },
    [FamilySettingDropdownListItemIDs.clearSpaceOwner]: {
        eventCategory: FamilyAnalyticsCategory.pageOwner,
        action: FamilyAnalyticsPageActions.showButtonClear,
    },
    [FamilySettingDropdownListItemIDs.clearSpace]: {
        eventCategory: FamilyAnalyticsCategory.pageGuest,
        action: FamilyAnalyticsPageActions.showButtonClear,
    },
};

export const sendFamilyAnalyticsDropdowItem = (id: string) => {
    const { eventCategory, action } = FamilySettingDropdownMapToAnalytics[id as FamilySettingDropdownListItemIDs];

    sendFamilyAnalytics({ eventCategory, action });
};
