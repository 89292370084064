import config from 'Cloud/config';
import { parse, stringify } from 'qs';
import { isEmpty, path, pathOr } from 'ramda';
import { PromocodeAPICall } from 'reactApp/api/promo/PromocodeAPICall';
import { openBuyModal } from 'reactApp/components/BuyModal/renderBuyModal';
import { openFastCheckout as openFastCheckoutAction } from 'reactApp/modules/payment/payment.module';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { productsController } from 'reactApp/modules/products/products.controller';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { openPromocodeResultDialog } from 'reactApp/sections/PromocodeLanding/ResultDialog/PromocodeResultDialog.helpers';
import { store as reduxStore, store } from 'reactApp/store';
import { Product } from 'reactApp/types/Billing';
import { ActivateError, EPromocodeApiErrorCodes, isActivateError } from 'reactApp/ui/Promocode/promocode.constants';
import { sendXray } from 'reactApp/utils/ga';
import { getRequestPaymentUrl } from 'reactApp/utils/getRequestPaymentUrl';
import { openerDelayed } from 'reactApp/utils/opener';

export const IS_MOBILE_BROWSER = config.get('MOBILE_BROWSER');

export const sendPageGa = (action, label = '') => {
    sendXray(['promocodes-lp', action, label]);
};

const openAuthPopup = (successPage = window.location.href) => {
    try {
        reduxStore.dispatch(
            authPopup({
                closable: true,
                loginRequest: true,
                successPage,
            })
        );
    } catch (_) {}
};

const onActivateFail = ({ error }: ActivateError) => {
    openPromocodeResultDialog({
        success: false,
        isTinkoffPromo: false,
        errorCode: error || 'internal',
        onClose: () => {
            sendPageGa('dialog', 'close');
            sendPageGa('err-popup', error);
        },
    });
};

const onActivateSuccess = ({ isTinkoffPromo }: { isTinkoffPromo: boolean }) => {
    openPromocodeResultDialog({
        success: true,
        isTinkoffPromo,
        onClose: () => {
            sendPageGa('dialog', 'close');
            openerDelayed('/', 200, true);
        },
    });
};

const getProduct = (data): { productId: string | null; product: Product | null | undefined } => {
    // если это активация скидки через подарок, то productId ДОЛЖЕН быть в 'services', 'unlocked', 'web',
    // если через промо подписку, то 'web' - это объект содержащий в себе поле product_id
    const productId = pathOr(path(['services', 'unlocked', 'web'], data), ['services', 'unlocked', 'web', 'product_id'], data) as string;

    return {
        productId,
        product: productId ? ProductsSelectors.getProductById(reduxStore.getState(), productId) : null,
    };
};

export const activatePromocode = async (promocode: string) => {
    const state = reduxStore.getState();
    const login = UserSelectors.getEmail(state);

    sendPageGa('activate', 'click');

    if (IS_MOBILE_BROWSER) {
        sendPageGa('activate-click-mobile');
    }

    const query = parse(window.location.search, { ignoreQueryPrefix: true }) || {};
    const { utm_source, action, promocode: queryPromocode } = query;
    const params = !isEmpty(query) ? `&${stringify(query)}` : '';

    // пока не поддерживаем аквтивацию на таче
    // если это телефон/планшет и есть mt_link_id, то открываем приложение
    // но если redirect_to_app = 0, то игнорируем диплинк
    // if (IS_MOBILE_BROWSER && mt_link_id && redirect_to_app !== '0') {
    //     open(`https://trk.mail.ru/c/${mt_link_id}?promocode=${promocode}&quota=${quota}&mt_link_id=${mt_link_id}${params}`, '_self');
    //     return;
    // }

    // проверяем авторизацию
    if (!login) {
        const actionActivate = !action ? `${params ? '&' : '?'}action=activate` : '';
        const promocodeStr = queryPromocode ? '' : `${actionActivate ? '&' : '?'}promocode=${promocode}`;
        const successPage = `${window.location.href}${actionActivate}${promocodeStr}`;
        openAuthPopup(successPage);
        return;
    }

    try {
        const { data } = await new PromocodeAPICall().makeRequest({ promocode });

        sendPageGa('activate');

        await productsController.loadProducts();

        const { product, productId } = getProduct(data);

        // если есть productId(т.е. это или триал, или скидочный тариф),
        // то поднимаем  окно оплаты для веба, делаем редирект на /subs для тача
        if (productId && product) {
            if (IS_MOBILE_BROWSER) {
                const redirectUrl = getRequestPaymentUrl({ isHomeRoute: false, tariffId: product.id });

                return openerDelayed(redirectUrl);
            }

            if (product.hasTrial) {
                openBuyModal({
                    id: product.id,
                    onSuccess: () => {
                        sendPageGa('trial', 'success');
                        openerDelayed('/home?action=promocode-success', 200, true);
                    },
                    onClose: () => {
                        sendPageGa('trial', 'close');
                    },
                    source: 'promocodes',
                });
                return Promise.resolve();
            }

            store.dispatch(
                openFastCheckoutAction({
                    productId: product.id,
                    onSuccess: () => {
                        sendPageGa('tariff', 'success');
                        openerDelayed('/', 200, true);
                    },
                    onClose: () => {
                        sendPageGa('tariff', 'close');
                    },
                })
            );
            return Promise.resolve();
        } else if (productId && !product) {
            sendPageGa('activate', 'fail');
            onActivateFail({ error: EPromocodeApiErrorCodes.not_new_user });
            return Promise.resolve();
        }

        onActivateSuccess({
            isTinkoffPromo: utm_source === 'tinkoff',
        });

        return Promise.resolve();
    } catch (errorData) {
        sendPageGa('activate', 'fail');
        onActivateFail({ error: isActivateError(errorData) ? errorData.error : EPromocodeApiErrorCodes.internal });
        return Promise.resolve();
    }
};
