import { createSelector } from '@reduxjs/toolkit';
import { DisableAdsTariffIds } from 'reactApp/appHelpers/featuresHelpers';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { RootState } from 'reactApp/store';
import { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';

export const getTariffsList = createSelector(
    [(state: RootState, tariffIds: DisableAdsTariffIds) => ProductsSelectors.getBuyDisableAdsFrameProducts(state, tariffIds)],
    (tariffs): ITariffsByTab[] => {
        const RECOMMENDED_ID = 'W64G';
        return tariffs.reduce((acc: ITariffsByTab[], { space, tariffId, yearProduct, monthProduct }) => {
            if (yearProduct) {
                acc.push({
                    space,
                    tariffId,
                    isRecommended: tariffId.includes(RECOMMENDED_ID),
                    products: {
                        yearProduct,
                        monthProduct,
                    },
                });
            }
            return acc;
        }, []);
    }
);
