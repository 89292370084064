export enum EPromoLandings {
    MOBILE = 'mobile',
    FILES_TRANSFER = 'files-transfer',
    PHOTO = 'photo',
    PHOTO_NEW = 'photo-new',
    PRO = 'pro',
    DOBRO = 'dobro',
    EDITOR = 'editor',
    CLOUD_WEB = 'cloudWeb',
    PARTNERS = 'partners',
    NEW_YEAR = 'new-year',
    VK_FEST = 'vk-fest',
    EVENTS = 'events',
    FILES = 'cloud-files',
    PRESENT = 'present',
    NINE_MAY = 'cloud-promo-9may',
    MAIL = 'promo-mail',
    PDF = 'cloud-promo-pdf-redaktor',
    CLOUD_NEW = 'cloud-new',
    CLOUD_SECURITY = 'cloud-security',
}

export const PROMO_LANDING_TYPE = {
    'promo/files': EPromoLandings.FILES_TRANSFER,
    'promo/partners': EPromoLandings.PARTNERS,
    'promo/photo': EPromoLandings.PHOTO_NEW,
    'promo/pro': EPromoLandings.PRO,
    'promo/present': EPromoLandings.PRESENT,
    'promo/9may': EPromoLandings.NINE_MAY,
    'promo/mail': EPromoLandings.MAIL,
    'promo/pdf-redaktor': EPromoLandings.PDF,
    'promo/dobro': EPromoLandings.DOBRO,
    'promo/online-redaktor-dokumentov': EPromoLandings.EDITOR,
    'promo/newyear': EPromoLandings.NEW_YEAR,
    'promo/vkfest': EPromoLandings.VK_FEST,
    'promo/events': EPromoLandings.EVENTS,
    mobile: EPromoLandings.MOBILE,
    promo: EPromoLandings.CLOUD_WEB,
    'promo/cloud-files': EPromoLandings.FILES,
    'promo/summer': EPromoLandings.CLOUD_NEW,
    'promo/security': EPromoLandings.CLOUD_SECURITY,
};

export const PROMO_REG_EXP = new RegExp(`^/(${Object.keys(PROMO_LANDING_TYPE).join('|')})/?$`);

export enum EReactLandings {
    QUOTA = 'quota',
    QUOTA_IOS = 'quota/ios',
    QUOTA_ANDROID = 'quota/android',
    QUOTA_PARTNER = 'quota/partner',
    QUOTA_TARIFFS = 'quota/tariffs',
    PROMOCODES = 'promocodes',
    DISCOUNT = 'discount',
    UPLOADER = 'uploader',
    BUY = 'buy',
    CHOICE = 'choice',
    // tempexp_17127-next-line
    BUY_DISABLE_ADS = 'buy/disable-ads',
    HAPPY_BIRTHDAY_CLOUD = 'happybirthdaycloud',
}

export const REACT_LANDING_TYPE = {
    'promo/quota': EReactLandings.QUOTA,
    'promo/quota/ios': EReactLandings.QUOTA_IOS,
    'promo/quota/android': EReactLandings.QUOTA_ANDROID,
    'promo/quota/partner': EReactLandings.QUOTA_PARTNER,
    'promo/quota/tariffs': EReactLandings.QUOTA_TARIFFS,
    promocodes: EReactLandings.PROMOCODES,
    discount: EReactLandings.DISCOUNT,
    uploader: EReactLandings.UPLOADER,
    buy: EReactLandings.BUY,
    choice: EReactLandings.CHOICE,
    // tempexp_17127-next-line
    'buy/disable-ads': EReactLandings.BUY_DISABLE_ADS,
    'promo/happybirthdaycloud': EReactLandings.HAPPY_BIRTHDAY_CLOUD,
};

export const REACT_LANDINGS_REG_EXP = new RegExp(`^/(${Object.keys(REACT_LANDING_TYPE).join('|')})/?$`);
