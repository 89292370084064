import { ServerError } from './ServerError';

class UserError extends ServerError {
    static radarName = 'failure_{SOURCE}_user-error';

    className = 'UserError';
    message = 'Пользовательская ошибка.';
    headers;

    /**
     * код ошибки. например ARGUMENTS/REQUIRED
     * @type {string}
     */
    error = '';

    /**
     * набор полей запроса, в которых случилась ошибка this.error
     * @type {Array}
     */
    fields = [];

    /**
     * @constructor
     * @extends {Failure}
     * @param {Error} stack
     * @param {string} source
     */
    constructor({ version }, headers = null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.error = version === 2 ? this.response.body : this.response.error;
        this.fields = this.response.fields || [];
        this.headers = headers;
    }
}

export { UserError };
