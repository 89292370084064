import { Spacing, Text } from '@vkontakte/vkui';
import React, { forwardRef, RefObject, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './AutouploadTooltip.css';
import { AutouploadTooltipProps } from './AutouploadTooltip.types';

export const AUTOUPLOAD_TOOLTIP_ID = 'autoupload-10tb-tooltip';
export const AUTOUPLOAD_TOOLTIP_ID_MOBILE = 'autoupload-10tb-tooltip-mobile';

export const AutouploadTooltip = forwardRef<HTMLDivElement, AutouploadTooltipProps>(({ onClose }, ref) => {
    const dispatch = useDispatch();
    const isMobile = EnvironmentSelectors.isMobile();

    const handleOnShow = useCallback(() => {
        dispatch(UserStorageActions.set(isMobile ? AUTOUPLOAD_TOOLTIP_ID_MOBILE : AUTOUPLOAD_TOOLTIP_ID, true));
        sendQuotaGa({ action: 'tooltip_10tb_for_phone', label: 'view' });
    }, [dispatch, isMobile]);

    const handleClose = useCallback(() => {
        onClose?.(false);
    }, [onClose]);

    const handleClickLink = () => {
        sendQuotaGa({ action: 'tooltip_10tb_for_phone', label: 'click' });
    };

    return (
        <FloatingTooltip
            target={ref as RefObject<HTMLDivElement>}
            placement={ETooltipPlacement.top}
            appearance={ETooltipAppearance.dark}
            size={ETooltipSize.medium}
            onShow={handleOnShow}
            closeOnOutsideClick={false}
            onClose={handleClose}
            usePortal={isMobile}
            allowFlip={false}
            belowSticky={true}
        >
            <div className={styles.root}>
                <Text className={styles.text}>
                    У вас будет дополнительное место
                    <br />
                    для автозагрузки фото и видео
                </Text>
                <Spacing size={4} />
                <a
                    href="https://promo-rules.hb.ru-msk.vkcs.cloud/10-tb-rules.pdf"
                    target="_blank"
                    className={styles.link}
                    onClick={handleClickLink}
                    rel="noreferrer"
                >
                    Подробнее
                </a>
            </div>
        </FloatingTooltip>
    );
});

AutouploadTooltip.displayName = 'AutouploadTooltip';
