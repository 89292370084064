import { xray } from 'lib/xray';
import React, { memo, ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_DEV } from 'reactApp/appHelpers/configHelpers';
import { welcomeDefaultTariff } from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { initProducts } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { RootState } from 'reactApp/store';
import Content from 'reactApp/ui/Content/Content';
import { WelcomeDefaultTariffSplash } from 'reactApp/ui/WelcomeDefaultTariffSplash/WelcomeDefaultTariffSplash';
import { isFramed } from 'reactApp/utils/isFramed';
import { sendPostMessage } from 'reactApp/utils/windowHelper';

import styles from './Choice.css';

enum EPostMessageType {
    size = 'SIZE',
    close = 'CLOSE',
    success = 'SUCCESS',
    continueFree = 'CONTINUE_FREE',
    continuePaid = 'CONTINUE_PAID',
}
const CHOICE_FORK_KEY = 'choice-fork';

const sendFrameMessage = ({ type, ...data }) => {
    sendPostMessage({ key: CHOICE_FORK_KEY, type, ...data });
};

const { productId: queryProductId } = getQueryParams();

export const ChoiceIframe = memo((): ReactElement | null => {
    const dispatch = useDispatch();

    const { isLoading, isLoaded, hasError } = useSelector(ProductsSelectors.getLifeCycleState);

    const productFromQuery = useSelector((state: RootState) => ProductsSelectors.getAvailableProductById(state, queryProductId));
    const productFromConfig = useSelector((state: RootState) =>
        ProductsSelectors.getAvailableProductById(state, welcomeDefaultTariff?.tariff)
    );

    const product = productFromQuery || productFromConfig;

    useEffect(() => {
        xray.send('choice-frame_view_web');
        dispatch(initProducts());
    }, []);

    useEffect(() => {
        if (hasError || (isLoaded && !product)) {
            xray.send('choice-frame_error_web');
        }
    }, [hasError, isLoaded, product]);

    if (!isFramed() && !IS_DEV) {
        xray.send('choice-frame_error_not_frame');
        opener('/', true);
    }

    const content = useMemo(() => {
        if (!product) {
            return;
        }
        return (
            <WelcomeDefaultTariffSplash
                onContinue={() => {
                    sendFrameMessage({ type: EPostMessageType.close });
                }}
                onClose={() => {
                    sendFrameMessage({ type: EPostMessageType.close });
                }}
                onSuccess={() => {
                    sendFrameMessage({ type: EPostMessageType.success });
                }}
                onContinueFree={() => {
                    sendFrameMessage({ type: EPostMessageType.continueFree });
                }}
                onContinuePaid={() => {
                    sendFrameMessage({ type: EPostMessageType.continuePaid });
                }}
                product={product}
            />
        );
    }, [product]);

    return (
        <Content wrapClass={styles.content} isLoading={isLoading} hasError={hasError || (isLoaded && !product)}>
            {content}
        </Content>
    );
});

ChoiceIframe.displayName = 'ChoiceIframe';
