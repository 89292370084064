import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_B2B_BIZ_USER, IS_MY_TEAM, NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { showMobileAppPromo } from 'reactApp/modules/promo/promo.module';
import { isOwnPublic, isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { BackToRoot } from 'reactApp/ui/BackToRoot/BackToRoot';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { PublicUploadHeader } from 'reactApp/ui/PublicUploadHeader/PublicUploadHeader';
import { ToolbarNew } from 'reactApp/ui/Toolbar/Toolbar.new';
import { UploadBlock } from 'reactApp/ui/UploadBlock/UploadBlock';
import { Worm } from 'reactApp/ui/Worm/Worm';
import { noop } from 'reactApp/utils/helpers';

import styles from './Folder.css';

interface Props {
    noTopBanner?: boolean;
    isShove?: boolean;
}

export const Folder = memo(function Folder({ noTopBanner = true, isShove = false }: Props): ReactElement {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState<boolean>(false);
    const rootRef = useRef<HTMLDivElement>(null);
    const isUploadEnabled = useSelector(isPublicUploadEnabled);
    const isOwner = useSelector(isOwnPublic);

    /**
     * В этом месте нужен перерендер чтобы в DataList передался уже существующий customScrollElement
     */
    useEffect(() => setVisible(true), []);

    const { subscribeToInput, unsubscribeOfInput, processDrop } = useUploadInputHandlers();

    const onDownload = useCallback(() => {
        dispatch(showMobileAppPromo({}));
    }, []);

    return (
        <div className={classNames(styles.root, { [styles.root_newHeader]: NEW_PORTAL_HEADER, [styles.root_shove]: isShove })}>
            {!noTopBanner && !IS_B2B_BIZ_USER && (
                <div className={styles.wormWrapper}>
                    <Worm />
                </div>
            )}
            {IS_MY_TEAM && (
                <div className={styles.backToCloud}>
                    <BackToRoot />
                </div>
            )}
            {NEW_PORTAL_HEADER && (
                <div
                    ref={rootRef}
                    className={classNames(styles.toolbar_wrapper, { [styles.toolbar_wrapper_newHeader]: NEW_PORTAL_HEADER })}
                >
                    <ToolbarNew onDownload={onDownload} />
                </div>
            )}
            {!NEW_PORTAL_HEADER && <BreadcrumbsContainer />}
            {isUploadEnabled && <PublicUploadHeader />}
            <div ref={rootRef} className={classNames(styles.datalist, { [styles.datalist_newHeader]: NEW_PORTAL_HEADER })}>
                {visible && <DataList goTo={noop} customScrollElement={isShove ? rootRef.current : undefined} />}
            </div>
            <UploadBlock
                showDropArea={isOwner}
                allowDnd={true}
                gaCategory="public"
                subscribeToInput={subscribeToInput}
                unsubscribeOfInput={unsubscribeOfInput}
                processDrop={processDrop}
                isOwnerInViewMode={isOwner && !isUploadEnabled}
            />
        </div>
    );
});
