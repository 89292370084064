import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { tariffBuyWhenDisableAdsModalTariffIds } from 'reactApp/appHelpers/featuresHelpers';
import { RootState } from 'reactApp/store';
import { getTariffsList } from 'reactApp/ui/IframePages/BuyDisableAds/DisableAdsScreen/TariffList/TariffList.selectors';

export function useDisableAdsTariffs(tariffsFromQuery?: string) {
    const tariffIds = useMemo(() => {
        if (tariffsFromQuery && tariffBuyWhenDisableAdsModalTariffIds?.hasOwnProperty(tariffsFromQuery)) {
            return tariffBuyWhenDisableAdsModalTariffIds[tariffsFromQuery];
        }
        return { year: [], month: [] };
    }, [tariffsFromQuery, tariffBuyWhenDisableAdsModalTariffIds]);

    return { tariffIds, tariffs: useSelector((state: RootState) => getTariffsList(state, tariffIds)) };
}
