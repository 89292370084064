import React, { createRef, memo, useCallback, useEffect } from 'react';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isDomainFolder } from 'reactApp/modules/storage/folder.helpers';
import { getCurrentFolder, hasParentsDomainFolder } from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { TUploadTypeClick } from 'reactApp/modules/upload/upload.model';
import { setButtonTypeClickAction, setUploadFromAction } from 'reactApp/modules/upload/upload.module';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { EUploadSource } from 'reactApp/modules/uploading/uploading.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';

import styles from './UploadInput.css';

export const UPLOAD_INPUT_ID = 'UPLOAD_INPUT_ID';

let inputRef;

/**
 * Загрузку и выбор файлов для загрузки мы делаем везде через input. Инициируется это кликом по нему.
 * Но клик можно делать только в цепочке, в начале которой действие юзера (например, клик по другому элементу),
 * иначе браузер пишет ошибку. То есть просто запустить тут асинхронный экшен нельзя.
 * Потому тут такой костыль - сохраняем инпут в переменную, и вызываем клик по нему тут.
 */
export function emitUploadStart({ type, from = '' }: { type: TUploadTypeClick; from?: string }) {
    const isOverquotaUser = UserSelectors.isOverQuotaUser(store.getState());

    /**
     * Необходимо игнорировать квоту пользователя, если мы загружаем
     * файлы в доменную или пбличную папку.
     * Проверка на достаточность места в таких папках происходит далее, после выбора файла(-ов) для загрузки.
     */
    const currentFolder = getCurrentFolder(store.getState()) as unknown as CloudItem;
    const ignoreUserOverquota =
        isDomainFolder(currentFolder) || hasParentsDomainFolder(store.getState(), currentFolder) || from === EStorageType.public;

    if (isOverquotaUser && !ignoreUserOverquota) {
        store.dispatch(
            showSnackbarAction({
                id: 'overquota',
                text: 'Превышен объём Облака',
                type: SnackbarTypes.failure,
                closable: true,
            })
        );

        return;
    }
    /**
     *  CLOUDWEB-14993
     *  Если пытаемся загрузить в корзину в разделе документов, то показываем попап, поправить когда переедем на отдельный домен
     */
    if (
        window.location.pathname.includes('alldocuments/trashbin') ||
        (IS_DOCUMENTS_DOMAIN && window.location.pathname.includes('trashbin'))
    ) {
        openPopupHelper({
            popupName: popupNames.NOT_DOCUMENT_MODAL,
            data: { notUploadExt: [] },
        });

        return;
    }

    store.dispatch(setButtonTypeClickAction(type));
    if (from) {
        store.dispatch(setUploadFromAction(from));
    }

    if (type === 'folder') {
        inputRef?.current?.setAttribute('webkitdirectory', true);
    } else {
        inputRef?.current?.removeAttribute('webkitdirectory');
    }

    // Если грузить повторно тот же самый файл(ы), то загрузка не будет происходить тк не отработает onChange
    // Поэтому очищаем из рефки предыдущее значение.
    if (inputRef?.current?.value) {
        inputRef.current.value = null;
    }

    inputRef?.current?.click();
}

export const UploadInput = memo(({ allowedMimeTypes }: { allowedMimeTypes?: string }) => {
    const ref = createRef<HTMLInputElement>();

    const { subscribeToInput, unsubscribeOfInput } = useUploadInputHandlers({ disableDrop: true, source: EUploadSource.toolbar });

    useEffect(() => {
        inputRef = ref;
    }, [ref?.current, allowedMimeTypes]);

    useEffect(() => {
        subscribeToInput(ref?.current);

        return () => {
            unsubscribeOfInput(ref?.current);
            inputRef = null;
        };
    }, [allowedMimeTypes]);

    const handleClick = useCallback(() => {
        ref?.current?.click();
    }, []);

    return (
        <input
            id={UPLOAD_INPUT_ID}
            type="file"
            multiple
            className={styles.root}
            ref={ref}
            onClick={handleClick}
            accept={allowedMimeTypes}
        />
    );
});

UploadInput.displayName = 'UploadInput';
