import { ServerError } from './ServerError';

class HttpError extends ServerError {
    static radarName = 'failure_{SOURCE}_http-{STATUS}';

    isRetryable = true;
    maxRetries = 2;
    retryTimeout = 5000;

    /**
     * @constructor
     * @extends {ServerError}
     * @param {Error} stack
     * @param {string} source
     */
    constructor({ status }) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.message = this.message.replace('{STATUS}', status);
        HttpError.radarName = HttpError.radarName.replace('{STATUS}', status);
    }

    className = 'HttpError';
    message = 'Ошибка HTTP {STATUS}';
}

export { HttpError };
