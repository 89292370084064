import React from 'react';
import { ChangeHistory } from 'reactApp/ui/App/components/ChangeHistory';
import { FrozenWarningWrapper } from 'reactApp/ui/App/components/FrozenWarningWrapper';
import { InitComponent } from 'reactApp/ui/App/components/InitComponent';
import { PopupsNew } from 'reactApp/ui/Popup/PopupsNew';
import { Snackbars } from 'reactApp/ui/Snackbars/Snackbars';

export const CommonRootComponents = ({ onIsLoading }) => {
    /* Child effects are run in order. Therefore initing here is before any other effects are called. */
    return (
        <>
            <InitComponent setIsLoading={onIsLoading} />
            <ChangeHistory />
            <Snackbars />
            <PopupsNew />
            <FrozenWarningWrapper />
        </>
    );
};
