import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DOM_ID_PHONE_HEADER_ERRORS_PAGE } from 'reactApp/appHelpers/advertisingConfigHelpers';
import { IS_BLOCKED, IS_IOS_MOBILE } from 'reactApp/appHelpers/configHelpers';
import { abAutoupload10TB, summerPromotion } from 'reactApp/appHelpers/featuresHelpers';
import { getReceivedGiftsLoadingState, hasAutoupload10TbGift } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { historyPush } from 'reactApp/modules/router/router.module';
import { selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { isUploaderVisible } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ErrorPage } from 'reactApp/sections/ErrorPage/ErrorPage';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { ActionsBar, EActionsMode } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar';
import { ActionsCarousel } from 'reactApp/ui/Mobile/ActionsCarousel/ActionsCarousel';
import { BreadcrumbsContainer as Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/BreadcrumbsContainer';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { PromoBlock } from 'reactApp/ui/Mobile/PromoBlock/PromoBlock';
import { PromoBlockData } from 'reactApp/ui/Mobile/PromoBlock/PromoBlock.data';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';
import { UploadDropArea } from 'reactApp/ui/UploadDropArea/UploadDropArea';
import { UploadListMobileContainer } from 'reactApp/ui/UploadList/UploadListMobile';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './MobileHomePage.css';

export const MobileHomePage = memo(function MobileHomePage(): ReactElement | null {
    const dispatch = useDispatch();
    const location = useLocation();
    const isSelecting = useSelector(SelectionsSelectors.isSelecting);
    const selectedIds = useSelector(SelectionsSelectors.getSelectedIdxs);
    const isDefaultContent = useSelector(isDefaultRootContent);
    const statusPage = useSelector(selectStatusPage);
    const showUploader = useSelector(isUploaderVisible);
    const showActionsBar = selectedIds.length > 0;
    const showUploadArea = !showUploader && !showActionsBar;
    const isFrozen = useSelector(UserSelectors.isFrozen);

    const { subscribeToInput, unsubscribeOfInput } = useUploadInputHandlers();

    // tempexp-16460-start
    const promoBlock10tbProps = PromoBlockData.AUTOUPLOAD_10TB_PROMO_BLOCK;
    const hasAutoupload1Tb = useSelector(hasAutoupload10TbGift);
    const { isLoading: isGiftLoading } = useSelector(getReceivedGiftsLoadingState);
    const showAutouploadPromoBlock = !isGiftLoading && Boolean(abAutoupload10TB.length) && !hasAutoupload1Tb && !summerPromotion;
    const handlePromoBlock10tbClick = useCallback(() => {
        dispatch(
            historyPush({
                id: getIdByStorage(EStorageType.quotaCleaner),
                search: `?utm_source=cloud_touch_${
                    IS_IOS_MOBILE ? 'ios' : 'and'
                }&utm_medium=touch_union_quota_landing&utm_campaign=touch_promo_block_10tb&utm_term=16460`,
            })
        );
    }, [dispatch]);
    // tempexp-16460-end

    // tempexp-17087-start
    const summerPromoBlockProps = PromoBlockData.SUMMER_PROMO;
    const handleSummerPromoBlockClick = useCallback(() => {
        dispatch(
            historyPush({
                id: getIdByStorage(EStorageType.quotaCleaner),
                search: `?utm_source=cloud_touch_${
                    IS_IOS_MOBILE ? 'ios' : 'and'
                }&utm_medium=touch_union_quota_landing&utm_campaign=infoblock_summer_action&utm_term=17087`,
            })
        );
    }, [dispatch]);
    // tempexp-17087-end

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'touch-home' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'home' });
    }, []);

    useEffect(() => {
        if (isDefaultContent) {
            dispatch(checkUpdateLicenseRequest());
        }
    }, [isDefaultContent]);

    useRouteChangeProcessing({ storage: EStorageType.home, path: location.pathname, search: location.search });

    if (IS_BLOCKED || isFrozen) {
        return null;
    }

    if (statusPage) {
        return (
            <div className={classNames(styles.root, styles.root_error)}>
                <div id={DOM_ID_PHONE_HEADER_ERRORS_PAGE} />
                <Toolbar disableActions />
                <ErrorPage status={statusPage} />
            </div>
        );
    }

    return (
        <>
            <div className={styles.root}>
                <Toolbar showMenu />
                {summerPromotion && <PromoBlock {...summerPromoBlockProps} onClick={handleSummerPromoBlockClick} />}
                {showAutouploadPromoBlock && <PromoBlock {...promoBlock10tbProps} onClick={handlePromoBlock10tbClick} />}
                <div className={styles.content}>
                    <Breadcrumbs />
                    <ActionsCarousel disabled={isSelecting} />
                    <Datalist />
                </div>
            </div>
            {showActionsBar && <ActionsBar mode={EActionsMode.PRIMARY} />}
            {showUploader && <UploadListMobileContainer />}
            {showUploadArea && (
                <UploadDropArea subscribeToInput={subscribeToInput} unsubscribeOfInput={unsubscribeOfInput} gaCategory={'mobile'} />
            )}
        </>
    );
});
