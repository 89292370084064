import settings from 'Cloud/settings';
import { IS_AUTO_TEST_HIDE, IS_MY_TEAM, IS_VKT_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { welcomeDefaultTariff } from 'reactApp/appHelpers/featuresHelpers';
import { loadProductsSuccess } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUserQuotaSuccess } from 'reactApp/modules/userQuota/userQuota.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { RootState, store as reduxStore } from 'reactApp/store';
import { renderWelcomeDefaultTariffSplash } from 'reactApp/ui/WelcomeDefaultTariffSplash/WelcomeDefaultTariffSplash.heplers';
import { call, put, select, take } from 'typed-redux-saga';

export const DEFAULT_TARIFF_FORK_PROMO_ID = 'default-tariff-fork-modal';
const PERIOD_TO_SHOW_DAYS = 60;

function markPromoShown() {
    reduxStore.dispatch(promoShown(EPromoType.defaultTariffFork));
    storeHelper.markAsShown(DEFAULT_TARIFF_FORK_PROMO_ID);
    reduxStore.dispatch(removePromo(EPromoType.defaultTariffFork));
}

export function* initDefaultTariffForkModal() {
    const storage = yield* select(getCurrentStorage);
    const isPaid = yield* select(UserSelectors.isPaidUser);
    const isNewbie = yield* select(UserSelectors.isNewbie);
    const isBizUser = yield* select(UserSelectors.isBizUser);

    const periodPassed = storeHelper.isPassed(DEFAULT_TARIFF_FORK_PROMO_ID, { daysCount: PERIOD_TO_SHOW_DAYS });

    if (periodPassed) {
        storeHelper.clearStore(DEFAULT_TARIFF_FORK_PROMO_ID);
    }

    if (
        storeHelper.getValue(DEFAULT_TARIFF_FORK_PROMO_ID) ||
        storage !== EStorageType.home ||
        isPaid ||
        isNewbie ||
        isBizUser ||
        IS_AUTO_TEST_HIDE ||
        IS_MY_TEAM ||
        IS_VKT_WEBVIEW ||
        settings?.request?.action ||
        !welcomeDefaultTariff
    ) {
        return;
    }

    yield take(loadUserQuotaSuccess.toString());
    const isOverquota = yield* select(UserQuotaSelectors.isOverquota);

    if (isOverquota) {
        return;
    }

    yield take(loadProductsSuccess);
    const product = yield select((state: RootState) => ProductsSelectors.getProductById(state, welcomeDefaultTariff?.tariff));

    if (!product) {
        return;
    }

    yield* put(
        addPromoToShowQueue({
            type: EPromoType.defaultTariffFork,
            promoId: DEFAULT_TARIFF_FORK_PROMO_ID,
            onShow: () => markPromoShown(),
            onClose: () => markPromoShown(),
            product,
        })
    );
}

export function* showDefaultTariffForkModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.defaultTariffFork));

    if (!promo) {
        return;
    }

    promo.onShow();

    yield call(renderWelcomeDefaultTariffSplash, { onClose: promo.onClose, product: promo.product });
}
