import { Failure } from '../Failure';

class DmrError extends Failure {
    static radarName = 'failure_{SOURCE}_dmr';
    component: any;
    url: string;
    loadTime: any;
    className = 'DmrError';

    constructor({ url, loadTime, component }) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.component = component;
        this.url = url;
        this.loadTime = loadTime;
    }

    getDetails() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        const details = super.getDetails(...arguments);

        details.url = this.url;
        details.loadTime = this.loadTime;
        details.component = this.component;

        return details;
    }
}

export { DmrError };
