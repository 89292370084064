import { Counter } from '@vkontakte/vkui';
import store from 'lib/store';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureShowNewStoriesCounter } from 'reactApp/modules/features/features.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getNewStoriesCount } from 'reactApp/modules/stories/stories.selectors';
import { HeaderWidget } from 'reactApp/ui/HeaderWidget/HeaderWidget';
import { StorySummaryItem } from 'reactApp/ui/StoriesWidgetComponent/Stories.types';
import { StoriesWidgetComponent } from 'reactApp/ui/StoriesWidgetComponent/StoriesWidgetComponent';
import { noop } from 'reactApp/utils/helpers';

import { STORIES_WIDGET_LOCAL_STORAGE_KEY } from './StoriesWidget.constants';
import { sendStoryWidgetDwh } from './StoriesWidget.helpers';
import styles from './StoriesWidget.new.css';

export interface StoriesWidgetNewProps {
    stories?: StorySummaryItem[];
    onExpand?: (expanded: boolean) => void;
}

export const StoriesWidgetNew = memo(function StoriesWidgetNew({ stories, onExpand = noop }: StoriesWidgetNewProps) {
    const [isClosed, setClosed] = useState(store.get(STORIES_WIDGET_LOCAL_STORAGE_KEY));

    const showCounter = useSelector(getFeatureShowNewStoriesCounter);
    const newStoriesCount = useSelector(getNewStoriesCount);

    const handleExpand = useCallback(
        (expanded: boolean) => {
            setClosed(!expanded);
            onExpand(expanded);

            sendStoryWidgetDwh({
                action: expanded ? 'view-stories_reopen' : 'view-stories_close',
                source: EStorageType.story,
            });
        },
        [setClosed, onExpand]
    );

    useEffect(() => {
        if (stories?.length) {
            sendStoryWidgetDwh({
                action: isClosed ? 'view-stories_hide' : 'view-stories_open',
                count_stories: stories?.length,
                source: EStorageType.story,
            });
        }
    }, [isClosed, stories?.length]);

    const header = useMemo(() => {
        return showCounter && newStoriesCount > 0 ? <Counter className={styles.badge}>{newStoriesCount}</Counter> : null;
    }, [showCounter, newStoriesCount]);

    const body = useMemo(() => {
        return <StoriesWidgetComponent stories={stories} />;
    }, [stories]);

    return (
        <div className={styles.root}>
            <HeaderWidget
                title="Истории"
                header={header}
                body={body}
                expandLocalstoreKey={STORIES_WIDGET_LOCAL_STORAGE_KEY}
                onExpand={handleExpand}
            />
        </div>
    );
});
