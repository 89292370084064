import { captureMessage } from '@sentry/browser';
import api from 'Cloud/Application/api';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { vkTeamsBridge } from 'reactApp/modules/vkTeam/vkTeam.module';

export function openTab(url: string, target = '') {
    try {
        const openViaVKTeamsBridge = IS_MY_TEAM && /^vkteams:\/\/miniapp.*/.test(url);

        if (!openViaVKTeamsBridge) {
            const tab = window.open(url, target || '');

            if (tab) {
                tab.focus();
            }

            return tab;
        }

        vkTeamsBridge.send('OpenLink', { url }).catch((error) => {
            console.error(error);
        });
    } catch (error) {
        console.error(error);
    }
}

export function openPopup(url: string, target = '') {
    try {
        const popup = window.open(url, target, 'toolbar=0,status=0,menubar=0,location=0,directories=0,width=626,height=436');

        if (popup) {
            popup.focus();
        } else {
            captureMessage('POPUP BLOCKED', {
                arguments: {
                    url,
                },
            } as any);

            api.emit('api-fail', 200, 'popup-open');
        }

        return popup;
    } catch (error) {
        console.error(error);
    }
}
