import { Rubles } from 'Cloud/Application/Rubles';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { HIDE_ADS } from 'reactApp/appHelpers/configHelpers';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { loadPublicAlbumPageRequest } from 'reactApp/modules/public/publicAlbum.actions';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { isEmptyFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { isUploaderVisible } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { ActionsBar } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar';
import { ActionsCarousel } from 'reactApp/ui/Mobile/ActionsCarousel/ActionsCarousel';
import { BreadcrumbsContainer as Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/BreadcrumbsContainer';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { DownloadAppButton } from 'reactApp/ui/Mobile/DownloadAppButton/DownloadAppButton';
import { ExpiresInfo } from 'reactApp/ui/Mobile/ExpiresInfo/ExpiresInfo';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';
import { UploadInfo } from 'reactApp/ui/Mobile/UploadInfo/UploadInfo';
import { RollContentAds } from 'reactApp/ui/RollContent/RollContentAds';
import { UploadBlockContainer } from 'reactApp/ui/UploadBlock/UploadBlockContainer';
import { UploadListMobileContainer } from 'reactApp/ui/UploadList/UploadListMobile';
import { sendCurrTimeRadar, sendPerfMetrics } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './MobilePublicPage.css';

export const MobilePublicPage = memo(({ storage, isAlbum = false }: { storage: EStorageType; isAlbum: boolean }): ReactElement => {
    const dispatch = useDispatch();
    const location = useLocation();

    const isAuthorized = !!useSelector(UserSelectors.getEmail);
    const isUploadEnabled = useSelector(isPublicUploadEnabled);
    const isSelecting = useSelector(SelectionsSelectors.isSelecting);
    const selectedIds = useSelector(SelectionsSelectors.getSelectedIdxs);
    const isEmpty = useSelector(isEmptyFolder);
    const showUploader = useSelector(isUploaderVisible);
    const isWebview = useSelector(EnvironmentSelectors.isWebview);
    const emptyFolderUploadAllowed = isEmpty && isUploadEnabled;
    const hasAdsAndEmptyFolderUploadAllowed = !HIDE_ADS && emptyFolderUploadAllowed;
    const showActionsBar = selectedIds.length > 0;
    const isBiz = useSelector(UserSelectors.isBizUser);

    const [showUploadInfo, setShowUploadInfo] = useState(true);

    useRouteChangeProcessing({
        storage,
        path: location.pathname.replace(`/${isAlbum ? 'album' : storage}/`, ''),
        search: location.search,
    });

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'touch-public' });
        sendPerfMetrics('mpubpage');
        sendCurrTimeRadar('mpubpage_mnt');
    }, []);

    // @todo унести в сагу
    useEffect(() => {
        if (isUploadEnabled && isAuthorized) {
            dispatch(contactsSlice.actions.listRequest());
        }

        dispatch(loadPublicAlbumPageRequest());

        Rubles.runPhoneTopBannerUpdater();

        if (!isBiz && !isWebview) {
            Rubles.updateMobSplash();
        }
    }, []);

    const hideUploadInfo = useCallback(() => {
        setShowUploadInfo(false);
    }, []);

    return (
        <>
            <div
                translate="no"
                className={classNames(styles.root, {
                    [styles.root_withTopInfo]: !HIDE_ADS || emptyFolderUploadAllowed,
                    [styles.root_withTopInfoAndEmptyFolderUploadAllowed]: hasAdsAndEmptyFolderUploadAllowed,
                })}
            >
                <Toolbar showMenu={!isWebview} />
                <div className={styles.content}>
                    <Breadcrumbs />
                    <div className={styles.expiresInfo}>
                        <ExpiresInfo />
                    </div>
                    {showUploadInfo && (
                        <div className={styles.uploadInfo}>
                            <UploadInfo closable onClose={hideUploadInfo} />
                        </div>
                    )}

                    <ActionsCarousel disabled={isSelecting} />
                    <Datalist />
                </div>
            </div>

            {showActionsBar && <ActionsBar />}
            {showUploader && <UploadListMobileContainer />}
            {!isUploadEnabled && !HIDE_ADS && !isEmpty && <RollContentAds />}
            {!isSelecting && !isWebview && (
                <div className={styles.appButton}>
                    <DownloadAppButton />
                </div>
            )}
            <UploadBlockContainer />
        </>
    );
});

MobilePublicPage.displayName = 'MobilePublicPage';
