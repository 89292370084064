import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon16ArrowRightOutline } from '@vkontakte/icons';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { IS_FREE_BIZ_SAAS_USER } from 'reactApp/appHelpers/configHelpers';
import { abDecreaseBaseQuota } from 'reactApp/appHelpers/featuresHelpers';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { DataListItemCreateNew } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew';
import { CreateNewMod } from 'reactApp/ui/DataListItemCreateNew/DataListItemCreateNew.data';
import { TrialBanner } from 'reactApp/ui/WelcomePromo/TrialBanner/TrialBanner';
import { useWelcomePromoLogic } from 'reactApp/ui/WelcomePromo/WelcomePromo.hooks';

import styles from './WelcomePromo.css';

export const WelcomePromo = memo((): ReactElement => {
    const userTotalSpace = useSelector(UserQuotaSelectors.getTotal);
    const isNewbie = useSelector(UserSelectors.isNewbie);
    const { isLoaded: isSingleQuotaLoaded } = useSelector(UserQuotaSelectors.getLoadingState);

    const { trialId, handleSubscriptionsClick, showSubscriptions } = useWelcomePromoLogic({ forNewUser: false });

    // tempexp_16521-next-line
    const quota = abDecreaseBaseQuota && isNewbie ? bytesToNDigits(SPACE_LIST.gb_4, 3).value : userTotalSpace?.value;

    return (
        <div className={styles.root}>
            <div className={styles.textBlock}>
                <div className={styles.header}>Добро пожаловать в Облако!</div>
                <div className={styles.description}>
                    {/** tempexp_16521-next-line */}
                    {isSingleQuotaLoaded && (
                        <>
                            Теперь у вас есть <span className={styles.quota}>{quota}</span>.
                        </>
                    )}{' '}
                    Загружайте фото, делитесь файлами
                    <br /> и работайте вместе с другими пользователями.{' '}
                    {showSubscriptions && (
                        <span className={styles.tariffs} onClick={handleSubscriptionsClick}>
                            Тарифы <Icon16ArrowRightOutline display="inline" />
                        </span>
                    )}
                </div>
            </div>
            <div className={styles.actionBlock}>
                <div className={styles.createBlock}>
                    <DataListItemCreateNew isThumb mod={CreateNewMod.firstFolder} />
                    {!IS_FREE_BIZ_SAAS_USER && <DataListItemCreateNew isThumb mod={CreateNewMod.newDocument} />}
                </div>
                {Boolean(trialId) && <TrialBanner trialId={trialId ?? ''} />}
            </div>
        </div>
    );
});

WelcomePromo.displayName = 'WelcomePromo';
