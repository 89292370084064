import classNames from 'clsx';
import React, { FC } from 'react';
import { TariffCardContentSize } from 'reactApp/ui/TariffCardNew/TariffCard.types';

import styles from './TariffLabel.css';
import { ETariffLabelKind, ETariffLabelRadius, ITariffLabelProps } from './TariffLabel.types';

export const TariffLabel: FC<ITariffLabelProps> = ({
    dataQa,
    children,
    kind = ETariffLabelKind.blue,
    radius = ETariffLabelRadius.r24,
    contentSize = TariffCardContentSize.middle,
}) => (
    <div
        data-qa={dataQa}
        data-qa-kind={dataQa && kind}
        className={classNames(styles.label, {
            [styles[`contentSize_${contentSize}`]]: !!contentSize,
            [styles[`label_${kind}`]]: !!kind,
            [styles[`label_${radius}`]]: !!radius,
        })}
    >
        {children}
    </div>
);
