import config from 'Cloud/config';
import { ACTION_PROMO } from 'reactApp/appHelpers/configHelpers';
import { renderBuyModal } from 'reactApp/components/BuyModal/renderBuyModal';
import { openFastCheckout as openFastCheckoutAction } from 'reactApp/modules/payment/payment.module';
import { productsController } from 'reactApp/modules/products/products.controller';
import { updateProducts } from 'reactApp/modules/products/products.module';
import { waitForUserUpdate } from 'reactApp/modules/user/waitForUserUpdate';
import { store } from 'reactApp/store';
import { noopVoid } from 'reactApp/utils/helpers';
import { openSubscriptionsPage } from 'reactApp/utils/openHelpers';

const BILLING_ENABLED = config.get('user.cloud.billing.enabled');
const IS_ACTION_USER = config.get('IS_ACTION_USER');
const GO_TO_SUBSCRIPTIONS_ENABLED = IS_ACTION_USER && !!ACTION_PROMO;

const openFastCheckout = ({ productId, onSuccess = noopVoid, afterUpdate = noopVoid, onClose = noopVoid, paySource }) => {
    return new Promise((resolve) => {
        const afterUpdateCallback = () => {
            afterUpdate();
            resolve(true);
        };

        const onCloseCallback = () => {
            onClose();
            resolve(false);
        };

        store.dispatch(
            openFastCheckoutAction({
                productId,
                onSuccess,
                onClose: onCloseCallback,
                afterUpdate: afterUpdateCallback,
                isMarketingPromo: !!ACTION_PROMO,
                paySource,
            })
        );
    });
};

const openTariff = ({ id, onSuccess, afterUpdate = noopVoid, source = '', paySource }) => {
    return new Promise((resolve) => {
        const onSuccessCallback = () => {
            onSuccess();
            store.dispatch(updateProducts());
            waitForUserUpdate().then(() => {
                afterUpdate();
                resolve(true);
            });
        };

        const onClose = () => {
            resolve(false);
        };

        renderBuyModal({
            id,
            onSuccess: onSuccessCallback,
            onClose,
            source,
            paySource,
        });
    });
};

export const requestPayment = ({
    tariff,
    productId,
    showTariffList = false,
    forceFastCheckout,
    onProductSelect = noopVoid,
    onSuccess = noopVoid,
    afterUpdate = noopVoid,
    onClose = noopVoid,
    goToSubscriptions = openSubscriptionsPage,
    isMobile = false,
    source = '',
    paySource,
    query,
} = {}) => {
    if (!BILLING_ENABLED) {
        return Promise.resolve();
    }

    if (isMobile) {
        goToSubscriptions({ inSameTab: true, query: tariff ? `action=request-payment&productId=${tariff}` : '' });
        return Promise.resolve();
    }

    if (GO_TO_SUBSCRIPTIONS_ENABLED && !(tariff || productId) && !forceFastCheckout) {
        goToSubscriptions({ inSameTab: true, anchor: 'special' });
        return Promise.resolve();
    }

    return productsController
        .loadProducts()
        .then(() => {
            if (productId) {
                return openTariff({ id: productId, onSuccess, afterUpdate, source, paySource });
            }

            if (!showTariffList || tariff) {
                return openFastCheckout({ productId: tariff, onSuccess, afterUpdate, onClose, paySource });
            }

            openSubscriptionsPage({ query });
        })
        .catch(() => openSubscriptionsPage({ query }));
};
