/* eslint-disable max-lines */
import classNames from 'clsx';
import differenceInHours from 'date-fns/differenceInHours';
import format from 'date-fns/format';
import { ReactComponent as GiftTariff } from 'img/icons/giftTariff.svg';
import { ReactComponent as ProIcon } from 'img/icons/pro.svg';
import { ReactComponent as TimerIcon } from 'mrg-icons/src/mailru/status/time_outline.svg';
import React, { PureComponent } from 'react';
import { abOverquotaPopupTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { features } from 'reactApp/constants/products/features';
import { Button } from 'reactApp/ui/Button/Button';
import Countdown from 'reactApp/ui/Countdown/Countdown';
import { Switch } from 'reactApp/ui/Switch/Switch';
import { specialProductIds } from 'reactApp/ui/TariffCard/TariffCard.data';
import {
    isDoubleTariff,
    isMarketingPromo,
    isMarketingPromoQuataLanding,
    isOverquotaCard,
    isProProTariff,
    isProTariff,
    sendGa,
} from 'reactApp/ui/TariffCard/TariffCard.helpers';
import { ITariffCardProps, ITariffCardState } from 'reactApp/ui/TariffCard/TariffCard.types';
import { defaultDateFormat } from 'reactApp/utils/formatDate';
import { getDiscount } from 'reactApp/utils/getDiscount';
import { noop } from 'reactApp/utils/helpers';
import { isMonth6Period, isYearPeriod } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import { FeaturesList } from '../FeaturesList/FeaturesList';
import { TariffIcon } from '../TariffIcon/TariffIcon';
import styles from './TariffCard.css';

export class TariffCardComponent extends PureComponent<ITariffCardProps, ITariffCardState> {
    static defaultProps: Omit<ITariffCardProps, 'space' | 'onBuyClick' | 'id' | 'period' | 'isAutoDeleteForPaid'> = {
        onSelect: noop,
        onPeriodSwitch: noop,
        flags: { upload: null, paidFeatures: null },
        price: 0,
        isPrimary: false,
        isSelected: false,
        saving: 0,
        showGiftsIcons: false,
        isGift: false,
        isVisible: true,
        isYear: false,
        showSaving: false,
        discountPrice: 0,
        index: 0,
        promocode: null,
        gift: null,
        modificator: '',
        fontSize: '',
        tariffId: '',
        showDiscount: false,
        showOldPrice: false,
        secondaryTariffId: '',
        secondaryTariffPrice: 0,
        withoutTariffIcon: false,
        withoutTariffFeatures: false,
        labelText: '',
        topLabel: false,
        isProfessional: false,
        isNew: false,
        noSecondProduct: false,
        isBase: false,
        isRecommended: false,
        isMobile: false,
        noShadow: false,
    };

    state: ITariffCardState = {
        showGifts: !!this.props.isSelected,
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isSelected && !this.props.isSelected && prevState.showGifts) {
            this.dontShowGifts();
        }
        if (!prevProps.isSelected && this.props.isSelected && !prevState.showGifts) {
            this.handleShowGifts();
        }
    }

    dontShowGifts = () => {
        this.setState({ showGifts: false });
    };

    handleOnBuyClick = () => {
        const { id } = this.props;
        sendGa('buy-click', id);
        sendGa('card-click');
        this.props.onBuyClick(id);
    };

    handleOnSecondaryBuyClick = () => {
        const { onBuyClick, secondaryTariffId } = this.props;

        if (secondaryTariffId) {
            sendGa('secondary-buy-click', secondaryTariffId);
            onBuyClick(secondaryTariffId);
        }
    };

    handleOnClick = () => {
        const { isVisible, onSelect } = this.props;

        if (!isVisible) {
            return;
        }

        onSelect();
    };

    handleShowGifts = () => {
        this.setState({ showGifts: true });
        this.props.onSelect();
    };

    handleCheckPeriod = (e) => {
        e.stopPropagation();

        this.props.onPeriodSwitch({ isSwitchOn: !this.props.isYear, id: this.props.tariffId });
    };

    isBusinessTariff = () => this.props.id === specialProductIds.BusinessTariffId;

    renderHeader = () => {
        const { space, modificator, id, isYear, withoutTariffIcon, isNew, saving, noSecondProduct, isBase } = this.props;

        const isProPro = isProProTariff(modificator);
        const isBusiness = this.isBusinessTariff();
        const spaceText = isBusiness ? 'Нужно больше места' : space.space;
        const nameLabel = '';

        return (
            <div
                className={classNames({
                    [styles.header]: true,
                    [styles[`header_${modificator}`]]: !!modificator,
                    [styles.header_center]: withoutTariffIcon,
                    [styles.header_abtest]: isNew,
                })}
                onClick={this.handleOnClick}
            >
                {!(isProPro || withoutTariffIcon) && <TariffIcon space={space.original} size={135} modificator="promo" />}
                <div className={styles.tariffInfo}>
                    <div
                        className={classNames({
                            [styles.name]: true,
                            [styles[`name_${modificator}`]]: !!modificator,
                            [styles.name_business]: isBusiness,
                        })}
                    >
                        {isBusiness || !withoutTariffIcon || isBase ? '' : '+'} {spaceText}
                    </div>
                    <div className={styles.units}>{space.units}</div>
                    {nameLabel && (
                        <div
                            className={classNames({
                                [styles.nameLabel]: true,
                                [styles[`nameLabel_${space.space}`]]: !!space.space,
                            })}
                        >
                            {nameLabel}
                        </div>
                    )}
                </div>
                {isProPro && !isNew && (
                    <div className={styles.switch} onClick={this.handleCheckPeriod}>
                        <div
                            className={classNames({
                                [styles.switchLabel]: true,
                                [styles.switchLabel_active]: isYear,
                            })}
                        >
                            12 мес.
                        </div>
                        <Switch name={`tariff_${id}`} checked={!isYear} theme="rounded" disabled={false} isAwaysBlue={isProPro} />
                        <div
                            className={classNames({
                                [styles.switchLabel]: true,
                                [styles.switchLabel_active]: !isYear,
                            })}
                        >
                            6 мес.
                        </div>
                    </div>
                )}
                {isNew && isBase && <div className={classNames(styles.switchBlock, styles.baseHeader)}>Бесплатный</div>}
                {isNew && !isBase && !noSecondProduct && !isMarketingPromo(modificator) && (
                    <div className={styles.switch} onClick={this.handleCheckPeriod}>
                        <div
                            className={classNames({
                                [styles.switchLabel]: true,
                                [styles.switchLabel_active]: isYear,
                            })}
                        >
                            {saving ? <div>На год</div> : 'На год'}
                            {!!saving && <div>Экономия {saving} ₽</div>}
                        </div>
                        <div className={styles.switchBlock}>
                            <Switch name={`tariff_${id}`} checked={!isYear} theme="big" disabled={false} />
                        </div>
                        <div
                            className={classNames({
                                [styles.switchLabel]: true,
                                [styles.switchBlock_right]: true,
                                [styles.switchLabel_active]: !isYear,
                            })}
                        >
                            {isProPro ? (isNew ? 'На 6 месяцев' : 'На полгода') : 'На месяц'}
                        </div>
                    </div>
                )}
                {isNew && noSecondProduct && <div className={styles.labelNoSwitch}>{isYear ? 'На год' : 'На месяц'}</div>}
            </div>
        );
    };

    renderCountDown = (promocode) => (
        <div className={styles.expires}>
            <span>
                <Countdown endDate={promocode.expires} showSeconds={false} showAsPlainText />
            </span>
        </div>
    );

    renderExpires = (promocode) => (
        <div className={styles.expires}>
            <span>{format(promocode.expires, defaultDateFormat)}</span>
        </div>
    );

    renderBuyButton = () => {
        const {
            price,
            period,
            isPrimary,
            discountPrice,
            modificator,
            isVisible,
            saving,
            showSaving,
            showOldPrice,
            isYear,
            topLabel,
            isNew,
            forceShowPeriod,
            showMonthlyPrice,
            isBase,
            isQuotaTariffsLanding,
            isOverquotaB,
            space,
            giftText,
        } = this.props;
        const isDouble = isDoubleTariff(modificator) && isYear;

        let periodText = isNew || forceShowPeriod ? (isYear ? ' / год' : ' / месяц') : '';
        if (periodText && isMonth6Period(period)) {
            periodText = ' / полгода';
        }
        let bttnText = 'По запросу';
        let showPrice = discountPrice || price;
        let showYearPriceNote = false;

        if (!this.isBusinessTariff() && !isOverquotaB) {
            if (showMonthlyPrice) {
                periodText = ' / месяц';
                if (isYearPeriod(period)) {
                    showPrice = Math.round(showPrice / 12);
                    showYearPriceNote = true;
                }
            }
            bttnText = `Купить за ${formatPrice(showPrice)} ₽${periodText}`;
        }

        if (isDouble || isMarketingPromo(modificator)) {
            bttnText = `Купить за ${formatPrice(showPrice)} ₽/год`;
        }

        const isActionQuotaLp = isMarketingPromoQuataLanding(modificator);

        if (isQuotaTariffsLanding && isActionQuotaLp) {
            bttnText = isYear ? `${formatPrice(showPrice)} ₽ + ${giftText} в подарок` : `Купить за ${formatPrice(showPrice)} ₽ в месяц`;
        } else if (isQuotaTariffsLanding) {
            bttnText = isYear ? `Купить за ${formatPrice(showPrice)} ₽ в год` : `Купить за ${formatPrice(showPrice)} ₽ в месяц`;
        } else if (isOverquotaB) {
            bttnText = `Увеличить на ${space.value}`;
        }

        const savingText = isDouble ? `${formatPrice(price / 12)} ₽/месяц при&nbsp;оплате&nbsp;за&nbsp;год` : `Вы сэкономите ${saving} ₽`;
        const showPriceOld = showOldPrice && discountPrice;

        if (!isVisible) {
            return null;
        }

        if (isNew && isBase) {
            return (
                <Button size="medium" fluid className={styles.baseTariffButton}>
                    <span className={styles.priceBtn}>Уже включено</span>
                </Button>
            );
        }

        // tempexp_16790-start
        if (abOverquotaPopupTariffs && modificator === 'quota') {
            return (
                <Button
                    size="medium"
                    primary
                    fluid
                    onClick={this.handleOnBuyClick}
                    className={classNames({
                        [styles.button]: true,
                        [styles[`button_${modificator}`]]: !!modificator,
                        [styles[`button_ab_quota`]]: true,
                    })}
                >
                    <div>
                        <span className={classNames({ [styles.priceBtn]: true, [styles[`priceBtn_${modificator}`]]: true })}>
                            {abOverquotaPopupTariffs === 'c' ? (
                                `${formatPrice(price)} ₽ в месяц`
                            ) : (
                                <>
                                    {`${formatPrice((discountPrice || price) / 12)} ₽ в месяц`}
                                    <span>при покупке на год</span>
                                </>
                            )}
                        </span>
                    </div>
                </Button>
            );
        }
        // tempexp_16790-end

        return (
            <>
                {isMarketingPromo(modificator) && <div className={styles.priceOld}>{formatPrice(price)} ₽/год</div>}
                <Button
                    size="medium"
                    primary={isPrimary || isNew}
                    fluid
                    onClick={this.handleOnBuyClick}
                    className={classNames({
                        [styles.button]: true,
                        [styles[`button_${modificator}`]]: !!modificator,
                        [styles.button_year]: isYear,
                    })}
                >
                    <div>
                        <span className={classNames({ [styles.priceBtn]: true, [styles[`priceBtn_${modificator}`]]: true })}>
                            {bttnText}
                            {showPriceOld && <span className={styles.priceOld}>{`${formatPrice(price)} ₽`}</span>}
                        </span>
                    </div>
                </Button>
                {showSaving && !isNew && !topLabel && (
                    <div
                        className={classNames({
                            [styles.save]: true,
                            [styles[`save_${modificator}`]]: !!modificator,
                        })}
                    >
                        <span dangerouslySetInnerHTML={{ __html: savingText }} />
                    </div>
                )}
                {showYearPriceNote && (
                    <div className={styles.yearPriceNote}>При разовой оплате {`${formatPrice(discountPrice || price)} ₽`}/год</div>
                )}
            </>
        );
    };

    renderGifts = () => {
        const { space } = this.props;

        const { showGifts } = this.state;

        return (
            <div className={styles.gifts}>
                {!showGifts && (
                    <div className={styles.giftsButton} onClick={this.handleShowGifts}>
                        + подарок
                    </div>
                )}
                {showGifts && (
                    <div className={styles.giftDetails}>
                        <div className={styles.giftBlock}>
                            <div className={styles.giftBlockIcon}>
                                <TariffIcon space={space.original} size={65} modificator={'small'} withSpace />
                            </div>
                            <div className={styles.giftBlockText}>{`Годовая подписка на ${space.value} в подарок`}</div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderSecondaryButton = () => {
        const { secondaryTariffId, secondaryTariffPrice, isPrimary } = this.props;

        if (!secondaryTariffId) {
            return null;
        }

        return (
            <div
                className={classNames({
                    [styles.secondaryButton]: true,
                    [styles.secondaryButton_primary]: isPrimary,
                })}
                onClick={this.handleOnSecondaryBuyClick}
            >
                Купить за {formatPrice(secondaryTariffPrice)} ₽/месяц
            </div>
        );
    };

    renderTopLabel = () => {
        const { isProfessional, topLabel, labelText, space, showSaving, saving, discountPrice, price, modificator, isRecommended } =
            this.props;
        const { roundedDiscount } = getDiscount({ discountPrice, price });

        if (!topLabel && !labelText && !isRecommended) {
            return null;
        }

        const quota = space.space;
        const showDiscount = showSaving && !!roundedDiscount && !isMarketingPromo(modificator) && !isRecommended;

        let label = '';
        switch (true) {
            case showSaving && !!saving && !isRecommended:
                label = `Экономия ${saving} ₽`;
                break;
            case isRecommended:
                label = 'Рекомендуем';
                break;
            case quota === 128:
                label = 'Популярный';
                break;
            case quota === 256:
                label = 'Оптимальный';
                break;
            case isProfessional:
                label = 'PRO';
                break;
            case isMarketingPromo(modificator):
                label = `Скидка ${roundedDiscount}%`;
                break;
        }

        label = labelText ? labelText : label;

        if (!label) {
            return null;
        }

        return (
            <div className={styles.textLabelBlock}>
                {showDiscount && <div className={styles.textLabelDiscount}>−{roundedDiscount}%</div>}
                <div
                    className={classNames({
                        [styles.textLabel]: true,
                        [styles[`textLabel_${quota}`]]: !!quota,
                        [styles.textLabel_pro]: isProfessional,
                        [styles.textLabelRecommended]: isRecommended,
                    })}
                >
                    {label}
                </div>
            </div>
        );
    };

    render() {
        let { isSelected } = this.props;
        const {
            promocode,
            isPrimary,
            modificator,
            fontSize,
            isYear,
            flags,
            isGift,
            discountPrice,
            price,
            showDiscount,
            withoutTariffFeatures,
            showOldPrice,
            isAutoDeleteForPaid,
            isNew,
            isQuotaTariffsLanding,
            isBase,
            isRecommended,
            isOverquotaB,
            isMobile,
            noShadow,
        } = this.props;

        const isPro = isProTariff(modificator);
        const isProPro = isProProTariff(modificator);
        const isDouble = isDoubleTariff(modificator);
        const isOverquota = isOverquotaCard(modificator);
        const now = new Date();
        const showCountDown = promocode && differenceInHours(promocode.expires, now) <= 24;
        const showExpires = promocode && !showCountDown;
        const { roundedDiscount } = getDiscount({ discountPrice, price });
        const isBusiness = this.isBusinessTariff();
        const isAction = isMarketingPromo(modificator);
        const isActionQuotaLp = isMarketingPromoQuataLanding(modificator);

        if (isRecommended) {
            isSelected = true;
        }

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_primary]: isPrimary,
                    [styles.root_focused]: !!isSelected,
                    [styles.root_promocode]: promocode,
                    [styles[`root_${modificator}`]]: !!modificator,
                    [styles.root_gift]: !!isGift && !!isSelected,
                    [styles.root_new]: !!isNew,
                    [styles.rootNewMobile]: !!isNew && isMobile,
                    [styles.root_quota_action_focused]: isActionQuotaLp && isYear,
                    [styles.root_no_shadow]: noShadow,
                })}
                style={fontSize ? { fontSize } : {}}
                onClick={this.handleOnClick}
            >
                {isPro && (
                    <div className={styles.headerLabel}>
                        <ProIcon width={36} height={14} />
                    </div>
                )}
                {isGift && isSelected && (
                    <div className={styles.headerLabel}>
                        <GiftTariff width={22} height={22} />
                    </div>
                )}
                {this.renderTopLabel()}
                {!!promocode && (
                    <div className={classNames(styles.headerLabel, styles.headerLabelPromo)}>
                        <TimerIcon width={16} height={16} />
                        {showCountDown && this.renderCountDown(promocode)}
                        {showExpires && this.renderExpires(promocode)}
                    </div>
                )}
                {showDiscount && !!roundedDiscount && <div className={styles.discount}>{`−${roundedDiscount}%`}</div>}
                {this.renderHeader()}
                {showOldPrice && Boolean(discountPrice) && !isActionQuotaLp && (
                    <div className={styles.priceOld}>Вместо {`${formatPrice(price)} ₽`}</div>
                )}
                <div
                    className={classNames({
                        [styles.footer]: true,
                        [styles[`footer_${modificator}`]]: !!modificator,
                    })}
                >
                    {!isOverquota && this.renderBuyButton()}
                    {this.renderSecondaryButton()}
                    {!withoutTariffFeatures && (
                        <div className={styles.features}>
                            <FeaturesList
                                itemClassName={styles.feature}
                                showIcon
                                paidFeatures={!isNew && flags?.paidFeatures && !isPro && !isProPro && !isDouble && !isAction}
                                upload={!isNew && flags?.upload && !isPro && !isProPro && !isDouble && !isAction}
                                autodelete={!isNew && isAutoDeleteForPaid && !isPro && !isProPro && !isDouble && !isBusiness && !isAction}
                                modificator={isBusiness ? 'business' : 'new'}
                                professional={isPro || (isProPro && (isSelected || isNew))}
                                professionalNew={isProPro}
                                professionalShort={isPro && !isSelected && !isNew}
                                isYear={isYear}
                                isAction={isAction && !isNew}
                                customItems={isBusiness ? features.business : null}
                                paidWithIcon={isNew && !isProPro}
                                isOverquota={isOverquota}
                                isQuotaTariffsLanding={isQuotaTariffsLanding}
                                isQuotaTariffsLandingBase={isBase}
                                isOverquotaB={isOverquotaB}
                            />
                        </div>
                    )}
                    {isOverquota && this.renderBuyButton()}
                </div>
            </div>
        );
    }
}
