import theme from '@vkontakte/vkui-tokens/themes/cloud/cssVars/theme';
import classNames from 'clsx';
import { ReactComponent as DoneIcon } from 'mrg-icons/src/mailru/status/done.svg';
import React, { VFC } from 'react';
import { TariffCardContentSize } from 'reactApp/ui/TariffCardNew/TariffCard.types';

import styles from './TariffFeatureList.css';
import { ITariffFeatureListProps } from './TariffFeatureList.types';

export const TariffFeatureList: VFC<ITariffFeatureListProps> = ({
    dataQa,
    items,
    showIcon = false,
    large = false,
    accent = false,
    contentSize = TariffCardContentSize.middle,
}) => (
    <ul
        className={classNames(styles.featureList, {
            [styles[`contentSize_${contentSize}`]]: !!contentSize,
        })}
        data-qa={dataQa}
    >
        {items.map((item) => (
            <li
                key={item.key}
                className={classNames(styles.featureListItem, {
                    [styles.featureListItem_large]: large,
                    [styles.featureListItem_accent]: accent,
                    [styles.featureListItem_pointer]: !!item.onClick,
                })}
                onClick={item.onClick}
            >
                {showIcon && (
                    <div className={styles.featureListItemIcon}>{item.icon || <DoneIcon fill={theme.colorIconAccent.normal.value} />}</div>
                )}
                <div data-qa={`${dataQa}_text`}>{item.text}</div>
            </li>
        ))}
    </ul>
);
