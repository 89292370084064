import { sendPostMessage } from 'reactApp/utils/windowHelper';

export const sendFrameMessage = ({ type, ...data }) => {
    sendPostMessage({ key: 'cloud-fast-checkout', type, ...data });
};

export enum DisableAdsPostMessageType {
    size = 'SIZE',
    close = 'CLOSE',
    success = 'SUCCESS',
}
