import type { WopiParams } from 'Cloud/Application/Editor/types';
import { createForm } from 'Cloud/Application/Editor/utils/createForm';
import { xray } from 'lib/xray';
import type { CloudFile } from 'reactApp/modules/storage/storage.types';
import { noopVoid } from 'reactApp/utils/helpers';
import { EditorMode } from 'server/helpers/editors/types';

/**
 * Id сообщения
 */
const enum MessageID {
    // AppLoadingStatus = 'App_LoadingStatus',
    UIClose = 'UI_Close',
}
/**
 * Кастомный обработчик  сообщения
 */
const enum Type {
    Load = 'r7wopi_done',
    Error = 'r7wopi_err',
}

/**
 * @see https://support.r7-office.ru/document_server/api-document_server/settings-wopi/wopi-rest-api/postmessage/
 */
type Message = {
    /** Id сообщения */
    MessageId: MessageID;
    /** Кастомный обработчик  сообщения */
    type: Type;
    /** Время отправки сообщения в миллисекундах */
    SendTime: number;
    /** Дополнительная информация */
    Values: ReturnType<typeof JSON.parse>;
};

type Events = {
    onDocumentReady: () => void;
    onError: () => void;
};

const COMMON_PREFIX = 'r7wopi' as const;

/** Ошибка загрузки */
const enum Error {
    ParseJson = 'parse-json',
    Instance = 'instance',
    CustomInstance = 'custom-instance',
}

const sendErrorAnalytics = (mode: Mode, ext: string, error: Error, additionalInfo: string) => {
    const type = `${COMMON_PREFIX}_${mode}_error`;

    xray.send(`${type}_${error}_${ext}`, {
        rlog: `${type}`,
        rlog_message: { error: `${error}\n${additionalInfo}` },
    });
};

/** Статус загрузки */
const enum Status {
    Start = 'start',
    Error = 'error',
    Load = 'load',
}

const sendLoadAnalytics = (mode: Mode, ext: string, status: Status) => {
    xray.send(`${COMMON_PREFIX}_${mode}_status_${status}_${ext}`);
};

const sendLoadTimeAnalytics = (mode: Mode, ext: string, mountTime: number) => {
    const readyTime = performance.now();
    const time = Math.floor((readyTime - mountTime) / 1000);

    xray.send(`${COMMON_PREFIX}_${mode}_time_${ext}`, {
        i: {
            [time]: 1,
        },
    });
};

type PostMessageHandlerFn = (props: {
    ext: string;
    iframe: HTMLIFrameElement | null;
    mode: Options['mode'];
    events: Events;
    mountTime: number;
}) => (event: MessageEvent<string>) => void;

const postMessageHandler: PostMessageHandlerFn = ({ iframe, mode, events, mountTime, ext }) => {
    return (event: MessageEvent<string>) => {
        if (event.source !== iframe?.contentWindow) {
            return;
        }
        try {
            const message: Message = JSON.parse(event.data);
            const messagesMap: Record<MessageID | Type | 'default', () => void> = {
                [Type.Load]: () => {
                    sendLoadAnalytics(mode, ext, Status.Load);
                    sendLoadTimeAnalytics(mode, ext, mountTime);
                    events.onDocumentReady();
                },
                [Type.Error]: () => {
                    sendLoadAnalytics(mode, ext, Status.Error);
                    sendErrorAnalytics(mode, ext, Error.CustomInstance, Error.CustomInstance);
                    events.onError();
                },
                [MessageID.UIClose]: () => {
                    sendLoadAnalytics(mode, ext, Status.Error);
                    sendErrorAnalytics(mode, ext, Error.Instance, JSON.stringify(message?.Values));
                    events.onError();
                },
                default: () => noopVoid,
            };

            const listener = messagesMap[message?.MessageId || message.type] || messagesMap.default;

            return listener();
        } catch (error) {
            sendLoadAnalytics(mode, ext, Status.Error);
            sendErrorAnalytics(mode, ext, Error.ParseJson, JSON.stringify(error));
            events.onError();
        }
    };
};

type Mode = EditorMode.EDIT | EditorMode.VIEW;

type Options = {
    wopiParams: WopiParams;
    iframe: HTMLIFrameElement;
    mode: Mode;
    container: HTMLElement;
    events: Events;
};

type InitR7WopiFn = (file: CloudFile, options: Options) => () => void;

/**
 * Добавляет возможность открывать файл на редактирование/просмотр для R7Wopi.
 * Инициализирует механизм PostMessage и дает возможность отписки
 *
 * @param file  - файл, который открывается для просмотра или редактирования
 * @param options - необходимая конфигурация
 */
export const initR7Wopi: InitR7WopiFn = (file, { wopiParams, mode, iframe, container, events }) => {
    const mountTime = performance.now();
    const ext = file.ext;
    sendLoadAnalytics(mode, ext, Status.Start);
    const { accessParams, postContentType, url } = wopiParams || {};
    const form = createForm({
        action: url,
        inputs: accessParams,
        target: file.name,
        postContentType,
        container,
    });

    form.submit();

    const postMessageListener = postMessageHandler({
        iframe,
        ext,
        mode,
        events,
        mountTime,
    });

    window.addEventListener('message', postMessageListener);

    // Удаляем форму из DOM, она больше не нужна
    form.remove();

    return () => {
        window.removeEventListener('message', postMessageListener);
    };
};
