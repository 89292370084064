import { Icon16MoreVertical } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { DatalistPic } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { LinkIcon } from 'reactApp/ui/VKUIIcons';
import { noop } from 'reactApp/utils/helpers';

import styles from './DatalistRowItem.css';
import { IProps } from './DatalistRowItem.types';

const defaultOptionIconElement = <Icon16MoreVertical className={styles.icon} width={20} height={20} fill="currentColor" />;

export const DatalistRowItem = memo(
    ({
        id,
        isFolder,
        kind,
        ext,
        name,
        size,
        isVirus,
        isSelecting,
        isSelected,
        thumbUrl,
        onClick,
        onDoubleClick,
        onOptions,
        onShare,
        showOptionIcon = true,
        optionIconElement = defaultOptionIconElement,
        weblink,
        author,
        onQuotaCleanerSelect,
        moreBtnRef,
    }: IProps): ReactElement => {
        const storage = useSelector(getCurrentStorage);
        const { isPublic, isQuotaCleaner } = getStorage(storage);

        return (
            <div
                className={classNames(styles.root, { [styles.root_selecting]: isSelecting })}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                data-qa-id={id}
                data-qa-type={isFolder ? 'folder' : 'file'}
                data-qa-name={ext ? `${name}.${ext}` : name}
            >
                {isSelecting && (
                    <div className={styles.check} onClick={onQuotaCleanerSelect}>
                        <DataListControl type="check" active={isSelected} size={24} viewType={EViewMode.list} />
                    </div>
                )}
                <div className={styles.pic}>
                    <DatalistPic
                        isFolder={isFolder}
                        ext={ext || ''}
                        kind={kind}
                        thumbUrl={thumbUrl}
                        isVirus={isVirus}
                        viewMode={EViewMode.list}
                        author={author}
                    />
                </div>
                <div className={styles.info}>
                    <div className={styles.name}>
                        <NameComponent name={name} extension={ext} truncate />
                    </div>
                    <div className={styles.size}>{size}</div>
                </div>

                <div className={styles.buttons}>
                    {!isPublic && Boolean(weblink) && (
                        <div className={styles.button_publish} onClick={isSelecting ? noop : onShare}>
                            <LinkIcon className={styles.icon} width={14} height={14} />
                        </div>
                    )}
                    {showOptionIcon && optionIconElement && !isQuotaCleaner && (
                        <div ref={moreBtnRef} className={styles.button_more} onClick={isSelecting ? noop : onOptions}>
                            {optionIconElement}
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

DatalistRowItem.displayName = 'DatalistRowItem';
