import classNames from 'clsx';
import React, { memo } from 'react';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './BizItemName.css';

interface BizItemNameProps {
    className?: string;
    name: string;
    ext?: string;
    showTooltip?: boolean;
}

export const BizItemName = memo<BizItemNameProps>(function BizItemName({ className, name, ext, showTooltip = false }) {
    const itemName = `${name}${ext ? `.${ext}` : ''}`;

    return (
        <div className={classNames(styles.root, className)}>
            {showTooltip ? (
                <Hint text={itemName}>
                    <>{itemName}</>
                </Hint>
            ) : (
                itemName
            )}
        </div>
    );
});
