/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable new-cap */
import { xray } from 'lib/xray';
import { changeTheme, WhitelineTheme } from 'reactApp/modules/theme/theme.module';
import { destroyUser } from 'reactApp/modules/user/user.actions';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { showAuth } from 'reactApp/utils/auth';

import { setPhListnerAction, setPrevEmail } from './ph.actions';
import { getAccounts, getSuccessPage, reloadPage } from './ph.helpers';
import { getPhListner, getPrevEmail } from './ph.selectors';
import { AuthPopupAction, Params } from './ph.types';

export const authPopup =
    (data: AuthPopupAction = {}) =>
    async (dispatch, getState) => {
        const { closable = false, successPage, loginRequest, requiredAuthWhenDownload } = data;

        xray.send('ph_auth_show-popup');

        if (typeof window.__PH === 'undefined') {
            xray.send('ph_auth_show-popup_no-ph');
            return;
        }

        getAccounts().then((accounts) => {
            const params: Params = {
                closable,
                successPage,
                page: successPage,
                opener: 'cloud',
            };

            if (requiredAuthWhenDownload) {
                params.when_download = requiredAuthWhenDownload;
            }

            if (!loginRequest) {
                const domain = UserSelectors.getDomain(getState());
                const email = UserSelectors.getEmail(getState());
                params.formType = accounts && accounts.length ? 'restore' : 'relogin';

                if (email || domain) {
                    params.email = email;
                    params.domain = domain;
                }

                dispatch(destroyUser());
            }

            showAuth(params);
        });
    };

// eslint-disable-next-line unicorn/consistent-function-scoping
const authPopupHide = () => (dispatch) => {
    dispatch(updateUser())
        .then(() => {
            // @ts-ignore
            RADAR.beaconImmediate('auth_relogin').after(() => {
                window.__PH.authForm.hide();
                xray.send('ph_auth_relogin');
            });
        })
        .catch(() => reloadPage());
};

export const authUpdate =
    ({ data }, next) =>
    (dispatch, getState) => {
        const newEmail = data.email || null;
        const prevEmail = getPrevEmail(getState());
        xray.send('ph_auth_update');

        if (prevEmail && newEmail !== prevEmail) {
            dispatch(setPrevEmail({ prevEmail: null }));

            dispatch(authPopupHide());
        }

        next();
    };

export const authLost = () => (dispatch, getState) => {
    const prevEmail = getPrevEmail(getState());

    if (!prevEmail) {
        dispatch(setPrevEmail({ prevEmail: UserSelectors.getEmail(getState()) }));

        // @ts-ignore
        RADAR('auth_lost');
        xray.send('ph_auth_lost');

        dispatch(authPopup({ successPage: getSuccessPage(window.location) }));
    }
};

export const loginRequest = (e, next) => (dispatch) => {
    // @ts-ignore
    RADAR('auth_login-request');
    xray.send('ph_auth_login-request');

    const successPage = getSuccessPage(window.location);

    dispatch(authPopup({ closable: true, successPage, loginRequest: true }));

    next();
};

export const authChange =
    ({ data }, next) =>
    (dispatch) => {
        if (data.email) {
            xray.send('ph_auth_change');
            reloadPage();
        } else {
            // @ts-ignore
            RADAR('auth_lost');
            xray.send('ph_auth_change-lost');
            dispatch(authPopup({ successPage: getSuccessPage(window.location) }));
        }

        next();
    };

export const setPhListner = () => (dispatch, getState) => {
    const phListner = getPhListner(getState());

    return new Promise<void>((resolve) => {
        if (phListner) {
            return resolve();
        }

        dispatch(setPhListnerAction({ value: true }));

        if (typeof window.__PH !== 'undefined') {
            window.__PH.on('authLost', () => dispatch(authLost()));
            window.__PH.on('update', (e, next) => dispatch(authUpdate(e, next)));
            window.__PH.on('loginRequest', (e, next) => dispatch(loginRequest(e, next)));
            window.__PH.on('authChange', (e, next) => dispatch(authChange(e, next)));
            window.__PH.on('themeChange', (e) => dispatch(changeTheme(e?.data as WhitelineTheme)));
        }

        return resolve();
    });
};
