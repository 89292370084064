import config from 'Cloud/config';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { binClearRequest } from 'reactApp/modules/trashbin/trashbin.module';
import { getOpenedBin } from 'reactApp/modules/trashbin/trashbin.selectors';
import { createGaSender } from 'reactApp/utils/ga';

import { IProps } from './EmptyTrashBin.types';

const isBizUser = config.get('BIZ_USER');

const sendGa = createGaSender(`trashbin-empty${isBizUser ? '_biz' : ''}`);

export const EmptyTrashBin = React.memo(function EmptyTrashBin(props: IProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);
    const bin = useSelector(getOpenedBin);
    const dispatch = useDispatch();

    const onConfirm = useCallback(() => {
        setLoading(true);
        dispatch(binClearRequest({ id: bin?.id || '', tree: bin?.tree || '' }));
        sendGa('confirm');
        props.onClose();
    }, [bin?.id, bin?.tree, dispatch, props]);

    const onClose = useCallback(() => {
        sendGa('close');
        props.onClose();
    }, [props]);

    const renderHeader = useCallback(() => <>Удалить все объекты в корзине?</>, []);

    const renderContent = useCallback(() => {
        return <div>После этого их нельзя будет восстановить</div>;
    }, []);

    useEffect(() => sendGa('show'), []);

    return (
        <BaseConfirmDialog
            dataQAId="empty-trashbin-dialog"
            renderHeader={renderHeader}
            isNegative
            actionButtonText="Удалить навсегда"
            cancelButtonText="Не удалять"
            buttons={[EButtonType.action, EButtonType.cancel]}
            onClose={onClose}
            onAction={onConfirm}
            renderContent={renderContent}
            loading={loading}
            closeOnDimmerClick
        />
    );
});
