import { Icon12Cancel } from '@vkontakte/icons';
import theme from '@vkontakte/vkui-tokens/themes/cloud/cssVars/theme';
import { ReactComponent as CancelIcon } from 'img/icons/cancel_benefit.svg';
import { ReactComponent as DiskoIcon } from 'img/icons/disko.svg';
import { ReactComponent as DoneIcon } from 'img/icons/done_benefit.svg';
import { ReactComponent as VkMusicIcon } from 'img/icons/vk_music.svg';
import { plural } from 'lib/plural';
import React from 'react';
import { isFamilySubsInTariff, isOnlyWebAdInTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { IFeatureData } from 'reactApp/constants/products/features';
import { openDisko } from 'reactApp/constants/products/features.constants';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { Product } from 'reactApp/types/Billing';

import { benefitsBySpace } from './benefits.bySpace';
import { BenefitInfoType, BenefitKeyType, benefitsInfo } from './benefits.info';
import styles from './quotaFeatures.css';

const baseQuotaFeatures: IFeatureData[] = [
    {
        text: isOnlyWebAdInTariffs ? 'Без рекламы в веб-версии' : 'Без рекламы в Почте и Облаке',
        textJsx: !isOnlyWebAdInTariffs && (
            <>
                <a target="_blank" href={getQuotaPromoUrl({ query: PaymentUTM.featuresTariffsCard })} rel="noreferrer">
                    Без рекламы
                </a>
                {'\u00A0'}в Почте и Облаке
            </>
        ),
        key: 'ad',
    },
    {
        text: 'Загрузка файлов до\u00A0100\u00A0ГБ в Облако',
        key: 'upload',
    },
    {
        text: 'Отправка файлов до\u00A0100\u00A0ГБ в письмах',
        key: 'sending',
    },
    {
        text: 'Автоудаление папок',
        key: 'autodelete',
    },
    isFamilySubsInTariff
        ? {
              text: 'Делитесь местом с близкими',
              key: 'family',
          }
        : {
              text: 'Приоритетная поддержка',
              key: 'support',
          },
];
const baseQuotaProFeatures: IFeatureData[] = [
    {
        text: 'Лицензия Диск-О: на год в подарок',
        key: 'disko',
        icon: <DiskoIcon width={16} height={16} />,
        onClick: openDisko,
        delimiters: true,
    },
    {
        text: 'VK Музыка бесплатно на 3\u00A0месяца',
        key: 'combo',
        icon: <VkMusicIcon width={16} height={16} viewBox="0 0 18 18" />,
        delimiters: true,
    },
];

const baseQuotaProFeatures_6m = [
    {
        ...baseQuotaProFeatures[0],
        text: 'Лицензия ДискО: на 6\u00A0месяцев в подарок',
    },
    {
        ...baseQuotaProFeatures[1],
        text: 'VK Музыка бесплатно на 1\u00A0месяц',
    },
];

const baseQuotaCompact: IFeatureData[] = [
    {
        text: isOnlyWebAdInTariffs ? 'Без рекламы в веб-версии' : 'Без рекламы в Почте и Облаке',
        key: 'ad',
    },
    {
        text: 'Загрузка файлов до\u00A0100\u00A0ГБ',
        key: 'upload',
    },
    isFamilySubsInTariff
        ? {
              text: 'Делитесь местом с близкими',
              key: 'family',
          }
        : {
              text: 'Быстрый ответ поддержки',
              key: 'support',
          },
    {
        text: 'И другие возможности',
        textJsx: (
            <>
                И другие{'\u00A0'}
                <a href="#benefits">возможности</a>
            </>
        ),
        key: 'benefits',
    },
];

const baseFreeQuotaCompact: IFeatureData[] = [
    {
        text: isOnlyWebAdInTariffs ? 'Реклама в веб-версии' : 'Реклама в Почте и Облаке',
        key: 'ad',
        icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
    },
    {
        text: 'Нет загрузки больших файлов',
        key: 'upload',
        icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
    },
    isFamilySubsInTariff
        ? {
              text: 'Делиться местом нельзя',
              key: 'family',
              icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
          }
        : {
              text: 'Нет быстрой поддержки',
              key: 'support',
              icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
          },
    {
        text: 'Нет дополнительных возможностей',
        textJsx: (
            <>
                Нет{'\u00A0'}дополнительных
                <br />
                <a href="#benefits">возможностей</a>
            </>
        ),
        key: 'benefits',
        icon: <Icon12Cancel fill={theme.colorIconSecondary.normal.value} width={14} height={14} />,
    },
];

export const quotaFeatures: Record<string, IFeatureData[]> = {
    quotaTariffsLanding: baseQuotaFeatures,
    freeQuotaCompact: baseFreeQuotaCompact,
    quotaRegular: baseQuotaFeatures,
    quotaCompact: baseQuotaCompact,
    quotaProfessional: [...baseQuotaFeatures, ...baseQuotaProFeatures],
    quotaProfessional_6m: [...baseQuotaFeatures, ...baseQuotaProFeatures_6m],
};

// tempexp_16800-start
const featureIds = [benefitsInfo[BenefitKeyType.Ad].key, benefitsInfo[BenefitKeyType.Upload].key, benefitsInfo[BenefitKeyType.Family].key];
const expandedFeatureIds = [
    ...featureIds,
    benefitsInfo[BenefitKeyType.Support].key,
    benefitsInfo[BenefitKeyType.Calendar].key,
    benefitsInfo[BenefitKeyType.Safety].key,
    benefitsInfo[BenefitKeyType.Mobile].key,
    benefitsInfo[BenefitKeyType.Folders].key,
];

const quotaFeaturesBySpace: Record<string, IFeatureData[]> = {};

export function createQuotaFeaturesListByProductSpace(product: Product, variant: 'all' | 'all-active', shortText: boolean) {
    const cacheKey = `${product.space.original}-${variant}-${shortText ? 'short' : 'key'}`;
    const result: IFeatureData[] = quotaFeaturesBySpace[cacheKey] ?? [];

    if (product.isSingleQuota && !result.length) {
        expandedFeatureIds.forEach((featureKey) => {
            const benefit: BenefitInfoType | undefined = benefitsBySpace[product.space.original]?.[featureKey];
            if (benefit && variant === 'all-active') {
                result.push({
                    key: featureKey,
                    text: shortText ? benefit.shortText : benefit.fullText,
                });
            }
            if (variant === 'all') {
                if (benefit) {
                    const text = shortText ? benefit.shortText : benefit.fullText;
                    result.push({
                        key: featureKey,
                        text,
                        icon: <DoneIcon />,
                    });
                } else {
                    const text = shortText ? benefitsInfo[featureKey].shortText : benefitsInfo[featureKey].fullText;
                    result.push({
                        key: featureKey,
                        text,
                        textJsx: <span className={styles.cancelText}>{text}</span>,
                        icon: <CancelIcon />,
                    });
                }
            }
        });
        quotaFeaturesBySpace[cacheKey] = result;
    }
    return result;
}

export function getAdditionalBenefitsCountText(product: Product) {
    const pluralTitles = ['возможность', 'возможности', 'возможностей'];
    const activeBenefits = Object.values(benefitsBySpace[product.space.original] ?? {}).filter((b) => !!b);
    const count = activeBenefits.length - featureIds.length;
    return `${count} ${pluralTitles[plural(count)]}`;
}

// tempexp_16800-end
