import React from 'react';

import styles from './FilesFolderLogViewerStub.css';
import { useStubImage } from './useStubImage';

type FilesFolderLogViewerStubProps = {
    isOwner: boolean;
};

export const FilesFolderLogViewerStub: React.FC<FilesFolderLogViewerStubProps> = ({ isOwner }) => {
    const { stubImage } = useStubImage();

    const stubText = isOwner
        ? 'Выберите один файл или папку, чтобы увидеть историю'
        : 'Примите доступ в папку, чтобы просматривать историю';

    return (
        <div className={styles.wrapper}>
            <img src={stubImage} />
            <div className={styles.regular}>{stubText}</div>
        </div>
    );
};
