import {
    Icon20Cards2Outline,
    Icon20CloudOutline,
    Icon20DeleteOutline,
    Icon20DoorArrowRightOutline,
    Icon20HelpOutline,
    Icon20UserSlashOutline,
    Icon20UsersSlashOutline,
    Icon24Cards2Outline,
    Icon24CloudOutline,
    Icon24DeleteOutline,
    Icon24DoorArrowRightOutline,
    Icon24HelpOutline,
} from '@vkontakte/icons';
import { ReactComponent as Icon24UsersSlashOutline } from 'img/icons/vkui/users_slash_outline_24.svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { PaymentUTM } from 'reactApp/constants/paymentUTM';
import { FamilyAnalyticsCategory, FamilyAnalyticsPageActions, sendFamilyAnalytics } from 'reactApp/modules/family/family.analytics';
import { isUserFamilyMember, isUserFamilyOwner } from 'reactApp/modules/family/family.selectors';
import { getQuotaPromoUrl } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';
import { getSubscriptionsUrl } from 'reactApp/sections/SubscriptionsPage/SubscriptionsPage.constants';
import { noopVoid } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';

import { FamilySettingDropdownListItemIDs, IDropdownItem } from './FamilySettingsDropdown.types';

type useDropdownListHook = (props: {
    isMemberSettings?: boolean;
    onLeave?: () => void;
    onDelete?: () => void;
    clearSpace?: boolean;
}) => IDropdownItem[];

const onItemClick =
    (url: string, action: FamilyAnalyticsPageActions, owner = true) =>
    () => {
        sendFamilyAnalytics({
            eventCategory: owner ? FamilyAnalyticsCategory.pageOwner : FamilyAnalyticsCategory.pageGuest,
            action,
        });
        opener(url);
    };

export const useDropdownList: useDropdownListHook = ({ isMemberSettings, onLeave = noopVoid, onDelete = noopVoid, clearSpace = false }) => {
    const isFamilyMember = useSelector(isUserFamilyMember);
    const isFamilyOwner = useSelector(isUserFamilyOwner);

    const clearSpaceItem = clearSpace
        ? [
              {
                  id: isFamilyOwner ? FamilySettingDropdownListItemIDs.clearSpaceOwner : FamilySettingDropdownListItemIDs.clearSpace,
                  text: 'Освободить место',
                  icon: <Icon20DeleteOutline />,
                  iconMobile: <Icon24DeleteOutline />,
                  onClick: onItemClick(
                      getQuotaPromoUrl({ query: PaymentUTM.familyClear, hash: 'cleaner' }),
                      FamilyAnalyticsPageActions.clickButtonClear,
                      isFamilyOwner
                  ),
              },
          ]
        : [];

    if (isMemberSettings) {
        if (isFamilyOwner) {
            return [
                ...clearSpaceItem,
                {
                    id: FamilySettingDropdownListItemIDs.space,
                    text: 'Увеличить место',
                    icon: <Icon20CloudOutline />,
                    iconMobile: <Icon24CloudOutline />,
                    onClick: onItemClick(
                        getQuotaPromoUrl({ query: PaymentUTM.familyBuy }),
                        FamilyAnalyticsPageActions.clickButtonUpgradeStorage
                    ),
                },
                {
                    id: FamilySettingDropdownListItemIDs.subscriptionsOwner,
                    text: 'Мои подписки',
                    icon: <Icon20Cards2Outline />,
                    iconMobile: <Icon24Cards2Outline />,
                    onClick: onItemClick(getSubscriptionsUrl(PaymentUTM.familySubs), FamilyAnalyticsPageActions.clickButtonSubscriptions),
                },
                {
                    id: FamilySettingDropdownListItemIDs.deleteAll,
                    text: 'Удалить всех из подписки',
                    icon: <Icon20UsersSlashOutline />,
                    iconMobile: <Icon24UsersSlashOutline />,
                    onClick: onDelete,
                },
            ];
        }

        if (isFamilyMember) {
            return [
                ...clearSpaceItem,
                {
                    id: FamilySettingDropdownListItemIDs.subscriptions,
                    text: 'Мои подписки',
                    icon: <Icon20Cards2Outline />,
                    iconMobile: <Icon24Cards2Outline />,
                    onClick: onItemClick(
                        getSubscriptionsUrl(PaymentUTM.familySubs),
                        FamilyAnalyticsPageActions.clickButtonSubscriptions,
                        false
                    ),
                },
                {
                    id: FamilySettingDropdownListItemIDs.more,
                    text: 'Подробнее',
                    icon: <Icon20HelpOutline />,
                    iconMobile: <Icon24HelpOutline />,
                    onClick: onItemClick(
                        getQuotaPromoUrl({ query: PaymentUTM.familyMore }),
                        FamilyAnalyticsPageActions.clickButtonMore,
                        false
                    ),
                },
                {
                    id: FamilySettingDropdownListItemIDs.leave,
                    text: 'Покинуть подписку',
                    icon: <Icon20DoorArrowRightOutline />,
                    iconMobile: <Icon24DoorArrowRightOutline />,
                    onClick: onLeave,
                },
            ];
        }
    }

    return [
        {
            id: FamilySettingDropdownListItemIDs.deleteMember,
            text: 'Удалить из подписки',
            icon: <Icon20UserSlashOutline />,
            iconMobile: <Icon20UserSlashOutline />,
            onClick: onDelete,
        },
    ];
};
