import format from 'date-fns/format';
import React, { ReactNode } from 'react';
import { IProduct } from 'reactApp/modules/products/products.types';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import { defaultDateFormat } from 'reactApp/utils/formatDate';
import { getFirstSomePeriodsAsWord, getPeriodName, getTrialOrDiscountDateEnd, parsePeriod } from 'reactApp/utils/Period';
import { formatPrice } from 'reactApp/utils/priceHelpers';

import { Options } from '../VkBuyModalWithSidebar.types';

interface TrialSidebarContent {
    header: ReactNode;
    description: string;
    features: string[];
    footer: ReactNode;
}

const features = {
    ad: 'Без рекламы в Почте и Облаке',
    adGenetive: 'Почту и Облако без рекламы',
    upload: 'Загрузку файлов до 100 ГБ',
    uploadCloud: 'Загрузка файлов до 100 ГБ в Облако',
    autodelete: 'Автоудаление папок',
    supportGenetive: 'Приоритетную поддержку',
    support: 'Приоритетную поддержка',
    attaches: 'Отправка файлов до 100 ГБ в письмах',
    send: 'Отправку файлов до 100 ГБ',
    share: 'Добавление ещё 3 участников',
};

export const useContent = (product: IProduct, opts?: Options): TrialSidebarContent => {
    const { sidebarNewContent } = opts ?? {};
    const { space, trialPeriod, discountPeriod, period, discountPrice, price, hasTrial, hasDiscount } = product ?? {};
    const freePeriod = hasTrial ? trialPeriod : discountPeriod;
    const priceText = hasTrial ? 'бесплатно' : `за ${formatPrice(discountPrice)} ₽`;

    if (sidebarNewContent) {
        const trialString = hasTrial ? `Для привязки и проверки карты мы спишем и сразу же вернем 1 ₽. ` : null;

        const discountMatch = parsePeriod(discountPeriod ?? '');
        const discountString =
            hasDiscount && discountMatch
                ? `${hasTrial ? `Через ${getPeriodName(trialPeriod ?? '')} с ` : 'С'} карты спишется ${formatPrice(
                      discountPrice
                  )} ₽ за ${getFirstSomePeriodsAsWord(Number(discountMatch[1]), discountMatch[2])} подписки`
                : null;

        const priceStringPrefix = discountString ? ', каждый следующий ' : `Каждый следущий ${getPeriodName(period)} `;
        const priceString = `будет стоить ${formatPrice(price)} ₽. `;

        return {
            header: (
                <>
                    Получить {space?.value}
                    <br />
                    на {getPeriodName(trialPeriod || discountPeriod || period)} {priceText}
                </>
            ),
            description: `Какие преимущества вы получите:`,
            features: [features.adGenetive, features.upload, features.send, features.share, features.supportGenetive],
            footer: (
                <>
                    {trialString}
                    {discountString}
                    {priceStringPrefix}
                    {priceString}
                    {
                        'Отменить автоплатёж можно в любое время в настройках подписки. \
                    C привязанной карты будут списываться платежи за все активные подписки, которые вы покупаете на сайте. \
                    Оформляя подписку, вы принимаете '
                    }
                    <ButtonLink size="medium" primary inline href="/LA/" target="_blank" fontInherit underline={false}>
                        {'условия использования'}
                    </ButtonLink>
                    {' и даёте нам право регулярно списывать платежи.'}
                </>
            ),
        };
    }

    // если настоящий триал с бесплатным периодом
    if (hasTrial) {
        return {
            header: `${space?.value} в Облаке в подарок`,
            description: `Получите ${space?.value} в Облаке на ${getPeriodName(freePeriod || '', true)} бесплатно`,
            features: [features.ad, features.uploadCloud, features.attaches, features.autodelete, features.support],
            footer: `
                Для проверки карты мы спишем и сразу же вернем 1 руб. Стоимость тарифа ${formatPrice(price)} руб. начиная с \
                ${Boolean(freePeriod) && format(getTrialOrDiscountDateEnd(freePeriod), defaultDateFormat)}. Подписка продлевается
                автоматически, её можно отменить в любой момент.
            `,
        };
    }

    // фейк триал с первым периодом за 1 рубль
    return {
        header: (
            <>
                Получите <span>{space?.value}</span> <br />
                {priceText} на {getPeriodName(freePeriod || period)}
            </>
        ),
        description: 'За один рубль вы получите:',
        features: [`${space?.value} дополнительного места`, features.ad, features.upload, features.autodelete, features.supportGenetive],
        footer: `Стоимость тарифа ${formatPrice(price)} ₽, начиная с
                ${
                    Boolean(freePeriod) && format(getTrialOrDiscountDateEnd(freePeriod), defaultDateFormat)
                }. Подписка продлевается автоматически, её
                можно отменить в любой момент.`,
    };
};
