import { BReactComponent } from 'blocks-cloud/b-react-component';
import { BuyModal } from 'reactApp/components/BuyModal/BuyModal';
import { IOwnProps } from 'reactApp/components/BuyModal/BuyModal.types';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

let buyDialog: BReactComponent;
let el;

export const openBuyModal = ({ onClose, ...data }: IOwnProps) =>
    openPopupHelper({
        popupName: popupNames.BUY_MODAL,
        data,
        onClose,
    });

export const renderBuyModal = ({
    id,
    onSuccess,
    onClose,
    source = '',
    paySource,
}: {
    id?: number | string;
    onSuccess();
    onClose();
    source?: string;
    paySource?: string;
}): void => {
    const destroyBuyModal = (): void => {
        if (!buyDialog) {
            return;
        }
        buyDialog.destroy();
        buyDialog = null;
    };

    const handleClose = (): void => {
        if (onClose) {
            onClose();
        }
        destroyBuyModal();
    };

    const handleSuccess = (): void => {
        if (onSuccess) {
            onSuccess();
        }
    };

    if (!buyDialog) {
        buyDialog = new BReactComponent({
            ReactComponent: BuyModal,
        });
    } else {
        destroyBuyModal();
    }

    if (!el) {
        el = document.createElement('div');
    }

    document.body.appendChild(el);

    buyDialog.renderTo(el, {
        props: {
            id,
            onSuccess: handleSuccess,
            onClose: handleClose,
            source,
            paySource,
        },
    });
};
