import { getMonthes } from 'reactApp/utils/Period';

export function formatPrice(value?: number, locale?: string, ceil?: boolean): string {
    if (!value && value !== 0) {
        return '';
    }

    const price = Number(ceil ? Math.ceil(value) : value).toFixed(0);

    return Number(price).toLocaleString(locale);
}

export function getMonthProductPrice(product, useDiscount = true) {
    const period = (useDiscount && product.discountPeriod) || product.period;
    const months = getMonthes(period);
    const price = (useDiscount && product.discountPrice) || product.price;

    return price / months;
}
