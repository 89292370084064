import classNames from 'clsx';
import React, { FC, useCallback } from 'react';
import { useDisclosure } from 'reactApp/hooks/useDisclosure';
import { EmptyComponentProps } from 'reactApp/ui/Empty/new/Empty.types';
import { sendEmptyStatesAnalitics } from 'reactApp/utils/emptyStatesGa';
import { createGaSender } from 'reactApp/utils/ga';

import { DetailsDialog } from '../DetailsDialog/DetailsDialog';
import { useCreateAutoDeleteFolderHandler } from '../hooks';
import styles from './Description.css';

interface DescriptionProps extends EmptyComponentProps {
    className?: string;
}

const sendGa = createGaSender('autodelete-empty');

export const Description: FC<DescriptionProps> = ({ className }) => {
    const detailsDialogDisclosure = useDisclosure();

    const { createFolder } = useCreateAutoDeleteFolderHandler({ sendGa });

    const handleClickDetails = useCallback(() => {
        sendEmptyStatesAnalitics({ action: 'autodelete-more' });

        detailsDialogDisclosure.onOpen();
    }, [detailsDialogDisclosure]);

    const handleCreateFolder = useCallback(() => {
        sendEmptyStatesAnalitics({ action: 'autodelete-create' });

        createFolder();
    }, [createFolder]);

    return (
        <div className={classNames(className)}>
            Установите таймер на удаление, чтобы освободить место от ненужных файлов.{' '}
            <a data-qa-id="empty-link" className={styles.link} onClick={handleClickDetails}>
                Подробнее
            </a>
            {detailsDialogDisclosure.isOpen && (
                <DetailsDialog onClose={detailsDialogDisclosure.onClose} onFolderCreate={handleCreateFolder} />
            )}
        </div>
    );
};
