import { IS_IOS_MOBILE, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';

interface Purchase {
    tariffId: string;
    price: string;
    hasDiscount?: string;
    hasTrial: string;
    period: string;
    space: string;
    eventCategory: string;
}

export const sendGaPurchase = ({ tariffId, price, space, period, hasTrial, hasDiscount, eventCategory }: Purchase) => {
    if (!window.dataLayer) {
        return;
    }
    try {
        window.gtag('event', 'purchase', {
            transaction_id: `${tariffId}_${new Date().valueOf().toString()}`,
            value: price,
            event_category: eventCategory,
            items: [
                {
                    item_id: tariffId,
                    index: 0,
                    item_space: space,
                    item_period: period,
                    price,
                    hasDiscount,
                    hasTrial,
                    quantity: 1,
                    eventCategory,
                },
            ],
        });
    } catch (_) {}
};

export const getPlatformInfo = () => {
    const isMobile = EnvironmentSelectors.isMobile();
    const platformParams: string[] = [];

    if (!isMobile) {
        platformParams.push('web');
    } else if (IS_IOS_MOBILE) {
        platformParams.push('ios');
    } else {
        platformParams.push('and');
    }

    if (IS_WEBVIEW) {
        platformParams.push('wv');
    }

    return platformParams;
};
