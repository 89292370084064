// tempexp_16800-start
export enum BenefitKeyType {
    Support = 'support',
    Mobile = 'mobile',
    Safety = 'safety',
    Calendar = 'calendar',
    Folders = 'folders',
    Upload = 'upload',
    Family = 'family',
    Ad = 'ad',
}
export interface BenefitInfoType {
    key: BenefitKeyType;
    fullText: string;
    shortText: string;
}

export type BenefitsInfoType = Record<BenefitKeyType, BenefitInfoType>;

export const benefitsInfo: BenefitsInfoType = {
    [BenefitKeyType.Support]: {
        key: BenefitKeyType.Support,
        fullText: 'Приоритетная поддержка в течение часа с 9:00 до 21:00',
        shortText: 'Приоритетная поддержка',
    },
    [BenefitKeyType.Mobile]: {
        key: BenefitKeyType.Mobile,
        fullText: 'Автосохранение файлов с телефона в Облако',
        shortText: 'Автосохранение файлов с телефона',
    },
    [BenefitKeyType.Safety]: {
        key: BenefitKeyType.Safety,
        fullText: 'Проверка вложений антивирусом',
        shortText: 'Проверка вложений антивирусом',
    },
    [BenefitKeyType.Calendar]: {
        key: BenefitKeyType.Calendar,
        shortText: 'Полезные виджеты всегда на виду',
        fullText: 'Полезные виджеты Календаря и Задач всегда на виду',
    },
    [BenefitKeyType.Folders]: {
        key: BenefitKeyType.Folders,
        fullText: 'Отдельные папки для рассылок и чеков',
        shortText: 'Отдельные папки для рассылок',
    },
    [BenefitKeyType.Upload]: {
        key: BenefitKeyType.Upload,
        fullText: 'Загрузка больших файлов до 100 ГБ',
        shortText: 'Загрузка файлов до 100 ГБ',
    },
    [BenefitKeyType.Family]: {
        fullText: 'Можно поделиться местом с 3 близкими',
        shortText: 'Добавление ещё 3 участников',
        key: BenefitKeyType.Family,
    },
    [BenefitKeyType.Ad]: {
        shortText: 'Без рекламы в Почте и Облаке',
        fullText: 'Без рекламы в Почте и Облаке',
        key: BenefitKeyType.Ad,
    },
};

// tempexp_16800-end
