import classNames from 'clsx';
import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { loadAlbumRequest } from 'reactApp/modules/albums/albums.actions';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './AlbumPage.css';
import { useAlbumId } from './hooks/useAlbumId';

export const AlbumPage = memo(function AlbumPage() {
    const dispatch = useDispatch();
    const { albumId, initialItemId } = useAlbumId();

    useEffect(() => {
        dispatch(routeChangeSuccess({ id: albumId, storage: EStorageType.albums, params: {}, __isFolder: true, url: '', __parent: '' }));
        dispatch(loadAlbumRequest({ albumId }));
    }, [albumId]);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'album' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'album' });
    }, [albumId]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <div className={classNames({ [styles.headerContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                <BreadcrumbsContainer />
            </div>
            <div className={classNames({ [styles.datalistContainer_new_portal_header]: NEW_PORTAL_HEADER })}>
                <DataList goTo={noop} initialItemId={initialItemId} />
            </div>
        </div>
    );
});
