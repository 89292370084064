import { Button, SizeType, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, ReactElement, RefObject, useCallback, useEffect, useState } from 'react';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './CopyWeblinkTooltip.css';

export interface CopyWeblinkTooltipProps {
    target?: RefObject<HTMLElement>;
    onClose: () => void;
}

const enum TooltipSteps {
    First = 'First',
    Second = 'Second',
}

const tooltipTexts = {
    [TooltipSteps.First]: {
        title: 'Ссылка может быть красивой',
        description: (
            <>
                С подпиской Mail Space придумайте
                <br /> свой уникальный адрес ссылки
            </>
        ),
        buttonText: 'Попробовать за 1 ₽',
    },
    [TooltipSteps.Second]: {
        title: 'Спасибо за интерес!',
        description: (
            <>
                Мы ещё работаем над функцией — когда всё
                <br /> будет готово, сразу же сообщим о запуске
            </>
        ),
        buttonText: 'Буду ждать',
    },
} as const;

export const CopyWeblinkTooltip = memo(({ target, onClose }: CopyWeblinkTooltipProps): ReactElement | null => {
    const [step, setStep] = useState<TooltipSteps>(TooltipSteps.First);
    const isFirstStep = step === TooltipSteps.First;

    const handleClose = useCallback(
        (e) => {
            e?.preventDefault();
            e?.stopPropagation();
            emitAnalyticEvent(AnalyticEventNames.NICE_LINK_TOOLTIP_CLOSE);
            onClose();
        },
        [onClose]
    );

    const handleClickButton = useCallback(
        (e) => {
            e?.stopPropagation();
            if (isFirstStep) {
                setStep(TooltipSteps.Second);
                emitAnalyticEvent(AnalyticEventNames.NICE_LINK_TOOLTIP_CLICK);
            } else {
                onClose?.();
                emitAnalyticEvent(AnalyticEventNames.NICE_LINK_THANKS_TOOLTIP_CLICK);
            }
        },
        [isFirstStep, onClose]
    );

    useEffect(() => {
        if (isFirstStep) {
            emitAnalyticEvent(AnalyticEventNames.NICE_LINK_TOOLTIP_SHOW);
        } else {
            emitAnalyticEvent(AnalyticEventNames.NICE_LINK_THANKS_TOOLTIP_SHOW);
        }
    }, [isFirstStep]);

    if (!target?.current) {
        return null;
    }

    return (
        <FloatingTooltip
            target={target}
            placement={ETooltipPlacement.right}
            onClose={handleClose}
            withinModal
            overAll
            qaId="tooltip-sharing-link-fakedoor-tooltip"
        >
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.withBackgroundImage]: isFirstStep,
                })}
            >
                <div className={styles.textBlock}>
                    <Title level="3">{tooltipTexts[step].title}</Title>
                    <div className={styles.description}>{tooltipTexts[step].description}</div>
                </div>
                <Button
                    size="l"
                    appearance="neutral"
                    sizeY={SizeType.COMPACT}
                    mode="secondary"
                    className={classNames(styles.button, {
                        [styles.compactButton]: !isFirstStep,
                    })}
                    onClick={handleClickButton}
                >
                    {tooltipTexts[step].buttonText}
                </Button>
            </div>
        </FloatingTooltip>
    );
});

CopyWeblinkTooltip.displayName = 'CopyWeblinkTooltip';
