import { BReactComponent } from 'blocks-cloud/b-react-component';
import config from 'Cloud/config';
import {
    IS_AUTO_TEST_HIDE,
    IS_REACT_PAGE,
    IS_REACT_PROMO_QUOTA_ANDROID_PAGE,
    IS_REACT_PROMO_QUOTA_IOS_PAGE,
    IS_REACT_PROMO_QUOTA_PAGE,
    IS_REACT_PROMO_QUOTA_PARTNER_PAGE,
    IS_REACT_PROMO_QUOTA_TARIFFS_PAGE,
} from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { TopBanner } from 'reactApp/ui/TopBanner/TopBanner';
import { ITopBannerProps } from 'reactApp/ui/TopBanner/TopBanner.types';
import { sendGa } from 'reactApp/utils/ga';
import { sendConfigPixel } from 'reactApp/utils/sendPixel';
import { select } from 'typed-redux-saga';

export type TTopBannerConfig = {
    id?: string;
    banner_id: string;
    banner_height?: string;
    html_content?: string;
    close_color: string;
    show_pixel?: string;
    click_pixel?: string;
    close_pixel?: string;
};

const PROMO_TOP_BANNER_CONFIG = config.get('PROMO_TOP_BANNER') as TTopBannerConfig | undefined;

const BANNER_ID = PROMO_TOP_BANNER_CONFIG?.id || PROMO_TOP_BANNER_CONFIG?.banner_id;
const banner = PROMO_TOP_BANNER_CONFIG?.html_content ? BANNER_ID : null;

let hasShow = false;
let hasClick = false;

const sendPromoGa = (action: string): void => {
    sendGa('promo-top-banner', action, BANNER_ID);
};

const onBannerClick = () => {
    if (hasClick) {
        return;
    }
    hasClick = true;
    sendConfigPixel(PROMO_TOP_BANNER_CONFIG, 'click_pixel');
    sendPromoGa('click');
};

const onBannerShow = () => {
    if (hasShow) {
        return;
    }
    hasShow = true;
    sendConfigPixel(PROMO_TOP_BANNER_CONFIG, 'show_pixel');
    sendPromoGa('show');
};

const onBannerClose = () => {
    sendConfigPixel(PROMO_TOP_BANNER_CONFIG, 'close_pixel');
    sendPromoGa('close');
};

let reactBanner: BReactComponent;

export function showPromoBanner(promo) {
    if (!promo || reactBanner) {
        return;
    }

    const root = document.querySelector('#topBanner');

    if (!root) {
        return;
    }

    reactBanner = new BReactComponent({
        ReactComponent: TopBanner,
    });

    const props: ITopBannerProps = {
        closeDarkTheme: promo.closeColor === 'color_dark',
        htmlContent: promo?.html,
        htmlReact: promo?.htmlReact,
        onShow: () => promo.onShow(),
        onClose: () => {
            promo.onClose();
            setTimeout(() => {
                reactBanner?.destroy();
                reactBanner = null;
            }, 100); //  чтобы событие close успело уйти
        },
        onClick: () => {
            promo.onClick();
            setTimeout(() => {
                reactBanner?.destroy();
                reactBanner = null;
            }, 100); //  чтобы событие click успело уйти
        },
    };

    reactBanner.renderTo(root, {
        props,
    });
}

const DISABLE_BANNER_BY_LOCATION = ['/uploader', '/buy', '/choice'];

export function* initTopBanner() {
    if (!banner) {
        return;
    }

    const storage = yield* select(getCurrentStorage);
    const isPhone = EnvironmentSelectors.isPhone();

    if (
        isPhone ||
        IS_AUTO_TEST_HIDE ||
        (!IS_REACT_PAGE && !(storage === EStorageType.attaches || storage === EStorageType.home || storage === EStorageType.public)) ||
        IS_REACT_PROMO_QUOTA_PAGE ||
        IS_REACT_PROMO_QUOTA_IOS_PAGE ||
        IS_REACT_PROMO_QUOTA_ANDROID_PAGE ||
        IS_REACT_PROMO_QUOTA_PARTNER_PAGE ||
        IS_REACT_PROMO_QUOTA_TARIFFS_PAGE ||
        DISABLE_BANNER_BY_LOCATION.includes(window.location.pathname)
    ) {
        return;
    }

    const isB2B = yield* select(UserSelectors.isB2BUser);
    const isOverQuota = yield* select(UserSelectors.isOverQuotaUser);

    if (isOverQuota || isB2B || !PROMO_TOP_BANNER_CONFIG) {
        return;
    }

    showPromoBanner({
        onShow: onBannerShow,
        onClose: onBannerClose,
        onClick: onBannerClick,
        html: PROMO_TOP_BANNER_CONFIG.html_content,
        closeColor: PROMO_TOP_BANNER_CONFIG.close_color,
        height: PROMO_TOP_BANNER_CONFIG.banner_height ? Number(PROMO_TOP_BANNER_CONFIG.banner_height) : undefined,
        promoId: BANNER_ID || '',
    });
}
