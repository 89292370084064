import { debounce } from '@vkontakte/vkjs';
import { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import classNames from 'clsx';
import { ReactComponent as CoEditIcon } from 'img/icons/coedit.svg';
import { xray } from 'lib/xray';
import { ReactComponent as CloudIcon } from 'mrg-icons/src/mailru/logotypes/cloud.svg';
import React, { createRef, PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { downloadItem } from 'reactApp/appHelpers/appDownload';
import { getCurrentState, getIsCloudAvailabe, goTo, updateItemFavoriteFlag } from 'reactApp/appHelpers/appHelpers';
import { ENABLE_FULL_RESPONSIVE, IS_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { publishHelper } from 'reactApp/appHelpers/publishHelper';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { RenamedFileMeta, setRenamedFileIdInsideViewer } from 'reactApp/appHelpers/updateRenamedFileInsideViewer';
import { renderSharingNew } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { BREAKPOINT_MD } from 'reactApp/constants/breakpoints';
import { getEditorItem, isEnableRenamingInsideEditor } from 'reactApp/modules/editor/editor.selectors';
import { EditorItem } from 'reactApp/modules/editor/editor.types';
import { getFeatureMyOfficeEditorPromo, getFeatureWopi } from 'reactApp/modules/features/features.selectors';
import { addToFavorites, removeFromFavorites } from 'reactApp/modules/modifying/modifying.actions';
import { renameRequest } from 'reactApp/modules/modifying/sagas/rename.saga';
import { goToRegisterPage } from 'reactApp/modules/ph/ph.helpers';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { renderSmallWindowWarningDialog } from 'reactApp/ui//SmallWindowWarningDialog/smallWindowWarningDialog.helpers';
import { EditorHeaderMapState, EditorHeaderProps } from 'reactApp/ui/EditorHeader/EditorHeaderContainer';
import { getBackLink } from 'reactApp/ui/EditorHeader/helpers/EditorHeader.helpers';
import { EColor, FavoriteIcon } from 'reactApp/ui/FavoriteIcon/FavoriteIcon';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { DownloadIcon, LinkIcon, ShareIcon, UploadIcon } from 'reactApp/ui/VKUIIcons';
import { createGaSender, sendGa } from 'reactApp/utils/ga';
import { noop, noopPromise } from 'reactApp/utils/helpers';
import { isEditingAttach } from 'reactApp/utils/isEditingFile';
import { sendEditRadar } from 'reactApp/utils/sendEditRadar';

import styles from './EditorHeader.css';
import { HeaderButton } from './HeaderButton';
import { HeaderFileName } from './HeaderFileName';
import { ReactComponent as ArrowIcon } from './icons/arrowBack.svg';
import { EditorHeaderButtonPromoContainer as MyOfficePromoHeaderButton } from './MyOfficePromo/EditorHeaderButton/EditorHeaderButtonPromoContainer';
import { ShareButtonCoEditPopup } from './ShareButtonCoEditPopup';

export const updateWeblinkInUrlAction = (id, weblink): void => {
    const state = getCurrentState();

    if (!state) {
        return;
    }

    const { ...params } = state.params;

    if (weblink) {
        params.weblink = weblink;
    } else {
        delete params.weblink;
    }

    goTo({ id, params });
};

interface State {
    showBubble: boolean;
    isDownloadOpen: boolean;
    windowWidth?: number;
}

const mapStateToProps = (state, props): EditorHeaderMapState => {
    const { itemSelector = getEditorItem } = props;
    const currentStorage = getCurrentStorage(state);

    const isAnonymous = UserSelectors.isAnonymous(state);
    const myOfficePromoEditorEnabled = !!getFeatureMyOfficeEditorPromo(state);
    const file = itemSelector(state);
    const isWopiFeatureEnabled = getFeatureWopi(state);

    return {
        backLink: getBackLink(file),
        updateWeblinkInUrl: updateWeblinkInUrlAction,
        renameItem: renameRequest,
        isAnonymous,
        enableRenaming: isEnableRenamingInsideEditor(state),
        file,
        storage: currentStorage,
        id: file?.id,
        isWopiFeatureEnabled,
        myOfficePromoEditorEnabled,
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadUser: (): void => dispatch(loadUser()),
    showNotify: (options): void => dispatch(showSnackbarAction(options)),
    toggleFavorite: (item: EditorItem): void =>
        dispatch(item.isInFavorites ? removeFromFavorites({ items: [item] }) : addToFavorites({ items: [item], from: 'editor' })),
    setRenamedFileIdInsideViewer: (init: RenamedFileMeta) => dispatch(setRenamedFileIdInsideViewer(init)),
});

export class EditorHeaderComponent extends PureComponent<EditorHeaderProps, State> {
    static defaultProps = {
        showBubble: false,
        onBubbleShow: noop,
        toggleFavorite: noop,
        updateWeblinkInUrl: noop,
        renameItem: noop,
        loadUser: noop,
        showNotify: noop,
        isPublic: false,
        isStock: false,
        enableFavorites: false,
        isAnonymous: false,
        backLink: '',
        file: null,
        id: '',
        onBackToCloud: null,
        gaCategory: '',
        returnButtonTitle: 'Вернуться в Облако',
        editorId: undefined,
    };

    public readonly state: State = {
        showBubble: false,
        isDownloadOpen: false,
        windowWidth: undefined,
    };

    buttonTextRef = createRef<HTMLDivElement>();

    gaSender = createGaSender(`${this.props.gaCategory}-${this.props.isPublic ? 'public' : 'home'}`);

    componentDidMount(): void {
        const { file, onBubbleShow, showBubble, loadUser, isPublic, updateWeblinkInUrl } = this.props;
        this.gaSender('show');

        xray.send('editor-header-rendered', { i: 'blue' });

        if (isPublic) {
            sendGa('public-edit', 'file-type', 'document');
            const ext = file?.ext;
            if (ext) {
                sendGa('public-edit', 'file-extention', ext);
            }
        } else {
            updateWeblinkInUrl(file?.id, file?.weblink);
        }

        if (this.buttonTextRef.current && showBubble) {
            onBubbleShow();
            this.setState({
                showBubble: true,
            });
        }

        loadUser();

        if (IS_BIZ_USER && ENABLE_FULL_RESPONSIVE) {
            this.updateDimensions();
            window.addEventListener('resize', debounce(this.updateDimensions, 1500));
            this.renderWarningDialog();
        }
    }

    componentDidUpdate(prevProps: EditorHeaderProps): void {
        const { editorId } = this.props;
        const { windowWidth } = this.state;
        const baseWarningDialogManageCondition =
            IS_BIZ_USER && ENABLE_FULL_RESPONSIVE && editorId && editorId === 'myoffice' && windowWidth;

        if (prevProps.file?.id !== this.props.file?.id || prevProps.file?.weblink !== this.props.file?.weblink) {
            this.props.updateWeblinkInUrl(this.props.file?.id, this.props.file?.weblink);
        }

        /*
            на экране < 1024px интерфейс моего офиса не адаптируется корректно,
            поэтому в респонсив версии показываем попап с предупреждением
        */
        if (baseWarningDialogManageCondition && windowWidth < BREAKPOINT_MD) {
            this.renderWarningDialog();
        }

        //  на экранах > 1024 скрываем попап с предупреждением
        if (baseWarningDialogManageCondition && windowWidth >= BREAKPOINT_MD) {
            this.closeWarningDialog();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    handlePublish = (e?: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>): void => {
        const { file } = this.props;

        e?.stopPropagation();

        this.gaSender('publish', 'click', { type: 'button' });

        this.setState({
            showBubble: false,
        });

        publishHelper({ item: file, gaSuffix: '_header' });
    };

    renderWarningDialog = () => {
        renderSmallWindowWarningDialog();
    };

    closeWarningDialog = () => {
        closePopupHelper(popupNames.SMALL_WINDOW_WARNING_DIALOG);
    };

    updateDimensions = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    handleShareDocument = () => {
        const { id = '' } = this.props;
        this.gaSender('share', 'click');

        renderSharingNew({ id, from: EFrom.SHARING });
    };

    handleToggleFavorite = (): void => {
        const { toggleFavorite, file } = this.props;
        this.gaSender('favorite', 'click');
        const isInFavoritesPrev = !!file?.isInFavorites;

        if (!file) {
            return;
        }

        toggleFavorite(file);

        this.gaSender('favorite', 'success');

        this.sendEditorRadar('favorite');

        updateItemFavoriteFlag(file?.id, !isInFavoritesPrev);
    };

    handleFileNameClick = (): void => this.gaSender('rename', 'click');

    // Отличается от gaSender, т.к. отправляют другие продуктовые метрики
    sendEditorRadar = (i: string) => {
        const { storage, file, isWopiFeatureEnabled, backLink } = this.props;
        if (file && storage) {
            sendEditRadar({
                i,
                storage,
                item: file,
                version: isWopiFeatureEnabled ? MYOFFICE_VARIANTS.wopi : MYOFFICE_VARIANTS.amr,
                isAttach: isEditingAttach(storage),
                back: i === 'back' ? backLink : undefined,
            });
        }
    };

    handleFileNameEdit = ({ value }): Promise<any> => {
        const { showNotify, file, renameItem, setRenamedFileIdInsideViewer } = this.props;

        const trimedValue = value.trim();

        if (trimedValue === file?.nameWithoutExt) {
            return Promise.resolve();
        }

        if (!trimedValue) {
            showNotify({
                type: SnackbarTypes.failure,
                text: 'Название не может быть пустым',
                id: 'rename',
                closable: true,
            });
            return Promise.reject();
        }

        const newName = `${trimedValue}.${file?.ext}`;

        return renameItem(file, newName)
            .then(() => {
                this.gaSender('rename', 'success');

                this.sendEditorRadar('rename');
                /**
                 * При переименовании файла из редактора МойОфис необходимо обновить вкладку просмотрщика.
                 * Отслеживание изменений происходит в localstorage.
                 */
                if (file?.home && file?.name) {
                    setRenamedFileIdInsideViewer({ newId: file.home.split(file.name).join(newName), outdateId: file.home });
                }

                goTo({ id: this.props.file?.id });
                showNotify({
                    id: 'document-rename-success',
                    type: SnackbarTypes.success,
                    text: 'Файл успешно переименован',
                    closable: true,
                });
            })
            .catch((error) => {
                this.gaSender('rename', 'error');
                showNotify({
                    type: SnackbarTypes.failure,
                    text: `Не удалось переименовать файл: ${error}`,
                    id: 'document-rename-error',
                    closable: true,
                    bigSnackbar: true,
                });
                return Promise.reject();
            });
    };

    handleDownload = (): void => {
        const { file } = this.props;

        this.gaSender('download', 'click');

        this.sendEditorRadar('download-content');

        downloadItem({ itemOrId: file });
    };

    handleDownloadDropdown = () => {
        this.setState(({ isDownloadOpen }) => ({
            isDownloadOpen: !isDownloadOpen,
        }));
    };

    handleSaveToCloud = (): void => {
        const fileId = this.props.file?.id;

        this.gaSender('savetocloud', 'click');

        if (!fileId) {
            return;
        }

        this.sendEditorRadar('save-in-cloud');

        toolbarActions.clone({ id: fileId, source: 'editor' });
    };

    closeBubble = (): void => {
        this.setState({
            showBubble: false,
        });
    };

    private renderRightGroup = (): ReactElement | null => {
        const { file, isPublic, isStock, downloadDropdownList, isWopiFeatureEnabled, isViewer } = this.props;
        const { showBubble, isDownloadOpen } = this.state;
        if (isStock) {
            return null;
        }

        const shareButton = (
            <div onClick={this.handlePublish}>
                <HeaderButton
                    title={file?.weblink ? 'Настройки доступа' : 'Открыть доступ'}
                    icon={<ShareIcon />}
                    onClick={this.handlePublish}
                    size={181}
                    textRef={this.buttonTextRef}
                />
            </div>
        );

        return (
            <>
                <HeaderButton
                    title="Скачать"
                    icon={<DownloadIcon />}
                    onClick={this.handleDownload}
                    dropdownList={downloadDropdownList}
                    onDropdownClick={this.handleDownloadDropdown}
                    isDropdownOpen={isDownloadOpen}
                    isWopiFeatureEnabled={isWopiFeatureEnabled}
                />
                {isPublic ? (
                    <HeaderButton
                        title="Поделиться ссылкой"
                        icon={<LinkIcon />}
                        onClick={this.handleShareDocument}
                        textRef={this.buttonTextRef}
                    />
                ) : (
                    <>
                        {showBubble && (
                            <FloatingTooltip
                                target={this.buttonTextRef}
                                placement={ETooltipPlacement.bottomEnd}
                                onClose={this.closeBubble}
                                hideArrow
                                qaId="tooltip-co-edit"
                            >
                                <div className={styles.bubble}>
                                    <div className={styles.bubbleIcon}>
                                        <CoEditIcon width={145} height={100} />
                                    </div>
                                    <div>
                                        <div className={styles.bubbleTitle}>Совместное редактирование документов</div>
                                        <div className={styles.bubbleText}>
                                            Работайте совместно. Вы можете открывать доступ к вашим документам по секретной ссылке.
                                        </div>
                                    </div>
                                </div>
                            </FloatingTooltip>
                        )}
                        {!isPublic && !isViewer && !isStock ? <ShareButtonCoEditPopup>{shareButton}</ShareButtonCoEditPopup> : shareButton}
                    </>
                )}
                <MyOfficePromoHeaderButton fileExt={file?.ext} />
            </>
        );
    };

    render(): ReactElement {
        const {
            enableFavorites,
            backLink,
            file,
            isAnonymous,
            isPublic,
            onBackToCloud,
            returnButtonTitle = 'Вернуться в Облако',
            isViewer,
            licenseExpired = false,
            enableRenaming,
            myOfficePromoEditorEnabled = false,
        } = this.props;

        // Скрываем кнопку, т.к. у анонминого пользователя нет возможности завести облако в on-premise
        const isCloudAvailabe = IS_ONPREMISE ? !isAnonymous && getIsCloudAvailabe() : getIsCloudAvailabe();
        const showCloneToCloud = !isViewer && !isAnonymous && isPublic && !file?.home && isCloudAvailabe;
        // Скрываем кнопку в on-premise, т.к. пользователь не может сам завести себе облако
        const showCreateCloudButton = isAnonymous && !IS_ONPREMISE;

        return (
            <>
                <div className={styles.root}>
                    <div className={styles.leftGroup}>
                        {showCreateCloudButton ? (
                            <HeaderButton title="Завести Облако" onClick={goToRegisterPage} icon={<CloudIcon width={20} height={20} />} />
                        ) : (
                            isCloudAvailabe && (
                                <HeaderButton
                                    title={returnButtonTitle}
                                    link={onBackToCloud ? null : backLink}
                                    onClick={onBackToCloud || noop}
                                    icon={<ArrowIcon width={9} height={14} viewBox="0 0 5 8" />}
                                    sendEditorRadar={this.sendEditorRadar}
                                />
                            )
                        )}
                        {showCloneToCloud && (
                            <HeaderButton title="Сохранить в Облако" icon={<UploadIcon />} onClick={this.handleSaveToCloud} />
                        )}
                    </div>
                    <div className={styles.middleGroup}>
                        <HeaderFileName
                            fileName={file?.nameWithoutExt || ''}
                            readOnly={!enableRenaming || isPublic || !!file?.readOnly}
                            onEdit={enableRenaming ? this.handleFileNameEdit : noopPromise}
                            onClick={enableRenaming ? this.handleFileNameClick : noop}
                            title={file?.isInFavorites ? 'Убрать из избранных' : 'Добавить в избранные'}
                            icon={
                                enableFavorites ? (
                                    <FavoriteIcon
                                        onClick={this.handleToggleFavorite}
                                        className={styles.favoritesIcon}
                                        active={file?.isInFavorites}
                                        color={EColor.WHITE}
                                    />
                                ) : null
                            }
                        />
                    </div>
                    <div
                        className={classNames(styles.rightGroup, {
                            [styles.myOfficePromoEditorEnabled]: myOfficePromoEditorEnabled,
                        })}
                    >
                        {this.renderRightGroup()}
                    </div>
                </div>
                {licenseExpired && (
                    <div className={styles.license}>
                        <div>Количество лицензий закончилось</div>
                    </div>
                )}
            </>
        );
    }
}

export const EditorHeader = connect(mapStateToProps, mapDispatchToProps)(EditorHeaderComponent);
