import { Icon20Chain, Icon20UsersOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isDomainFolder, isReadOnly } from 'reactApp/appHelpers/appHelpers';
import { ENABLE_FULL_RESPONSIVE, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { useRenameInlineDoubleClick } from 'reactApp/hooks/useRenameInlineDoubleClick';
import { EInlineEditPlace } from 'reactApp/modules/modifying/modifying.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import styles from 'reactApp/ui/Breadcrumbs/Breadcrumb.css';
import { useDragAndDrop } from 'reactApp/ui/Breadcrumbs/Breadcrumbs.hooks';
import { BreadcrumbItem } from 'reactApp/ui/Breadcrumbs/Breadcrumbs.types';
import { ContentEditable } from 'reactApp/ui/ContentEditable/ContentEditable';
import { DataListControl, EDataListControlVariant } from 'reactApp/ui/DataListControl/DataListControl';
import { DropNotify } from 'reactApp/ui/DropNotify/DropNotify';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { Link } from 'reactApp/ui/Link/Link';
import { sendGa } from 'reactApp/ui/TariffsSection/TariffsSection.helpers';
import { noop } from 'reactApp/utils/helpers';
import { getTrimmedText } from 'reactApp/utils/textHelpers';

export const Breadcrumb = memo(
    ({
        breadcrumb,
        onClick,
        isActive,
        onShareClick,
        onPublishClick,
        isFollowToSharedFolders,
        goToSharedFolders,
        isVKEmbedded,
        isInlineIntegration,
    }: {
        breadcrumb: BreadcrumbItem;
        storage?: EStorageType;
        isActive: boolean;
        onClick: (e: React.MouseEvent, breadcrumb: BreadcrumbItem) => void;
        onShareClick: (e: React.MouseEvent, breadcrumb: BreadcrumbItem) => void;
        onPublishClick: (e: React.MouseEvent, breadcrumb: BreadcrumbItem) => void;
        isFollowToSharedFolders?: boolean;
        goToSharedFolders?: () => void;
        isVKEmbedded?: boolean;
        isInlineIntegration?: boolean;
    }): ReactElement => {
        const breadcrumbRef = useRef<HTMLDivElement | null>(null);
        const cloudItem = useSelector((state: RootState) => getItemById(state, breadcrumb.id));
        const { isHome, isAlbums, isPublic } = getStorage(breadcrumb.storage);
        const readOnly = isReadOnly(cloudItem);
        const isDomain = isDomainFolder(cloudItem);
        const isEditable =
            isActive &&
            (isHome || isAlbums) &&
            !readOnly &&
            !isDomain &&
            breadcrumb.id !== ROOT_FOLDER_ID &&
            breadcrumb.id !== '/albums' &&
            !breadcrumb?.disableEdit;

        const { isEdit, handleDoubleClick, onRename } = useRenameInlineDoubleClick(
            breadcrumb.text,
            breadcrumb.id,
            EInlineEditPlace.breadcrumbs
        );

        const handleGoTo = useCallback(
            (e): void => {
                if (!isActive) {
                    sendGa('breadcrumbs', 'click');
                    onClick(e, breadcrumb);
                }
            },
            [breadcrumb.id, isActive, onClick]
        );

        const handleClick = useCallback((): void => {
            if (isFollowToSharedFolders && !cloudItem?.parent) {
                goToSharedFolders?.();
            }

            if (!isActive) {
                sendGa('breadcrumbs', 'click');
            }
        }, [breadcrumb.id, isActive, sendGa, isFollowToSharedFolders, goToSharedFolders]);

        const handleOnPublishClick = useCallback(
            (e) => {
                onPublishClick(e, breadcrumb);
            },
            [breadcrumb.id, onPublishClick]
        );

        const handleOnShareClick = useCallback(
            (e) => {
                onShareClick(e, breadcrumb);
            },
            [breadcrumb.id, onShareClick]
        );

        const { isDraggingOver, handleMouseMove, handleMouseLeave, handleStopDrag, isAllowedToDrop } = useDragAndDrop(
            breadcrumb.id,
            isActive
        );

        const renderControls = (breadcrumb.showShare || breadcrumb.showWeblink) && isActive;

        let lengthStringBreadcrumbs = 27;
        if (PUBLIC_SHOVE) {
            lengthStringBreadcrumbs = 22;
        } else if (isPublic) {
            lengthStringBreadcrumbs = 33;
        }

        return (
            <Link
                id={breadcrumb.id}
                href={breadcrumb?.href}
                storage={breadcrumb.storage}
                handleGoTo={handleGoTo}
                handleClick={handleClick}
                search={breadcrumb?.search}
                noLink={breadcrumb?.noLink}
            >
                <div
                    className={classNames(styles.root, {
                        [styles.root_active]: isActive,
                        [styles.root_vk_embedded]: isVKEmbedded,
                        [styles.root_inlineIntegration]: isInlineIntegration,
                    })}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    onMouseUp={handleStopDrag}
                    ref={breadcrumbRef}
                >
                    {isEdit ? (
                        <div className={styles.titleEdit}>
                            <ContentEditable value={breadcrumb.text} onEdit={onRename} selectAllOnFocus />
                        </div>
                    ) : (
                        <Hint text={isEditable ? 'Переименовать' : breadcrumb.text}>
                            <div
                                className={classNames(styles.text, { [styles.text_inlineIntegration]: isInlineIntegration })}
                                onDoubleClick={isEditable ? handleDoubleClick : noop}
                            >
                                {ENABLE_FULL_RESPONSIVE ? breadcrumb.text : getTrimmedText(breadcrumb.text, lengthStringBreadcrumbs)}
                            </div>
                        </Hint>
                    )}
                    {isDraggingOver && (
                        <DropNotify dragOverName={breadcrumb.text} isAllowedToDrop={isAllowedToDrop} target={breadcrumbRef} />
                    )}
                    {renderControls && (
                        <div className={styles.controls}>
                            {breadcrumb.showWeblink && (
                                <Hint text="Поделиться">
                                    <div className={styles.control}>
                                        <DataListControl
                                            size={32}
                                            type="publish"
                                            active={!!breadcrumb.weblink}
                                            onClick={handleOnPublishClick}
                                            variant={EDataListControlVariant.noBorder}
                                            icon={<Icon20Chain />}
                                        />
                                    </div>
                                </Hint>
                            )}
                            {breadcrumb.showShare && (
                                <Hint text="Настроить доступ">
                                    <div className={styles.control}>
                                        <DataListControl
                                            size={32}
                                            type="share"
                                            active={breadcrumb.kind === 'shared'}
                                            onClick={handleOnShareClick}
                                            variant={EDataListControlVariant.noBorder}
                                            icon={<Icon20UsersOutline />}
                                        />
                                    </div>
                                </Hint>
                            )}
                        </div>
                    )}
                </div>
            </Link>
        );
    }
);
Breadcrumb.displayName = 'Breadcrumb';
