import classNames from 'clsx';
import React, { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_PORTAL_HEADER } from 'reactApp/appHelpers/configHelpers';
import { searchLoadRequestStart, searchLoadSuccess } from 'reactApp/modules/search/search.module';
import { getSearchContentIndexed, getSearchContentStatus, getSearchLoadingState } from 'reactApp/modules/search/search.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { Toolbar } from 'reactApp/ui/Toolbar/Toolbar';
import { ToolbarContextEnum } from 'reactApp/ui/Toolbar/Toolbar.types';
import { WebSearchToolbar } from 'reactApp/ui/WebSearchToolbar/WebSearchToolbar';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './SearchPage.css';

interface IProps {
    initialItemId?: string;
}

export const SearchPage: VFC<IProps> = ({ initialItemId = '' }) => {
    const dispatch = useDispatch();
    const isContentIndexed = useSelector(getSearchContentIndexed);
    const { webSearchContentEnabled } = useSelector(getSearchContentStatus);
    const { isLoaded } = useSelector(getSearchLoadingState);
    const { query, searchType, searchSource } = useSelector(SettingsSelectors.getQueryParams);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'result-search' });
    }, []);

    useEffect(() => {
        if (typeof isContentIndexed === 'undefined') {
            return;
        }

        if (typeof query !== 'undefined') {
            dispatch(searchLoadRequestStart());
        } else {
            dispatch(searchLoadSuccess());
        }
    }, [query, searchType, searchSource, isContentIndexed, dispatch]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
            })}
        >
            <div
                className={classNames(styles.searchPageHeader, {
                    [styles.searchPageHeader_new_portal_header]: NEW_PORTAL_HEADER,
                })}
            >
                <BreadcrumbsContainer />
                {!NEW_PORTAL_HEADER && (
                    <>
                        {webSearchContentEnabled ? (
                            <Toolbar toolbarType={ToolbarContextEnum.pageContentContext} />
                        ) : (
                            <div className={styles.viewSelection}>
                                <Toolbar toolbarType={ToolbarContextEnum.pageContentContext} />
                            </div>
                        )}
                    </>
                )}
                {webSearchContentEnabled && <WebSearchToolbar className={styles.contentSearchToolbar} />}
            </div>
            {isLoaded ? (
                <DataList
                    goTo={noop}
                    storage={EStorageType.search}
                    initialItemId={initialItemId}
                    selectableClassName={styles.datalistSelectable}
                />
            ) : (
                <Spinner />
            )}
        </div>
    );
};

SearchPage.displayName = 'SearchPage';
