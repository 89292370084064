import { Button, Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { FC, useCallback, useRef } from 'react';
import { Portal } from 'react-portal';
import { useSelector } from 'react-redux';
import { useDisclosure } from 'reactApp/hooks/useDisclosure';
import { getFeatureAbAutoDelete } from 'reactApp/modules/features/features.selectors';
import { isAutoDeleteEnabled } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { PromoProTooltip } from 'reactApp/ui/PromoProTooltip/PromoProTooltip';
import { renderModalDialog } from 'reactApp/utils/createDialogToolkit';

import styles from './DetailsDialog.css';

interface DetailsDialogProps {
    className?: string;
    onClose: () => void;
    onFolderCreate: React.MouseEventHandler<HTMLElement>;
}

export const DetailsDialog: FC<DetailsDialogProps> = ({ className, onClose, onFolderCreate }) => {
    const promoProDisclosure = useDisclosure();
    const autodeleteAb = useSelector(getFeatureAbAutoDelete)?.data;
    const isAutodeleteFeatureEnabled = !!useSelector(isAutoDeleteEnabled);

    const createButtonRef = useRef<HTMLDivElement>(null);

    const handleCreateClick: React.MouseEventHandler<HTMLElement> = useCallback(
        (e) => {
            if (!isAutodeleteFeatureEnabled) {
                promoProDisclosure.onOpen();
            }

            onFolderCreate(e);
        },
        [isAutodeleteFeatureEnabled, onFolderCreate, promoProDisclosure]
    );

    return (
        <Dialog
            open
            header=""
            closeOnDimmerClick
            id="shared-autodelete-details-dlg"
            onCancel={onClose}
            size="large"
            bigCloseIcon
            closable
            className={classNames(styles.root, className)}
        >
            <Content isModal wrapClass={styles.content}>
                <video className={styles.video} autoPlay muted loop>
                    <source type="video/ogg" src="https://cloud-common-static.hb.bizmrg.com/autodelete/v2.ogg" />
                    <source type="video/mp4" src="https://cloud-common-static.hb.bizmrg.com/autodelete/v2.mp4" />
                    <source type="video/webm" src="https://cloud-common-static.hb.bizmrg.com/autodelete/v2.mebm" />
                </video>
                <div className={styles.details}>
                    <Text className={styles.title}>Как работают автоудаляемые папки</Text>
                    <Spacing size={16} />
                    <Text className={styles.description}>
                        Чтобы не тратить место в Облаке на устаревшие файлы, установите таймер с&nbsp;автоматическим удалением и поделитесь
                        ссылкой
                    </Text>
                </div>
                <Spacing size={32} />
                <div className={styles.actions} ref={createButtonRef}>
                    <Button size="l" className={styles.button} onClick={handleCreateClick}>
                        Создать папку
                    </Button>
                </div>
            </Content>
            {promoProDisclosure.isOpen && (
                <Portal>
                    <PromoProTooltip
                        target={createButtonRef}
                        onClose={promoProDisclosure.onClose}
                        gaLabel="autodelete-empty-tooltip"
                        tariffsType={autodeleteAb}
                        bottom
                        overAll
                    />
                </Portal>
            )}
        </Dialog>
    );
};

export const renderDetailsDialog = renderModalDialog(DetailsDialog, 'DetailsDialog');
