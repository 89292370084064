import React, { ReactElement, useCallback, useRef } from 'react';
import { Product } from 'reactApp/types/Billing';
import { TariffCardLoader } from 'reactApp/ui/IframePages/BuyDisableAds/DisableAdsScreen/TariffCardLoader/TariffCardLoader';
import { TariffListCard } from 'reactApp/ui/IframePages/BuyDisableAds/DisableAdsScreen/TariffListCard/TariffListCard';
import { ITariffsByTab } from 'reactApp/ui/TariffSectionNew/TariffsSection.types';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { ArrowLeftIcon, ArrowRightIcon } from 'reactApp/ui/VKUIIcons';
import { Pagination, Swiper as SwiperInstance } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './SwiperTariffList.css';

interface SwiperTariffListProps {
    tariffs: ITariffsByTab[];
    tariffsCount: number;
    isLoading: boolean;
    activeTab: ETabsName;
    isLoaded: boolean;
    onBuyClick: (product: Product) => void;
}

const swiperModules = [Pagination];
const swiperPagination = { clickable: true };

export const SwiperTariffList = ({
    tariffs,
    tariffsCount,
    isLoaded,
    isLoading,
    activeTab,
    onBuyClick,
}: SwiperTariffListProps): ReactElement => {
    const swiperRef = useRef<SwiperInstance>();

    const onBeforeInit = useCallback(
        (swiper) => {
            swiperRef.current = swiper;
        },
        [swiperRef]
    );

    return (
        <div className={styles.root}>
            <div className={styles.swiperBackwardBtn} onClick={() => swiperRef.current?.slidePrev()}>
                <ArrowLeftIcon />
            </div>
            <div className={styles.swiperForwardBtn} onClick={() => swiperRef.current?.slideNext()}>
                <ArrowRightIcon />
            </div>
            <Swiper
                className={styles.swiperContainer}
                slidesPerView="auto"
                centeredSlides
                initialSlide={1}
                loopedSlides={3}
                spaceBetween={12}
                loop
                modules={swiperModules}
                pagination={swiperPagination}
                onBeforeInit={onBeforeInit}
            >
                {isLoaded &&
                    tariffs.map((tariff, index) => (
                        <SwiperSlide key={tariff.tariffId} virtualIndex={index} className={styles.slide}>
                            <TariffListCard activeTab={activeTab} tariff={tariff} onBuyClick={onBuyClick} />
                        </SwiperSlide>
                    ))}
                {isLoading &&
                    Array.from(new Array(tariffsCount)).map((_, index) => (
                        <SwiperSlide key={index} virtualIndex={index} className={styles.slide}>
                            <TariffCardLoader key={index} />
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
};

SwiperTariffList.displayName = 'SwiperTariffList';
