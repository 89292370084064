import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { advExpId, disableButtonModal, tariffBuyCustomFeatures } from 'reactApp/appHelpers/featuresHelpers';
import { getFeatureABDisableAdsSnackbar } from 'reactApp/modules/features/features.selectors';
import { openBuyCheckout } from 'reactApp/modules/payment/payment.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { getAdvDisableLink } from 'reactApp/ui/AdvBanners/AdvBanners.helpers';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { EPromoAction, sendSplashscreenAnalytics } from 'reactApp/utils/splashscreenAlertGa';

import { EButtonAction, sendDisableAdsAnalytics } from './DisableButton.analytics';
import { DisableButtonComponent } from './DisableButtonComponent';

interface IProps {
    text?: string;
    className?: string;
    onClick?: () => void;
    id: string;
    theme?: 'light' | 'dark';
}

// места где показываем кнопку https://jira.vk.team/browse/CLOUDWEB-12562
export const DisableButton = ({ text = 'Отключить рекламу', className = '', onClick = noop, id, theme = 'light' }: IProps) => {
    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    // tempexp_13536-start
    const storage = useSelector(getCurrentStorage);
    const snackbarFeatureName = useSelector(getFeatureABDisableAdsSnackbar);
    const shouldShowButton = snackbarFeatureName && snackbarFeatureName !== 'b';
    const dispatch = useDispatch();

    useEffect(() => {
        if (shouldShowButton) {
            sendSplashscreenAnalytics({
                action: EPromoAction.SHOW,
                name: 'disable-adv-button',
                source: storage,
            });
        }

        sendDisableAdsAnalytics({
            action: EButtonAction.SHOW,
            place: id,
        });

        sendXray(`noads-button-show-${id}`, advExpId ? `${advExpId}` : null);
    }, []);

    let featuresList: string[] = [];

    const abVariant = disableButtonModal;

    if (abVariant && tariffBuyCustomFeatures[abVariant]) {
        featuresList = tariffBuyCustomFeatures[abVariant];
    }

    const handleOnClick = useCallback(
        (e) => {
            if (disableButtonModal) {
                e.preventDefault();
                e.stopPropagation();
                emitAnalyticEvent(AnalyticEventNames.DISABLE_BUTTON_CLICK);
                sendDwh({ eventCategory: 'disable-button-click' });
                dispatch(
                    openBuyCheckout({
                        featuresList,
                        onSuccess: () => {
                            emitAnalyticEvent(AnalyticEventNames.DISABLE_BUTTON_PAYMENT);
                            sendDwh({ eventCategory: 'disable-button-payment' });
                        },
                        onShow: () => {
                            emitAnalyticEvent(AnalyticEventNames.DISABLE_BUTTON_SHOW_CHECKOUT);
                            sendDwh({ eventCategory: 'disable-button-checkout' });
                        },
                        paySource: 'cloud_disable_ads',
                    })
                );
            }
            if (shouldShowButton) {
                sendSplashscreenAnalytics({
                    action: EPromoAction.CLICK,
                    name: 'disable-adv-button',
                    source: storage,
                    name_button: 'disable-adv',
                });
            }

            sendDisableAdsAnalytics({
                action: EButtonAction.CLICK,
                place: id,
            });

            sendXray(`noads-button-click-${id}`, advExpId ? `${advExpId}` : null);

            onClick();
        },
        [dispatch]
    );

    if (snackbarFeatureName === 'b') {
        return null;
    }
    // tempexp_13536-end

    const href = getAdvDisableLink({ isAnonymous, storage });

    return <DisableButtonComponent text={text} href={href} onClick={handleOnClick} className={className} theme={theme} />;
};
