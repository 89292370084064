import { find, keys, map, pathOr, propEq } from 'ramda';
import { contactsSelectors } from 'reactApp/modules/contacts/contacts.selectors';
import { PublicFile, PublicFolder, PublicState } from 'reactApp/modules/public/public.types';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { Kind } from 'reactApp/types/Tree';
import { createSelector } from 'reselect';

const getPublicState = (state: RootState): PublicState => state.public;

export const getPublicItemById = createSelector(
    getPublicState,
    (state, id): string => id,
    (publicState, id) => {
        return publicState.list[id];
    }
);

export const getCurrentPublicFolder = createSelector(
    getCurrentRouteId,
    (state) => state,
    (routeId, state): PublicFolder | undefined => {
        if (!routeId) {
            return;
        }

        const item = getPublicItemById(state, routeId);

        if (!item) {
            return;
        }

        if (item.isFolder) {
            return item;
        }

        return getPublicItemById(state, item.parent) as PublicFolder;
    }
);

export const getCurrentPublicItem = createSelector(
    getCurrentRouteId,
    (state) => state,
    (routeId, state): PublicFolder | PublicFile | undefined => {
        if (!routeId) {
            return;
        }

        return getPublicItemById(state, routeId);
    }
);
export const getPublicRootWeblink = createSelector(getPublicState, (publicState) => publicState.rootWeblink);

export const isPublicUploadEnabled = createSelector(getPublicRootWeblink, (rootWeblink): boolean => rootWeblink.isUploadEnabled);
export const isWeblinkDownloadable = createSelector(getPublicRootWeblink, (rootWeblink): boolean =>
    Boolean(rootWeblink.weblinkDownloadable)
);
export const isPublicDownloadableEnabled = createSelector(
    getPublicRootWeblink,
    (rootWeblink): boolean => !!rootWeblink.weblinkDownloadable
);
export const getPublicExpires = createSelector(getPublicRootWeblink, (rootWeblink): number | undefined => rootWeblink.weblinkExpires);
export const isPublicAutodelete = createSelector(getPublicRootWeblink, (rootWeblink): boolean => Boolean(rootWeblink.isAutodelete));
export const getPublicOwner = createSelector(getPublicState, (publicState) => publicState.owner);

export const isOwnPublic = createSelector(getPublicRootWeblink, (rootWeblink): boolean => rootWeblink.isOwn);

export const getCurrentPublicFolderChilds = createSelector(getCurrentPublicFolder, (folder) => folder?.childs || []);

export const getAuthors = (state, folder: PublicFolder) => folder?.authors || [];

export const getFolderAuthors = createSelector(contactsSelectors.getContacts, getAuthors, (contacts = [], authors = {}) => {
    return map(
        (author) => ({
            email: author,
            name: pathOr(author, ['name'], find(propEq('email', author), contacts || [])),
        }),
        keys(authors)
    );
});

export const getPublicUploadLimit = createSelector(getPublicRootWeblink, (rootWeblink): number | undefined => rootWeblink.uploadLimit);

export const getIsSinglePublic = createSelector(
    (state: RootState) => getPublicItemById(state, getPublicRootWeblink(state).weblink),
    (rootItem) => rootItem && !rootItem.isFolder
);

export const getPublicBreadcrumbs = createSelector(getCurrentPublicFolder, getPublicRootWeblink, (publicState, rootWeblink) => {
    if (!publicState || !rootWeblink) {
        return [];
    }

    const { weblink, name } = rootWeblink;
    const currentWeblink = publicState.weblink.replace(weblink, '');

    const breadCrumbs = [
        {
            name,
            id: weblink,
            kind: 'folder' as Kind,
            storage: EStorageType.public,
        },
    ];

    const pathParts = currentWeblink.split('/').filter(Boolean);

    pathParts.reduce((fullPath, path) => {
        const currentPath = `${fullPath}/${path}`;

        breadCrumbs.push({
            name: path,
            id: currentPath,
            kind: 'folder' as Kind,
            storage: EStorageType.public,
        });

        return currentPath;
    }, weblink);

    return breadCrumbs;
});

export const getCurrentFolderCursor = createSelector(getCurrentPublicFolder, (folder): string | undefined => folder?.cursor);

export const geIsOpenDwhRadarSent = createSelector(getPublicState, (state): boolean => Boolean(state.isOpenDwhRadarSent));

export const forceShowDownloadAppButton = createSelector(getPublicState, (publicState): boolean =>
    Boolean(publicState.forceShowDownloadButton)
);
