import { Icon20ChevronRight } from '@vkontakte/icons';
import { Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import store from 'lib/store';
import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { noop } from 'reactApp/utils/helpers';

import styles from './HeaderWidget.css';

export interface HeaderWidgetProps {
    title: string;
    header: ReactNode;
    body: ReactNode;
    expandLocalstoreKey: string;
    onExpand?: (expanded: boolean) => void;
}

export const HeaderWidget = memo(function HeaderWidget({ title, header, body, expandLocalstoreKey, onExpand = noop }: HeaderWidgetProps) {
    const [isClosed, setClosed] = useState<boolean>(store.get(expandLocalstoreKey));

    const handleExpand = useCallback(() => {
        const newValue = !isClosed;
        setClosed(newValue);
        store.set(expandLocalstoreKey, newValue);
    }, [isClosed, setClosed]);

    useEffect(() => {
        onExpand(!isClosed);
    }, [isClosed, onExpand]);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.collapser} onClick={handleExpand}>
                    <Icon20ChevronRight
                        className={classNames(styles.expandIcon, {
                            [styles.expandIconExpanded]: !isClosed,
                        })}
                    />
                    <Title className={styles.title} level="2">
                        {title}
                    </Title>
                </div>
                <div className={styles.headerContent}>{header}</div>
            </div>
            <div className={classNames(styles.body, { [styles.bodyClosed]: isClosed })}>{body}</div>
        </div>
    );
});
