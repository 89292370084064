import React from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { IS_FRAME_PAGES } from 'reactApp/appHelpers/configHelpers';
import { usePortal } from 'reactApp/hooks/usePortal';
import { isPublic as isPublicSelector } from 'reactApp/modules/router/router.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { FrozenWarning } from 'reactApp/ui/FrozenWarning/FrozenWarning';

export const FrozenWarningWrapper = () => {
    const el = document.querySelector('#react-warning');

    const isFrozen = useSelector(UserSelectors.isFrozen);
    const isPublic = useSelector(isPublicSelector);

    const elPortalTarget = usePortal('react-warning');

    if (!IS_FRAME_PAGES && el && !isPublic && isFrozen) {
        return createPortal(<FrozenWarning />, elPortalTarget);
    }

    return null;
};
