import { Headline } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as CloudLogo } from 'img/icons/cloud_icon_inline_integration.svg';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { useQuotaInfo } from 'reactApp/ui/Space/SingleSpace/hooks/useQuotaInfo';
import { Tooltip } from 'reactApp/ui/Tooltip/Tooltip';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';

import styles from './InlineIntegrationQuotaHeader.css';
// import {Icon16ChevronOutline} from "@vkontakte/icons";

export const InlineIntegrationQuotaHeader = () => {
    const [isHovered, setIsHovered] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    const { total, usedTotal } = useQuotaInfo(false);
    const { usedPercent } = useSelector(UserQuotaSelectors.getUserQuotaState);

    const getTooltipText = () => {
        if (usedPercent >= 80 && usedPercent < 100) {
            return 'Облако почти заполнено,';
        }
        if (usedPercent === 100) {
            return 'Облако заполнено,';
        }
        if (usedPercent > 100) {
            return 'Облако переполнено,';
        }
    };

    return (
        <div className={styles.header}>
            <CloudLogo className={styles.logo} />
            <Headline level="2" weight="2" className={styles.text}>
                Облако
            </Headline>
            <div
                ref={ref}
                className={classNames(styles.loadStatus, { [styles.isFull]: usedPercent >= 80 })}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {usedTotal && (
                    <Headline level="2">
                        Занято {usedTotal?.value} из {total?.value}
                    </Headline>
                )}
                {/* TODO: добавить, когда по клику будет открываться лендинг квоты под социальным аккаунтом*/}
                {/* <Icon16ChevronOutline />*/}
            </div>

            {usedPercent >= 80 && isHovered && (
                <Tooltip
                    theme="inlineIntegration"
                    target={ref}
                    minWidth={false}
                    placement={tooltipPlacements.BOTTOM}
                    smartPlacement={false}
                    noArrow={true}
                    offset={{ y: 4 }}
                >
                    <div className={styles.tooltip}>
                        <div>{getTooltipText()}</div>
                        <div>увеличьте объём памяти</div>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};
