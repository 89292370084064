import { Text } from '@vkontakte/vkui';
import { ReactComponent as CloseIcon } from 'img/icons/close_20.svg';
import { ReactComponent as CloudLogo } from 'img/icons/cloud_24.svg';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_AUTO_TEST_HIDE, IS_FAMILY_USER } from 'reactApp/appHelpers/configHelpers';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { mailSpaceTrialPromo } from 'reactApp/appHelpers/featuresHelpers';
import { openTariffBuy } from 'reactApp/modules/payment/payment.module';
import { initProducts } from 'reactApp/modules/products/products.module';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { sendDwh } from 'reactApp/utils/ga';

import styles from './MailspacePublicTrialBanner.css';

interface IProps {}

const MAILSPACE_TRIAL_PROMO_ID = 'mailspace-trial-promo';
const MAILSPACE_TRIAL_PROMO_SHOW_COUNT = `${MAILSPACE_TRIAL_PROMO_ID}-count`;
const MAILSPACE_64GB_TARIFF = 'W64GB_1m_mail_modal_window_C_trial_force_single_quota_cloud';
const MAILSPACE_128GB_TARIFF = 'W128GB_1m_refund_payers_trial_force_single_quota_cloud';

function markPromoShown(dispatch) {
    dispatch(promoShown(EPromoType.mailspaceTrialPromo));
    storeHelper.markAsShown(MAILSPACE_TRIAL_PROMO_ID);
    dispatch(removePromo(EPromoType.mailspaceTrialPromo));
}
export const MailspacePublicTrialBanner = memo<IProps>(() => {
    const text = `Подписка Mail Space на ${mailSpaceTrialPromo === 'b' ? '64' : '128'} ГБ`;
    const buttonText = 'Попробовать за 1 ₽';
    const [isShowing, setIsShowing] = useState(true);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isBizUser = useSelector(UserSelectors.isBizUser);

    const dispatch = useDispatch();
    const shownCount = Number(dispatch(UserStorageActions.get(MAILSPACE_TRIAL_PROMO_SHOW_COUNT)) || 0);
    dispatch(initProducts());
    const periodInDays = shownCount < 5 ? 7 : 14;
    const periodPassed =
        storeHelper.getValue(MAILSPACE_TRIAL_PROMO_ID) && storeHelper.isPassed(MAILSPACE_TRIAL_PROMO_ID, { daysCount: periodInDays });

    if (periodPassed) {
        storeHelper.clearStore(MAILSPACE_TRIAL_PROMO_ID);
    }

    const needToHide = storeHelper.getValue(MAILSPACE_TRIAL_PROMO_ID) || IS_AUTO_TEST_HIDE || isCorpUser || isBizUser || IS_FAMILY_USER;

    useEffect(() => {
        if (!needToHide) {
            emitAnalyticEvent(AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_SHOW);
            sendDwh({ eventCategory: 'mail-space-trial-promo-show' });
        }
    }, []);

    if (needToHide) {
        return null;
    }

    const handleClick = () => {
        markPromoShown(dispatch);
        emitAnalyticEvent(AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLICK);
        sendDwh({ eventCategory: 'mail-space-trial-promo_click' });
        dispatch(
            openTariffBuy({
                productId: mailSpaceTrialPromo === 'b' ? MAILSPACE_64GB_TARIFF : MAILSPACE_128GB_TARIFF,
                paySource: 'ballon_trial',
                isMobile: true,
            })
        );
    };

    const handleClose = () => {
        markPromoShown(dispatch);
        emitAnalyticEvent(AnalyticEventNames.MAIL_SPACE_TRIAL_PROMO_CLOSE);
        sendDwh({ eventCategory: `mail-space-trial-promo_close` });
        setIsShowing(false);
    };

    return isShowing ? (
        <div className={styles.root}>
            <div className={styles.wrapper} onClick={handleClick}>
                <div className={styles.iconWrapper}>
                    <CloudLogo />
                </div>
                <div className={styles.textWrapper}>
                    <Text className={styles.text}>{text}</Text>
                    <Text className={styles.buttonText}>{buttonText}</Text>
                </div>
            </div>
            <div className={styles.close} onClick={handleClose}>
                <CloseIcon />
            </div>
        </div>
    ) : null;
});

MailspacePublicTrialBanner.displayName = 'MailspacePublicTrialBanner';
