import React from 'react';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { useIntegrationMessageHandler } from 'reactApp/sections/InlineIntegrationHomePage/hooks/useIntegrationMessageHandler';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { InlineIntegrationQuotaHeader } from 'reactApp/ui/InlineIntegrationQuotaHeader/InlineIntegrationQuotaHeader';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { AddToolbar } from 'reactApp/ui/Toolbar/AddToolbar';

import styles from './InlineIntegrationHomePage.css';

export const InlineIntegrationHomePage: React.FC = () => {
    useIntegrationMessageHandler();

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <InlineIntegrationQuotaHeader />
                {!IS_PHONE_BROWSER && <AddToolbar />}
            </div>
            <BreadcrumbsContainer />
            <div className={styles.datalist}>
                <Datalist />
            </div>
        </div>
    );
};
