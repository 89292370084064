import classNames from 'clsx';
import React, { memo, useEffect } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';

import styles from './BannerOffer.css';
import { EBannerOfferTheme, Props } from './BannerOffer.types';
import { useBannerOfferCallback } from './hooks/useBannerOfferCallback';

export const BannerOffer = memo(function BannerOffer(props: Props): JSX.Element | null {
    const {
        product,
        onClose: handleClose,
        onClick: handleClick,
        onShow: handleShow,
        title,
        text,
        icon,
        button = 'Получить',
        id = EBannerOfferTheme.LIGHT,
    } = props;

    const { onClick, onClose, onShow } = useBannerOfferCallback({
        id,
        handleClose,
        handleClick,
        handleShow,
        product,
    });

    useEffect(() => onShow(), []);

    return (
        <Dialog open={true} onCancel={onClose} closeOnEscape className={styles.modal} closeOnDimmerClick>
            <section
                className={classNames({
                    [styles.root]: true,
                    [styles[`root_theme_${EBannerOfferTheme.LIGHT}`]]: true,
                })}
            >
                <div className={styles.iconBlock}>
                    <img src={icon} alt="Облако mail.ru" />
                </div>
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.text}>{text}</div>
                <Button primary fluid onClick={onClick} size="big" theme="vk" className={styles.button}>
                    {button}
                </Button>
            </section>
        </Dialog>
    );
});
