import React, { memo } from 'react';
import { Portal } from 'react-portal';
import { IS_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { BaseInputDialog } from 'reactApp/components/BaseConfirmDialog/BaseInputDialog';
import { BuyModal } from 'reactApp/components/BuyModal/BuyModal';
import { ConvertFb2ToEpubDialog } from 'reactApp/components/ConvertFb2ToEpubDialog/ConvertFb2ToEpubDialog';
import { CreateCopyOfNonEditableFileModal } from 'reactApp/components/CreateCopyOfNoneditableFileModal/CreateCopyOfNoneditableFileModal';
import { CreateDocumentByLinkDialog } from 'reactApp/components/CreateDocumentByLinkDialog/CreateDocumentByLinkDialog';
import { DisableAdsPromo } from 'reactApp/components/DisableAdsPromo/DisableAdsPromo';
import { EmptyTrashBin } from 'reactApp/components/EmptyTrashBin/EmptyTrashBin';
import { NewDocumentModal } from 'reactApp/components/NewDocumentModal/NewDocumentModal';
import { NewFolderModal } from 'reactApp/components/NewFolderModal/NewFolderModal';
import { RemoveDialog } from 'reactApp/components/RemoveDialog/RemoveDialog';
import { SharingNewMount } from 'reactApp/components/SharingNewBiz/Mount/SharingNewMount';
import { SharingNewReject } from 'reactApp/components/SharingNewBiz/Reject/SharingNewReject';
import { SharingNewBiz } from 'reactApp/components/SharingNewBiz/SharingNewBiz';
import { SharingNewUnMount } from 'reactApp/components/SharingNewBiz/Unmount/SharingNewUnMount';
import { SharingNew } from 'reactApp/components/SharingWindow/SharingNew/SharingNew';
import { UploadBigFileDialog } from 'reactApp/components/UploadBigFileDialog/UploadBigFileDialog';
import { VkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { SelectAlbumPopup } from 'reactApp/sections/AlbumsPage/SelectAlbumPopup/SelectAlbumPopup';
import { SelectFromCloudPopup } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/SelectFromCloudPopup';
import { FamilyAcceptInviteDialog } from 'reactApp/sections/FamilyPage/FamilyAcceptInviteDialog/FamilyAcceptInviteDialog';
import { FamilyErrorDialog } from 'reactApp/sections/FamilyPage/FamilyErrorDialog/FamilyErrorDialog';
import { PayInBrowser } from 'reactApp/sections/MobileSubscriptionsPage/PayInBrowser/PayInBrowser';
import { DeletePageModal } from 'reactApp/sections/PdfEditPage/DeletePageModal/DeletePageModal';
import { DeletePageRejectedModal } from 'reactApp/sections/PdfEditPage/DeletePageRejectedModal/DeletePageRejectedModal';
import { RecognitionPopup } from 'reactApp/sections/PersonalDocuments/RecognitionPopup/RecognitionPopup';
import { PromocodeResultDialog } from 'reactApp/sections/PromocodeLanding/ResultDialog/PromocodeResultDialog';
import { AutouploadSuccessDialog } from 'reactApp/sections/QuotaLanding/AutouploadSuccessDialog/AutouploadSuccessDialog';
import { CancelAutorenewDialog } from 'reactApp/sections/QuotaLanding/CancelAutorenewDialog/CancelAutorenewDialog';
import { DetailedTariffBenefitsPopup } from 'reactApp/sections/QuotaLanding/DetailedTariffBenefitsPopup/DetailedTariffBenefitsPopup';
import { MobileNavigationPopup } from 'reactApp/sections/QuotaLanding/MobileNavigationPanel/MobileNavigationPopup';
import { UpsaleMonthTariffDialog } from 'reactApp/sections/QuotaLanding/UpsaleMonthTariffDialog/UpsaleMonthTariffDialog';
import { SubscriptionModal } from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal';
import { AddCardModal } from 'reactApp/ui/AddCardModal/AddCardModal';
import { AsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal';
import { MustAttachPhoneDialog } from 'reactApp/ui/AskPhoneDialog/MustAttachPhoneDialog';
import { SuccessAttachPhoneDialog } from 'reactApp/ui/AskPhoneDialog/SuccessAttachPhoneDialog';
import { AuthDialog } from 'reactApp/ui/AuthDialog/AuthDialog';
import { BannerOffer } from 'reactApp/ui/BannerOffer/BannerOffer';
import { BuyBusinessDialog } from 'reactApp/ui/BuyBusinessDialog/BuyBusinessDialog';
import { CancelAutoRenew10TbModal } from 'reactApp/ui/CancelAutoRenew10TbModal/CancelAutoRenew10TbModal';
import { CancelAutoRenewAutouploadModal } from 'reactApp/ui/CancelAutoRenewAutouploadModal/CancelAutoRenewAutouploadModal';
import { CancelAutoRenewTrialModal } from 'reactApp/ui/CancelAutoRenewTrialModal/CancelAutoRenewTrialModal';
import { CancelSubscriptionWithCaptchaModal } from 'reactApp/ui/CancelSubscriptionWithCaptchaModal/CancelSubscriptionWithCaptchaModal';
import { CoEditPublicAlertPopup } from 'reactApp/ui/CoEditPublicAlertPopup/CoEditPublicAlertPopup';
import { ConfirmEmailMobile } from 'reactApp/ui/ConfirmEmail/ConfirmEmailMobile/ConfirmEmailMobile';
import { ConfirmEmailDialog, ConfirmEmailScreen } from 'reactApp/ui/ConfirmEmail/renderConfirmEmail';
import { PreCopyWeblinkDialog } from 'reactApp/ui/CopyWeblink/PreCopyWeblinkDialog/PreCopyWeblinkDialog';
import { DeleteConfirmationDialog } from 'reactApp/ui/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { DisabledFeatureDialog } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog';
import { EbookReaderDialog } from 'reactApp/ui/EbookReaderDialog/EbookReaderDialog';
import { FamilyInviteModal } from 'reactApp/ui/Family/FamilyInviteModal/FamilyInviteModal';
import { FileHistory } from 'reactApp/ui/FileHistory/FileHistory';
import { AuthModal } from 'reactApp/ui/Mobile/AuthModal/AuthModal';
import { CancelAutoRenewalModal } from 'reactApp/ui/Mobile/CancelAutoRenewalModal/CancelAutoRenewalModal';
import { CancelAutoRenewTrialModalMobile } from 'reactApp/ui/Mobile/CancelAutoRenewTrialModalMobile/CancelAutoRenewTrialModalMobile';
import { CloudNewFeaturesModal } from 'reactApp/ui/Mobile/CloudNewFeaturesModal/CloudNewFeaturesModal';
import { DiscountsModal } from 'reactApp/ui/Mobile/DiscountsModal/DiscountsModal';
import { DownloadAppModal } from 'reactApp/ui/Mobile/DownloadAppModal/DownloadAppModal';
import { DownloadDropdown } from 'reactApp/ui/Mobile/DownloadDropdown/DownloadDropdown';
import { FileInfoModal } from 'reactApp/ui/Mobile/FileInfoModal/FileInfoModal';
import { FilterAuthorsModal } from 'reactApp/ui/Mobile/FilterAuthorsModal/FilterAuthorsModal';
import { FilterFacesModal } from 'reactApp/ui/Mobile/FilterFacesModal/FilterFacesModal';
import { GiftPromocodeModal } from 'reactApp/ui/Mobile/GiftPromocodeModal/GiftPromocodeModal';
import { LeftMenu } from 'reactApp/ui/Mobile/LeftMenu/LeftMenu';
import { MobilePublicPromoBanner } from 'reactApp/ui/Mobile/MobilePublicPromoBanner/MobilePublicPromoBanner';
import { MobileViewer } from 'reactApp/ui/Mobile/MobileViewer/MobileViewer';
import { OptionsModal } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal';
import { RequiredSignUpDialog } from 'reactApp/ui/Mobile/RequiredSignUpDialog/RequiredSignUpDialog';
import { SearchHistoryNotification } from 'reactApp/ui/Mobile/SearchHistoryNotification/SearchHistoryNotification';
import { SearchNotification } from 'reactApp/ui/Mobile/SearchNotification/SearchNotification';
import { SplashScreen } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen';
import { SubscriptionInfoModal } from 'reactApp/ui/Mobile/SubscriptionInfoModal/SubscriptionInfoModal';
import { SubscriptionsModal as SubscriptionsTouchModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal';
import { SuspiciousDialog as SuspiciousDialogMobile } from 'reactApp/ui/Mobile/SuspiciousDialog/SuspiciousDialog';
import { UserChangeModal } from 'reactApp/ui/Mobile/UserChangeModal/UserChangeModal';
import { UserLoginMenu } from 'reactApp/ui/Mobile/UserLoginMenu/UserLoginMenu';
import { VirusDialog } from 'reactApp/ui/Mobile/VirusDialog/VirusDialog';
import { YearFilterModal } from 'reactApp/ui/Mobile/YearFilterModal/YearFilterModal';
import { NotDocumentModal } from 'reactApp/ui/NotDocumentModal/NotDocumentModal';
import { NoWarningDialog } from 'reactApp/ui/NoWarningDialog/NoWarningDialog';
import { OverquotaModal } from 'reactApp/ui/OverquotaPopup/new/OverquotaModal';
import { OverquotaPopup } from 'reactApp/ui/OverquotaPopup/OverquotaPopup';
import { PollPopup } from 'reactApp/ui/PollPopup/PollPopup';
import { PopupMobileAppQR } from 'reactApp/ui/PopupMobileAppQR/PopupMobileAppQR';
import { QuotaPopup } from 'reactApp/ui/QuotaPopup/QuotaPopup';
import { ImageEditorSurvey } from 'reactApp/ui/ReactViewer/ViewerImage/components/ImageEditSurvey/ImageEditorSurvey';
import { RemoveCardModal } from 'reactApp/ui/RemoveCardModal/RemoveCardModal';
import { SafeFakedoorModal } from 'reactApp/ui/SafeFakedoorModal/SafeFakedoorModal';
import { SelectFolderConnected } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog';
import { SliderModal } from 'reactApp/ui/SliderModal/SliderModal';
import { SmallWindowWarningDialog } from 'reactApp/ui/SmallWindowWarningDialog/SmallWindowWarningDialog';
import { SpecialBanner } from 'reactApp/ui/SpecialBanner/SpecialBanner';
import { SuspiciousDialog } from 'reactApp/ui/SuspiciousDialog/SuspiciousDialog';
import { TariffBuyConnected } from 'reactApp/ui/TariffBuy/TariffBuy';
import { ViolatedFilenameDialog } from 'reactApp/ui/ViolatedFilenameDialog/ViolatedFilenameDialog';
import { VirusDialogComponent } from 'reactApp/ui/VirusDialog/VirusDialog';
import { WebPushBubble } from 'reactApp/ui/WebPush/WebPushBubble';
import { WelcomeDefaultTariffSplash } from 'reactApp/ui/WelcomeDefaultTariffSplash/WelcomeDefaultTariffSplash';
import { ReduxPopup } from 'redux-popup';

import { getPopupProps } from './Popup.helpers';

const QuotaCleanerPage = React.lazy(
    () => import(/* webpackChunkName: "quotaCleanerLanding" */ '../../ui/QuotaCleanerPage/QuotaCleanerPage')
);
const QuotaCleanerPageMobile = React.lazy(
    () => import(/* webpackChunkName: "QuotaCleanerMobile" */ '../../ui/QuotaCleanerPage/QuotaCleanerPageMobile/QuotaCleanerPageMobile')
);

export const PopupsNew = memo(() => (
    <Portal>
        <ReduxPopup {...getPopupProps({ name: popupNames.VIRUS_DLG, component: VirusDialogComponent })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DISABLED_FEATURE, component: DisabledFeatureDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.RECOGNITION_POPUP, component: RecognitionPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.WEB_PUSH_TOOLTIP, component: WebPushBubble })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SELECT_FOLDER_DIALOG, component: SelectFolderConnected })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.FILE_HISTORY, component: FileHistory })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.REMOVE_DIALOG, component: RemoveDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.RENAME_DIALOG, component: BaseInputDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.ASK_NAME_DIALOG, component: BaseInputDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.UPDATE_CARD, component: AddCardModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CANCEL_MAIL_SUBSCRIPTION, component: CancelAutorenewDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOUNT_DIALOG, component: SharingNewMount })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.UNMOUNT_DIALOG, component: SharingNewUnMount })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.REJECT_DIALOG, component: SharingNewReject })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.TARIFF_BUY, component: TariffBuyConnected })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PAY_IN_BROWSER, component: PayInBrowser })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.VKBUY_MODAL, component: VkBuyModalWithSidebar })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DOWNLOAD_MOBILE, component: DownloadDropdown })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CREATE_FOLDER, component: NewFolderModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CREATE_DOCUMENT, component: NewDocumentModal })} />
        <ReduxPopup
            {...getPopupProps({ name: popupNames.SHARING_DIALOG, component: IS_ONPREMISE || IS_BIZ_USER ? SharingNewBiz : SharingNew })}
        />
        <ReduxPopup {...getPopupProps({ name: popupNames.CONFIRM_EMAIL_SCREEN, component: ConfirmEmailScreen })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CONFIRM_EMAIL_DIALOG, component: ConfirmEmailDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.OVERQUOTA_BANNER, component: OverquotaPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.OVERQUOTA_MODAL, component: OverquotaModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_OPTIONS, component: OptionsModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_VIEWER, component: MobileViewer })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_FILTER_AUTHOR, component: FilterAuthorsModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_FILTER_FACES, component: FilterFacesModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.NO_WARNING, component: NoWarningDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.AUTH_MODAL, component: AuthModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_VIRUS_DIALOG, component: VirusDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_SUSPICIOS_DIALOG, component: SuspiciousDialogMobile })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_GIFT_PROMOCODE_DIALOG, component: GiftPromocodeModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SELECT_ALBUM, component: SelectAlbumPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUSPICIOS_DIALOG, component: SuspiciousDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_LEFT_MENU, component: LeftMenu })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_SPLASH_SCREEN, component: SplashScreen })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.QUOTA_MOBILE_NAVIGATION, component: MobileNavigationPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.WHATS_NEW_DIALOG, component: WhatsNewDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DISABLE_ADS_PROMO, component: DisableAdsPromo })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CONFIRM_EMAIL_MOBILE, component: ConfirmEmailMobile })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BANNER_OFFER, component: BannerOffer })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_FILE_INFO_MODAL, component: FileInfoModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BANNER_OFFER, component: BannerOffer })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CLEAR_TRASHBIN, component: EmptyTrashBin })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_DOWNLOAD_APP, component: DownloadAppModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTIONS_MOBILE, component: SubscriptionsTouchModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_INFO_MOBILE, component: SubscriptionInfoModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_AUTO_RENEWAL_MOBILE, component: CancelAutoRenewalModal })} />
        <ReduxPopup
            {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_WITH_CAPTCHA, component: CancelSubscriptionWithCaptchaModal })}
        />
        <ReduxPopup {...getPopupProps({ name: popupNames.SELECT_FROM_CLOUD_DIALOG, component: SelectFromCloudPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.POLL_DIALOG, component: PollPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SPECIAL_BANNER, component: SpecialBanner })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DELETE_CONFIRMATION_DIALOG, component: DeleteConfirmationDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.QUOTA_CLEANER_PAGE, component: QuotaCleanerPage })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.QUOTA_CLEANER_PAGE_MOBILE, component: QuotaCleanerPageMobile })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MUST_ATTACH_PHONE, component: MustAttachPhoneDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUCCESS_ATTACH_PHONE, component: SuccessAttachPhoneDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.GENERIC_CONFRIMATION_DIALOG, component: BaseConfirmDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.UPLOAD_BIG_FILE, component: UploadBigFileDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.QUOTA_POPUP, component: QuotaPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.REMOVE_CARD, component: RemoveCardModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PRE_COPY_WEBLINK, component: PreCopyWeblinkDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_MODAL, component: SubscriptionModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_USER_CHANGE_MODAL, component: UserChangeModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_USER_LOGIN_MENU, component: UserLoginMenu })} />
        {IS_ONPREMISE && <ReduxPopup {...getPopupProps({ name: popupNames.VIOLATED_FILENAME, component: ViolatedFilenameDialog })} />}
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_SEARCH_HISTORY_NOTIFICATION, component: SearchHistoryNotification })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_SEARCH_NOTIFICATION, component: SearchNotification })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CO_EDIT_ALERT, component: CoEditPublicAlertPopup })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_CLOUD_NEW_FEATURES, component: CloudNewFeaturesModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.ASIDE_PROMO_MODAL, component: AsidePromoModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BUY_BUSINESS_DIALOG, component: BuyBusinessDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.AUTH_DIALOG, component: AuthDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SMALL_WINDOW_WARNING_DIALOG, component: SmallWindowWarningDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.NOT_DOCUMENT_MODAL, component: NotDocumentModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DELETE_PAGE_MODAL, component: DeletePageModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.DELETE_PAGE_REJECTED_MODAL, component: DeletePageRejectedModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_DISCOUNTS, component: DiscountsModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.YEAR_FILTER_MODAL, component: YearFilterModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CREATE_COPY_OF_NONEDITABLEFILE, component: CreateCopyOfNonEditableFileModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.PROMOCODE_RESULT, component: PromocodeResultDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.BUY_MODAL, component: BuyModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.IMAGE_EDITOR, component: ImageEditorSurvey })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.EBOOK_READER, component: EbookReaderDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SLIDER_MODAL, component: SliderModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.FAMILY_INVITE_MODAL, component: FamilyInviteModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.FAMILY_ACCEPT_INVITE, component: FamilyAcceptInviteDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.FAMILY_ERROR, component: FamilyErrorDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_PUBLIC_BANNER, component: MobilePublicPromoBanner })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CONVERT_FB2_TO_EPUB, component: ConvertFb2ToEpubDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SAFE_FAKEDOOR, component: SafeFakedoorModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_REQUIRED_SIGNUP, component: RequiredSignUpDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.CREATE_NEW_DOCUMENT_BY_LINK, component: CreateDocumentByLinkDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_TRIAL, component: CancelAutoRenewTrialModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_TRIAL_MOBILE, component: CancelAutoRenewTrialModalMobile })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.UPSALE_MONTH_TARIFF, component: UpsaleMonthTariffDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.MOBILE_APP_QR, component: PopupMobileAppQR })} />
        {/* tempexp_16481-start */}
        <ReduxPopup {...getPopupProps({ name: popupNames.AUTOUPLOAD_PROMO_SUCCESS, component: AutouploadSuccessDialog })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_AUTOUPLOAD, component: CancelAutoRenewAutouploadModal })} />
        <ReduxPopup {...getPopupProps({ name: popupNames.SUBSCRIPTION_CANCEL_10TB, component: CancelAutoRenew10TbModal })} />
        {/* tempexp_16481-end */}
        {/* tempexp_16800-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.DETAILED_TARIFF_BENEFITS, component: DetailedTariffBenefitsPopup })} />
        {/* tempexp_17086-next-line */}
        <ReduxPopup {...getPopupProps({ name: popupNames.WELCOME_DEFAULT_TARIFF_SPLASH, component: WelcomeDefaultTariffSplash })} />
    </Portal>
));

PopupsNew.displayName = 'PopupsNew';
