import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDwh, sendXray } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { OverquotaModalProps } from './OverquotaModal.types';

export const renderOverquotaModal = (data: OverquotaModalProps) => openPopupHelper({ popupName: popupNames.OVERQUOTA_MODAL, data });

export enum OverquotaBannerCtrl {
    view = 'view',
    close = 'close',
    purchase = 'purchase',
    cleaning = 'cleaning',
}

export const sendDwhOverquota = ({
    category = ECategoryGa.splashscreen_alert,
    action,
    storage,
    control,
    name = 'overquota_splash_new',
}: {
    category?: ECategoryGa.splashscreen_alert | ECategoryGa.informationAlert;
    action: 'show' | 'click';
    storage: EStorageType;
    name?: 'overquota_splash_new' | 'overquota_information_alert_new';
    control?: OverquotaBannerCtrl;
}) => {
    let source = 'home';
    if (storage === EStorageType.public) {
        source = 'public';
    }
    if (storage === EStorageType.documents || storage === EStorageType.alldocuments) {
        source = 'document';
    }

    sendXray([category, name, action]);

    sendDwh({
        eventCategory: category,
        action,
        dwhData: {
            platform: 'web',
            place: 'cloud',
            source,
            name,
            name_button: control,
        },
        sendImmediately: true,
    });
};
