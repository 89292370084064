import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { changeDocumentDomainToCloud } from 'reactApp/utils/documentsDomain';

export const SUBSCRIPTIONS_URL = IS_DOCUMENTS_DOMAIN
    ? `${changeDocumentDomainToCloud(window.location.origin)}/subscriptions`
    : `${window.location.origin}/subscriptions`;

export const getSubscriptionsUrl = (query = '') => {
    const cloudOrigin = IS_DOCUMENTS_DOMAIN ? changeDocumentDomainToCloud(window.location.origin) : window.location.origin;

    const url = `/subscriptions${query ? `?${query}` : ''}`;

    return `${cloudOrigin}${url}`;
};
