import { Icon20Add } from '@vkontakte/icons';
import React, { memo, useCallback } from 'react';

import styles from './CreateAlbum.css';

interface IProps {
    onClick?(): void;
}

export const CreateAlbum = memo(function CreateAlbum({ onClick }: IProps) {
    const createAlbumHandler = useCallback(() => {
        onClick?.();
    }, [onClick]);

    return (
        <div className={styles.root} onClick={createAlbumHandler} data-qa-id="create-album">
            <div className={styles.addIcon}>
                <Icon20Add />
            </div>
            Создать альбом
        </div>
    );
});
