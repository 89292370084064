import { Method } from 'axios';
import { APICallV3Post } from 'reactApp/api/APICall';

interface Response {
    name: string;
    size: number;
    home: string;
}

export class EpubConvertV3APICall extends APICallV3Post<Response> {
    _method = 'ovidius/epub/convert';
    _type: Method = 'post';
}
