import { useEffect } from 'react';

export const setRebranding = () => {
    document.body.classList.add('rebranding');
};

export const useGlobalRebranding = (isRebranding: boolean) => {
    useEffect(() => {
        if (!isRebranding) {
            return;
        }
        return setRebranding();
    }, [isRebranding]);
};
