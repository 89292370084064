import { Spacing } from '@vkontakte/vkui';
import debounce from 'lodash.debounce';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { Product } from 'reactApp/types/Billing';
import Content from 'reactApp/ui/Content/Content';
import { useDisableAdsTitle } from 'reactApp/ui/IframePages/BuyDisableAds/hooks/useDisableAdsTitle';
import { DisableAdsPostMessageType, sendFrameMessage } from 'reactApp/ui/IframePages/BuyDisableAds/utils';
import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { sendDwh } from 'reactApp/utils/ga';
import useResizeObserver from 'use-resize-observer/polyfilled';

import styles from './DisableAdsScreen.css';
import { TariffList } from './TariffList/TariffList';
import { TariffPeriodSwitch } from './TariffPeriodSwitch/TariffPeriodSwitch';

interface DisableAdsScreenProps {
    titleFromQuery?: string;
    tariffsIdsFromQuery?: string;
    activeTabFromQuery?: string;
    onBuyClick: (product: Product) => void;
}

export const DisableAdsScreen = memo(({ activeTabFromQuery, tariffsIdsFromQuery, titleFromQuery, onBuyClick }: DisableAdsScreenProps) => {
    const title = useDisableAdsTitle(titleFromQuery);
    const { hasError } = useSelector(ProductsSelectors.getLifeCycleState);
    const [activeTab, setActiveTab] = useState<ETabsName>(activeTabFromQuery === 'm' ? ETabsName.month : ETabsName.year);

    const onResize = useCallback(
        debounce(({ height = 0 }: { height: number }) => {
            sendFrameMessage({ type: DisableAdsPostMessageType.size, height, width: BREAKPOINT_SM });
        }),
        []
    );
    const { ref } = useResizeObserver<HTMLDivElement>({ onResize });

    const isFirstEffectCalled = useRef(false);
    useEffect(() => {
        if (!isFirstEffectCalled.current) {
            emitAnalyticEvent(AnalyticEventNames.BUY_DISABLE_ADS_MODAL_SHOWN, { selectedPeriod: activeTab });
            sendDwh({ eventCategory: 'tariffs-quota', action: 'modal-noads-show', dwhData: { selectedPeriod: activeTab } });
            isFirstEffectCalled.current = true;
        } else {
            emitAnalyticEvent(AnalyticEventNames.BUY_DISABLE_ADS_MODAL_PERIOD_CHANGE, { selectedPeriod: activeTab });
            sendDwh({ eventCategory: 'tariffs-quota', action: 'modal-noads-switch', dwhData: { selectedPeriod: activeTab } });
        }
    }, [activeTab]);

    return (
        <div ref={ref} className={styles.contentRefWrapper}>
            <Content wrapClass={styles.content} hasError={hasError}>
                <div className={styles.title}>{title}</div>
                <Spacing size={32} />
                <TariffPeriodSwitch checkedOption={activeTab} onClickSwitch={setActiveTab} />
                <Spacing size={24} />
                <TariffList tariffsIdsFromQuery={tariffsIdsFromQuery} activeTab={activeTab} onBuyClick={onBuyClick} />
            </Content>
        </div>
    );
});

DisableAdsScreen.displayName = 'DisableAdsScreen';
