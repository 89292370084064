import { Spacing } from '@vkontakte/vkui';
import React from 'react';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';

import styles from './TariffCardLoader.css';

export const TariffCardLoader = () => (
    <div className={styles.root}>
        <Spacing size={55} />
        <div className={styles.quota}>
            <div className={styles.space}>
                <Placeholder />
            </div>
            <div className={styles.units}>
                <Placeholder />
            </div>
        </div>
        <Spacing size={33} />
        <div className={styles.informer}>
            <Placeholder />
        </div>

        <Spacing size={24} />

        {Array.from(new Array(3)).map((_, i) => (
            <>
                <div className={styles.feature} key={i}>
                    <Placeholder />
                </div>
                <Spacing size={16} />
            </>
        ))}

        <Spacing size={16} />

        <div className={styles.button}>
            <Placeholder />
        </div>
    </div>
);
