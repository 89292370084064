import { TypedEvent, TypedEventType } from '../eventEmitter';
import { AnalyticEventType } from './types';

/**
 *  Регистрация события важного для аналитики,
 *  его метаданные и инструменты подписки и нотификации
 * (отписка не предполагается, т.к. на весь Life Cycle приложения)
 */
export class AnalyticEvent<T = unknown> implements AnalyticEventType<T> {
    private readonly eventEmitter: TypedEventType<T> = new TypedEvent<T>();
    public readonly shortName: string;
    /**
     * @param {string} shortName - короткое имя события (show, click, buy, ...etc), будет добавляться к названию эксперимента при передаче в графану.
     */
    constructor(shortName: string) {
        this.shortName = shortName;
    }

    on(listener: (args: T) => void) {
        this.eventEmitter.on(listener);
    }

    emit(args: T) {
        this.eventEmitter.emit(args);
    }
}
