import { BReactComponent } from 'blocks-cloud/b-react-component';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorageSelectedItems } from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { store } from 'reactApp/store';
import { ContextMenu, ContextMenuItem } from 'reactApp/ui/ContextMenu/ContextMenu';
import { createContextMenu } from 'reactApp/ui/ContextMenu/createContextMenuList';
import { DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';

let contextMenu: BReactComponent;

function renderContextMenu({
    posX,
    posY,
    renderDefault,
    menuItems,
    theme,
    gaSuffix,
    storage,
}: {
    posX: number;
    posY: number;
    renderDefault?: boolean;
    menuItems?: ContextMenuItem[];
    theme?: DropdownTheme;
    gaSuffix?: string;
    storage?: EStorageType;
}): void {
    if (contextMenu) {
        contextMenu.destroy();
        contextMenu = null;
    }
    const section = getCurrentStorage(store.getState()) as EStorageType;
    storage = storage || section;
    const items: CloudItem[] = getStorageSelectedItems(store.getState(), storage) as CloudItem[];

    // @ts-ignore
    const list = menuItems?.length > 0 ? menuItems : createContextMenu({ storage, items, renderDefault, section });

    if (!list || !list.length) {
        return;
    }

    contextMenu = new BReactComponent({
        ReactComponent: ContextMenu,
    });

    const el = document.querySelector('#react-contextmenu');
    contextMenu.renderTo(el, {
        props: {
            posX,
            posY,
            list,
            theme,
            gaSuffix: gaSuffix || storage,
            close: closeContextMenu,
        },
    });
}

function closeContextMenu(): void {
    if (contextMenu) {
        contextMenu.destroy();
        contextMenu = null;
    }
}

export { renderContextMenu, closeContextMenu };
