import { ReactComponent as GbIcon } from 'img/countdown/gb.svg';
import React from 'react';
import {
    ACTION_PROMO,
    IS_ACTION_USER,
    IS_AUTO_TEST_HIDE,
    IS_AUTO_TEST_PROMO,
    IS_MY_TEAM,
    PROMO_TARIFFS,
} from 'reactApp/appHelpers/configHelpers';
import { summerPromotion } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUserQuotaSuccess } from 'reactApp/modules/userQuota/userQuota.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { store } from 'reactApp/store';
import { put, take } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

export const SPACE_COUNTDOWN_ID = 'gb-place';
export const SPACE_PROMO_ID = `promo-countdown_${SPACE_COUNTDOWN_ID}`;

export const COUNTDOWN_DATA = {
    id: SPACE_COUNTDOWN_ID,
    text: '<span>-20%</span> на место</br>для ваших файлов',
    img: <GbIcon width={70} height={33} />,
    productId: PROMO_TARIFFS?.spacePromo,
};

export function* initSpacePromo() {
    const storage = yield* select(getCurrentStorage);
    const { isSubscription, isPublicOrStock } = getStorage(storage);
    const isPaidUser = yield* select(UserSelectors.isPaidUser);
    const isBizUser = yield* select(UserSelectors.isBizUser);
    const isCorpUser = yield* select(UserSelectors.isCorpUser);
    const isPhone = yield* select(EnvironmentSelectors.isPhone);
    const isAnonymous = yield* select(UserSelectors.isAnonymous);
    const isOverQuota = yield* select(UserSelectors.isOverQuotaUser);
    const isDefaultContent = yield* select(isDefaultRootContent);
    const isAction = IS_ACTION_USER && !isOverQuota && (!!ACTION_PROMO || IS_AUTO_TEST_PROMO);

    if (
        IS_AUTO_TEST_HIDE ||
        isPhone ||
        isAction ||
        isAnonymous ||
        isBizUser ||
        isPaidUser ||
        isCorpUser ||
        isSubscription ||
        IS_MY_TEAM ||
        isPublicOrStock ||
        isDefaultContent ||
        storeHelper.isPassed(SPACE_PROMO_ID, { daysCount: 1 }) ||
        summerPromotion
    ) {
        return;
    }

    const { isLoaded } = yield* select(UserQuotaSelectors.getLoadingState);

    if (!isLoaded) {
        yield take(loadUserQuotaSuccess);
    }

    const { usedPercent } = yield* select(UserQuotaSelectors.getUserQuotaState);
    if (usedPercent >= 7) {
        return;
    }

    const lastTime = storeHelper.getLastTime(SPACE_PROMO_ID);
    const lastTimeDate = lastTime ? new Date(lastTime) : new Date();
    const endDate = lastTimeDate.setDate(lastTimeDate.getDate() + 1);

    yield put(
        addPromoToShowQueue({
            type: EPromoType.space,
            promoId: SPACE_PROMO_ID,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.space));
                if (!lastTime) {
                    storeHelper.markAsShown(SPACE_PROMO_ID);
                }
            },
            onClose: () => store.dispatch(removePromo(EPromoType.space)),
            data: COUNTDOWN_DATA,
            endDate,
        })
    );
}
