import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

// https://docs.google.com/spreadsheets/u/2/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0/edit

export type TSendAnalytics = (label: string, isFamily?: boolean, name?: string) => void;

type TUseSendSpaceAnalytics = (props: {
    isCountdownPromo: boolean;
    isMarketingPromo: boolean;
    skipAnalytics: boolean;
    summerPromotion: boolean;
}) => TSendAnalytics;

export const useSendSpaceAnalytics: TUseSendSpaceAnalytics = ({ isCountdownPromo, isMarketingPromo, skipAnalytics, summerPromotion }) => {
    const remaining = useSelector(UserQuotaSelectors.getRemaining);
    const total = useSelector(UserQuotaSelectors.getTotal);

    let action = EPaymentGa.space;

    if (isCountdownPromo) {
        action = EPaymentGa.spaceDiscount;
    } else if (isMarketingPromo || summerPromotion) {
        action = EPaymentGa.spaceAction;
    }

    return useCallback(
        (label: string, isFamily = false, name?: string) => {
            if (skipAnalytics) {
                return;
            }

            sendPaymentGa({
                action,
                label,
                size_quota: total?.original,
                free_quota: remaining?.original,
                group: 'b',
                ...(isFamily && { paid_user_invite: true }),
                ...(name && { name }),
            });
        },
        [action, remaining?.original, skipAnalytics, total?.original]
    );
};
