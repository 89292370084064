import { IRadarOptions, sendXray } from '../ga';
import { getPlatformInfo } from '../gaHelpers';
import { AnalyticEventsType, AnalyticInfoType, AnalyticSenderType, DataType, ExtractEventArg } from './types';
import { featureValueToString } from './utils';

export { AnalyticEvent } from './analyticEvent';

type SendXrayArgs<T> = (
    eventArgs: ExtractEventArg<T>,
    info: {
        expName: string;
        expValue: string;
        eventName: string;
        iData?: DataType;
        radarOptions?: IRadarOptions;
    }
) => AnalyticInfoType;

type OptionalArgIfInferedIsEmpty<T> = Record<string, never> extends T ? [eventArgs?: T] : [eventArgs: T];

type FeatureValueType = string | number | object | boolean | null | undefined;

export const defineAnalyticEvents = <EventKey extends string, Events extends AnalyticEventsType<EventKey>>(
    events: Events,
    analyticSender: AnalyticSenderType = sendXray
) => ({
    /**
     *  Нотифицировать о произошедшем событии
     *
     *  @param {T} expName - короткое название для эксперимента в графану (ограниечение на название метрики целиком 64).
     *  @param {string} expValue - текущее значение эксперимента.
     */
    emitAnalyticEvent<T extends EventKey>(eventName: T, ...args: OptionalArgIfInferedIsEmpty<ExtractEventArg<Events[T]>>) {
        const event = events[eventName];
        const [eventArgs] = args;
        event.emit(eventArgs);
    },

    /**
     *  Регистрация эксперимента для отправки технической аналитики.
     *
     *  @param {string} expName - короткое название для эксперимента в графану (ограниечение на название метрики целиком 64).
     *  @param {FeatureValueType} expValue - текущее значение фичи для эксперимента.
     *  @param {T | T[]} analyticEventNames - на какие события важные для аналитики произойдет отправка.
     *  @param options.radarOptions - настройки отсылки радара.
     *  @param {{[key: string]: number}} options.iData:  Пример: {'ext':1, 'time': 1000}
     *  @param {boolean} options.sendPlatformData - Default: true; отправлять информации о платформе (web, ios, and, wv). Пример my-exp-a-ios-wv.
     *  @param  options.sendXrayArgs - Кастомизация аргументов sendXray, а так же возможность отменить отправку для каждого типа события или
     *  @param  options.mapEventNames - Подмена имени события единая для всех.
     *  @param {boolean} options.abortIfUnexpectedFeatureValue - Default: true; отменяет отправку аналитики для значений из holdback слоя (false, undefined, null).
     *  @param {FeatureValueType[]} options.unexpectedFeatureValues - Default: [undefined, null, false]; значения фич, для которых отменяется отправка значений
     */
    registerExperiment<T extends EventKey>(
        expName: string,
        expValue: FeatureValueType,
        analyticEventNames: T | T[],
        options: {
            unexpectedFeatureValues?: FeatureValueType[];
            abortIfUnexpectedFeatureValue?: boolean;
            sendPlatformData?: boolean;
            mapEventNames?: {
                [key in T]?: string;
            };
            iData?: DataType;
            radarOptions?: IRadarOptions;
            sendXrayArgs?:
                | SendXrayArgs<Events[T]>
                | {
                      [key in T]?: SendXrayArgs<Events[key]>;
                  };
        } = {}
    ) {
        const eventNames = Array.isArray(analyticEventNames) ? analyticEventNames : [analyticEventNames];
        const {
            sendXrayArgs,
            sendPlatformData = true,
            iData: commonIData,
            radarOptions: commonRadarOptions,
            mapEventNames,
            abortIfUnexpectedFeatureValue = true,
            unexpectedFeatureValues = [undefined, null, false],
        } = options;

        const strExpValue = featureValueToString(expValue, abortIfUnexpectedFeatureValue ? unexpectedFeatureValues : []);
        if (typeof strExpValue === 'string') {
            eventNames.forEach((eventName) => {
                const event = events[eventName];
                if (!event) {
                    throw new Error(`Событие ${eventName} не зарегестрировано`);
                }
                const processor = typeof sendXrayArgs === 'function' ? sendXrayArgs : sendXrayArgs?.[eventName];
                const mappedEventName = mapEventNames?.[eventName];
                const eventShortName = mappedEventName ?? event.shortName;
                event.on((args) => {
                    const eventArgs = args as ExtractEventArg<Events[T]>;
                    const {
                        cancel,
                        iData = commonIData,
                        options = commonRadarOptions,
                        eventName: newShortEventName,
                        names = [expName, newShortEventName ?? eventShortName, strExpValue, ...(sendPlatformData ? getPlatformInfo() : [])],
                    } = processor?.(eventArgs, {
                        expName,
                        expValue: strExpValue,
                        eventName: eventShortName,
                        iData: commonIData,
                        radarOptions: commonRadarOptions,
                    }) ?? {
                        cancel: false,
                    };
                    if (!cancel) {
                        analyticSender(names, iData, options);
                    }
                });
            });
        }
    },
});
