import { DiscountType } from 'reactApp/sections/MobileSubscriptionsPage/Discounts/Components/DiscountCard/DiscountCard.types';

export const DISCOUNTS = {
    trial: 'trial',
    promo: 'promo',
    special: 'special',
    promocode: 'promocode',
    summerPromo: 'summerPromo',
} as const;

export const ACTIVATE_PROMOCODE_CARD = {
    id: DISCOUNTS.promocode,
    type: DISCOUNTS.promocode,
    title: 'Используйте промокод',
    text: 'Если у вас есть промокод —\n самое время его активировать',
    link: '/promocodes',
};

export const buttonTextMap: Record<DiscountType, string> = {
    promo: 'Все скидки',
    special: 'Приобрести',
    trial: 'Подключить',
    promocode: 'Ввести промокод',
    summerPromo: 'Забрать скидку',
};
