import { toNDigits } from '@mail/cross-sizes-utils';
import { Rubles } from 'Cloud/Application/Rubles';
import { social } from 'Cloud/Application/Social';
import sha1 from 'js-sha1';
import { pathOr } from 'ramda';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadItem } from 'reactApp/appHelpers/appDownload';
import { HIDE_ADS, IS_PUBLIC_FOLDER } from 'reactApp/appHelpers/configHelpers';
import { mailSpaceTrialPromo } from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams, isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { useIntersectionObserver } from 'reactApp/hooks/useInterSectionObserver';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getWeblinkFromPublicId, isPreviewable } from 'reactApp/modules/file/utils';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { StockFolder } from 'reactApp/modules/stock/stock.type';
import { getCurrentItem, getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { removeViewerPlaceholder } from 'reactApp/modules/viewer/viewer.helpers';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { RootState } from 'reactApp/store';
import { MailspacePublicTrialBanner } from 'reactApp/ui/MailspacePublicTrialBanner/MailspacePublicTrialBanner';
import { MobileViewerItem } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerItem/MobileViewerItem';
import { useCallbacks } from 'reactApp/ui/Mobile/OptionsModal/hooks/useCallbacks';
import { OptionsModalSource } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.constants';
import { renderMobileOptionsModal } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.helpers';
import { sendViewerDwh } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { RollContentAds } from 'reactApp/ui/RollContent/RollContentAds';
import { IFile } from 'reactApp/ui/ViewerToolbar/ViewerToolbar.types';
import { ViewerToolbarMobile } from 'reactApp/ui/ViewerToolbar/ViewerToolbarMobile';
import { canCloneWeblink } from 'reactApp/utils/cloneHelpers';
import { formatDate, formatExpires } from 'reactApp/utils/formatDate';
import { sendXray } from 'reactApp/utils/ga';
import { sendCurrTimeRadar, sendPerfMetrics } from 'reactApp/utils/helpers';

import styles from './PublicFile.css';

// 'public-file' - общая категория событий для белого и черного одиночного паблика
const EVENT_CATEGORY = 'public-file';
// 48 - хедер, 114 - топ баннер рекламы
const DEFAULT_TOP_HEIGHT_HEADER = 48;
const DEFAULT_TOP_HEIGHT = DEFAULT_TOP_HEIGHT_HEADER + 114;

export const PublicFileConnected = memo(({ storage }: { storage: EStorageType }) => {
    const [isVisible, setIsVisible] = useState(false);
    const dispatch = useDispatch();

    const isAuthorized = !useSelector(UserSelectors.isAnonymous);
    const isBiz = useSelector(UserSelectors.isBizUser);
    const isWebview = useSelector(EnvironmentSelectors.isWebview);
    const withMailSpacePromo = mailSpaceTrialPromo && mailSpaceTrialPromo !== 'a';

    const fileOrFolder = useSelector(getCurrentItem) as CloudFile;
    let routeId = useSelector(getCurrentRouteId);
    if (!IS_PUBLIC_FOLDER && storage === EStorageType.stock) {
        routeId = (fileOrFolder as unknown as StockFolder)?.childs[0];
    }
    const file = useSelector((state: RootState) => getStorageItemById(state, storage, routeId)) as CloudFile;

    const params = getQueryParams();

    const item: IFile = {
        id: file?.id,
        name: file?.isFolder ? file?.name : file?.nameWithoutExt ?? '',
        size: toNDigits({ bytes: file?.size, maxDigitsCount: 3 })?.value,
        extension: file?.ext,
        date: pathOr(0, ['mtime'], file),
        expires: pathOr(0, ['weblinkExpires'], file),
    };

    const date = item.date ? formatDate(item.date) : '';
    const expires = item.expires ? formatExpires(item.expires) : '';

    const isPreviewableItem = isPreviewable(file);

    const socialList = social.list.filter((social) => social.id !== 'email');
    const onSocial = (id) => social.publishItem(id, file);

    const sendGA = (action) => {
        sendXray(['publ-viewic-file', action, isAuthorized ? 'user' : 'anon']);
    };
    const sendDwh = useCallback(
        (data) => {
            sendViewerDwh({
                id_media: sha1(file?.id || ''),
                source: `touch-${storage}`,
                is_stories: false,
                id_public: getWeblinkFromPublicId(file?.id) ?? 'None',
                ...data,
            });
        },
        [file, storage]
    );

    useEffect(() => {
        sendPerfMetrics(`mpubfile`);
        sendCurrTimeRadar(`mpubfile_mnt`);

        Rubles.runPhoneTopBannerUpdater();

        if (!isBiz && !isWebview) {
            Rubles.updateMobSplash();
        }
    }, []);

    useEffect(() => {
        if (!file) {
            return;
        }
        removeViewerPlaceholder();
    }, [file]);

    useRouteChangeProcessing({
        storage,
        path: location.pathname.replace(`/${storage}/`, ''),
        search: location.search,
    });

    const onIntersecting = () => {
        setIsVisible(true);
    };
    const onIntersectingOut = () => {
        setIsVisible(false);
    };

    const refIntersection = useIntersectionObserver({
        onIntersecting,
        onIntersectingOut,
        rootMargin: '30px',
    });

    const handleOnClone = useCallback(() => {
        const eventAction = isAuthorized ? 'clone-click' : 'auth-click';

        sendGA(eventAction);

        sendXray([EVENT_CATEGORY, eventAction]);

        if (isAuthorized) {
            toolbarActions.clone({
                id: item.id,
                destination: '',
                source: 'public-files',
            });
        } else {
            dispatch(
                authPopup({
                    closable: true,
                    loginRequest: true,
                    successPage: `${window.location.href}${window.location.search}`,
                })
            );
        }
    }, [isAuthorized, item.id]);

    const { onDownload: onDownloadHook } = useCallbacks({
        storage,
        id: item.id,
        onClose: handleOnClone,
        sendAnalytics: sendGA,
        isAppViewer: params && Boolean(params.is_app_viewer),
        source: OptionsModalSource.PUBLIC_FILE,
    });

    const openToolbarOptions = useCallback(() => {
        renderMobileOptionsModal({
            id: item.id,
            storage,
            onlyActions: true,
            sendAnalytics: sendDwh,
            skipRename: true,
            skipDelete: true,
            source: OptionsModalSource.PUBLIC_FILE,
        });
    }, [item.id, sendDwh, storage]);

    const handleOnDownload = () => {
        const eventAction = 'download-click';
        sendGA(eventAction);

        sendXray([EVENT_CATEGORY, eventAction]);

        onDownloadHook();
    };

    const handleOnSocial = (id) => {
        sendGA('social-click');

        onSocial(id);
    };

    const content = useMemo(() => {
        if (!file) {
            return null;
        }

        return (
            <MobileViewerItem
                isPublic
                selected
                forceArchiveViewer
                itemId={file?.id}
                isSingle={true}
                handleDownload={() => downloadItem({ itemOrId: file, fromViewer: true })}
                storage={storage}
                isViewContentOnly={isViewContentOnly}
                sendAnalytics={sendDwh}
                openToolbarOptions={openToolbarOptions}
                showToolbarInArchive={isViewContentOnly}
            />
        );
    }, [file]);

    const minHeight = `max(calc(var(--dvh, --vh)*100 - ${
        window.scrollY + (HIDE_ADS ? DEFAULT_TOP_HEIGHT_HEADER : DEFAULT_TOP_HEIGHT) + 158
    }px), 50vh)`;

    if (!file) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.content} style={{ minHeight, maxHeight: minHeight }}>
                {content}
            </div>
            {withMailSpacePromo && <MailspacePublicTrialBanner />}
            {!isViewContentOnly && (
                <ViewerToolbarMobile
                    file={item}
                    date={date}
                    expires={expires}
                    isPreviewableItem={isPreviewableItem}
                    canClone={canCloneWeblink(isAuthorized, storage)}
                    socialList={socialList}
                    handleOnClone={handleOnClone}
                    handleOnDownload={handleOnDownload}
                    handleOnSocial={handleOnSocial}
                    noBottomRound={HIDE_ADS}
                />
            )}
            {!HIDE_ADS && !isViewContentOnly && (
                <div className={styles.adsWrapper} ref={refIntersection}>
                    {isVisible && <RollContentAds darkMode showElementIsInViewport />}
                </div>
            )}
        </div>
    );
});

PublicFileConnected.displayName = 'PublicFileConnected';
