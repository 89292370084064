import { requestPayment } from 'Cloud/Application/Payment';
import saveSubsImg from 'img/saveSubs.png';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getProductById } from 'reactApp/modules/products/products.selectors';
import { RootState } from 'reactApp/store';
import { Product } from 'reactApp/types/Billing';
import { getInfoForSaveSubscriptionsPromo } from 'reactApp/ui/Mobile/SplashScreen/helpers/SaveSubscriptionPromo.helpers';
import { ESplashScreenMod, UseContentHook } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.types';

export const useSaveSubscriptionScreen: UseContentHook = ({ hookParams }) => {
    const { productId, source = '', oldSubscriptionSpace, isTransit } = hookParams || {};

    // TODO: если id невалидный, то product будет undefined и всё посыпется. Стоит предусмотреть и убрать as Product
    const product = useSelector((state: RootState) => getProductById(state, productId)) as Product;

    const { text, buttonText, title } = getInfoForSaveSubscriptionsPromo({ product, source, oldSubscriptionSpace, isTransit });

    const onClick = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({ isMobile: true, tariff: product?.id });
    }, [product?.id]);

    return {
        id: isTransit ? 'mobile-save-subs-transit' : 'mobile-save-subs',
        title,
        description: text,
        buttonText,
        onClick,
        img: saveSubsImg,
        mod: ESplashScreenMod.red,
    };
};
