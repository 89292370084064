import React, { useCallback, useEffect, useState } from 'react';
import { animated, useSpringRef, useTransition } from 'react-spring';
import { emitAnalyticEvent } from 'reactApp/appHelpers/experimentAnalytic';
import { AnalyticEventNames } from 'reactApp/appHelpers/experimentAnalytic/eventNames';
import { Product } from 'reactApp/types/Billing';
import styles from 'reactApp/ui/IframePages/BuyDisableAds/BuyDisableAds.css';
import { EmbeddedBuyScreen } from 'reactApp/ui/IframePages/BuyDisableAds/EmbeddedBuyScreen/EmbeddedBuyScreen';
import { sendDwh } from 'reactApp/utils/ga';

function useDisplayTransition(product: Product | null) {
    const transRef = useSpringRef();
    const transform = (open: boolean) => (open ? 'translate3d(100%,0,0)' : 'translate3d(-50%,0,0)');
    const transitions = useTransition(product, {
        ref: transRef,
        keys: null,
        from: { opacity: 1, transform: transform(!!product) },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0.5, transform: transform(!product) },
    });
    useEffect(() => {
        transRef.start();
    }, [product]);
    return transitions;
}

export function useEmbeddedBuyScreen() {
    const [product, setProduct] = useState<Product | null>(null);

    const transitions = useDisplayTransition(product);

    const closeEmbeddedBuy = useCallback(() => setProduct(null), []);

    const openEmbeddedBuy = useCallback((product: Product) => {
        emitAnalyticEvent(AnalyticEventNames.BUY_DISABLE_ADS_MODAL_TARIFF_CLICK, { product });
        sendDwh({ eventCategory: 'tariffs-quota', action: 'modal-noads-click', dwhData: { tariff: product.id } });
        setProduct(product);
    }, []);

    const embeddedContent = (
        <>
            {transitions((style, product) => {
                return (
                    product && (
                        <animated.div style={style} className={styles.content} key={product.id}>
                            <EmbeddedBuyScreen product={product} onClose={closeEmbeddedBuy} />
                        </animated.div>
                    )
                );
            })}
        </>
    );

    return {
        embeddedContent,
        openEmbeddedBuy,
    } as const;
}
