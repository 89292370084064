import axios, { AxiosResponse } from 'axios';
import { downloadDocumentWithoutRedirect } from 'Cloud/Application/Editor/MyOffice/helpers/downloadDocument';
import { downloadDocumentApi } from 'reactApp/api/axios.corsapi';
import { O2Auth } from 'reactApp/api/O2Auth';
import { IS_DOCUMENTS_DOMAIN, USER_EMAIL } from 'reactApp/appHelpers/configHelpers';
import { o2UploadFeature } from 'reactApp/appHelpers/featuresHelpers';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';

type Content = ArrayBuffer | null;

const auth = o2UploadFeature && IS_DOCUMENTS_DOMAIN ? new O2Auth({ clientId: o2UploadFeature.clientId, login: USER_EMAIL }) : undefined;

export const isBuffer = (data: any): data is ArrayBuffer => !!data?.byteLength;

const downloadInBuffer = (url: string): Promise<AxiosResponse<Content>> =>
    axios.get<Content>(url, { responseType: 'arraybuffer', withCredentials: true });

export const getAttachType = (file?: CloudFile): EAttachTypes | undefined => {
    if (!file) {
        return undefined;
    }

    if ('attachType' in file) {
        return file.attachType;
    }

    if (file.storage === EStorageType.stock) {
        return EAttachTypes.cloudStock;
    }

    return undefined;
};

/**
 * Если attach типа temporary, то он может быть также
 * attach, cloud_stock, cloud
 */
const getAttachActualType = (file: CloudFile): EAttachTypes | undefined => {
    const attachType = getAttachType(file);
    if (attachType === EAttachTypes.temporary && 'type' in file) {
        return file.type as EAttachTypes;
    }

    return attachType;
};

const getAttachDownloadUrl = (file: CloudFile, attachType?: EAttachTypes): string | undefined => {
    switch (attachType) {
        case EAttachTypes.temporary:
            return file.url?.downloadAPI;
        case EAttachTypes.cloudStock:
            return file.url?.download;
        case EAttachTypes.cloud:
            return file.url?.get;
        default:
            return file.url?.view;
    }
};

export const downloadDocAttach = async (
    url: string,
    attachType: EAttachTypes,
    attachActualType: EAttachTypes
): Promise<{ error?: unknown; content?: Content }> => {
    switch (attachType) {
        case EAttachTypes.attach:
            return downloadDocumentWithoutRedirect(url, true);
        case EAttachTypes.cloudStock: {
            // сюда попадают и стоки stock
            const { data: content } = await downloadInBuffer(url);
            return { content };
        }
        case EAttachTypes.cloud: {
            const { data: response } = await axios.get(url, {
                withCredentials: true,
                params: { no_redirect: true },
            });

            const { data: file } = await downloadInBuffer(response.redirect);
            return { content: file };
        }
        case EAttachTypes.temporary: {
            if (attachActualType === EAttachTypes.cloudStock) {
                const { data: content } = await downloadInBuffer(url);
                return { content };
            }

            const { data: content } = await downloadDocumentApi(url);
            return { content };
        }
    }
};

const getAttachContent = async (
    url: string,
    type: EAttachTypes,
    actualType: EAttachTypes
): Promise<{ error?: unknown; content?: Content }> => {
    const { content, error } = await downloadDocAttach(url, type, actualType);

    if (error || !content) {
        return { error: error || new Error('no_content') };
    }

    if (!isBuffer(content)) {
        return { error: new Error('downl_err_resp') };
    }

    return { content };
};

/**
 * Общая функция скачивания любого док аттача
 * Скачивает в arraybuffer
 */
export const downloadAttach = async (file: CloudFile): Promise<{ content?: Content; error?: unknown }> => {
    const attachType = getAttachType(file);
    const attachActualType = getAttachActualType(file);

    if (!attachType || !attachActualType) {
        return { error: new Error('no_atatch_type') };
    }

    const downloadUrl = getAttachDownloadUrl(file, attachType);

    if (!downloadUrl) {
        return { error: new Error('no_download_url') };
    }

    return getAttachContent(downloadUrl, attachType, attachActualType);
};

/**
 * Функция скачивания документа через О2 на домене документов
 */
const downloadDocDomainDocument = async (url: string, secondTry = false) => {
    if (auth) {
        try {
            const token = await auth.getToken();

            const { data: response } = await axios.get(url, {
                headers: O2Auth.prepareAuthHeader(token),
                params: { no_redirect: true },
            });

            const { data: file } = await downloadInBuffer(response.redirect);

            return file;
        } catch (error: any) {
            if (error.status === 403 && !secondTry) {
                await auth.refreshToken();
                return await downloadDocDomainDocument(url, true);
            }

            throw new Error('Getting download url by O2 failed.');
        }
    }
};

/**
 * Функция скачивания стока
 */
const downloadStock = async (url: string) => {
    try {
        const { data } = await axios.get<Content>(url, {
            withCredentials: true,
            params: { no_redirect: true },
            responseType: 'arraybuffer',
        });

        return data;
    } catch (error: any) {
        throw new Error(error);
    }
};

/**
 * Функция скачивания обычного документа
 */
const downloadBasicDocument = async (url: string) => {
    try {
        const { data: response } = await axios.get(url, {
            withCredentials: true,
            params: { no_redirect: true },
        });

        const { data: file } = await downloadInBuffer(response.redirect);

        return file;
    } catch (error: any) {
        throw new Error(error);
    }
};

/**
 * Общая функция скачивания док аттачей + облачных доков
 * Не умеет в некоторые типы аттачей
 * Для поддержки всех типов аттачей @see downloadAttach
 */
export const downloadDocument = async (
    url: string,
    isStock = false,
    radars?: { onError: () => void; onSuccess: () => void }
): Promise<{ content?: ArrayBuffer; error?: unknown }> => {
    try {
        let content;

        if (isStock) {
            content = await downloadStock(url);
        } else if (auth) {
            content = await downloadDocDomainDocument(url);
        } else {
            content = await downloadBasicDocument(url);
        }

        radars?.onSuccess();

        return { content };
    } catch (error: any) {
        radars?.onError();

        return { error };
    }
};

export const downloadDocumentEmbedded = async (url: string, options?: { radars: { onError: () => void; onSuccess: () => void } }) => {
    try {
        const { data: content } = await axios.get<Content>(url, { responseType: 'arraybuffer' });
        options?.radars?.onSuccess();
        return { content };
    } catch (error: any) {
        options?.radars?.onError();
        return { error };
    }
};
