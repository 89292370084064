export const ITEM_MIN_PADDING = 4;
export const ITEM_MIN_PADDING_PHONE = 12;
export const ITEM_VERTICAL_PADDING = 8;
export const ITEM_VERTICAL_PADDING_INLINE_INTEGRATION = 4;
export const GRID_ITEM_WIDTH = 168;
export const GRID_ITEM_HEIGHT = 200;
export const ROW_HEIGHT = 35;
export const PHONE_MAX_WIDTH = 720;
export const LARGE_PHONE_MIN_WIDTH = 480;
export const TOOLBAR_HEIGHT = 65;
export const SCROLL_GAP = 10;
export const DIVIDER_HEIGHT = 57;
export const GALLERY_TYPES_COUNT = 3;
export const GALLERY_BLOCK_HEIGHT_RATIO = 0.45;
export const SQUARES_ITEM_SIZE = 170;
export const SQUARES_ITEM_SIZE_180 = 180;
export const SQUARES_ITEM_MIN_PADDING = 2;
export const ITEM_HEIGHT_RESPONSIVE = 64;
export const INCOMING_ITEM_HEIGHT_RESPONSIVE = 86;
export const HIDDEN_ITEM_HEIGHT = 0;
export const COMPENSATE_SCROLL_PADDING = 8;
