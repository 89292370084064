import throttle from 'lodash.throttle';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { DatalistBanner } from 'reactApp/ui/AdvBanners/DatalistBanner/DatalistBanner';
import { UploadProvocation } from 'reactApp/ui/Datalist/UploadProvocation/UploadProvocation';
import { RESIZE_THROTTLE_TIME_MS } from 'reactApp/ui/Datalist/UploadProvocation/UploadProvocation.constants';
import { IProvocation } from 'reactApp/ui/Datalist/UploadProvocation/UploadProvocation.types';
import { Footer } from 'reactApp/ui/Footer/Footer';

interface IProps {
    itemsCount: number;
}

export const ExtraBlocks = memo(({ itemsCount }: IProps) => {
    const provocationRef = useRef<IProvocation | null>(null);
    const isProvocationShown = Boolean(provocationRef?.current?.canShow());
    // Если реклама уже показана, то в даталисте занято место под нее.
    // Чтобы при удалении файлов UploadProvocation на него не становился, добавлен этот стейт
    const [adShown, setAdShown] = useState(false);
    // Проверку на рекламу можно делать только после того, как асинхронно отработает проверка на аплоад, иначе реклама может влезть первой
    const [isProvocationChecked, setProvocationChecked] = useState(false);

    const storage = useSelector(getCurrentStorage);
    const { isHome, isPublicOrStock } = getStorage(storage);
    const viewMode = useSelector((state) => SettingsSelectors.getViewByStorage(state, storage));

    const handleOnChecked = useCallback((isChecked: boolean) => {
        setProvocationChecked(isChecked);
    }, []);

    const onResize = useCallback(() => {
        if (!isHome) {
            // Не в хомяке UploadProvocation нет, там только реклама, она в даталисте не зависит от размеров
            return;
        }
        setProvocationChecked(false);
        setAdShown(false);
    }, [isHome]);

    const handleOnResize = useCallback(throttle(onResize, RESIZE_THROTTLE_TIME_MS), [onResize]);

    const handleOnBannerShow = useCallback(() => {
        setAdShown(true);
    }, []);

    useEffect(() => {
        window.addEventListener?.('resize', handleOnResize);

        return () => {
            window.removeEventListener?.('resize', handleOnResize);
        };
    }, [handleOnResize]);

    useEffect(() => {
        // При ресайзе или изменении параметров, надо пересчитать заново весь цикл: сначала проверить, влазит ли UploadProvocation, потом рекламу
        // Это важно для автотестов - показывается реклама, удаляется один элемент даталиста, и теперь показывается провокация
        onResize();
    }, [viewMode, itemsCount, onResize]);

    const isAbsolutePos = !(isProvocationShown && provocationRef.current?.canShow()) && (!adShown || isPublicOrStock);

    return (
        <>
            {isHome && !adShown && <UploadProvocation ref={provocationRef} itemsCount={itemsCount} onChecked={handleOnChecked} />}
            {!isProvocationShown && (!isHome || isProvocationChecked) && (
                <DatalistBanner itemsCount={itemsCount} onShow={handleOnBannerShow} />
            )}
            <Footer
                isAbsolutePos={isAbsolutePos}
                right={isAbsolutePos ? 8 : undefined}
                bottom={isAbsolutePos ? 3 : undefined}
                storage={storage}
            />
        </>
    );
});

ExtraBlocks.displayName = 'ExtraBlocks';
