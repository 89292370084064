import { ClientError } from './ClientError';

class CancelError extends ClientError {
    static radarName = 'failure_{SOURCE}_cancel';

    className = 'CancelError';
    message = 'Запрос отменен';
}

export { CancelError };
