import React, { FC, memo, RefObject, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { MobileAppPromoType } from 'reactApp/modules/promo/promoMobileApp/promoMobileApp.types';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { renderPopupMobileAppQR } from 'reactApp/ui/PopupMobileAppQR/PopupMobileAppQR.helpers';
import { sendDwh } from 'reactApp/utils/ga';

interface Props {
    isSinglePublic?: boolean;
    elementRef: RefObject<HTMLDivElement | null>;

    onShow?(): void;

    onClose?(): void;

    onClick?(): void;
}

export const MobileAppPromoTooltip: FC<Props> = memo(({ elementRef }) => {
    const [show, setShow] = useState(false);
    const promoData = useSelector(PromoSelectors.pullPromo(EPromoType.mobileAppPromo));

    useEffect(() => {
        if (elementRef?.current && promoData && promoData.tipType?.includes(MobileAppPromoType.attaches)) {
            setTimeout(() => setShow(true), 1500);
        }
    }, [promoData, elementRef?.current]);

    if (!show || !promoData) {
        return null;
    }

    return (
        <FloatingTooltip
            target={elementRef?.current}
            title="Скачайте Облако на смартфон"
            text={
                <span data-qa-id="tip-text">
                    С приложением доступ к вашим
                    <br />
                    файлам будет постоянно под рукой
                </span>
            }
            buttonText="Скачать приложение"
            placement={ETooltipPlacement.bottom}
            closeOnOutsideClick={false}
            qaId="mobile-app-promo-tip"
            appearance={ETooltipAppearance.dark}
            onClick={() => {
                promoData.onClose();
                setShow(false);
                renderPopupMobileAppQR({ isDark: true, url: 'https://trk.mail.ru/c/d68sz6' });
            }}
            onShow={() => {
                promoData.onShow();
                promoData.onMarkShown(MobileAppPromoType.attaches);

                sendDwh({
                    eventCategory: 'web_to_mobile',
                    label: 'install_tooltips',
                    dwhData: {
                        from: 'attaches',
                    },
                });
            }}
            isAlternativeButton
            onClose={() => promoData.onClose()}
            onTooltipClick={(event) => event.stopPropagation()}
        />
    );
});

MobileAppPromoTooltip.displayName = 'MobileAppPromoTooltip';
