/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { getFeatureParam } from 'Cloud/Application/FeaturesEs6';
import { EHashLibType } from 'Cloud/webWorkers/types';
import { logger } from 'lib/logger';
import throttle from 'lodash.throttle';
import { HttpErrorCodes } from 'reactApp/api/HttpErrorCodes';
import { O2Auth } from 'reactApp/api/O2Auth';
import { UploadResumableCheckAPICall } from 'reactApp/api/UploadResumableCheckAPICall';
import { IS_ONPREMISE, USER_EMAIL, X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { isHashCalcLibCrypto, isHashCalcLibWasm, o2UploadFeature } from 'reactApp/appHelpers/featuresHelpers';
import { EMPTY_FILE_HASH } from 'reactApp/constants/magicIdentificators';
import { getDomainFolderQuotaFree } from 'reactApp/modules/domainFolders/domainFolders.selectors';
import { FILE_READ_BLOCK_LENGTH, isUploadResumeAvailable, MAX_SIZE_FOR_WASM_HASH_LIB } from 'reactApp/modules/features/features.helpers';
import { HashCalculator } from 'reactApp/modules/file/hashCalculator';
import { getMountedFolderQuotaFree } from 'reactApp/modules/home/home.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { FileTooLargeError } from 'reactApp/modules/uploading/errors/FileTooLargeError';
import { HttpError } from 'reactApp/modules/uploading/errors/HttpError';
import { WrongResultError } from 'reactApp/modules/uploading/errors/WrongResultError';
import { ConnectionFail } from 'reactApp/modules/uploading/fails/ConnectionFail';
import { FileReaderError } from 'reactApp/modules/uploading/fails/FileReaderError';
import { FileWithoutHashFail } from 'reactApp/modules/uploading/fails/FileWithoutHashFail';
import { HashCalcError } from 'reactApp/modules/uploading/fails/HashCalcError';
import { IllegalContentFail } from 'reactApp/modules/uploading/fails/IllegalContentFail';
import { OverQuotaFail } from 'reactApp/modules/uploading/fails/OverQuotaFail';
import { UnsupportedFolderTransferFail } from 'reactApp/modules/uploading/fails/UnsupportedFolderTransferFail';
import { parseBackendResponseTextOfPost, parseBackendResponseTextOfPut } from 'reactApp/modules/uploading/helpers/backend.helpers';
import {
    checkFileSizeLessThanPublicLimit,
    checkFileSizeLessThanUserLimit,
} from 'reactApp/modules/uploading/helpers/cloudFs/cloudFs.helpers';
import {
    FILE_WITHOUT_HASH_MAX_SIZE,
    getFullBody,
    getHexCode,
    isEdge,
    isFirefox,
    MAX_READABLE_SIZE,
} from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { readFile } from 'reactApp/modules/uploading/helpers/fs/readFile';
import { readFileContent } from 'reactApp/modules/uploading/helpers/fs/readFileContent';
import {
    getWeightedMovingAverage,
    isRetryableHttpStatus,
    isWasmSupported,
    sendGaUploaderNew,
} from 'reactApp/modules/uploading/helpers/uploading.helpers';
import {
    getPerfDiffInMs,
    getWaitTimeFromHeader,
    isCancelableXhrState,
    MB,
    sendMetricsToDwh,
} from 'reactApp/modules/uploading/serviceClasses/helpers';
import { UploaderData } from 'reactApp/modules/uploading/serviceClasses/UploaderData';
import { UploadingCancel } from 'reactApp/modules/uploading/serviceClasses/UploadingCancel';
import { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { EUploadingState, EUploadingType } from 'reactApp/modules/uploading/uploading.types';
import { EFileError, EFileStatus, EProgressStatus, IUpdateInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import { setProgressStatusAction, updateTotalProgress, updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';
import { getTrimmedText } from 'reactApp/utils/textHelpers';
import { isDocumentsDomain } from 'server/helpers/isDocumentsDomain';

const urlBuilder = new UrlBuilder();

const UPLOAD_API_POLLING_TIME = 4 * 60 * 60 * 1000;
const UPLOAD_API_PING_INTERVAL = 5000;

let TEMP_MAX_FILESIZE_FOR_WASM_LIB = MAX_SIZE_FOR_WASM_HASH_LIB;
if (TEMP_MAX_FILESIZE_FOR_WASM_LIB) {
    // @ts-ignore
    const devMemory = navigator?.deviceMemory || 0;
    if (devMemory < 4) {
        TEMP_MAX_FILESIZE_FOR_WASM_LIB = 1000 * MB;
    } else if (devMemory < 6) {
        TEMP_MAX_FILESIZE_FOR_WASM_LIB = 3000 * MB;
    } else if (devMemory < 8) {
        TEMP_MAX_FILESIZE_FOR_WASM_LIB = 5000 * MB;
    }
}

/**
 * HTTP-метод загузки файла (PUT или POST).
 * CLOUDWEB-7724: в Firefox 56 обнаружились проблемы с PUT,
 * поэтому добавлен специальный параметр фичи.
 */
const HTTP_METHOD = getFeatureParam('newUpload', isFirefox ? 'firefoxMethod' : 'method', 'POST');

/**
 * Время задержки между EVENT_PROGRESS (в миллисекундах).
 */
const PROGRESS_DELAY = getFeatureParam('newUpload', 'progressDelay', 300);

const uploaderUploadSpeedThrottled = throttle((speedMbSec: number) => {
    store.dispatch(updateTotalProgress({ speedMbSec }));
}, 2500);

export class Uploader {
    public descriptor: UploadingDescriptor;
    public _metrics: Record<string, number> = {};

    private _o2Auth =
        o2UploadFeature && isDocumentsDomain(window.location.hostname)
            ? new O2Auth({ clientId: o2UploadFeature.clientId, login: USER_EMAIL })
            : null;

    private _axios: UploadResumableCheckAPICall | null = null;
    private _timeOutStep: string | null = '';
    private _timeOutTimerId: any;
    private _isUserCanceled = false;

    // Это нужно для связи с ивентами от xhr
    private promise?: Promise<UploaderData>;

    // Резолвит промис, который возвращает метод upload.
    private _resolve?(value: UploaderData): void;

    // Реджектит промис, который возвращает метод upload.
    private _reject?(value: UploaderData): void;

    constructor(descriptor: UploadingDescriptor) {
        this.descriptor = descriptor;
    }

    /**
     * Адрес сервера для загрузки файла, полученный от балансера.
     */
    private _url = '';

    private _onXhrProgressCallTime = 0;

    private _uploadStartTime = -1;

    private _xhr;

    private _getData = () => {
        const xhr = this._xhr;
        const params: any = {
            url: this._url,
        };

        if (xhr) {
            params.status = xhr.status;
            params.responseText = xhr.responseText;
        }

        return new UploaderData(params);
    };

    /// XHR event processing
    _onXhrAbort = (_: Event | null, data: UploaderData) => {
        const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
        const stack = new Error('UploadingCancel');
        data.error = new UploadingCancel(stack, source);
        this._fail(data);
    };

    _onXhrError = (_: Event | null, data: UploaderData) => {
        const status = data.status;
        const source = EUploadReasonSource.SOURCE_BACKEND;
        let stack;
        let reason;
        let error;

        if (status === HttpErrorCodes.ILLEGAL) {
            stack = new Error('IllegalContentFail');
            reason = new IllegalContentFail(stack, source);
        } else if (status === HttpErrorCodes.TOO_LARGE) {
            stack = new Error('FileTooLargeError');
            reason = new FileTooLargeError(stack, source);
        } else if (status && !isRetryableHttpStatus(status)) {
            stack = new Error('HttpError');
            reason = new HttpError(stack, source, status, data.responseText);
        } else {
            stack = new Error('ConnectionFail');
            reason = new ConnectionFail(stack, source);
            error = EFileError.CONNECTION_ERROR;
        }

        data.error = reason;

        const descriptor = this.descriptor;

        if (status === 0 && (descriptor.state === EUploadingState.STATE_CANCEL || this._isUserCanceled)) {
            return;
        }

        this._fail(data);

        sendGaUploaderNew('file', error);

        if (descriptor) {
            store.dispatch(
                updateUploadFilesAction({
                    descriptorId: descriptor.id,
                    status: error === EFileError.CONNECTION_ERROR ? EFileStatus.PAUSED : EFileStatus.ERROR,
                    error,
                })
            );
        }
    };

    _onXhrLoad = (_: Event | null, data: UploaderData) => {
        const HTTP_OK = 200;
        const HTTP_CREATED = 201;
        const status = data.status;

        if (status === HTTP_OK || status === HTTP_CREATED) {
            const responseText = data.responseText;
            let responseData;

            if (HTTP_METHOD === 'POST') {
                responseData = parseBackendResponseTextOfPost(responseText);
            } else {
                responseData = parseBackendResponseTextOfPut(responseText);
                responseData.size = this.descriptor.file?.size;
            }

            Object.assign(data, responseData);

            const error = responseData.error;

            if (error) {
                this._fail(data);
            } else {
                const kilobytes = data.size / 1024;
                const seconds = (Date.now() - this._uploadStartTime) / 1000;

                data.speed = parseFloat((kilobytes / seconds).toFixed(1));
                this._done(data);
            }
        } else {
            this._onXhrError(null, data);
        }
    };

    _onXhrProgress(event: ProgressEvent, data: UploaderData) {
        const now = Date.now();

        if (now - this._onXhrProgressCallTime > PROGRESS_DELAY) {
            this._onXhrProgressCallTime = now;

            const total = data.size || event.total;
            const loaded = event.loaded + (data.startBytes || 0);
            let progress;

            if (total && loaded) {
                const loadedPercent = Math.round((loaded * 100) / total);
                progress = Math.min(loadedPercent, 100);
            } else {
                progress = 0;
            }

            data.progress = progress;
            data.loaded = loaded;

            this.handleProgress(data);
        }
    }

    handleEvent = (event: Event | ProgressEvent) => {
        const promise = new Promise((resolve) => {
            const data = this._getData();

            switch (event.type) {
                case EUploadingType.abort:
                    this._onXhrAbort(event, data);

                    break;

                case EUploadingType.error:
                    this._onXhrError(event, data);

                    break;

                case EUploadingType.load:
                    this._onXhrLoad(event, data);

                    break;

                case EUploadingType.progress:
                    this._onXhrProgress(event as ProgressEvent, data);

                    break;
            }

            resolve(null);
        });

        promise.catch((error) => {
            const data = this._getData();

            data.error = error;
            this._fail(data);
        });
    };

    _listenXhr = (xhr: XMLHttpRequest) => {
        xhr.addEventListener('abort', this);
        xhr.addEventListener('error', this);
        xhr.addEventListener('load', this);
        xhr.upload.addEventListener('progress', this);
    };

    _stopListeningXhr = (xhr: XMLHttpRequest) => {
        if (typeof xhr.removeEventListener !== 'function') {
            return;
        }

        xhr.removeEventListener('abort', this);
        xhr.removeEventListener('error', this);
        xhr.removeEventListener('load', this);
        xhr.upload.removeEventListener('progress', this);
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    uploaderTotalProgressThrottled = throttle((item: IUpdateInputFile | IUpdateInputFile[]) => {
        // Прогресс может прилететь уже после обработки завершения аплоада, так что игнорим.
        const { state } = this.descriptor;
        if (
            uploadingService.isUserCanceled ||
            state === EUploadingState.STATE_CANCEL ||
            state === EUploadingState.STATE_DONE ||
            state === EUploadingState.STATE_PAUSED ||
            this.descriptor.hasCanceledParent()
        ) {
            this.uploaderTotalProgressThrottled.cancel();
            return;
        }

        store.dispatch(setProgressStatusAction({ status: EProgressStatus.PROGRESS }));

        store.dispatch(updateUploadFilesAction(item));
    }, 500);

    // Events for descriptor and UI
    handleProgress(data: UploaderData) {
        this.descriptor.progress = data.progress;

        if (this.descriptor.error instanceof ConnectionFail) {
            this.descriptor.error = null;
        }

        if (this.descriptor.state === EUploadingState.STATE_CANCEL) {
            this.uploaderTotalProgressThrottled.cancel();
            return;
        }

        const diffLoaded = data.loaded - this.descriptor.loaded;

        this.descriptor.loaded = data.loaded;

        const newUploadedBytes = this.descriptor.loaded - this.descriptor.previouslyUploadedBytes;

        let timeRemain;

        const now = performance.now();
        const speed = this.descriptor.loaded > 1 ? ((newUploadedBytes / 1024 / 1024) * 1000) / (now - this.descriptor.startTime) : 0;
        if (speed > 0) {
            this.descriptor.speed = getWeightedMovingAverage(this.descriptor.speed || speed, speed);
            timeRemain = Math.round((this.descriptor.size - this.descriptor.loaded) / 1024 / 1024 / this.descriptor.speed);
        }

        this.descriptor.uploadingPacketConfig.currentProgressBytes += diffLoaded;

        const speedPacket =
            ((this.descriptor.uploadingPacketConfig.currentProgressBytes / 1024 / 1024) * 1000) /
            (Date.now() - this.descriptor.uploadingPacketConfig.startTime);

        if (this.descriptor.uploadingPacketConfig.currentProgressBytes && speedPacket > 0) {
            uploadingService.uploadPacketSpeedMbytesSec = getWeightedMovingAverage(
                uploadingService.uploadPacketSpeedMbytesSec,
                speedPacket
            );

            uploaderUploadSpeedThrottled(uploadingService.uploadPacketSpeedMbytesSec);
        }

        this.uploaderTotalProgressThrottled({
            descriptorId: this.descriptor.id,
            cloudPath: this.descriptor.cloudPath,
            progress: data.progress,
            loaded: data.loaded,
            status: EFileStatus.PROGRESS,
            timeRemain,
            error: '',
        });
    }

    async _makeRequest(body: File | FormData) {
        const descriptor = this.descriptor;
        if (descriptor) {
            descriptor.startTime = performance.now();
        }

        if (!isUploadResumeAvailable || HTTP_METHOD === 'POST') {
            const xhr = new XMLHttpRequest();

            this._xhr = xhr;

            xhr.open(HTTP_METHOD, this._url);
            xhr.withCredentials = true;
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            this._listenXhr(xhr);
            this._uploadStartTime = Date.now();

            xhr.send(body);
            return;
        }

        // http://cloud.pages.gitlab.corp.mail.ru/api/uploader

        // 1. check file status on the server
        this._axios = null;

        const size = 'size' in body ? body.size : 0;

        let receivedBytes = 0;
        let recheckUploadCompleteTimeMs = 0;
        let url = this._url;
        try {
            this._axios = new UploadResumableCheckAPICall();
            const O2Headers = o2UploadFeature ? await this._o2Auth?.getHeaters() : {};
            const res = await this._axios.makeRequest(null, {
                url,
                headers: {
                    'Content-Range': `bytes */${size}`,
                    ...O2Headers,
                },
            });

            switch (res.status) {
                case 200: // partialy uploaded
                    receivedBytes = Number.parseInt(res.headers['x-received'] || '0');
                    descriptor.previouslyUploadedBytes = receivedBytes;
                    url = res.headers['x-location'] || url;
                    sendGaUploaderNew('waspartialy-upldd');

                    break;
                case 201: {
                    // uploaded. (Client should confirm ownship?)
                    // continue to add file
                    this._xhr = res;
                    const data = this._getData();
                    data.responseText = this.descriptor.hash;
                    descriptor.previouslyUploadedBytes = size;
                    this._onXhrLoad(null, data);
                    sendGaUploaderNew('wasprvs-upldd');

                    sendMetricsToDwh(this, size, size);

                    return;
                }
                case 202: {
                    // uploaded and is moving to storage, recheck it
                    this._xhr = res;
                    const data = this._getData();
                    data.responseText = this.descriptor.hash;
                    descriptor.previouslyUploadedBytes = size;
                    recheckUploadCompleteTimeMs = getWaitTimeFromHeader(res.headers['x-wait-for']);

                    break;
                }
            }
        } catch (error: any) {
            switch (error.status) {
                case 404: // file not found on the sever
                    break;
                case 403: // O2 Token expired
                    this._o2Auth?.refreshToken();
                    break;
                case 400: // upload error
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    logger.error('upload check status error: ', 400, error.headers ? error.headers['x-upload-error'] : '');
                    if (error.headers && error.headers['x-upload-error']) {
                        sendGaUploaderNew(`upld_err_${error.headers['x-upload-error']}`);
                    }
                    this._onXhrAbort(null, new UploaderData({}));
                    return;
                default:
                    logger.error('upload check status error: ', error, error.status);

                    if (!window.navigator.onLine || !error.status) {
                        this._xhr = error;
                        const data = this._getData();
                        this._onXhrError(null, data);
                        sendGaUploaderNew('uploadchk_err_0');
                    } else {
                        this._onXhrAbort(null, new UploaderData({}));
                    }
                    return;
            }
        }

        // 2. upload file
        let warning504 = false;
        if (recheckUploadCompleteTimeMs <= 0) {
            let invalidToken = false;
            do {
                this._uploadStartTime = Date.now();

                try {
                    this._axios = new UploadResumableCheckAPICall();
                    const O2Headers = o2UploadFeature ? await this._o2Auth?.getHeaters() : {};
                    const res = await this._axios.makeRequest(receivedBytes ? (body as File).slice(receivedBytes) : body, {
                        url,
                        headers: {
                            'Content-Range': `bytes ${receivedBytes}-${size - 1}/${size}`,
                            ...O2Headers,
                        },
                        onUploadProgress: (event?) => {
                            this._xhr = res;
                            const data = this._getData?.();
                            data.startBytes = receivedBytes;
                            data.size = size;
                            this._onXhrProgress?.(event, data);
                        },
                    });

                    sendGaUploaderNew(`uploadfinish_succ_${res.status}`);

                    if (res.status === 202) {
                        recheckUploadCompleteTimeMs = getWaitTimeFromHeader(res.headers['x-wait-for']);
                    }

                    this._xhr = res;
                    invalidToken = false;
                } catch (error: any) {
                    if (error.status === 403 && !invalidToken) {
                        invalidToken = true;
                        this._o2Auth?.refreshToken();
                    } else {
                        invalidToken = false;
                        if (error.status === 400) {
                            sendGaUploaderNew('uploadfinish_err');
                        }

                        sendGaUploaderNew(`uploadfinish_err_${error.status}`);

                        if (error.status === 504) {
                            recheckUploadCompleteTimeMs = getWaitTimeFromHeader();
                            sendGaUploaderNew('warning-504');
                            warning504 = true;
                        } else {
                            logger.error('upload error: ', error.status, error.headers ? error.headers['x-upload-error'] : '');
                            if (error.headers && error.headers['x-upload-error'] && descriptor?.state !== EUploadingState.STATE_CANCEL) {
                                sendGaUploaderNew(`upld_err2_${error?.headers['x-upload-error']}`);
                                sendKaktamLog({
                                    text: `upload error:${error}`,
                                    // @ts-ignore
                                    memoryAvailableGb: navigator?.deviceMemory,
                                    // @ts-ignore
                                    heapSize: performance?.memory?.jsHeapSizeLimit,
                                    fileSize: size,
                                    hash: this.descriptor?.hash,
                                    id: this.descriptor?.id,
                                    pageId: X_PAGE_ID,
                                });
                            }

                            this._xhr = error;
                            const data = this._getData();
                            this._onXhrError(null, data);

                            return;
                        }
                    }
                }
            } while (invalidToken);
        }

        this._metrics.putTime = this._uploadStartTime ? getPerfDiffInMs(this._uploadStartTime) : 0;

        // 3. continue to file add
        const pollStartTime = Date.now();
        if (recheckUploadCompleteTimeMs > 0) {
            const pollStartTime = performance.now();
            let radarSent = false;
            let invalidToken = false;
            for (let i = 0; i < UPLOAD_API_POLLING_TIME / UPLOAD_API_PING_INTERVAL; i++) {
                try {
                    if (performance.now() - pollStartTime > 5 * 60 * 1000 && !radarSent) {
                        radarSent = true;
                        sendGaUploaderNew('check-warning-5m');
                    }

                    this._axios = new UploadResumableCheckAPICall();
                    const O2Headers = o2UploadFeature ? await this._o2Auth?.getHeaters() : {};

                    const res = await this._axios.makeRequest(null, {
                        url,
                        headers: {
                            'Content-Range': `bytes */${size}`,
                            ...O2Headers,
                        },
                    });

                    if (res.status === 201) {
                        // uploaded. (Client should confirm ownship?)
                        // continue to add file
                        if (warning504) {
                            // После 504 (таймаут не беке) проверка показала, что файл все-таки был успешно загружен
                            sendGaUploaderNew('warning-504-loaded');
                        }
                        this._xhr = res;
                        break;
                    } else if (res.status === 202) {
                        if (i >= UPLOAD_API_POLLING_TIME / UPLOAD_API_PING_INTERVAL) {
                            sendGaUploaderNew('check-timeout');
                            logger.error(`cannot get 201 status during ${UPLOAD_API_POLLING_TIME}`);
                            this._onXhrAbort(null, new UploaderData({}));
                            break;
                        }
                        await new Promise((resolve) => setTimeout(resolve, UPLOAD_API_PING_INTERVAL));
                    }
                } catch (err: any) {
                    if (err.status === 403 && !invalidToken) {
                        invalidToken = true;
                        this._o2Auth?.refreshToken();
                    } else {
                        logger.error('upload check status error: ', err.status);
                        this._onXhrAbort(null, new UploaderData({}));
                        return;
                    }
                }
            }
        }

        this._metrics.pollTime = getPerfDiffInMs(pollStartTime);

        sendMetricsToDwh(this, size, receivedBytes);

        const data = this._getData();
        data.responseText = this.descriptor.hash;
        this._onXhrLoad(this._xhr, data);

        this._axios = null;
    }

    _timeout = () => {
        sendGaUploaderNew('timeout', 'error', { message: this._timeOutStep || '' });
        sendKaktamLog({ message: this._timeOutStep || '' });
    };

    stopTimeoutTimer = () => {
        if (this._timeOutTimerId) {
            clearTimeout(this._timeOutTimerId);
            this._timeOutTimerId = null;
            this._timeOutStep = null;
        }
    };

    _upload = async () => {
        this._timeOutTimerId = setTimeout(this._timeout.bind(this), 30000);

        try {
            const file = this.descriptor.file;
            const config = this.descriptor.uploadingPacketConfig;

            if (!file) {
                return;
            }

            // Проверяет, что файл до сих пор доступен в локальной ФС.
            // в edge ограниченое кол-во считываний entry и fileread
            if (isEdge && !file.type) {
                const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
                const stack = new Error('UnsupportedFolderTransferFail');
                throw new UnsupportedFolderTransferFail(stack, source);
            } else if (!isEdge && file.size < MAX_READABLE_SIZE) {
                // Выполняем проверку только для небольших файлов,
                // чтобы не получить исключение "out of memory".
                await readFile(file);
                this._timeOutStep = 'readFile';
            }

            // Проверяет, удовлетворяет ли файл ограничениям Облака:
            // • Не больше 32GB.
            // • Не превышает лимит пользователя (2GB для бесплатного тарифа).
            // • Не превышает значение `upload_limit` для пабликов с кошарингом
            let size;
            const isPublic = this.descriptor.uploadingPacketConfig.storage === EStorageType.public;
            if (isPublic) {
                this._timeOutStep = 'checkFileSizeLessThanPublicLimit';
                size = checkFileSizeLessThanPublicLimit(file.size, config.publicUploadLimit);
            } else {
                // Проверяет, удовлетворяет ли файл ограничениям браузера:
                // • Не больше 4GB в IE.uploader._timeOutStep = 'checkFileSizeLessThanLimit';
                size = checkFileSizeLessThanUserLimit(file.size, config.userFileSizeLimit);
            }

            // Проверяет, достаточно ли места в Облаке.
            // На пабликах проверяем квоту на бекенде, ибо файлы грузятся в облако хозяина, а доступа к ней мы не имеем
            if (!isPublic) {
                this._timeOutStep = 'checkEnoughSpace';
                this.checkEnoughSpace(size);
            }

            // Для файлов размером меньше 21 байт
            // вместо hash в базу помещается байткод.
            if (size <= FILE_WITHOUT_HASH_MAX_SIZE) {
                const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
                const stack = new Error('FileWithoutHashFail');
                throw new FileWithoutHashFail(stack, source);
            }

            // Получает от балансировщика адрес сервера для загрузки файла.
            let url = this._url;
            if (!url) {
                url = urlBuilder.upload();
                this._timeOutStep = 'getUploadUrl';
            }
            this._url = url;

            // Получает данные файла.
            this._timeOutStep = 'getFullBody';

            const body = getFullBody(file, HTTP_METHOD);

            this._metrics = {
                diskReadTime: 0,
                hashCalcTime: 0,
                startTime: Date.now(),
            };

            // Вычисляем хеш файла на клиенте для нового апи аплоада
            if (isUploadResumeAvailable && size > FILE_WITHOUT_HASH_MAX_SIZE) {
                const hash = this.descriptor.hash || (await this.calculateHash(file, size));

                if (this._isUserCanceled) {
                    return;
                }

                this._url = this._url.replace('[HASH]', hash ?? '');
                this.descriptor.hash = hash;
            }

            if (this._isUserCanceled) {
                return;
            }

            // Выполняет загрузку файла.
            this.stopTimeoutTimer();
            await this._makeRequest(body);
        } catch (error: any) {
            this.stopTimeoutTimer();

            const errorText = error.toString() || '';

            if (errorText.toLowerCase().includes('memory')) {
                sendXray('upld-err-hash-mem');
                // eslint-disable-next-line max-lines
                sendKaktamLog({
                    text: `hash calc exception:${error}`,
                    error, // @ts-ignore
                    memoryAvailableGb: navigator?.deviceMemory,
                    // @ts-ignore
                    heapSize: performance?.memory?.jsHeapSizeLimit,
                    fileSize: this.descriptor.size,
                    id: this.descriptor.id,
                    pageId: X_PAGE_ID,
                });
            }

            if (errorText.includes('FileReader') && errorText.includes('ReferenceError')) {
                // Мы в режиме lockdown в iOS/Mac, скорее всего
                sendXray('upld-err-flrd-ld');
                // eslint-disable-next-line max-lines
                sendKaktamLog({
                    text: `uploader: no FileReader:${error}`,
                    error, // @ts-ignore
                    memoryAvailableGb: navigator?.deviceMemory,
                    // @ts-ignore
                    heapSize: performance?.memory?.jsHeapSizeLimit,
                    fileSize: this.descriptor.size,
                    id: this.descriptor.id,
                    pageId: X_PAGE_ID,
                });

                const stack = new Error('FileReaderError');
                const reason = new FileReaderError(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);

                return Promise.reject(reason);
            }

            // Для файлов размером меньше 21 байт,
            // вместо hash возвращаем байткод в шестнадцатеричном виде,
            // дополненный нулями до длины облачного hash (40 байт).

            let hexCode;
            try {
                if (error instanceof FileWithoutHashFail) {
                    const file = this.descriptor.file;

                    if (file?.size) {
                        hexCode = await getHexCode(file);
                    } else {
                        hexCode = EMPTY_FILE_HASH;
                    }
                } else {
                    throw error;
                }

                const data = this._getData();

                data.hash = hexCode;
                data.size = this.descriptor.file?.size ?? 0;

                this._done(data);
            } catch (error: any) {
                if (error.toString().includes('NotReadableError')) {
                    const stack = new Error('FileReaderError');
                    const reason = new FileReaderError(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);

                    return Promise.reject(reason);
                }

                const data = this._getData();
                data.error = error;
                this._fail(data);

                throw error;
            }
        }
    };

    async calculateHash(file: File, size: number): Promise<string> {
        // @ts-ignore
        this.handleProgress({ progress: 0, loaded: 1 });

        let hashLib = EHashLibType.js;

        // На файле больше 16ГБ примерно падает ошибка, по памяти - надо потом чинить
        if (isHashCalcLibWasm && isWasmSupported && (!TEMP_MAX_FILESIZE_FOR_WASM_LIB || size <= TEMP_MAX_FILESIZE_FOR_WASM_LIB)) {
            hashLib = EHashLibType.wasm;
        }
        if (isHashCalcLibCrypto && size <= FILE_READ_BLOCK_LENGTH) {
            hashLib = EHashLibType.cryptoApi;
        }

        const processHash = async (hashCalculator: HashCalculator, file: File, startIdx: number, stopIdx: number) => {
            const startRead = Date.now();

            return readFileContent(file, startIdx, stopIdx)
                .catch((err) => {
                    sendGaUploaderNew(`readfile_error${err.name ? `_${err.name}` : ''}`);
                    const stack = new Error('FileReaderError');
                    const reason = new FileReaderError(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);

                    return Promise.reject(reason);
                })
                .then((data) => {
                    this.stopTimeoutTimer();

                    this._metrics.diskReadTime += getPerfDiffInMs(startRead);

                    const startHash = Date.now();

                    if (!data) {
                        sendGaUploaderNew('error', 'readfile');
                        return;
                    }

                    return hashCalculator.addData(data, hashLib).then(() => {
                        this._metrics.hashCalcTime += getPerfDiffInMs(startHash);
                    });
                })
                .catch((err) => {
                    this.stopTimeoutTimer();
                    sendGaUploaderNew(`hashcalc_error${err.name ? `_${err.name}` : getTrimmedText(err.toString().toLowerCase(), 20)}`);
                    // @ts-ignore
                    sendKaktamLog({
                        text: `processHash hash calc exception`,
                        // @ts-ignore
                        // eslint-disable-next-line compat/compat
                        memoryAvailableGb: navigator?.deviceMemory,
                        // @ts-ignore
                        // eslint-disable-next-line compat/compat
                        heapSize: performance?.memory?.jsHeapSizeLimit,
                        fileSize: this.descriptor.size,
                        id: this.descriptor.id,
                        err,
                        pageId: X_PAGE_ID,
                    });
                    logger.error('hash calc exception', err);
                    const stack = new Error('HashCalcError');
                    const reason = new HashCalcError(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);
                    return Promise.reject(reason);
                });
        };

        const start = Date.now();

        const hashCalculator = new HashCalculator();
        hashCalculator.fileSize = size;
        this._timeOutStep = 'hashCalculator.init';
        await hashCalculator.init(hashLib);

        let hashInitTime = 0;
        const startIdx = 0;
        const lastIdx = file.size - 1;
        for (let idx = startIdx; idx <= lastIdx; idx += FILE_READ_BLOCK_LENGTH) {
            if (hashInitTime === 0) {
                hashInitTime += getPerfDiffInMs(start);
            }

            this._timeOutStep = 'readFileContent';

            if (this._isUserCanceled || this.descriptor.state === EUploadingState.STATE_CANCEL) {
                return '';
            }

            await processHash(hashCalculator, file, idx, Math.min(idx + FILE_READ_BLOCK_LENGTH - 1, lastIdx));
        }

        this._timeOutStep = 'hashCalculator.addData';
        await hashCalculator.addData(size.toString(), hashLib);

        this._timeOutStep = 'hashCalculator.getHash';
        const time = Date.now();
        const hash = await hashCalculator.getHash();
        const hashLast = getPerfDiffInMs(time);
        this._metrics.hashCalcTime += hashLast + hashInitTime;

        return hash;
    }

    upload = async () => {
        const fileSize = this.descriptor.file?.size || 0;

        this.promise = new Promise<UploaderData>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });

        await this._upload();

        let data: any = await this.promise;
        // Проверяет размер и hash файла после загрузки.
        const hasWrongSize = fileSize !== data.size;
        const hasWrongHash = fileSize && data?.hash === EMPTY_FILE_HASH;

        if (hasWrongSize || hasWrongHash) {
            // log initial size too
            data = {
                ...data,
                initialSize: fileSize,
            };

            const message = JSON.stringify(data);
            const source = EUploadReasonSource.SOURCE_BACKEND;
            const stack = new Error('WrongResultError');
            throw new WrongResultError(stack, source, message);
        }

        return data;
    };

    cancel() {
        const data = this._getData();
        const xhr = this._xhr;

        this._isUserCanceled = true;

        this.uploaderTotalProgressThrottled?.cancel();

        if (xhr) {
            const readyState = xhr.readyState;
            const isCancelable = isCancelableXhrState(readyState);

            if (isCancelable) {
                xhr.abort();
            } else if (readyState !== XMLHttpRequest.DONE) {
                this._onXhrAbort(null, data);
            }
        } else {
            this._axios?.cancel();
            this._onXhrAbort(null, data);
        }
    }

    _done(data: UploaderData) {
        this._resolve?.(data);
        this._destroy();
    }

    _fail(data) {
        if (this._reject) {
            this._reject(data);
            this._destroy();
        }
    }

    _destroy() {
        const xhr = this._xhr;

        if (xhr) {
            this._stopListeningXhr(xhr);

            if (isCancelableXhrState(xhr.readyState)) {
                xhr.abort();
            }

            this._xhr = null;
        }

        this._resolve = undefined;
        this._reject = undefined;
    }

    checkEnoughSpace(size) {
        const free = this.getFreeSpace();

        if (free < size) {
            const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
            const stack = new Error('OverQuotaFail');
            throw new OverQuotaFail(stack, source);
        }

        return free;
    }

    getFreeSpace() {
        if (IS_ONPREMISE) {
            if (this.descriptor.uploadingPacketConfig.isDomainFolder || this.descriptor.uploadingPacketConfig.hasParentDomainFolder) {
                return getDomainFolderQuotaFree(store.getState(), this.descriptor.uploadingPacketConfig.workingDirectory);
            }

            if (this.descriptor.uploadingPacketConfig.isMountedFolder) {
                return getMountedFolderQuotaFree(store.getState(), this.descriptor.uploadingPacketConfig.workingDirectory);
            }
        }

        const space = UserSelectors.getCloudSpace(store.getState());
        return space.free.original;
    }
}
