import { Icon24DownloadOutline } from '@vkontakte/icons';
import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
    DOWNLOAD_DISK_O_PROMO_CONFIG,
    ENABLE_FULL_RESPONSIVE,
    IS_BLOCKED,
    IS_DOCUMENTS_DOMAIN,
    IS_ONPREMISE,
    NEW_PORTAL_HEADER,
} from 'reactApp/appHelpers/configHelpers';
import { abSafeFakeDoorSelector, isAllDocumentsHelpButton, isHomeSidebar } from 'reactApp/appHelpers/featuresHelpers';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { HelpButton } from 'reactApp/components/HelpButton/HelpButton';
import { BREAKPOINT_MD } from 'reactApp/constants/breakpoints';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { usePortal } from 'reactApp/hooks/usePortal';
import { ActionPanelSelectors } from 'reactApp/modules/actionpanel/actionpanel.selectors';
import { isAlertForOverquota } from 'reactApp/modules/features/features.helpers';
import { changeHomeHistory } from 'reactApp/modules/home/home.actions';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage, selectStatusPage } from 'reactApp/modules/router/router.selectors';
import { sidebarToggle } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getIdByStorage, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { groupedIds, isRootCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getAllowedMimeTypes } from 'reactApp/modules/upload/upload.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ErrorPage } from 'reactApp/sections/ErrorPage/ErrorPage';
import { useMobileAppPromo } from 'reactApp/sections/MainPage/hooks/useMobileAppPromo';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { RootState } from 'reactApp/store';
import { EHeaderColor } from 'reactApp/ui/Header/HeaderWrapper';
import { OverquotaBanner } from 'reactApp/ui/OverquotaBanner/OverquotaBanner';
import { TreePromoMobileApp } from 'reactApp/ui/ReactViewer/TreePromoMobileApp/TreePromoMobileApp';
import { SAFE_FAKEDOOR_STORE_ID } from 'reactApp/ui/SafeFakedoorModal/SafeFakedoorModal.constants';
import { SidebarBanner } from 'reactApp/ui/SidebarBanner/SidebarBanner';
import { Space } from 'reactApp/ui/Space/Space';
import { AddToolbar } from 'reactApp/ui/Toolbar/AddToolbar';
import { ToolbarNew } from 'reactApp/ui/Toolbar/Toolbar.new';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';
import { TreeConnected } from 'reactApp/ui/TreeComponent/TreeComponent';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { loadFolder } from 'reactApp/ui/TreeComponent/TreeComponent.helpers';
import { TreePromo } from 'reactApp/ui/TreePromo/TreePromo';
import { TrialPromo } from 'reactApp/ui/TrialPromo/TrialPromo';
import { UploadBlock } from 'reactApp/ui/UploadBlock/UploadBlock';
import { UploadInput } from 'reactApp/ui/UploadInput/UploadInput';
import { ChevronLeftOutlineIcon } from 'reactApp/ui/VKUIIcons';
import { usePHSearchBlock } from 'reactApp/ui/WebSearch/helpers';
import { Worm } from 'reactApp/ui/Worm/Worm';
import { changeCloudToDocumentDomain } from 'reactApp/utils/documentsDomain';
import { createGaSender, sendGa as sendGaHelper } from 'reactApp/utils/ga';
import { scrollToTop } from 'reactApp/utils/helpers';
import opener from 'reactApp/utils/opener';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import { ESafeFakedoorAnalytics, safeFakedoorAnalytics } from 'reactApp/utils/safeFakedoorGa';

import { useDiskOTreePromo } from './hooks/useDiskOTreePromo';
import styles from './MainPage.css';

const sendGa = createGaSender('main');

interface Props {
    children: ReactElement | null;
    storage?: EStorageType;
    showUploader?: boolean;
    allowDnd?: boolean;
    renderWorm?: boolean;
    showToolbar?: boolean;
    showSpace?: boolean;
    showTree?: boolean;
    showBackHomeButton?: boolean;
}

export const MainPage = memo(
    // eslint-disable-next-line max-lines-per-function, complexity
    ({
        children,
        storage,
        showUploader = true,
        allowDnd = true,
        renderWorm = false,
        showToolbar = true,
        showSpace = true,
        showTree = true,
        showBackHomeButton = false,
    }: Props): ReactElement | null => {
        const dispatch = useDispatch();
        const location = useLocation();
        const params = useParams();
        const [largeLBreakpointHit] = useMinWidthBreakpoint(BREAKPOINT_MD);

        const isNewbie = useSelector(UserSelectors.isNewbie);
        const isAnonymous = useSelector(UserSelectors.isAnonymous);
        const isBizUser = useSelector(UserSelectors.isBizUser);
        const isOverquota = useSelector(UserSelectors.isOverQuotaUser);
        const isFrozen = useSelector(UserSelectors.isFrozen);
        const statusPage = useSelector(selectStatusPage);
        const isSidebarOpened = useSelector(SettingsSelectors.isSidebarOpened);
        const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');
        const isActionPanelOpened = useSelector(ActionPanelSelectors.isActionPanelOpened);

        const uploadPortalTarget = usePortal('uploadAreaRoot');
        const helpButtonPortalTarget = usePortal('helpButtonRoot');

        const { subscribeToInput, unsubscribeOfInput, processDrop } = useUploadInputHandlers();
        const leftBlockWrapperRef = useRef<HTMLDivElement | null>(null);

        const { showDiskOTreePromo, handleDiskOTreePromoShow, handleDiskOTreePromoClick, handleDiskOTreePromoClose } = useDiskOTreePromo();
        const { showMobileAppTreePromo, handleTreePromoMobileAppClick, handleTreePromoMobileAppClose } = useMobileAppPromo();

        const { isHome, isReactPage, isAttaches, isIntegration, isAllDocuments, isDocuments, isInlineIntegration } = getStorage(
            storage as EStorageType
        );

        const closeSidebarHandler = useCallback(() => dispatch(sidebarToggle(false)), [dispatch]);

        usePHSearchBlock();

        useEffect(() => {
            closeSidebarHandler();
        }, [largeLBreakpointHit, closeSidebarHandler]);

        const curStorage = useSelector(getCurrentStorage);
        const handleMenuClick = useCallback(
            ({ id, storage, href, level }) => {
                const domainBizFoldersSection = id === ETreeRootIds.domain && level === 0;
                const isAllDocuments = id === ETreeRootIds.alldocuments;

                if (isAllDocuments) {
                    sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'docs-from-menu' });
                    const path = `${changeCloudToDocumentDomain(window.location.origin)}?fromPage=${curStorage}`;
                    opener(path);
                    return;
                }

                if (domainBizFoldersSection) {
                    return;
                }

                if (
                    chooseVariant(
                        abSafeFakeDoorSelector,
                        { control: false, variant2: id === ETreeRootIds.safe },
                        { skipCondition: { variant1: true } }
                    )
                ) {
                    safeFakedoorAnalytics(ESafeFakedoorAnalytics.CLICK, { entity: 'chapter' });

                    openPopupHelper({
                        popupName: popupNames.SAFE_FAKEDOOR,
                    });

                    return;
                }

                const { isReactPage, isStart, isHome, isIntegration } = getStorage(storage);

                if (isReactPage || isStart) {
                    if (isHome || isIntegration || isInlineIntegration) {
                        // для storage === integration || inline-integration критически важно не терять гет параметры при навигации
                        dispatch(changeHomeHistory({ id, search: location.search }));
                    } else {
                        dispatch(historyPush({ id }));
                    }
                    return;
                }

                const homeItemId = (storage === EStorageType.home && id) || '';
                const fullPath = `/${storage}${homeItemId}${location.search ?? ''}`;

                window.location = href || fullPath;
            },
            [location, curStorage]
        );

        const handleOnMenuExpand = useCallback(({ id, storage, dispatch }) => {
            loadFolder({ id, storage, dispatch });
        }, []);

        useEffect(() => {
            scrollToTop();

            sendGa('show');
            sendGaHelper('lm', 'show');
        }, []);

        useRouteChangeProcessing({ storage, path: location.pathname, search: location.search });

        useEffect(() => {
            if (
                isReactPage &&
                !isHome &&
                !isIntegration &&
                !isInlineIntegration &&
                !isAttaches &&
                !isAllDocuments &&
                (isAnonymous || isFrozen || isNewbie) &&
                !IS_DOCUMENTS_DOMAIN
            ) {
                open(isAnonymous ? '/promo' : '/home', '_self');
            }
        }, [isFrozen, isAnonymous, storage, isReactPage, isHome, isAttaches, isIntegration, isAllDocuments, isNewbie]);

        useEffect(() => {
            const classes = classNames(styles.container, {
                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
            }).split(' ');
            uploadPortalTarget.classList.remove(...classes);
            if (isActionPanelOpened) {
                uploadPortalTarget.classList.add(...classes);
            }
        }, [uploadPortalTarget, isActionPanelOpened]);

        const leftBlock = useMemo(
            () => (
                <div
                    className={classNames(styles.leftBlock, {
                        [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                    })}
                >
                    {showToolbar && (
                        <div
                            className={classNames(styles.toolbar, {
                                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                                [styles.toolbar_new_portal_header]: NEW_PORTAL_HEADER,
                            })}
                        >
                            {ENABLE_FULL_RESPONSIVE && (
                                <ToolbarItem
                                    id="toolbar-collapse-item"
                                    text="Свернуть"
                                    icon={<ChevronLeftOutlineIcon />}
                                    onClick={closeSidebarHandler}
                                />
                            )}
                            <AddToolbar />
                        </div>
                    )}
                    {showBackHomeButton && (
                        <div className={styles.backButtonBlock}>
                            <RouterLink to={getIdByStorage(EStorageType.home)} reloadDocument>
                                <ToolbarItem className={styles.backButton} id="back" text="Вернуться" icon={<ChevronLeftOutlineIcon />} />
                            </RouterLink>
                        </div>
                    )}

                    {showSpace && <Space className={classNames({ [styles.spacePromo_new_portal_header]: NEW_PORTAL_HEADER })} />}
                    <TrialPromo text="+ 1 ТБ на месяц бесплатно" href={DOWNLOAD_DISK_O_PROMO_CONFIG?.treePromoLink} />
                    {showDiskOTreePromo && DOWNLOAD_DISK_O_PROMO_CONFIG?.treePromoLink && (
                        <TreePromo
                            text="Скачать Облако для ПК"
                            icon={<Icon24DownloadOutline />}
                            href={DOWNLOAD_DISK_O_PROMO_CONFIG?.treePromoLink}
                            onShow={handleDiskOTreePromoShow}
                            onClick={handleDiskOTreePromoClick}
                            onClose={handleDiskOTreePromoClose}
                        />
                    )}
                    {/* если не отображаем ничего из вышеперечисленного, то хотя бы добавим отступ */}
                    {!showToolbar && !showSpace && !showDiskOTreePromo && <Spacing size={24} />}
                    {showTree && (
                        <div
                            className={classNames(styles.treeWrapper, {
                                [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                                [styles.treeWrapper_new_portal_header]: NEW_PORTAL_HEADER,
                            })}
                        >
                            <TreeConnected
                                key={storeHelper.getValue(SAFE_FAKEDOOR_STORE_ID)}
                                onClick={handleMenuClick}
                                onExpand={handleOnMenuExpand}
                                gaSuffix="sp"
                                search={location?.search}
                            />
                        </div>
                    )}
                    {showMobileAppTreePromo && (
                        <TreePromoMobileApp onClick={handleTreePromoMobileAppClick} onClose={handleTreePromoMobileAppClose} />
                    )}
                </div>
            ),
            [
                location?.search,
                storage,
                handleMenuClick,
                showDiskOTreePromo,
                handleDiskOTreePromoShow,
                handleDiskOTreePromoClick,
                handleDiskOTreePromoClose,
                closeSidebarHandler,
                storeHelper.getValue(SAFE_FAKEDOOR_STORE_ID),
            ]
        );

        const handleLeftBlockWrapperOverlayClick = useCallback(
            (evt) => {
                if (ENABLE_FULL_RESPONSIVE && leftBlockWrapperRef.current === evt.target) {
                    closeSidebarHandler();
                }
            },
            [closeSidebarHandler]
        );

        const items = useSelector((state: RootState) => groupedIds(state, storage as EStorageType, false)) as string[];
        const isRootFolder = useSelector((state: RootState) => isRootCurrentFolder(state, storage));

        const hideToolbar = (items.length === 0 && isHome && isRootFolder) || (isDocuments && !params.documentType) || isInlineIntegration;

        if (isFrozen) {
            return null;
        }

        if (statusPage) {
            return <ErrorPage status={statusPage} />;
        }

        const isAdWorm = renderWorm && !isHomeSidebar;
        const isAdSidebar = renderWorm && isHomeSidebar;

        const showOverquotaBanner = Boolean(isOverquota && isAlertForOverquota && !isBizUser && !IS_ONPREMISE);

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_new_portal_header]: NEW_PORTAL_HEADER,
                    [styles.alignLeft]: isInlineIntegration,
                    [styles.root_sidebar_banner]: isAdSidebar,
                })}
            >
                {!IS_BLOCKED && !isInlineIntegration && (
                    <div
                        ref={leftBlockWrapperRef}
                        onClick={handleLeftBlockWrapperOverlayClick}
                        className={classNames(styles.leftBlockWrapper, {
                            [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                            [styles.visible]: isSidebarOpened,
                        })}
                    >
                        {leftBlock}
                    </div>
                )}
                {!IS_BLOCKED && (
                    <div
                        className={classNames(styles.rightBlock, {
                            [styles.responsive]: ENABLE_FULL_RESPONSIVE,
                            [styles.rightBlock_new_portal_header]: NEW_PORTAL_HEADER,
                        })}
                    >
                        {showOverquotaBanner && <OverquotaBanner />}
                        <div className={styles.rightBlockContent}>
                            {isAdWorm && (
                                <div className={styles.wormWrapper}>
                                    <Worm />
                                </div>
                            )}
                            {NEW_PORTAL_HEADER && !hideToolbar && (
                                <div className={styles.toolbarWrap}>
                                    <ToolbarNew color={EHeaderColor.WHITE} />
                                </div>
                            )}
                            {NEW_PORTAL_HEADER ? <div className={styles.rightBlockContentWrapper}>{children}</div> : children}
                        </div>
                    </div>
                )}
                {!IS_BLOCKED && isAdSidebar && <SidebarBanner />}

                {!IS_BLOCKED &&
                    createPortal(
                        <UploadBlock
                            showDropArea={showUploader}
                            allowDnd={allowDnd}
                            subscribeToInput={subscribeToInput}
                            unsubscribeOfInput={unsubscribeOfInput}
                            processDrop={processDrop}
                            gaCategory="fast"
                            containerElement={uploadPortalTarget}
                        />,
                        uploadPortalTarget
                    )}

                {isAllDocuments && isAllDocumentsHelpButton && createPortal(<HelpButton storage={storage} />, helpButtonPortalTarget)}

                <UploadInput allowedMimeTypes={allowedMimeTypes} />
            </div>
        );
    }
);

MainPage.displayName = 'MainPage';

MainPage.whyDidYouRender = true;
